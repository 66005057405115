import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormFeedback,
} from "reactstrap";
import Loader from "../../components/Common/Loader";
import { getApiData, putApiData } from "../../helpers/axiosHelper";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import Select from "react-select";
import { agentList } from "../../common/data/roles";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import SelectStyle from "../../common/data/SelectStyle";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import { mobileNumberValidator } from "../../common/data/commonfunctions";
import ShowHidePassword from "../Authentication/ShowHidePassword";

const ViewAgentDetails = ({
  isOpen,
  toggle,
  agentUid,
  getAgents,
  editMode,
}) => {
  const [agentData, setAgentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    if (isOpen && agentUid) {
      fetchAgentData(agentUid);
    }
  }, [isOpen, agentUid]);

  const fetchAgentData = async (agentUid) => {
    try {
      setLoading(true);
      const response = await getApiData(
        `api/AgentUser/GetAgentView?AgentID=${agentUid}`
      );
      setAgentData(response);
    } catch (error) {
      console.error("Error fetching agent data:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: agentData?.firstName || "",
      lastName: agentData?.lastName || "",
      middleName: agentData?.middleName || "",
      email: agentData?.email || "",
      mobile: agentData?.mobile || "",
      roleId: agentData?.roleId || "",
      companyId: agentData?.agentCompanyId || "",
      password: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only letters")
        .required("Please Enter First Name"),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name should contain only letters")
        .required("Please Enter Last Name"),
      middleName: Yup.string(),
      email: Yup.string()
        .email("Invalid Email Address")
        .required("Please Enter Email"),
      mobile: Yup.string()
        .matches(
          new RegExp(
            `^\\+?\\d{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
          ),
          `Invalid Mobile Number`
        )
        .required("Please Enter Mobile"),
      password: Yup.string().matches(
        /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
        "Password must be at least 8 characters and must contain at least one digit, one uppercase letter, and one special character"
      ),
      confirmpassword: Yup.string().oneOf(
        [Yup.ref("password")],
        "Passwords must match"
      ),
    }),
    onSubmit: async (values) => {
      const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

      if (result.isConfirmed) {
        if (JSON.stringify(values) === JSON.stringify(formik.initialValues)) {
          toast.info("No changes to save", {
            position: "top-right",
            autoClose: 3000,
          });
          return;
        }

        const combinedValues = {
          ...values,
          agentUid,
          userName: values.email,
          status: agentData?.userStatus,
        };

        if (combinedValues.email === formik.initialValues.email) {
          delete combinedValues.email;
        }
        if (combinedValues.mobile === formik.initialValues.mobile) {
          delete combinedValues.mobile;
        }

        try {
          setLoading(true);
          const updatedResponse = await putApiData(
            `api/AgentUser/UpdateAgent`,
            combinedValues
          );

          if (updatedResponse?.success) {
            toast.success("Updated Successfully", {
              position: "top-right",
              autoClose: 3000,
            });
            toggle();
            getAgents();
          } else {
            toast.error(updatedResponse?.message || "Update Failed", {
              position: "top-right",
              autoClose: 2000,
            });
          }
        } catch (error) {
          console.error("Error updating Agent data:", error);
        } finally {
          setLoading(false);
        }
      }
    },
  });
  const handleRoleChange = (selectedOption) => {
    formik.setFieldValue("roleId", selectedOption.value);
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} centered toggle={toggle}>
        <ModalHeader toggle={toggle}>Agent Details</ModalHeader>
        <ModalBody>
          {loading ? (
            <Loader />
          ) : (
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="firstName">First Name</Label>
                    <RequiredAsterisk />
                    <Input
                      name="firstName"
                      type="text"
                      id="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={editMode}
                      invalid={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <FormFeedback>{formik.errors.firstName}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="middleName">Middle Name</Label>
                    <Input
                      name="middleName"
                      type="text"
                      placeholder="Enter the Middle Name"
                      id="middleName"
                      value={formik.values.middleName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={editMode}
                      invalid={
                        formik.touched.middleName && formik.errors.middleName
                      }
                    />
                    {formik.touched.middleName && formik.errors.middleName && (
                      <FormFeedback>{formik.errors.middleName}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="lastName">Last Name</Label>
                    <RequiredAsterisk />
                    <Input
                      name="lastName"
                      type="text"
                      id="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={editMode}
                      invalid={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <FormFeedback>{formik.errors.lastName}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="email">Email</Label>
                    <RequiredAsterisk />
                    <Input
                      name="email"
                      type="email"
                      id="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={editMode}
                      invalid={formik.touched.email && formik.errors.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <FormFeedback>{formik.errors.email}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="mobile">Mobile</Label>
                    <RequiredAsterisk />
                    <Input
                      name="mobile"
                      type="text"
                      id="mobile"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={editMode}
                      invalid={formik.touched.mobile && formik.errors.mobile}
                    />
                    {formik.touched.mobile && formik.errors.mobile && (
                      <FormFeedback>{formik.errors.mobile}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Label for="roleId">Role</Label>
                    <Select
                      id="roleId"
                      value={agentList.find(
                        (agent) => agent.value === formik.values.roleId
                      )}
                      onChange={handleRoleChange}
                      options={agentList}
                      isDisabled={editMode}
                      styles={SelectStyle}
                    />
                    {formik.touched.roleId && formik.errors.roleId && (
                      <div className="invalid-feedback d-block">
                        {formik.errors.roleId}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="password">Password</Label>
                    <div className="input-group">
                      <Input
                        name="password"
                        placeholder="********"
                        type={isPasswordVisible ? "text" : "password"}
                        id="password"
                        disabled={editMode}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <ShowHidePassword
                        isPasswordVisible={isPasswordVisible}
                        toggleVisibility={() =>
                          setIsPasswordVisible(!isPasswordVisible)
                        }
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="confirmpassword">Confirm Password</Label>
                    <div className="input-group">
                      <Input
                        name="confirmpassword"
                        placeholder="********"
                        type={isPasswordVisible ? "text" : "password"}
                        id="confirmpassword"
                        disabled={editMode}
                        value={formik.values.confirmpassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <ShowHidePassword
                        isPasswordVisible={isPasswordVisible}
                        toggleVisibility={() =>
                          setIsPasswordVisible(!isPasswordVisible)
                        }
                      />
                      {formik.errors.confirmpassword ? (
                        <FormFeedback type="invalid">
                          {formik.errors.confirmpassword}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="text-end">
                  {!editMode && (
                    <Button
                      type="submit"
                      color="success"
                      onClick={formik.handleSubmit}
                    >
                      Update
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </ModalBody>
      </Modal>
      <ToastContainer limit={1} closeButton={false} />
    </React.Fragment>
  );
};

export default ViewAgentDetails;
