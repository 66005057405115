import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import RequiredAsterisk from "../../../components/Common/RequiredAsterisk";
import ReactSelect from "react-select";
import SelectStyle from "../../../common/data/SelectStyle";
import {
  getAgentBeneficiaryByCompanyId,
  getCustomerBeneficiaryByCustomerId,
  toastError,
  toggleMode,
  useCustomer,
} from "../../../common/data/commonfunctions";
import CustomLoader from "../../../components/Common/CustomLoader";
import CustomerBeneficiaryModal from "../../ManageUser/CustomerBeneficiary/CustomerBeneficiaryModal";
import { TransactionType } from "./../../../common/data/transactionType";
import ButtonSpinner from "../../../components/Common/ButtonSpinner";
import { debounce } from "lodash";
import {
  baseCurrency,
  displayDigits,
  displayFlag,
  getCurrencyFlag,
  rateDigits,
} from "../../../common/data/currency";
import { useLatestTransactions } from "../../../common/data/latest-transaction-context";
import AddCustomer from "../../Dashboard_Admin/ManageUser/Components/AddCustomer";

const BookingForm = (props) => {
  const {
    addTransactionValidation,
    state,
    setState,
    isLoading,
    companyOptions,
    getAgentsByCompany,
    handleUserType,
    customActiveTab,
    findCurrRate,
    currencyRate,
    findMainCurrRate,
    SGD,
  } = props;

  let newConversionRate = parseFloat(currencyRate);

  const { allCurrencies } = useLatestTransactions();
  const { customerList, delayGetCustomer, loading } = useCustomer();
  const transfer3rdScenario =
    customActiveTab === TransactionType.Transfer &&
    addTransactionValidation.values.fromCurrType?.label !==
      addTransactionValidation.values.toCurrType?.label &&
    addTransactionValidation.values.fromCurrType?.label !== baseCurrency &&
    addTransactionValidation.values.toCurrType?.label !== baseCurrency;

  const transfer1stScenario =
    customActiveTab === TransactionType.Transfer &&
    addTransactionValidation.values.fromCurrType?.label === baseCurrency;

  const transfer2ndScenario =
    customActiveTab === TransactionType.Transfer &&
    addTransactionValidation.values.toCurrType?.label === baseCurrency;

  const [viewModal, setViewModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const toggleViewDetails = () => setViewModal(!viewModal);
  const toggleAddUser = () => setAddModal(!addModal);

  const [benState, setBenState] = useState({
    beneficiaries: [],
    bankData: null,
    custData: null,
    docData: [],
    addressData: null,
    modal: false,
    customerId: null,
    UId: null,
  });

  const setRateHistoryId = (field, value) => {
    addTransactionValidation.setFieldValue(field, value);
  };

  const toggleModal = () => {
    setBenState((prev) => ({
      ...prev,
      modal: !prev.modal,
    }));
  };

  const getBeneficiaryByCustomerId = async (customerId) => {
    const response = await getCustomerBeneficiaryByCustomerId(customerId);
    if (Array.isArray(response)) {
      const filteredActiveBen = response.filter(
        (b) => b.active === "Active" && b.status === "Completed"
      );
      setState((prevState) => ({
        ...prevState,
        beneficiaryOptions: filteredActiveBen,
      }));
    }
  };

  const findCharges = useCallback(
    (currency) => {
      if (
        !currency ||
        (customActiveTab !== TransactionType.Transfer &&
          customActiveTab !== TransactionType.Buy &&
          customActiveTab !== TransactionType.Sell)
      ) {
        return 0;
      }

      let currencyData;

      if (customActiveTab === TransactionType.Transfer) {
        currencyData = state.currencyListTransfer.find(
          (x) => x.label === currency
        );
      } else if (customActiveTab === TransactionType.Buy) {
        currencyData = state.currencyListSell.find((x) => x.label === currency);
      } else if (customActiveTab === TransactionType.Sell) {
        currencyData = state.currencyListBuy.find((x) => x.label === currency);
      }

      const wireTransferFee = currencyData?.wireTransferFee ?? 0;
      const commissionCharges = currencyData?.commissionCharges ?? 0;
      const totalFees = currencyData?.totalFees ?? 0;
      const transferRate = currencyData?.transferRate ?? 0;
      const totalFeesAgent = currencyData?.totalFeesAgent ?? 0;
      const agentCharges = currencyData?.agentCharges ?? 0;

      return {
        wireTransferFee,
        commissionCharges,
        totalFees,
        transferRate,
        totalFeesAgent,
        agentCharges,
      };
    },
    [
      state.currencyListTransfer,
      state.currencyListBuy,
      state.currencyListSell,
      customActiveTab,
    ]
  );

  const {
    wireTransferFee,
    commissionCharges,
    totalFees,
    transferRate,
    agentCharges,
  } = findCharges(
    customActiveTab === 2 || customActiveTab === 1
      ? addTransactionValidation.values.fromCurrType?.label
      : addTransactionValidation.values.toCurrType?.label
  );

  const transferCommissionCharges = () => {
    if (transfer3rdScenario) {
      const buyRate = findMainCurrRate(
        addTransactionValidation.values.fromCurrType?.label,
        TransactionType.Buy
      );

      const convCommission = commissionCharges / buyRate;
      const convAgentCharges = agentCharges / buyRate;
      const convWireFee = wireTransferFee / buyRate;

      return (
        parseFloat(convAgentCharges) +
        parseFloat(convCommission) +
        parseFloat(convWireFee)
      );
    }
    return totalFees;
  };

  const totalFeeAmt = transferCommissionCharges();

  let totalAmtConvert =
    parseFloat(addTransactionValidation.values.fromCurrValue) -
    (customActiveTab === TransactionType.Transfer ? totalFeeAmt : agentCharges);

  const isTransactionValid =
    addTransactionValidation.values.fromCurrType?.label !== null &&
    addTransactionValidation.values.fromCurrType &&
    addTransactionValidation.values.toCurrType &&
    addTransactionValidation.values.toCurrType?.label !== null;

  const validStock =
    customActiveTab === 1
      ? state.fromCurrTypeStock !== "" && state.fromCurrTypeStock !== 0
      : true;

  const isAmoutValid =
    addTransactionValidation.values.fromCurrValue !== "" &&
    addTransactionValidation.values.toCurrValue !== "" &&
    addTransactionValidation.values.fromCurrValue !== "NaN" &&
    addTransactionValidation.values.toCurrValue !== "NaN";

  const checkCurrRate = (tab) => {
    switch (tab) {
      case 2: {
        const result = state.currencyListBuy?.find(
          (c) => c.label === addTransactionValidation.values.fromCurrType?.label
        );
        return result !== null && result !== undefined ? true : false;
      }
      case 1: {
        const result = state.currencyListSell?.find(
          (c) => c.label === addTransactionValidation.values.fromCurrType?.label
        );

        return result !== null && result !== undefined ? true : false;
      }
      case 3: {
        const result = state.currencyListTransfer?.find(
          (c) => c.label === addTransactionValidation.values.fromCurrType?.label
        );

        return result !== null && result !== undefined ? true : false;
      }
    }
  };

  const validAmount =
    addTransactionValidation.values.fromCurrValue !== "" &&
    addTransactionValidation.values.toCurrValue !== "";

  const extractCurrType =
    addTransactionValidation.values.toCustBenId?.currency ??
    addTransactionValidation.values.toCompanyBenId?.currency
      ? addTransactionValidation.values.toCustBenId?.currency
          .split("(")[1]
          ?.split(")")[0] ??
        addTransactionValidation.values.toCompanyBenId?.currency
          .split("(")[1]
          ?.split(")")[0]
      : null;

  const tabCondition = customActiveTab === 1 || customActiveTab === 2;

  const getBen = async (customerId) => {
    const response = await getCustomerBeneficiaryByCustomerId(customerId);
    if (Array.isArray(response)) {
      const filteredActiveBen = response?.filter(
        (b) => b.active === "Active" && b.status === "Completed"
      );

      setState((prevState) => ({
        ...prevState,
        beneficiaryOptions: filteredActiveBen,
      }));
    }
  };
  const getAgentBen = async (companyId) => {
    const response = await getAgentBeneficiaryByCompanyId(companyId);
    if (Array.isArray(response)) {
      const filteredActiveBen = response?.filter(
        (b) => b.active === 1 && b.status === "Completed"
      );
      setState((prevState) => ({
        ...prevState,
        agentBeneficiaryOptions: filteredActiveBen,
      }));
    }
  };

  useEffect(() => {
    if (customActiveTab === 3) {
      if (
        (addTransactionValidation.values.toCustBenId !== null ||
          addTransactionValidation.values.toCompanyBenId !== null) &&
        (addTransactionValidation.values.fromCurrType !== null ||
          addTransactionValidation.values.fromCurrType !== undefined ||
          addTransactionValidation.values.fromCurrType !== "")
      ) {
        const currency = state.currencyListTransfer.find(
          (opt) => opt.label === extractCurrType
        );

        addTransactionValidation.setFieldValue("toCurrType", {
          label: extractCurrType,
          value: extractCurrType,
          currencyValue: currency?.currencyValue,
        });
        setRateHistoryId("toRateHistoryId", currency?.rateHistoryId);
      } else {
        setState((prev) => ({
          ...prev,
          amtErr: "Active Ratesheet not available for the selected currency",
        }));
      }
    }
  }, [
    addTransactionValidation.values.toCustBenId,
    addTransactionValidation.values.toCompanyBenId,
  ]);

  const handleCustChange = async (selectedOption) => {
    addTransactionValidation.setFieldValue("fromCustomerId", selectedOption);
    setBenState((prev) => ({
      ...prev,
      customerId: selectedOption?.customerId,
      UId: selectedOption?.UId,
    }));
    if (customActiveTab === 1 || customActiveTab === 2) {
      addTransactionValidation.setFieldValue("fromCurrValue", "");
      addTransactionValidation.setFieldValue("toCurrValue", "");
      addTransactionValidation.setFieldValue("fromCurrType", "");
    }
    if (customActiveTab === 3) {
      addTransactionValidation.setFieldValue("toCustBenId", null);
      addTransactionValidation.setFieldValue("fromCurrType", null);
      addTransactionValidation.setFieldValue("toCurrType", null);
      addTransactionValidation.setFieldValue("toCurrValue", "");
      addTransactionValidation.setFieldValue("fromCurrValue", "");
      if (selectedOption?.value) {
        await getBeneficiaryByCustomerId(selectedOption.value);
      }
    }
    if (selectedOption?.value === 0) {
      setState((prevState) => ({
        ...prevState,
        showAddCustomer: true,
      }));
    }
  };

  const handleBenChange = (selectedOption) => {
    addTransactionValidation.setFieldValue("toCustBenId", selectedOption);
    addTransactionValidation.setFieldValue("fromCurrType", null);
    if (customActiveTab === 3) {
      addTransactionValidation.setFieldValue("toCurrType", null);
      addTransactionValidation.setFieldValue("toCurrValue", "");
      addTransactionValidation.setFieldValue("fromCurrValue", "");
    }
  };

  const handlePrecision = (value) => {
    return Math.round((value + Number.EPSILON) * 1000000000) / 1000000000;
  };

  const formatToTwoDecimals = (value) => {
    if (value !== "" && value !== undefined) {
      const numValue = parseFloat(value);
      return isNaN(numValue) ? "" : Number(numValue).toFixed(2);
    }
    return "";
  };

  const calculateConvertedToValue = debounce((e) => {
    const value = parseFloat(
      e?.target?.value ?? addTransactionValidation.values.fromCurrValue
    );
    setState((prevState) => ({
      ...prevState,
      amtErr:
        totalFeeAmt > value
          ? "Amount should be greater than Total Fee Amount"
          : null,
    }));

    let convertedRate;
    if (transfer3rdScenario) {
      convertedRate = (value - totalFeeAmt) * newConversionRate;
    } else if (transfer1stScenario) {
      convertedRate = (value - totalFeeAmt) / newConversionRate;
    } else if (transfer2ndScenario) {
      convertedRate = value * newConversionRate - totalFeeAmt;
    } else {
      convertedRate =
        customActiveTab === TransactionType.Buy
          ? (value - totalFeeAmt) / newConversionRate
          : value * newConversionRate - totalFeeAmt;
    }

    const result = handlePrecision(convertedRate);

    addTransactionValidation.setFieldValue(
      "toCurrValue",
      result > 0 ? result : 0
    );
    setState((prevState) => ({
      ...prevState,
      displayToCurrValue: result > 0 ? formatToTwoDecimals(result) : "0.00",
      negativeError: result < 0 ? "Enter a higher value" : null,
    }));
  }, 0);

  const calculateConvertedFromValue = debounce((e) => {
    const value = parseFloat(
      e?.target?.value ?? addTransactionValidation.values.toCurrValue
    );

    if (isNaN(value) || value === "") {
      return;
    }
    let convertedRate;

    if (transfer3rdScenario) {
      convertedRate = value / newConversionRate + totalFeeAmt;
    } else if (transfer1stScenario) {
      convertedRate = value * newConversionRate + totalFeeAmt;
    } else if (transfer2ndScenario) {
      convertedRate = (value + totalFeeAmt) / newConversionRate;
    } else {
      convertedRate =
        customActiveTab === TransactionType.Buy
          ? value * newConversionRate + totalFeeAmt
          : (value + totalFeeAmt) / newConversionRate;
    }

    const result = handlePrecision(convertedRate);

    addTransactionValidation.setFieldValue("fromCurrValue", result);
    setState((prevState) => ({
      ...prevState,
      amtErr:
        totalFeeAmt > result
          ? "Amount should be greater than Total Fee Amount"
          : null,
      displayFromCurrValue: formatToTwoDecimals(result),
    }));
  }, 0);

  const findRate = () => {
    const discountedRate =
      addTransactionValidation.values.discountedRate !== "" ? "" : null;
    let rate;

    if (customActiveTab == 1 || customActiveTab === 2) {
      rate = discountedRate
        ? discountedRate
        : parseFloat(
            addTransactionValidation.values.fromCurrType?.currencyValue
          ) ||
          parseFloat(addTransactionValidation.values.toCurrType?.currencyValue);
    } else {
      if (addTransactionValidation.values.fromCurrType?.label === SGD.label) {
        rate = discountedRate
          ? discountedRate
          : findCurrRate(
              addTransactionValidation.values.toCurrType?.label,
              TransactionType.Transfer
            );
      } else if (
        addTransactionValidation.values.toCurrType?.label === SGD.label
      ) {
        rate = discountedRate
          ? discountedRate
          : findMainCurrRate(
              addTransactionValidation.values.fromCurrType?.label,
              TransactionType.Buy
            );
      } else {
        const buyRate = findMainCurrRate(
          addTransactionValidation.values.fromCurrType?.label,
          TransactionType.Buy
        );

        const transferRate = findCurrRate(
          addTransactionValidation.values.toCurrType?.label,
          TransactionType.Transfer
        );
        rate = discountedRate ? "" : buyRate / transferRate;
      }
    }

    return rate ?? 0;
  };

  let Rate = findRate();

  const calculateConvertedDiscountValue = debounce((e) => {
    const inputValue = e.target.value;
    const discountedRate = inputValue === "" ? "" : parseFloat(inputValue);

    // if (!discountedRate) {
    //   if (customActiveTab === 1) return calculateConvertedFromValue();
    //   else if (customActiveTab === 2) return calculateConvertedToValue();
    //   else if (transfer3rdScenario) return calculateConvertedFromValue();
    //   else if (transfer2ndScenario) return calculateConvertedFromValue();
    //   else if (transfer1stScenario) return calculateConvertedToValue();
    // }

    let effectiveConversionRate = Rate;

    if (discountedRate && !isNaN(discountedRate)) {
      effectiveConversionRate = discountedRate;
    }

    if (
      isNaN(effectiveConversionRate) ||
      effectiveConversionRate === null ||
      effectiveConversionRate === ""
    ) {
      return;
    }

    const fcValue =
      customActiveTab === 1 || transfer1stScenario
        ? parseFloat(addTransactionValidation.values.fromCurrValue)
        : customActiveTab === 2 || transfer2ndScenario
        ? parseFloat(addTransactionValidation.values.fromCurrValue)
        : parseFloat(addTransactionValidation.values.fromCurrValue);

    let convertedRate;

    if (fcValue) {
      if (transfer3rdScenario) {
        convertedRate = fcValue * effectiveConversionRate - totalFeeAmt;
      } else if (transfer2ndScenario) {
        convertedRate = (fcValue - totalFeeAmt) / effectiveConversionRate;
      } else if (transfer1stScenario) {
        convertedRate = fcValue / effectiveConversionRate + totalFeeAmt;
      } else {
        convertedRate =
          customActiveTab === 1
            ? (fcValue - totalFeeAmt) / effectiveConversionRate
            : fcValue * effectiveConversionRate - totalFeeAmt;
      }
    }
    const result = handlePrecision(convertedRate);

    addTransactionValidation.setFieldValue("discountedRate", discountedRate);

    if (customActiveTab === 1) {
      setState((prevState) => ({
        ...prevState,
        displayFromCurrValue: !isNaN(fcValue)
          ? formatToTwoDecimals(fcValue)
          : null,
        displayToCurrValue: formatToTwoDecimals(result),
      }));
    } else if (customActiveTab === 2 || transfer2ndScenario) {
      setState((prevState) => ({
        ...prevState,
        displayFromCurrValue: !isNaN(fcValue)
          ? formatToTwoDecimals(fcValue)
          : null,
        displayToCurrValue: formatToTwoDecimals(result),
      }));
    }

    addTransactionValidation.setFieldValue(
      customActiveTab === 1
        ? "toCurrValue"
        : customActiveTab === 2
        ? "toCurrValue"
        : transfer3rdScenario
        ? "toCurrValue"
        : transfer2ndScenario
        ? "toCurrValue"
        : "toCurrValue",
      formatToTwoDecimals(result > 0 ? result : 0)
    );
  }, 0);

  const renderFromCurrency = () => {
    return customActiveTab === 1 ? (
      <Col md={6}>
        <FormGroup>
          <Label htmlFor="fromCurrType">Give Currency</Label>
          <RequiredAsterisk />
          <ReactSelect
            id="fromCurrType"
            placeholder="Select Currency..."
            aria-label="fromCurrType"
            styles={SelectStyle}
            options={
              customActiveTab === 1
                ? state.currencyListSell.filter(
                    (currency) =>
                      !addTransactionValidation.values.toCurrType ||
                      currency.label !==
                        addTransactionValidation.values.toCurrType.label
                  )
                : []
            }
            value={addTransactionValidation.values.fromCurrType}
            onChange={(selectedOption) => {
              addTransactionValidation.setFieldValue(
                "fromCurrType",
                selectedOption
              );
              addTransactionValidation.setFieldValue("fromCurrValue", "");
              addTransactionValidation.setFieldValue("toCurrValue", "");
              addTransactionValidation.setFieldValue("discountedRate", "");
              setState((prevState) => ({
                ...prevState,
                displayFromCurrValue: "",
              }));
              setState((prevState) => ({
                ...prevState,
                displayToCurrValue: "",
              }));
              setRateHistoryId(
                customActiveTab === 2 || customActiveTab === 3
                  ? "fromRateHistoryId"
                  : "toRateHistoryId",
                selectedOption?.rateHistoryId
              );
            }}
            invalid={
              addTransactionValidation.touched.fromCurrType &&
              addTransactionValidation.errors.fromCurrType
            }
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            formatOptionLabel={(option) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {option.currencyFlag ? displayFlag(option.currencyFlag) : null}
                {option.label}
              </div>
            )}
          />
          {isTransactionValid && customActiveTab === 1 && (
            <div className="text-danger mt-1">
              Stock:{" "}
              {state.fromCurrTypeStock !== ""
                ? state.fromCurrTypeStock
                : "No Stock"}
            </div>
          )}
          {addTransactionValidation.errors.fromCurrType &&
            addTransactionValidation.touched.fromCurrType && (
              <span className="text-danger" style={{ fontSize: "80%" }}>
                {addTransactionValidation.values.fromCurrType === null
                  ? "Please Select a Currency"
                  : addTransactionValidation.errors.fromCurrType}
              </span>
            )}
        </FormGroup>
      </Col>
    ) : customActiveTab === 2 ? (
      <Col md={6}>
        <FormGroup>
          <Label htmlFor="fromCurrType">Get Currency</Label>
          <RequiredAsterisk />
          <ReactSelect
            id="fromCurrType"
            aria-label="fromCurrType"
            placeholder="Select Currency..."
            styles={SelectStyle}
            options={
              customActiveTab === 2
                ? state.currencyListBuy.filter(
                    (currency) =>
                      !addTransactionValidation.values.toCurrType ||
                      currency.label !==
                        addTransactionValidation.values.toCurrType?.label
                  )
                : []
            }
            value={addTransactionValidation.values.fromCurrType}
            onChange={(selectedOption) => {
              addTransactionValidation.setFieldValue(
                "fromCurrType",
                selectedOption
              );
              addTransactionValidation.setFieldValue("fromCurrValue", "");
              addTransactionValidation.setFieldValue("toCurrValue", "");
              addTransactionValidation.setFieldValue("discountedRate", "");
              setState((prevState) => ({
                ...prevState,
                displayFromCurrValue: "",
              }));
              setState((prevState) => ({
                ...prevState,
                displayToCurrValue: "",
              }));
              setRateHistoryId(
                customActiveTab === 2 || customActiveTab === 3
                  ? "fromRateHistoryId"
                  : "toRateHistoryId",
                selectedOption?.rateHistoryId
              );
            }}
            invalid={
              addTransactionValidation.touched.fromCurrType &&
              addTransactionValidation.errors.fromCurrType
            }
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            formatOptionLabel={(option) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {option.currencyFlag ? displayFlag(option.currencyFlag) : null}
                {option.label}
              </div>
            )}
          />

          {addTransactionValidation.errors.fromCurrType &&
            addTransactionValidation.touched.fromCurrType && (
              <span className="text-danger" style={{ fontSize: "80%" }}>
                {addTransactionValidation.values.fromCurrType === null
                  ? "Please Select a Currency"
                  : addTransactionValidation.errors.fromCurrType}
              </span>
            )}
        </FormGroup>
      </Col>
    ) : (
      <Col md={6}>
        <FormGroup>
          <Label htmlFor="fromCurrType">Get Currency</Label>
          <RequiredAsterisk />
          <ReactSelect
            id="fromCurrType"
            aria-label="fromCurrType"
            placeholder="Select Currency..."
            styles={SelectStyle}
            options={
              customActiveTab === 3
                ? state.currencyListTransfer.filter(
                    (currency) =>
                      currency.label !==
                      addTransactionValidation.values.toCurrType?.label
                  )
                : []
            }
            value={addTransactionValidation.values.fromCurrType}
            onChange={(selectedOption) => {
              addTransactionValidation.setFieldValue(
                "fromCurrType",
                selectedOption
              );
              addTransactionValidation.setFieldValue("fromCurrValue", "");
              setState((prevState) => ({
                ...prevState,
                displayFromCurrValue: "",
              }));
              setState((prevState) => ({
                ...prevState,
                displayToCurrValue: "",
              }));
              addTransactionValidation.setFieldValue("discountedRate", "");
              addTransactionValidation.setFieldValue("toCurrValue", "");
              setRateHistoryId(
                customActiveTab === 2 || customActiveTab === 3
                  ? "fromRateHistoryId"
                  : "toRateHistoryId",
                selectedOption?.rateHistoryId
              );
            }}
            invalid={
              addTransactionValidation.touched.fromCurrType &&
              addTransactionValidation.errors.fromCurrType
            }
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            formatOptionLabel={(option) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {option.currencyFlag ? displayFlag(option.currencyFlag) : null}
                {option.label}
              </div>
            )}
          />
          {addTransactionValidation.errors.fromCurrType &&
            addTransactionValidation.touched.fromCurrType && (
              <span className="text-danger" style={{ fontSize: "80%" }}>
                {addTransactionValidation.values.fromCurrType === null
                  ? "Please Select a Currency"
                  : addTransactionValidation.errors.fromCurrType}
              </span>
            )}
        </FormGroup>
      </Col>
    );
  };

  const renderToCurrency = () => {
    const currencyCode = customActiveTab === 3 ? extractCurrType : "SGD";
    const currencyFlag = getCurrencyFlag(allCurrencies, currencyCode);
    const currencyStyle = {
      position: "absolute",
      left: "10px",
      top: "50%",
      transform: "translateY(-50%)",
      width: "20px",
      height: "20px",
    };

    return customActiveTab === 1 || customActiveTab === 2 ? (
      <Col md={6}>
        <FormGroup>
          <Label htmlFor="fromCurrType">
            {customActiveTab === 1
              ? "Get"
              : customActiveTab === 2
              ? "Give"
              : null}{" "}
            Currency
          </Label>
          <RequiredAsterisk />
          <div style={{ position: "relative" }}>
            <Input
              id="fromCurrType"
              aria-label="fromCurrType"
              value={currencyCode}
              placeholder={
                customActiveTab === 3
                  ? "No currency for this beneficiary"
                  : undefined
              }
              disabled={true}
              className="form-control"
              style={{
                paddingLeft: "33px",
              }}
            />
            {currencyFlag && (
              <img
                src={`data:image/png;base64,${currencyFlag}`}
                alt={`${currencyCode} flag`}
                style={currencyStyle}
              />
            )}
          </div>
        </FormGroup>
      </Col>
    ) : (
      <Col md={6}>
        <FormGroup>
          <Label htmlFor="toCurrType">Beneficiary Receives</Label>
          <RequiredAsterisk />
          <div style={{ position: "relative" }}>
            <Input
              id="toCurrType"
              aria-label="toCurrType"
              value={extractCurrType}
              placeholder={
                customActiveTab === 3
                  ? "No currency for this beneficiary"
                  : undefined
              }
              disabled={true}
              className="form-control"
              style={{
                paddingLeft: "33px",
              }}
            />
            {currencyFlag && (
              <img
                src={`data:image/png;base64,${currencyFlag}`}
                alt={`${currencyCode} flag`}
                style={currencyStyle}
              />
            )}
          </div>
        </FormGroup>
      </Col>
    );
  };

  const customDiscountCol =
    customActiveTab === 1 &&
    addTransactionValidation.values.userType === state.userType.agent
      ? 6
      : (customActiveTab === 2 &&
          addTransactionValidation.values.userType === state.userType.agent) ||
        (customActiveTab === 3 &&
          addTransactionValidation.values.userType === state.userType.customer)
      ? 6
      : 12;

  const renderFromToStock = () => {
    return (
      isTransactionValid &&
      customActiveTab !== TransactionType.Transfer &&
      !isNaN(newConversionRate) && (
        <>
          <Col md={customDiscountCol}>
            <FormGroup>
              <Label htmlFor="discountedRate">Current/Discounted Rate</Label>
              <Input
                id="discountedRate"
                aria-label="discountedRate"
                type="number"
                placeholder="Enter if any Rate Discount"
                style={{
                  appearance: "textfield",
                  MozAppearance: "textfield",
                  WebkitAppearance: "none",
                }}
                {...addTransactionValidation.getFieldProps("discountedRate")}
                onFocus={(e) => {
                  e.currentTarget.onwheel = (event) => event.preventDefault();
                }}
                onChange={(e) => {
                  addTransactionValidation.handleChange(e);
                  calculateConvertedDiscountValue(e);
                }}
              />
            </FormGroup>
          </Col>
          <style>
            {`
            #discountedRate::-webkit-outer-spin-button,
            #discountedRate::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          `}
          </style>
        </>
      )
    );
  };

  const renderAmount = () => {
    if (
      isNaN(newConversionRate) &&
      addTransactionValidation.values.fromCurrType &&
      addTransactionValidation.values.toCurrType &&
      customActiveTab === TransactionType.Transfer
    ) {
      return <div>No Rates Available</div>;
    }

    return (
      validStock &&
      isTransactionValid &&
      checkCurrRate(customActiveTab) && (
        <Col md={!tabCondition ? 12 : 12}>
          <FormGroup>
            <Label htmlFor="fromCurrValue">
              {customActiveTab === TransactionType.Buy
                ? addTransactionValidation.values.toCurrType?.label
                : addTransactionValidation.values.fromCurrType?.label}{" "}
              - Amount
              {customActiveTab === TransactionType.Sell &&
                (agentCharges === "" || parseFloat(agentCharges) === 0
                  ? ` : (Rate - ${
                      !isNaN(parseFloat(newConversionRate))
                        ? parseFloat(
                            parseFloat(newConversionRate).toFixed(rateDigits)
                          )
                        : "0.00"
                    })`
                  : "")}
            </Label>
            <RequiredAsterisk />
            <Input
              id="fromCurrValue"
              aria-label="fromCurrValue"
              type="text"
              placeholder={`Enter ${
                customActiveTab === TransactionType.Buy
                  ? addTransactionValidation.values.toCurrType?.label
                  : addTransactionValidation.values.fromCurrType?.label
              } Amount...`}
              value={
                state.displayFromCurrValue ??
                addTransactionValidation.values.fromCurrValue
              }
              onChange={(e) => {
                const value = e.target.value;
                setState((prevState) => ({
                  ...prevState,
                  displayFromCurrValue: value,
                }));
                addTransactionValidation.handleChange(e);
                calculateConvertedToValue(e);
              }}
              onBlur={addTransactionValidation.handleBlur}
              invalid={
                (addTransactionValidation.touched.fromCurrValue &&
                  addTransactionValidation.errors.fromCurrValue) ||
                (state.amtErr ? true : false)
              }
            />
            {addTransactionValidation.touched.fromCurrValue &&
            addTransactionValidation.errors.fromCurrValue ? (
              <FormFeedback type="invalid">
                {addTransactionValidation.errors.fromCurrValue}
              </FormFeedback>
            ) : null}
            {state.amtErr ? (
              <span className="text-danger" style={{ fontSize: "80%" }}>
                {state.amtErr ?? ""}
              </span>
            ) : null}
          </FormGroup>
        </Col>
      )
    );
  };

  const renderConvertedAmount = () => {
    if (
      isNaN(newConversionRate) &&
      addTransactionValidation.values.fromCurrType &&
      addTransactionValidation.values.toCurrType &&
      customActiveTab === TransactionType.Transfer
    ) {
      return;
    }

    return (
      validStock &&
      isTransactionValid &&
      checkCurrRate(customActiveTab) && (
        <Col md={!tabCondition ? 12 : 12}>
          <FormGroup>
            <Label htmlFor="toCurrValue">
              {customActiveTab === TransactionType.Buy
                ? addTransactionValidation.values.fromCurrType?.label
                : addTransactionValidation.values.toCurrType?.label}
              - Amount
              {customActiveTab === TransactionType.Buy &&
                (agentCharges === "" || parseFloat(agentCharges) === 0
                  ? ` : (Rate - ${
                      !isNaN(parseFloat(newConversionRate))
                        ? parseFloat(
                            parseFloat(newConversionRate).toFixed(rateDigits)
                          )
                        : "0.00"
                    })`
                  : "")}
            </Label>
            <RequiredAsterisk />
            <Input
              id="toCurrValue"
              aria-label="toCurrValue"
              type="text"
              placeholder={`Converted ${
                customActiveTab === TransactionType.Buy
                  ? addTransactionValidation.values.fromCurrType?.label
                  : addTransactionValidation.values.toCurrType?.label
              } Amount...`}
              value={
                state.displayToCurrValue ??
                addTransactionValidation.values.toCurrValue
              }
              onChange={(e) => {
                const value = e.target.value;
                setState((prevState) => ({
                  ...prevState,
                  displayToCurrValue: value,
                }));
                addTransactionValidation.handleChange(e);
                calculateConvertedFromValue(e);
              }}
              onBlur={addTransactionValidation.handleBlur}
              invalid={
                (addTransactionValidation.touched.toCurrValue &&
                  addTransactionValidation.errors.toCurrValue) ||
                (state.negativeError ? true : false)
              }
            />
            {addTransactionValidation.touched.toCurrValue &&
            addTransactionValidation.errors.toCurrValue ? (
              <FormFeedback type="invalid">
                {addTransactionValidation.errors.toCurrValue}
              </FormFeedback>
            ) : null}
            {state.negativeError && (
              <span className="text-danger" style={{ fontSize: "80%" }}>
                {state.negativeError ?? ""}
              </span>
            )}
          </FormGroup>
        </Col>
      )
    );
  };

  const renderUserType = () => {
    return (
      customActiveTab !== 3 && (
        <Col md={6}>
          <FormGroup>
            <Label htmlFor="userType">User Type</Label>
            <RequiredAsterisk />
            {[
              state.userType.onCounter,
              state.userType.customer,
              state.userType.agent,
            ].map((option) => (
              <div
                key={option}
                className="d-flex flex-sm-wrap flex-md-nowrap mb-2"
              >
                <Input
                  type="radio"
                  id={option}
                  name="userType"
                  aria-label="userType"
                  value={option}
                  className="me-1"
                  checked={addTransactionValidation.values.userType === option}
                  onChange={(e) => {
                    handleUserType(e);
                  }}
                  invalid={
                    addTransactionValidation.errors.userType &&
                    addTransactionValidation.touched.userType
                  }
                />
                <Label htmlFor={option} className="mb-0">
                  {option}
                </Label>
              </div>
            ))}
            {addTransactionValidation.errors.userType &&
              addTransactionValidation.touched.userType && (
                <span className="text-danger" style={{ fontSize: "80%" }}>
                  {addTransactionValidation.errors.userType}
                </span>
              )}
          </FormGroup>
        </Col>
      )
    );
  };

  const requiredFees = useMemo(() => {
    if (
      customActiveTab !== TransactionType.Transfer &&
      customActiveTab !== TransactionType.Buy &&
      customActiveTab !== TransactionType.Sell
    )
      return [];

    const currency =
      customActiveTab === TransactionType.Buy ||
      customActiveTab === TransactionType.Sell ||
      transfer2ndScenario
        ? addTransactionValidation.values.toCurrType?.label
        : addTransactionValidation.values.fromCurrType?.label;

    if (!currency) return [];

    if (customActiveTab === 1 || customActiveTab === 2) {
      if (agentCharges > 0) {
        return [
          {
            label: "Agent Charges",
            value: 3,
            icon: null,
            charge:
              parseFloat(parseFloat(agentCharges || 0).toFixed(displayDigits)) +
              " " +
              currency,
          },
          ...(customActiveTab === 1
            ? [
                {
                  label: "Total Amount we'll convert",
                  value: 5,
                  icon: "=",
                  charge:
                    parseFloat(
                      parseFloat(totalAmtConvert || 0).toFixed(displayDigits)
                    ) +
                    " " +
                    currency,
                },
              ]
            : []),
          {
            label: "Exchange Rate",
            value: 6,
            icon:
              transfer3rdScenario ||
              transfer2ndScenario ||
              customActiveTab === 2
                ? "×"
                : "÷",
            charge: newConversionRate.toFixed(rateDigits),
          },
        ];
      }
    }
    const buyRate = findMainCurrRate(
      addTransactionValidation.values.fromCurrType?.label,
      TransactionType.Buy
    );
    const convCommission = commissionCharges / buyRate;
    const convAgentCharges = agentCharges / buyRate;
    const convWireFee = wireTransferFee / buyRate;
    return [
      ...(transfer3rdScenario
        ? [
            {
              label: "Wire Transfer Fee",
              value: 1,
              icon: null,
              charge:
                parseFloat(
                  parseFloat(convWireFee || 0).toFixed(displayDigits)
                ) +
                " " +
                currency,
            },
          ]
        : [
            {
              label: "Wire Transfer Fee",
              value: 1,
              icon: null,
              charge:
                parseFloat(
                  parseFloat(wireTransferFee || 0).toFixed(displayDigits)
                ) +
                " " +
                currency,
            },
          ]),

      ...(transfer3rdScenario
        ? [
            {
              label: "Commission Charges",
              value: 2,
              icon: null,
              charge: `${
                !isNaN(convCommission)
                  ? parseFloat(
                      parseFloat(convCommission).toFixed(displayDigits)
                    )
                  : 0
              } ${currency}`,
            },
          ]
        : [
            {
              label: "Commission Charges",
              value: 2,
              icon: null,
              charge:
                (isNaN(parseFloat(commissionCharges)) || !currency
                  ? 0
                  : parseFloat(
                      parseFloat(commissionCharges || 0).toFixed(displayDigits)
                    )) +
                " " +
                (currency || ""),
            },
          ]),

      ...(agentCharges && transfer3rdScenario
        ? [
            {
              label: "Agent Charges",
              value: 3,
              icon: null,
              charge:
                parseFloat(
                  parseFloat(convAgentCharges || 0).toFixed(displayDigits)
                ) +
                " " +
                currency,
            },
          ]
        : [
            {
              label: "Agent Charges",
              value: 3,
              icon: null,
              charge:
                parseFloat(
                  parseFloat(agentCharges || 0).toFixed(displayDigits)
                ) +
                " " +
                currency,
            },
          ]),

      {
        label: "Total Fees",
        value: 4,
        icon: "-",
        charge:
          parseFloat(parseFloat(totalFeeAmt || 0).toFixed(displayDigits)) +
          " " +
          currency,
      },
      ...(!transfer2ndScenario
        ? [
            {
              label: "Total Amount we'll convert",
              value: 5,
              icon: "=",
              charge:
                parseFloat(
                  parseFloat(totalAmtConvert || 0).toFixed(displayDigits)
                ) +
                " " +
                currency,
            },
          ]
        : []),
      {
        label: "Exchange Rate",
        value: 6,
        icon:
          transfer3rdScenario || transfer2ndScenario || customActiveTab === 2
            ? "×"
            : "÷",
        charge: newConversionRate.toFixed(rateDigits),
      },
    ];
  }, [
    customActiveTab,
    findCharges,
    addTransactionValidation.values.fromCurrType?.label,
    addTransactionValidation.values.fromCurrValue,
    addTransactionValidation.values.userType,
    newConversionRate,
    totalAmtConvert,
  ]);

  const renderCharges = () => {
    if (
      isNaN(newConversionRate) &&
      addTransactionValidation.values.fromCurrType &&
      addTransactionValidation.values.toCurrType &&
      customActiveTab === TransactionType.Transfer
    ) {
      return;
    }

    if (!addTransactionValidation.values.fromCurrType?.label) return;

    return (
      (customActiveTab === TransactionType.Transfer ||
        customActiveTab === TransactionType.Buy ||
        customActiveTab === TransactionType.Sell) && (
        <div className="d-flex flex-column align-items-center text-center gap-3 mt-4 w-100 mb-4">
          <div className="list-group w-100" style={{ maxWidth: "400px" }}>
            {requiredFees.map((x) => (
              <div
                key={x.value}
                className="list-group-item d-flex justify-content-between align-items-center py-3"
              >
                <span className="fw-bold">{x.label}</span>
                <div className="d-flex align-items-center gap-2">
                  {x.icon && (
                    <span
                      className="d-flex justify-content-center align-items-center rounded-circle bg-light text-dark"
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    >
                      {x.icon}
                    </span>
                  )}
                  <span className="text-muted">{x.charge || 0}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    );
  };

  const resetFields = (userType) => {
    if (userType === state.userType.customer) {
      addTransactionValidation.setFieldValue("agentCompany", null);
      addTransactionValidation.setFieldValue("fromCustomerId", null);
      addTransactionValidation.setFieldValue("fromCurrValue", null);
      addTransactionValidation.setFieldValue("toCurrValue", null);
      addTransactionValidation.setFieldValue("toCurrType", null);
      addTransactionValidation.setFieldValue("fromCurrType", null);
      addTransactionValidation.setFieldValue("agentId", null);
      addTransactionValidation.setFieldValue("toCustBenId", null);
    } else if (userType === state.userType.agent) {
      addTransactionValidation.setFieldValue("agentCompany", null);
      addTransactionValidation.setFieldValue("fromCurrType", null);
      addTransactionValidation.setFieldValue("discountedRate", null);
      addTransactionValidation.setFieldValue("agentId", null);
      addTransactionValidation.setFieldValue("toCurrType", null);
      addTransactionValidation.setFieldValue("toCompanyBenId", null);
      addTransactionValidation.setFieldValue("toCustBenId", null);
      addTransactionValidation.setFieldValue("fromCustomerId", null);
      addTransactionValidation.setFieldValue("fromCurrValue", null);
      addTransactionValidation.setFieldValue("toCurrValue", null);
    }
  };

  const renderUserTypeSelection = () => {
    const options = [
      { value: state.userType.customer, label: state.userType.customer },
      { value: state.userType.agent, label: state.userType.agent },
    ];

    return (
      customActiveTab === 3 && (
        <Col md={6}>
          <FormGroup>
            <Label>Select User Type</Label>
            <RequiredAsterisk />
            <div>
              {options.map((option) => (
                <div key={option.value} className="form-check">
                  <input
                    type="radio"
                    id={option.value}
                    name="userType"
                    value={option.value}
                    className="form-check-input"
                    checked={
                      addTransactionValidation.values.userType === option.value
                    }
                    onChange={() => {
                      addTransactionValidation.setFieldValue(
                        "userType",
                        option.value
                      );
                      resetFields(option.value);
                    }}
                  />
                  <label htmlFor={option.value} className="form-check-label">
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </FormGroup>
        </Col>
      )
    );
  };

  const renderCustomerAndAgentCompany = () => {
    const noBeneficiaryFound = state.agentBeneficiaryOptions?.length === 0;

    const activeCustomers = customerList.filter(
      (customer) => customer.userStatus === 4
    );

    return (
      <Col md={6}>
        {addTransactionValidation.values.userType ===
        state.userType.customer ? (
          <FormGroup>
            <Label htmlFor="fromCustomerId">Select Customer</Label>
            <RequiredAsterisk />
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <ReactSelect
                  placeholder="Enter atleast 3 Letters to get Customer Name..."
                  aria-label="fromCustomerId"
                  id="fromCustomerId"
                  value={addTransactionValidation.values.fromCustomerId}
                  styles={SelectStyle}
                  onChange={handleCustChange}
                  options={activeCustomers}
                  onInputChange={(inputValue, { action }) => {
                    if (action === "input-change" && inputValue.length >= 3) {
                      delayGetCustomer(inputValue);
                    }
                  }}
                  menuPortalTarget={document.getElementById(
                    "react-select-portal"
                  )}
                  menuPlacement="auto"
                >
                  {/* {loading && <ButtonSpinner />} */}
                </ReactSelect>
              </div>
              <Button
                color="primary"
                className="ms-2 flex-shrink-0"
                onClick={toggleAddUser}
              >
                +
              </Button>
            </div>
          </FormGroup>
        ) : addTransactionValidation.values.userType ===
          state.userType.agent ? (
          <>
            <FormGroup>
              <Label htmlFor="agentCompany">Select Agent Company</Label>
              <RequiredAsterisk />
              <ReactSelect
                placeholder="Select Agent Company..."
                aria-label="agentCompany"
                styles={SelectStyle}
                id="agentCompany"
                value={addTransactionValidation.values.agentCompany}
                onChange={(selectedOption) => {
                  addTransactionValidation.setFieldValue("agentId", null);
                  addTransactionValidation.setFieldValue(
                    "agentCompany",
                    selectedOption
                  );
                  addTransactionValidation.setFieldValue(
                    "toCompanyBenId",
                    null
                  );
                  addTransactionValidation.setFieldValue("fromCurrValue", "");
                  addTransactionValidation.setFieldValue("toCurrValue", "");
                  addTransactionValidation.setFieldValue("fromCurrType", "");
                  getAgentsByCompany(selectedOption?.value);
                  selectedOption?.value && getAgentBen(selectedOption?.value);
                }}
                options={companyOptions}
              />
            </FormGroup>
            {noBeneficiaryFound &&
            addTransactionValidation.values.agentCompany ? (
              <span className="text-danger" style={{ fontSize: "80%" }}>
                No beneficiary found for the selected company.
              </span>
            ) : null}
          </>
        ) : null}
      </Col>
    );
  };

  const renderBeneficiary = () => {
    return (
      customActiveTab === 3 &&
      addTransactionValidation.values.userType === state.userType.customer &&
      addTransactionValidation.values.fromCustomerId !== null && (
        <Col md={6}>
          <FormGroup>
            <Label for="toCustBenId">Select Beneficiary</Label>
            <RequiredAsterisk />
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <ReactSelect
                  placeholder="Select Beneficiary..."
                  aria-label="toCustBenId"
                  id="toCustBenId"
                  value={addTransactionValidation.values.toCustBenId}
                  styles={SelectStyle}
                  onChange={handleBenChange}
                  options={state.beneficiaryOptions}
                  getOptionLabel={(option) => option.beneficiaryName}
                  getOptionValue={(option) => option.custBenId}
                />
              </div>
              <Button
                color="primary"
                className="ms-2 flex-shrink-0"
                onClick={toggleModal}
              >
                +
              </Button>
            </div>
          </FormGroup>
        </Col>
      )
    );
  };

  const renderSelectAgent = () => {
    return addTransactionValidation.values.agentCompany !== null &&
      state.agentOptions?.length !== 0 ? (
      <Col md={6}>
        <FormGroup>
          <Label htmlFor="agentId">Select Agent</Label>
          <RequiredAsterisk />
          <ReactSelect
            id="agentId"
            aria-label="agentId"
            placeholder="Select Agent..."
            styles={SelectStyle}
            value={addTransactionValidation.values.agentId}
            onChange={(selectedOption) => {
              addTransactionValidation.setFieldValue("agentId", selectedOption);
              addTransactionValidation.setFieldValue("toCurrValue", "");
              addTransactionValidation.setFieldValue("fromCurrValue", "");
              addTransactionValidation.setFieldValue("fromCurrType", "");
            }}
            options={state.agentOptions}
          />
        </FormGroup>
      </Col>
    ) : null;
  };

  const renderSelectBen = () => {
    return addTransactionValidation.values.agentCompany !== null &&
      state.agentBeneficiaryOptions?.length !== 0 ? (
      <Col md={6}>
        <FormGroup>
          <Label htmlFor="toCompanyBenId">Select Beneficiary</Label>
          <RequiredAsterisk />
          <ReactSelect
            id="toCompanyBenId"
            aria-label="toCompanyBenId"
            placeholder="Select Beneficiary..."
            styles={SelectStyle}
            value={addTransactionValidation.values.toCompanyBenId}
            onChange={(selectedOption) => {
              addTransactionValidation.setFieldValue(
                "toCompanyBenId",
                selectedOption
              );
              addTransactionValidation.setFieldValue("fromCurrType", "");
            }}
            options={state.agentBeneficiaryOptions}
            getOptionLabel={(option) => option.beneficiaryName}
            getOptionValue={(option) => option.value}
          />
        </FormGroup>
      </Col>
    ) : null;
  };

  return (
    <React.Fragment>
      <CustomerBeneficiaryModal
        isOpen={benState.modal}
        toggle={toggleModal}
        mode={toggleMode.add}
        bankData={benState.bankData}
        custData={benState.custData}
        addressData={benState.addressData}
        customerId={benState.customerId}
        UId={benState.UId}
        getBeneficiaryByCustomerId={getBeneficiaryByCustomerId}
        setBeneficiaryState={setBenState}
      />
      <AddCustomer isOpen={addModal} toggle={toggleAddUser} />
      <Form>
        <Row>
          {renderUserTypeSelection()}
          {tabCondition ? renderUserType() : renderCustomerAndAgentCompany()}
          {tabCondition ? (
            renderCustomerAndAgentCompany()
          ) : customActiveTab === 3 ? (
            <>
              {renderBeneficiary()}
              {renderSelectAgent()}
              {renderSelectBen()}
            </>
          ) : null}
          {tabCondition ? renderSelectAgent() : null}

          {tabCondition &&
            (addTransactionValidation.values.fromCustomerId ||
              addTransactionValidation.values.agentId ||
              addTransactionValidation.values.userType ===
                state.userType.onCounter) && (
              <>
                {renderFromCurrency()}
                {renderToCurrency()}
              </>
            )}

          {tabCondition
            ? renderFromToStock()
            : (addTransactionValidation.values.fromCustomerId &&
                addTransactionValidation.values.toCustBenId &&
                renderFromCurrency()) ||
              (addTransactionValidation.values.agentId &&
                addTransactionValidation.values.toCompanyBenId &&
                renderFromCurrency())}
          {tabCondition ? (
            <>
              {renderAmount()}
              {validStock &&
                (customActiveTab === 1 || customActiveTab === 2) &&
                agentCharges > 0 &&
                renderCharges()}
              {renderConvertedAmount()}
            </>
          ) : (
            (addTransactionValidation.values.fromCustomerId &&
              addTransactionValidation.values.toCustBenId &&
              renderToCurrency()) ||
            (addTransactionValidation.values.agentId &&
              addTransactionValidation.values.toCompanyBenId &&
              renderToCurrency())
          )}

          {tabCondition ? null : renderFromToStock()}
          {tabCondition ? null : renderAmount()}
          {!tabCondition && renderCharges()}
          {!tabCondition && renderConvertedAmount()}
          {isTransactionValid &&
            checkCurrRate(customActiveTab) &&
            isAmoutValid &&
            !state.amtErr &&
            !state.negativeError && (
              <Button
                type="submit"
                color="primary"
                onClick={addTransactionValidation.handleSubmit}
                disabled={state.submitting}
              >
                {state.submitting ? <ButtonSpinner /> : "Submit"}
              </Button>
            )}
        </Row>
      </Form>
      {isLoading && (
        <div className="loader-container">
          <CustomLoader text="Transaction In Progress" />
        </div>
      )}
    </React.Fragment>
  );
};

export default React.memo(BookingForm);
