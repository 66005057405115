import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SignaturePad from "react-signature-canvas";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import { TransactionStatus } from "../../common/data/StatusLabels";
import { TransactionType } from "../../common/data/transactionType";
import CustomLoader from "../../components/Common/CustomLoader";
import {
  toastError,
  toastSucess,
  toastWarn,
} from "../../common/data/commonfunctions";
import Loader from "../../components/Common/Loader";
import { baseCurrency } from "../../common/data/currency";
import TransactionDocs from "../Transaction/ViewTransaction/TransactionDocs";
import VIewTransactionBeneficiaryAccordian from "../Transaction/ViewTransaction/VIewTransactionBeneficiaryAccordian";
import BranchInfo from "../Transaction/ViewTransaction/BranchInfo";
import ReviewInfo from "../Transaction/ViewTransaction/ReviewInfo";
import SignatureInfo from "../Transaction/ViewTransaction/SignatureInfo";
import CurrencyInfo from "../Transaction/ViewTransaction/CurrencyInfo";

const ViewLatestTransactionModel = (props) => {
  const {
    isOpen,
    toggle,
    activeTab,
    getAgentTransaction,
    getTransaction,
    transactionId,
  } = props;
  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);
  const [col12, setcol12] = useState(false);
  const [col13, setcol13] = useState(false);
  const [col14, setcol14] = useState(false);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [fileData, setFileData] = useState(null);
  const [isSign, setIsSign] = useState(null);
  const [isSignatureDrawn, setIsSignatureDrawn] = useState(false);
  const signaturePad = useRef(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState(null);
  const [fetchedSign, setFetchedSign] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [sendTransactionDetails, setSendTransactiondetails] = useState(null);
  const [sendOpTimeTransactionDetails, setSendOpTimeTransactiondetails] =
    useState(null);
  const [sendBranchDetails, setSendBranchDetails] = useState(null);
  const [commentData, setCommentData] = useState([]);

  const {
    fromCurrType,
    toCurrType,
    fromCurrValue,
    toCurrValue,
    transactionType,
    fromRatehistory,
    toRatehistory,
    exchangeRate,
    discountedRate,
    status,
    transactionDate,
    pickup,
    buyRateHistoryMainBranch,
    fromCustomerId,
    sign = null,
    approvedDate,
    companyId,
    toCompanyBenId,
    toCustBenId,
    transactionDocs = [],
  } = sendTransactionDetails || {};

  const fetchTransaction = async () => {
    setIsLoading(true);
    try {
      const response = await getApiData(
        `api/Transaction/GetTransaction?TransactionID=${transactionId}`
      );

      const branchResp = await getApiData(
        `api/BranchMaster/GetBranchWithOperation?branchId=${response?.fromBranchId}`
      );
      const commentData = response?.comments;
      setSendTransactiondetails(response);
      setCommentData(commentData);
      setSendOpTimeTransactiondetails(branchResp?.data?.operationalTime);
      setSendBranchDetails(branchResp?.data?.branch);
      if (response?.sign) {
        const signResp = response?.sign;
        setSelectedFile(signResp.transactionId);
        setIsSign(signResp.isSign);
        setFetchedSign(signResp.sign);
        setFileData(signResp.sign);
        setIsSignatureEmpty(!signResp.sign);
      }
      calculateCharges();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearSignature = () => {
    signaturePad.current?.clear();
    setIsSignatureDrawn(false);
    setIsSignatureEmpty(true);
    setFileData(null);
    setIsSign(null);
  };

  const [charges, setCharges] = useState({
    agentCharges: 0,
    wireTransferFee: 0,
    commissionCharges: 0,
    totalCharges: 0,
  });

  const handleAddSignSubmit = () => {
    const signature =
      fileData || signaturePad.current?.toDataURL().split(",")[1];

    if (fetchedSign === signature) {
      return;
    }

    if (!signature) {
      toast.error("No document or signature to submit.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const requestBody = {
      transactionId: transactionId,
      sign: signature,
      fileName: transactionId,
      isSign: isFileUploaded ? 0 : 1,
    };
    postApiData("api/TransactionSign/AddSign", requestBody)
      .then((response) => {
        if (response.success) {
          fetchSignature(transactionId);
        } else {
          toast.error("Submission failed", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => console.error("Error submitting data:", error));
  };

  const resetSign = () => {
    setCommentText("");
    setSelectedFile(null);
    setIsSign(null);
    setFileData(null);
    setIsSignatureEmpty(true);
    setShowSignaturePad(false);
  };

  const fetchSignature = () => {
    getApiData(
      `api/TransactionSign/GetSignByTransactionId?TransactionId=${transactionId}`
    )
      .then((response) => {
        if (response?.success) {
          const signatureData = response.data;
          if (signatureData) {
            setSelectedFile(signatureData.fileName || "");
            setIsSign(signatureData.isSign);
            setFetchedSign(signatureData.sign);
            setFileData(signatureData.sign || "");
            setIsSignatureEmpty(!signatureData.sign);
          } else {
            resetSign();
          }
        } else {
          resetSign();
        }
      })
      .catch((error) => console.error("Error fetching signature:", error));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size / (1024 * 1024) > 2) {
        toastWarn("File size must be less than 2MB");
        return;
      }

      const validTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!validTypes.includes(file?.type)) {
        toastWarn("Only PNG, JPEG, and JPG formats are allowed.");
        return;
      }

      const img = new Image();
      img.onload = () => {
        if (img.width > 300) {
          toastWarn("Image dimensions must be 300x80 px");
          return;
        }

        if (img.height > 80) {
          toastWarn("Image dimensions must be 300x80 px");
          return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedFile(file.name);
          setIsFileUploaded(true);
          setIsSign(1);
          setFileData(reader.result.split(",")[1]);
          setIsSignatureEmpty(true);
        };
        reader.readAsDataURL(file);
      };
      img.onerror = () => {
        toastError("Invalid image file.");
      };

      img.src = URL.createObjectURL(file);
    }
  };

  const handleSignatureEnd = () => {
    setIsSignatureDrawn(true);
    setFileData(signaturePad.current?.toDataURL().split(",")[1]);
    setIsSign(1);
  };

  const renderFileOrSignature = () => {
    if (!fileData && (isSign === null || isSign === 0)) {
      return (
        <div style={{ textAlign: "center" }}>
          <i
            className="fas fa-file"
            style={{ fontSize: "8rem", display: "block", margin: "0 auto" }}
          />
        </div>
      );
    } else if (fileData && isSign === 1) {
      return (
        <img
          src={`data:image/png;base64,${fileData}`}
          alt="Signature"
          style={{ width: "150px", height: "50px" }}
        />
      );
    }
  };

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
    setcol14(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
    setcol14(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
    setcol12(false);
    setcol13(false);
    setcol14(false);
  };

  const t_col12 = () => {
    setcol12(!col12);
    setcol10(false);
    setcol9(false);
    setcol11(false);
    setcol13(false);
    setcol14(false);
  };

  const t_col13 = () => {
    setcol13(!col13);
    setcol10(false);
    setcol9(false);
    setcol11(false);
    setcol12(false);
    setcol14(false);
  };
  const t_col14 = () => {
    setcol14(!col14);
    setcol9(false);
    setcol10(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
  };

  const transfer1stScenario =
    transactionType === TransactionType.Transfer &&
    fromCurrType === baseCurrency &&
    toCurrType !== baseCurrency;

  const transfer2ndScenario =
    transactionType === TransactionType.Transfer &&
    fromCurrType !== baseCurrency &&
    toCurrType === baseCurrency;

  const transfer3rdScenario =
    transactionType === TransactionType.Transfer &&
    fromCurrType !== baseCurrency &&
    toCurrType !== baseCurrency;

  let fromCurrCharges =
    transactionType === TransactionType.Buy ? fromRatehistory : toRatehistory;

  const parseFloatCharges = (value) => {
    return parseFloat(value);
  };

  const displayChargesForTransfer3rdScenario = (charge) => {
    return parseFloat(charge) / parseFloat(buyRateHistoryMainBranch?.value);
  };

  const calculateCharges = () => {
    if (
      transactionType === TransactionType.Buy ||
      transactionType === TransactionType.Sell
    ) {
      setCharges((prev) => ({
        ...prev,
        agentCharges: parseFloatCharges(fromCurrCharges?.agentCharges),
        totalCharges: parseFloatCharges(fromCurrCharges?.agentCharges),
      }));
    } else if (transfer1stScenario || transfer2ndScenario) {
      const commissionCharges = parseFloatCharges(
        fromCurrCharges?.commissionCharges
      );
      const wireTransferFee = parseFloatCharges(
        fromCurrCharges?.wireTransferFee
      );
      const agentCharges = parseFloatCharges(fromCurrCharges?.agentCharges);

      setCharges((prev) => ({
        ...prev,
        commissionCharges,
        wireTransferFee,
        agentCharges,
        totalCharges: agentCharges + wireTransferFee + commissionCharges,
      }));
    } else if (transfer3rdScenario) {
      const commissionCharges = displayChargesForTransfer3rdScenario(
        fromCurrCharges?.commissionCharges
      );
      const wireTransferFee = displayChargesForTransfer3rdScenario(
        fromCurrCharges?.wireTransferFee
      );
      const agentCharges = displayChargesForTransfer3rdScenario(
        fromCurrCharges?.agentCharges
      );

      setCharges((prev) => ({
        ...prev,
        commissionCharges,
        wireTransferFee,
        agentCharges,
        totalCharges: agentCharges + wireTransferFee + commissionCharges,
      }));
    }
  };

  const openCommentPopup = (status) => {
    setSelectedStatus(status);
    setIsCommentOpen(true);
  };

  const closeCommentPopup = () => {
    setIsCommentOpen(!isCommentOpen);
    setCommentText("");
  };

  const calculateDateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffInDays = Math.floor((end - start) / (1000 * 60 * 60 * 24));
    return diffInDays;
  };

  useEffect(() => {
    if (transactionId) {
      fetchTransaction();
    }
    if (status === TransactionStatus.WaitingForApprover) {
      t_col11();
    }
  }, [transactionId]);

  const handleCommentSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await postApiData("api/Transaction/ApproveOrReject", {
        transactionId: transactionId,
        comment: commentText,
        status: selectedStatus,
      });
      if (response?.success) {
        toastSucess(response?.message || "Success");
        closeCommentPopup();
        toggle();
        if (activeTab === 1) {
          getTransaction();
        } else if (activeTab === 2) {
          getAgentTransaction();
        }
      } else {
        toastWarn(response?.message);
      }
    } catch {
      toast.error("Error submitting data", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getBeneficiary = async () => {
    const benResponse = await getApiData(
      `api/CustomerBenMap/GetAllCustBenMapByCustomerId?CustomerId=${fromCustomerId}`
    );
    const selectedBeneficiary = benResponse?.data?.find(
      (item) => item.custBenId === toCustBenId
    );

    if (selectedBeneficiary) {
      const beneficiary = {
        beneficiaryName: selectedBeneficiary.beneficiaryName,
        currencyName: selectedBeneficiary.currencyName,
      };
      setBeneficiaryDetails(beneficiary);
    }
  };

  const fetchCompanyData = async () => {
    const companyResponse = await getApiData(
      `api/AgentBeneficiary/GetAllCompanyBen?CompanyId=${companyId}`
    );
    const mappedResponse = companyResponse?.data?.map((item) => ({
      companyBenId: item.companyBenId,
      companyId: item.companyId,
      beneficiaryName: item.beneficiaryName,
      currencyCode: item.currencyCode,
      currencyName: item.currencyName,
    }));

    const response = await getApiData(
      `api/AgentBeneficiary/GetBen?CompanyBenId=${toCompanyBenId}`
    );

    if (response) {
      const benId = mappedResponse?.find(
        (item) => item.companyBenId === toCompanyBenId
      );

      if (benId) {
        const beneficiary = {
          beneficiaryName: benId.beneficiaryName,
          currencyName: benId.currencyName,
        };

        setBeneficiaryDetails(beneficiary);
      }
    }
  };

  useEffect(() => {
    if (transactionType === TransactionType.Transfer) {
      if (companyId) {
        fetchCompanyData();
      } else if (fromCustomerId) {
        getBeneficiary();
      }
    }
  }, [transactionType, companyId, toCompanyBenId, fromCustomerId, toCustBenId]);

  const buttons = [
    {
      condition:
        sendOpTimeTransactionDetails !== null &&
        (status === 1 || status === 3 || status === 8),
      color: "primary",
      onClick: () =>
        openCommentPopup(
          transactionType === TransactionType.Buy ||
            transfer2ndScenario ||
            transfer3rdScenario
            ? 12
            : 4
        ),
      label: "Approve",
      disabled: false,
    },
    {
      condition:
        sendOpTimeTransactionDetails !== null &&
        (status === 1 ||
          status === 2 ||
          status === 3 ||
          status === 4 ||
          status === 8 ||
          status === 10),
      color: "danger",
      onClick: () => openCommentPopup(5),
      label: "Decline",
      disabled:
        sendOpTimeTransactionDetails !== null &&
        status === 4 &&
        calculateDateDifference(approvedDate, new Date()) === 1,
    },
    {
      condition: sendOpTimeTransactionDetails !== null && status === 10,
      color: "warning",
      onClick: () => openCommentPopup(10),
      label: "Regenerate QR",
      disabled: false,
    },
    {
      condition:
        sendOpTimeTransactionDetails !== null && (status === 1 || status === 8),
      color: "warning",
      onClick: () => openCommentPopup(2),
      label: "Review",
      disabled: false,
    },
    {
      condition: sendOpTimeTransactionDetails !== null && status === 10,
      color: "success",
      onClick: () =>
        openCommentPopup(
          transactionType === TransactionType.Transfer ? 12 : 11
        ),
      label: "Payment Received",
      disabled: false,
    },
    {
      condition: sendOpTimeTransactionDetails !== null && status === 11,
      color: "success",
      onClick: () => openCommentPopup(12),
      label: "Ready to Pay",
      disabled: false,
    },
    {
      condition: sendOpTimeTransactionDetails !== null && status === 12,
      color: "success",
      onClick: () => openCommentPopup(13),
      label: "Cash Collected",
      disabled: false,
    },
  ];

  const signatureNote = () => {
    return (
      <div className="text-center mt-2">
        <p className="text-danger">
          Note: Only JPG, JPEG, PNG formats are allowed, max size 2MB,
          dimensions must be 300x80 pixels.
        </p>
      </div>
    );
  };
  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
        size="lg"
        onClosed={resetSign}
      >
        <ToastContainer />
        <div className="modal-content">
          <ModalHeader toggle={toggle}>
            Transaction Details - {transactionId}
          </ModalHeader>
          <ModalBody>
            <div className="mt-3">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <CurrencyInfo
                  col9={col9}
                  t_col9={t_col9}
                  sendTransactionDetails={sendTransactionDetails}
                  status={status}
                  discountedRate={discountedRate}
                  exchangeRate={exchangeRate}
                  charges={charges}
                  transfer2ndScenario={transfer2ndScenario}
                  transfer3rdScenario={transfer3rdScenario}
                  fromRatehistory={fromRatehistory}
                  fromCurrType={fromCurrType}
                  toCurrType={toCurrType}
                  fromCurrValue={fromCurrValue}
                  toCurrValue={toCurrValue}
                  transactionType={transactionType}
                />

                <BranchInfo
                  col10={col10}
                  t_col10={t_col10}
                  sendOpTimeTransactionDetails={sendOpTimeTransactionDetails}
                  sendTransactionDetails={sendTransactionDetails}
                  sendBranchDetails={sendBranchDetails}
                  transactionDate={transactionDate}
                  pickup={pickup}
                />

                {transactionType === 3 && (
                  <VIewTransactionBeneficiaryAccordian
                    col12={col12}
                    t_col12={t_col12}
                    beneficiaryDetails={beneficiaryDetails}
                  />
                )}
                <ReviewInfo
                  col11={col11}
                  t_col11={t_col11}
                  commentData={commentData}
                  status={status}
                  openCommentPopup={openCommentPopup}
                />
                {status === 13 && sign?.sign.startsWith("iVBORw0") && (
                  <SignatureInfo
                    col13={col13}
                    t_col13={t_col13}
                    sendTransactionDetails={sendTransactionDetails}
                    sign={sign}
                  />
                )}
                {transactionDocs.length > 0 && (
                  <TransactionDocs
                    col14={col14}
                    t_col14={t_col14}
                    transactionDocs={transactionDocs}
                  />
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {buttons.map(
              (x) =>
                x.condition && (
                  <Button
                    key={x.label}
                    type="button"
                    color={x.color}
                    className="btn-primary"
                    onClick={x.onClick}
                    disabled={x.disabled}
                  >
                    {x.label}
                  </Button>
                )
            )}
          </ModalFooter>
        </div>
        {/* Add comment popup */}
        <Modal
          isOpen={isCommentOpen}
          toggle={() => setIsCommentOpen(false)}
          centered
          size="lg"
          onClosed={() => {
            resetSign();
          }}
        >
          <ModalHeader toggle={() => setIsCommentOpen(false)}>
            Add Comment
          </ModalHeader>
          <ModalBody>
            <textarea
              className="form-control"
              rows="5"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              placeholder="Write your comment here..."
            />
            {status === 12 && (
              <>
                <h5 className="mt-2 text-center">Signature</h5>
                {signatureNote()}
                <div className="text-center mt-2">
                  {renderFileOrSignature()}
                </div>

                <div className="text-center mt-3">
                  {!showSignaturePad && (
                    <>
                      <Button color="primary" className="me-2">
                        <label
                          htmlFor="upload-file"
                          style={{
                            cursor: "pointer",
                            color: "white",
                            margin: 0,
                          }}
                        >
                          <i className="fas fa-file-upload" />
                        </label>
                        <input
                          id="upload-file"
                          type="file"
                          style={{ display: "none" }}
                          accept=".png,.jpeg,.jpg"
                          onChange={handleFileUpload}
                        />
                      </Button>

                      <Button
                        color="primary"
                        onClick={() => setShowSignaturePad(true)}
                      >
                        <i className="fas fa-pencil-alt" />
                      </Button>
                    </>
                  )}

                  {showSignaturePad && (
                    <div
                      style={{
                        border: "1px solid #ced4da",
                        display: "inline-block",
                        marginTop: "10px",
                      }}
                    >
                      <SignaturePad
                        ref={signaturePad}
                        canvasProps={{
                          width: 400,
                          height: 145,
                          style: { cursor: "cell" },
                        }}
                        onEnd={handleSignatureEnd}
                      />
                      <Button
                        color="primary"
                        className="mt-2"
                        onClick={clearSignature}
                      >
                        <i className="fas fa-eraser" />
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (isFileUploaded || isSignatureDrawn) {
                  handleAddSignSubmit();
                }
                handleCommentSubmit();
              }}
              disabled={isLoading}
            >
              Submit
            </Button>

            <Button color="secondary" onClick={() => setIsCommentOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
          {isLoading && (
            <div className="loader-container">
              <CustomLoader text="Transaction In Progress" />
            </div>
          )}
        </Modal>
      </Modal>
    </React.Fragment>
  );
};

ViewLatestTransactionModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  sendTransactionDetails: PropTypes.object,
  commentData: PropTypes.array,
};

export default ViewLatestTransactionModel;
