import { useState, useCallback, useEffect } from "react";
import config from "../../../config";
import * as signalR from "@microsoft/signalr";

export const useNotificationService = ({ onNotificationReceived }) => {
  const [connection, setConnection] = useState(null);
  const hubUrl = `${config.apiUrl}notification`;

  const establishConnection = useCallback(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const token = authUser?.token;
    const role = authUser?.role;
    const uid = authUser?.uid;
    const group =
      role === "System Admin"
        ? "SystemAdminNotifications"
        : `Branch${authUser?.branchId}`;

    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: () => token,
        skipNegotiation: false,
        transport:
          signalR.HttpTransportType.WebSockets ||
          signalR.HttpTransportType.LongPolling ||
          signalR.HttpTransportType.ServerSentEvents,
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);

    newConnection
      .start()
      .then(() => {
        newConnection.invoke("JoinGroup", group);
        console.log(group, "connected");
      })
      .catch((err) => console.error("Error establishing connection", err));
    // Listen for new notifications
    newConnection.on("ReceiveSystemAdminNotifications", (notifications) => {
      if (typeof onNotificationReceived === "function") {
        onNotificationReceived(notifications);
      }
    });

    newConnection.on(`${uid}`, (notifications) => {
      if (typeof onNotificationReceived === "function") {
        onNotificationReceived(notifications);
      }
    });

    // Stops the connection
    return () => {
      if (newConnection) {
        newConnection
          .stop()
          .then(() => {
            console.log("Connection stopped");
          })
          .catch((err) => console.error(err));
      }
    };
  }, []);

  const restartConnection = () => {
    connection?.stop().then(() => {
      establishConnection();
    });
  };

  useEffect(() => {
    establishConnection();
  }, [establishConnection]);

  return { connection, restartConnection };
};
