import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import { getApiData } from "../../helpers/axiosHelper";
import Swal from "sweetalert2";
import { Button } from "reactstrap";

const CurrencyModal = ({
  selectedCurrencies,
  setSelectedCurrencies,
  onAddCurrency,
  onClearRates,
}) => {
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fromCurrencies, setFromCurrencies] = useState([]);
  const [toCurrency, setToCurrency] = useState(null);
  const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(true);
  const [addedCurrencies, setAddedCurrencies] = useState([]);

  const currenciesFromUSD = {
    JPY: "Japanese Yen",
    CHF: "Swiss Franc",
    CAD: "Canadian Dollar",
    CNY: "Chinese Yuan",
    SGD: "Singapore Dollar",
    HUF: "Hungarian Forint",
    DKK: "Danish Krone",
    SEK: "Swedish Krona",
    MXN: "Mexican Peso",
    TRY: "Turkish Lira",
    PLN: "Polish Zloty",
    ZAR: "South African Rand",
    NOK: "Norwegian Krone",
    RUB: "Russian Ruble",
    HKD: "Hong Kong Dollar",
    CLP: "Chilean Peso",
    ILS: "Israeli Shekel",
    USD: "US Dollar",
    INR: "Indian Rupee",
    AED: "United Arab Emirates Dirham",
    ANG: "Netherlands Antillean Guilder",
    ARS: "Argentine Peso",
    BDT: "Bangladeshi Taka",
    BGN: "Bulgarian Lev",
    BHD: "Bahraini Dinar",
    BND: "Brunei Dollar",
    BOB: "Bolivian Boliviano",
    BRL: "Brazilian Real",
    BWP: "Botswana Pula",
    COP: "Colombian Peso",
    CRC: "Costa Rican Col�n",
    CZK: "Czech Koruna",
    DZD: "Algerian Dinar",
    EGP: "Egyptian Pound",
    FJD: "Fijian Dollar",
    HNL: "Honduran Lempira",
    IDR: "Indonesian Rupiah",
    JMD: "Jamaican Dollar",
    JOD: "Jordanian Dinar",
    KES: "Kenyan Shilling",
    KRW: "South Korean Won",
    KWD: "Kuwaiti Dinar",
    KYD: "Cayman Islands Dollar",
    KZT: "Kazakhstani Tenge",
    LBP: "Lebanese Pound",
    LKR: "Sri Lankan Rupee",
    MAD: "Moroccan Dirham",
    MDL: "Moldovan Leu",
    MKD: "Macedonian Denar",
    MUR: "Mauritian Rupee",
    MVR: "Maldivian Rufiyaa",
    MYR: "Malaysian Ringgit",
    NAD: "Namibian Dollar",
    NGN: "Nigerian Naira",
    NIO: "Nicaraguan C�rdoba",
    NPR: "Nepalese Rupee",
    OMR: "Omani Rial",
    PEN: "Peruvian Sol",
    PGL: "PGL",
    PHP: "Philippine Peso",
    PKR: "Pakistani Rupee",
    PYG: "Paraguayan Guaran�",
    QAR: "Qatari Riyal",
    RON: "Romanian Leu",
    RSD: "Serbian Dinar",
    SAR: "Saudi Riyal",
    SCR: "Seychellois Rupee",
    SLL: "Sierra Leonean Leone",
    THB: "Thai Baht",
    TND: "Tunisian Dinar",
    TTD: "Trinidad and Tobago Dollar",
    TWD: "New Taiwan Dollar",
    TZS: "Tanzanian Shilling",
    UAH: "Ukrainian Hryvnia",
    UGX: "Ugandan Shilling",
    UYU: "Uruguayan Peso",
    UZS: "Uzbekistani Som",
    VND: "Vietnamese Dong",
    XOF: "West African CFA Franc",
    YER: "Yemeni Rial",
    AFN: "Afghan Afghani",
    ALL: "Albanian Lek",
  };

  const availableCurPairs = {
    "USD/JPY": "USD/JPY",
    "USD/CHF": "USD/CHF",
    "USD/CAD": "USD/CAD",
    "USD/CNY": "USD/CNY",
    "USD/SGD": "USD/SGD",
    "USD/HUF": "USD/HUF",
    "USD/DKK": "USD/DKK",
    "USD/SEK": "USD/SEK",
    "USD/MXN": "USD/MXN",
    "USD/TRY": "USD/TRY",
    "USD/PLN": "USD/PLN",
    "USD/ZAR": "USD/ZAR",
    "USD/NOK": "USD/NOK",
    "USD/RUB": "USD/RUB",
    "USD/HKD": "USD/HKD",
    "USD/CLP": "USD/CLP",
    "USD/ILS": "USD/ILS",
    "USD/INR": "USD/INR",
    "USD/AED": "USD/AED",
    "USD/ANG": "USD/ANG",
    "USD/ARS": "USD/ARS",
    "USD/BDT": "USD/BDT",
    "USD/BGN": "USD/BGN",
    "USD/BHD": "USD/BHD",
    "USD/BND": "USD/BND",
    "USD/BOB": "USD/BOB",
    "USD/BRL": "USD/BRL",
    "USD/BWP": "USD/BWP",
    "USD/COP": "USD/COP",
    "USD/CRC": "USD/CRC",
    "USD/CZK": "USD/CZK",
    "USD/DZD": "USD/DZD",
    "USD/EGP": "USD/EGP",
    "USD/FJD": "USD/FJD",
    "USD/HNL": "USD/HNL",
    "USD/IDR": "USD/IDR",
    "USD/JMD": "USD/JMD",
    "USD/JOD": "USD/JOD",
    "USD/KES": "USD/KES",
    "USD/KRW": "USD/KRW",
    "USD/KWD": "USD/KWD",
    "USD/KYD": "USD/KYD",
    "USD/KZT": "USD/KZT",
    "USD/LBP": "USD/LBP",
    "USD/LKR": "USD/LKR",
    "USD/MAD": "USD/MAD",
    "USD/MDL": "USD/MDL",
    "USD/MKD": "USD/MKD",
    "USD/MUR": "USD/MUR",
    "USD/MVR": "USD/MVR",
    "USD/MYR": "USD/MYR",
    "USD/NAD": "USD/NAD",
    "USD/NGN": "USD/NGN",
    "USD/NIO": "USD/NIO",
    "USD/NPR": "USD/NPR",
    "USD/OMR": "USD/OMR",
    "USD/PEN": "USD/PEN",
    "USD/PGL": "USD/PGL",
    "USD/PHP": "USD/PHP",
    "USD/PKR": "USD/PKR",
    "USD/PYG": "USD/PYG",
    "USD/QAR": "USD/QAR",
    "USD/RON": "USD/RON",
    "USD/RSD": "USD/RSD",
    "USD/SAR": "USD/SAR",
    "USD/SCR": "USD/SCR",
    "USD/SLL": "USD/SLL",
    "USD/THB": "USD/THB",
    "USD/TND": "USD/TND",
    "USD/TTD": "USD/TTD",
    "USD/TWD": "USD/TWD",
    "USD/TZS": "USD/TZS",
    "USD/UAH": "USD/UAH",
    "USD/UGX": "USD/UGX",
    "USD/UYU": "USD/UYU",
    "USD/UZS": "USD/UZS",
    "USD/VND": "USD/VND",
    "USD/XOF": "USD/XOF",
    "USD/YER": "USD/YER",
    "USD/AFN": "USD/AFN",
    "USD/ALL": "USD/ALL",
  };

  const currenciesToUSD = {
    EUR: "Euro",
    GBP: "British Pound",
    NZD: "New Zealand Dollar",
    AUD: "Australian Dollar",
    USD: "US Dollar",
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem("selectedPairs");
  };

  const CustomMultiValue = (props) => (
    <components.MultiValue {...props}>
      {props.data.label.props.children[0]}
      {props.data.value}
    </components.MultiValue>
  );

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await getApiData(
          "api/Currency/GetAllCurrencies",
          {},
          true
        );
        if (response?.success && Array.isArray(response.data)) {
          const mappedResponse = response.data.map((item) => ({
            label: (
              <>
                {item.currencyFlag && (
                  <img
                    src={`data:image/png;base64,${item.currencyFlag}`}
                    alt={`${item.currencyName} flag`}
                    style={{
                      width: "20px",
                      height: "15px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {item.currencyName}
              </>
            ),
            value: item.currencyCode,
            currencyFlag: item.currencyFlag,
            currencyName: item.currencyName,
          }));
          setAvailableCurrencies(mappedResponse);
        } else {
          console.error(
            "Failed to fetch currencies or invalid response format"
          );
        }
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };
    sessionStorage.removeItem("selectedPairs");
    fetchCurrencies();
  }, []);

  const handleClear = () => {
    setFromCurrencies([]);
    setToCurrency(null);
    clearSessionStorage();
  };

  const handleToggle = () => {
    setIsMultiSelectEnabled((prevState) => !prevState);
    handleClear();
  };

  const handleAdd = () => {
    const fromCurrencyDetails = fromCurrencies
      .map(
        (currencyValue) =>
          availableCurrencies?.find(
            (currency) => currency.value === currencyValue
          ) || null
      )
      .filter(Boolean);

    const toCurrencyData = availableCurrencies?.find(
      (currency) => currency.value === toCurrency
    );

    if (!fromCurrencyDetails.length || !toCurrencyData) {
      console.error("Invalid selection for from or to currency");
      return;
    }

    const formattedCurrencies = fromCurrencyDetails.map((fromCurrencyData) => ({
      fromCurrency: (
        <>
          {fromCurrencyData.currencyFlag && (
            <img
              src={`data:image/png;base64,${fromCurrencyData.currencyFlag}`}
              alt={`${fromCurrencyData.currencyName} flag`}
              style={{ width: "20px", height: "15px", marginRight: "5px" }}
            />
          )}
          {fromCurrencyData.currencyName}
        </>
      ),
      toCurrency: (
        <>
          {toCurrencyData.currencyFlag && (
            <img
              src={`data:image/png;base64,${toCurrencyData.currencyFlag}`}
              alt={`${toCurrencyData.currencyName} flag`}
              style={{ width: "20px", height: "15px", marginRight: "5px" }}
            />
          )}
          {toCurrencyData.currencyName}
        </>
      ),
    }));

    setAddedCurrencies((prev) => [...prev, ...formattedCurrencies]);
    handleClear();
  };

  const handleSelect = () => {
    if (addedCurrencies.length === 0) {
      // Show SweetAlert notification
      Swal.fire({
        icon: "info",
        title: "No currencies selected",
        text: "Please select at least one currency.",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didClose: () => {},
      });
      return;
    }

    const result = {};
    let currIndex = 1;
    const selectedCodes = [];

    addedCurrencies.forEach((currency) => {
      const fromCurrencyText = currency.fromCurrency.props.children[1];
      const toCurrencyText = currency.toCurrency.props.children[1];

      const fromCurrencyCode = fromCurrencyText.match(/\((.*?)\)/)?.[1];
      const toCurrencyCode = toCurrencyText.match(/\((.*?)\)/)?.[1];

      console.log("From currency:", fromCurrencyCode);
      console.log("To currency:", toCurrencyCode);

      if (!fromCurrencyCode || !toCurrencyCode) {
        console.error("Invalid currency codes detected");
        return;
      }

      const pair1 = `${fromCurrencyCode}/${toCurrencyCode}`;
      const pair2 = `${toCurrencyCode}/${fromCurrencyCode}`;
      let key = "";

      // Check if the pair exists in availableCurPairs as directPair
      if (availableCurPairs[pair1]) {
        selectedCodes.push({ directPair: pair1 });
        key = "directPair";
        result[`curr${currIndex}`] = { key, pair: pair1 };
      } else if (availableCurPairs[pair2]) {
        selectedCodes.push({ invertedPair: pair1 });
        key = "invertedPair";
        result[`curr${currIndex}`] = { key, pair: pair1 };
      } else {
        // If not a direct or inverted pair, handle as indirect pair
        key = "indirectPair";

        let fromPair = "";
        let toPair = "";

        // Check if fromCurrencyCode is USD
        if (fromCurrencyCode === "USD") {
          fromPair = `USD/${toCurrencyCode}`;
        } else if (currenciesFromUSD[fromCurrencyCode]) {
          fromPair = `USD/${fromCurrencyCode}`;
        } else if (currenciesToUSD[fromCurrencyCode]) {
          fromPair = `${fromCurrencyCode}/USD`;
        }

        // Check if toCurrencyCode is USD
        if (toCurrencyCode === "USD") {
          toPair = `${fromCurrencyCode}/USD`;
        } else if (currenciesFromUSD[toCurrencyCode]) {
          toPair = `USD/${toCurrencyCode}`;
        } else if (currenciesToUSD[toCurrencyCode]) {
          toPair = `${toCurrencyCode}/USD`;
        }

        // Add indirect pair to selectedCodes and result
        selectedCodes.push({ indirectPair: `${fromPair} - ${toPair}` });
        result[`curr${currIndex}`] = { key, from: fromPair, to: toPair };
      }
      currIndex++;
    });

    sessionStorage.setItem("selectedPairs", JSON.stringify(selectedCodes));

    if (onAddCurrency) {
      onAddCurrency(result);
    }

    setShowModal(false);
  };

  const sortedCurrencies = (selectedCurrencies) =>
    availableCurrencies
      .filter((option) => selectedCurrencies.includes(option.value))
      .concat(
        availableCurrencies.filter(
          (option) => !selectedCurrencies.includes(option.value)
        )
      );

  // Function to handle currency deletion
  const handleDeleteCurrency = (index) => {
    const updatedCurrencies = addedCurrencies.filter((_, i) => i !== index);

    const selectedPairs =
      JSON.parse(sessionStorage.getItem("selectedPairs")) || [];
    const fromCurrencyCode =
      addedCurrencies[index].fromCurrency.props.children[1].match(
        /\((.*?)\)/
      )?.[1];
    const toCurrencyCode =
      addedCurrencies[index].toCurrency.props.children[1].match(
        /\((.*?)\)/
      )?.[1];

    const pairToDelete = `${fromCurrencyCode}/${toCurrencyCode}`;
    const updatedPairs = selectedPairs.filter((pair) => pair !== pairToDelete);

    sessionStorage.setItem("selectedPairs", JSON.stringify(updatedPairs));

    setAddedCurrencies(updatedCurrencies);
  };

  const handleDropdownClear = () => {
    const selectedPairs =
      JSON.parse(sessionStorage.getItem("selectedPairs")) || [];
    const updatedPairs = addedCurrencies.reduce(
      (remainingPairs, currency, index) => {
        const fromCurrencyCode =
          currency.fromCurrency.props.children[1].match(/\((.*?)\)/)?.[1];
        const toCurrencyCode =
          currency.toCurrency.props.children[1].match(/\((.*?)\)/)?.[1];
        const pairToDelete = `${fromCurrencyCode}/${toCurrencyCode}`;
        return remainingPairs.filter((pair) => pair !== pairToDelete);
      },
      selectedPairs
    );

    sessionStorage.setItem("selectedPairs", JSON.stringify(updatedPairs));
    setAddedCurrencies([]);

    // Clear the rates table
    if (onClearRates) {
      onClearRates();
    }

    handleClear();
  };

  return (
    <div className="text-sm-end">
      <Button
        type="button"
        color="primary"
        onClick={() => setShowModal(true)}
        className="btn mb-2 me-2"
      >
        Select Currencies
      </Button>

      {showModal && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog" style={{ maxWidth: "650px" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Select Currencies for Live Rates
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="text-muted mb-0">
                    Please select at least one from/to currency.
                  </p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="multiSelectToggle"
                      checked={isMultiSelectEnabled}
                      onChange={handleToggle}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="multiSelectToggle"
                    >
                      {isMultiSelectEnabled ? "Multi-Select" : "Single-Select"}
                    </label>
                  </div>
                </div>

                <div className="mb-3">
                  <Select
                    options={sortedCurrencies(fromCurrencies)}
                    isMulti={isMultiSelectEnabled}
                    value={sortedCurrencies(fromCurrencies).filter((option) =>
                      fromCurrencies.includes(option.value)
                    )}
                    onChange={(selectedOptions) =>
                      setFromCurrencies(
                        Array.isArray(selectedOptions)
                          ? selectedOptions.map((option) => option.value)
                          : [selectedOptions.value]
                      )
                    }
                    placeholder="Select From"
                    components={
                      isMultiSelectEnabled
                        ? { MultiValue: CustomMultiValue }
                        : {}
                    }
                    hideSelectedOptions={false}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Select
                    options={availableCurrencies}
                    isMulti={false}
                    value={
                      toCurrency
                        ? availableCurrencies.find(
                            (option) => option.value === toCurrency
                          )
                        : null
                    }
                    onChange={(selectedOption) =>
                      setToCurrency(
                        selectedOption ? selectedOption.value : null
                      )
                    }
                    placeholder="Select To"
                    hideSelectedOptions={false}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                  />
                </div>

                <div
                  className="text-center mt-4"
                  style={{ marginLeft: "562px" }}
                >
                  <button className="btn btn-success" onClick={handleAdd}>
                    Add
                  </button>
                </div>

                {addedCurrencies.length > 0 && (
                  <div className="mt-4">
                    <h5>Added Currencies:</h5>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>From Currency</th>
                          <th>To Currency</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {addedCurrencies.map((currency, index) => (
                          <tr key={index}>
                            <td>{currency.fromCurrency}</td>
                            <td>{currency.toCurrency}</td>
                            <td>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDeleteCurrency(index)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={handleDropdownClear}
                >
                  Clear
                </button>
                <button className="btn btn-primary" onClick={handleSelect}>
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrencyModal;
