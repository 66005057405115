import React, { useState, useMemo, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  FormGroup,
  Button,
  Badge,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Loader from "../../../js/components/Common/Loader";
import TableContainer from "../../../js/components/Common/TableContainer";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import DownloadReport from "./DownloadReport";
import { useFormik } from "formik";
import ReactSelect from "react-select";
import { rateToFixed } from "../../common/data/commonfunctions";
import { mapStatus, mapTransactionType } from "../../common/data/StatusLabels";
import { formatDateToUserTimeZone } from "../../common/data/date";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import SelectStyle from "../../common/data/SelectStyle";
import { useCurrentPage } from "../../common/data/CustomPagination";
import { helpDocUrl } from "../HelpDocs/HelpDocUrl";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import RefreshButton from "../../common/data/RefreshButton";
import { mapTransactionData } from "../../common/data/transactionType";
import CustomDatePicker from "../../common/data/customDate";
import FilterDisplay from "./FilterDisplay";

const StockSoldReport = () => {
  document.title = "Stock Sold Report | Crescent Exchange";

  const [isLoading, setIsLoading] = useState(false);
  const [stockSoldData, setStockSoldData] = useState([]);
  const { allCurrencies } = useLatestTransactions();
  const { branches } = useLatestTransactions();
  const {
    saveCurrentPage,
    restoreCurrentPage,
    onPageChange,
    pageSize,
    currentPage,
  } = useCurrentPage();
  const today = new Date();
  const fromDate = today.toISOString().split("T")[0] + "T00:00:00"; // 'YYYY-MM-DDT00:00:00'
  const toDate = today.toISOString().split("T")[0] + "T23:59:59"; // 'YYYY-MM-DDT23:59:59'
  const [filtersApplied, setFiltersApplied] = useState({});

  // Use fromDate and toDate in your API request

  const isDate = (date) => {
    return isNaN(Date.parse(date));
  };

  const stockReport = useFormik({
    enableReinitialize: true,

    initialValues: {
      currencyCode: "",
      fromDate: "",
      toDate: "",
      branchId: null,
    },
    onSubmit: async (values) => {
      const { fromDate, toDate } = values;
      if (values.branchId && typeof values.branchId === "object") {
        values.branchId = values.branchId?.branchId;
      }
      if (values.currencyCode && typeof values.currencyCode === "object") {
        values.currencyCode = values.currencyCode?.currencyCode;
      }

      if (isDate(fromDate)) {
        delete values.fromDate;
      } else if (!values.currencyCode || values.currencyCode === undefined) {
        delete values.currencyCode;
      } else if (!values.branchId || values.branchId === null) {
        delete values.branchId;
      } else if (isDate(toDate)) {
        delete values.toDate;
      }

      getStockData(values);
      setFiltersApplied({
        currencyCode: values.currencyCode,
        fromDate: values.fromDate,
        toDate: values.toDate,
        branchId: values.branchId,
      });
    },
  });

  const getStockData = async (values) => {
    setIsLoading(true);
    try {
      const response = await postApiData(
        `api/Report/GetStockSoldReport`,
        values
      );
      setStockSoldData(mapTransactionData(response?.data));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "transactionId",
        filterable: false,
        includeInPDF: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      // {
      //   Header: "Name",
      //   accessor: "name",
      //   filterable: false,
      //   includeInPDF: true,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return cellProps.value ? cellProps.value : "On the Counter";
      //   },
      // },
      {
        Header: "Currency",
        accessor: "currencyType",
        disableFilters: true,
        includeInPDF: false,
        filterable: false,
        Cell: (cellProps) => {
          const { currencyType, transactionType } = cellProps.row.original;
          return (
            <div>
              {/* <Badge
                className={
                  "font-size-11 me-1 badge-soft-" + transactionType?.color
                }
              >
                {transactionType?.label}
              </Badge> */}
              <span>{currencyType}</span>
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "fromCurrValue",
        disableFilters: true,
        includeInPDF: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      // {
      //   Header: "Rate",
      //   accessor: "rate",
      //   disableFilters: true,
      //   includeInPDF: true,
      //   filterable: false,
      //   Cell: (cellProps) => {
      //     return cellProps.value ? cellProps.value : "";
      //   },
      // },
      {
        Header: "Converted SGD Amount",
        accessor: "toCurrValue",
        disableFilters: true,
        includeInPDF: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      // {
      //   Header: "Booked Date",
      //   accessor: "transactionDate",
      //   disableFilters: true,
      //   includeInPDF: true,
      //   filterable: false,
      //   Cell: (cellProps) => {
      //     return cellProps.value ? cellProps.value : "";
      //   },
      // },
      // {
      //   Header: "Pickup",
      //   accessor: "pickup",
      //   includeInPDF: true,
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: (cellProps) => {
      //     return cellProps.value ? cellProps.value : "Not Mentioned";
      //   },
      // },
      // {
      //   Header: "Moving Average",
      //   accessor: "movingAverage",
      //   disableFilters: true,
      //   includeInPDF: true,
      //   filterable: false,
      //   Cell: (cellProps) => {
      //     return cellProps.value ? cellProps.value : "0";
      //   },
      // },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   disableFilters: true,
      //   includeInPDF: true,
      //   filterable: false,
      //   show: false,
      //   Cell: (cellProps) => {
      //     return (
      //       <Badge
      //         className={
      //           "font-size-11 badge-soft-" +
      //           cellProps.row.original.status?.color
      //         }
      //       >
      //         {cellProps.row.original.status?.label}
      //       </Badge>
      //     );
      //   },
      // },
    ],
    []
  );

  const initialData = {
    currencyId: "0",
    fromDate: fromDate,
    toDate: toDate,
    branchId: 0,
  };

  useEffect(() => {
    getStockData();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div className="d-flex mb-4">
                <div className="mb-4 h4 card-title">Stock Sold Report</div>
                {HelpDocButton(helpDocUrl.stockReport)}
              </div>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label for="branchId">Branch</Label>
                    <ReactSelect
                      id="branchId"
                      name="branchId"
                      value={
                        stockReport.values.branchId
                          ? branches.find(
                              (option) =>
                                option.branchId === stockReport.values.branchId
                            )
                          : null
                      }
                      onChange={(selectedOpt) => {
                        stockReport.setFieldValue("branchId", selectedOpt);
                      }}
                      styles={SelectStyle}
                      options={[{ label: "All", value: 0 }, ...branches]}
                      maxMenuHeight={250}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="currencyCode">Currency</Label>
                    <ReactSelect
                      id="currencyCode"
                      name="currencyCode"
                      value={
                        stockReport.values.currencyCode
                          ? allCurrencies.find(
                              (option) =>
                                option.currencyCode ===
                                stockReport.values.currencyCode
                            )
                          : null
                      }
                      onChange={(selectedOpt) => {
                        stockReport.setFieldValue("currencyCode", selectedOpt);
                      }}
                      options={allCurrencies}
                      styles={SelectStyle}
                      maxMenuHeight={250}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fromDate">From Date</Label>
                    <RequiredAsterisk />
                    <CustomDatePicker
                      showYear={true}
                      selectedDate={
                        stockReport.values.fromDate &&
                        !isNaN(new Date(stockReport.values.fromDate))
                          ? new Date(stockReport.values.fromDate)
                          : null
                      }
                      onChange={(date) => {
                        const formattedDate =
                          date && !isNaN(date)
                            ? date.toISOString().split("T")[0]
                            : "";
                        stockReport.setFieldValue("fromDate", formattedDate);
                      }}
                      isInvalid={false}
                      value={stockReport.values.fromDate || ""}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="toDate">To Date</Label>
                    <RequiredAsterisk />
                    <CustomDatePicker
                      showYear={true}
                      selectedDate={
                        stockReport.values.toDate &&
                        !isNaN(new Date(stockReport.values.toDate))
                          ? new Date(stockReport.values.toDate)
                          : null
                      }
                      onChange={(date) => {
                        const formattedDate =
                          date && !isNaN(date)
                            ? date.toISOString().split("T")[0]
                            : "";
                        stockReport.setFieldValue("toDate", formattedDate);
                      }}
                      isInvalid={false}
                      value={stockReport.values.toDate || ""}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                  <div className="d-flex align-items-center gap-2">
                    <Button color="primary" onClick={stockReport.handleSubmit}>
                      Apply
                    </Button>
                    {filtersApplied && (
                      <Button
                        color="danger"
                        onClick={() => {
                          stockReport.resetForm({
                            values: {
                              currencyCode: "",
                              branchId: "",
                              fromDate: "",
                              toDate: "",
                            },
                          });
                          setFiltersApplied({});
                        }}
                      >
                        Clear
                      </Button>
                    )}
                    <div>
                      <DownloadReport
                        data={stockSoldData}
                        columns={columns}
                        reportTitle={"Stock Sold Report"}
                        includeCurrency={true}
                        showStatus={true}
                        fromDate={stockReport.values.fromDate || "N/A"}
                        toDate={stockReport.values.toDate || "N/A"}
                        currencyName={
                          stockReport.values.currencyCode || "All Currencies"
                        }
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <FilterDisplay
                filters={filtersApplied}
                branches={branches}
                stockReport={stockReport}
              />
              <div style={{ marginTop: "20px" }}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <TableContainer
                    columns={columns}
                    data={stockSoldData}
                    // customPageSize={10}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    isPageSelect={false}
                    customPageSize={pageSize}
                    pageSize={pageSize}
                    pageIndex={currentPage}
                    onPageChange={onPageChange}
                    refresh={true}
                    onRefreshClick={() => getStockData(stockReport.values)}
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StockSoldReport;
