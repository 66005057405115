import React, { useState, useMemo, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import ViewLatestTransactionModel from "./ViewTransactionDetailsDashboard";
import { deleteApiData, getApiData } from "../../helpers/axiosHelper";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { edit, trash } from "../../common/data/icons";
import {
  isWithinAWeek,
  toastError,
  toastSucess,
} from "../../common/data/commonfunctions";
import { useCurrentPage } from "../../common/data/CustomPagination";
import { helpDocUrl } from "./../HelpDocs/HelpDocUrl";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import { path } from "../../routes/path";

const TransactionTableDashboard = (props) => {
  document.title = "Admin Dashboard | Crescent Exchange";
  const {
    isLoading,
    activeTab,
    setActiveTab,
    transactionData,
    getAgentTransaction,
    getTransaction,
    branchId,
    setIsLoading,
  } = props;
  const [modal1, setModal1] = useState(false);
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const isSystemAdmin = authUser?.role === "System Admin";
  const [commentData, setCommentData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const toggleViewModal = () => setModal1(!modal1);
  const [transactionId, setTransactionId] = useState(null);
  const [isCustomer, setIsCustomer] = useState(true);

  const {
    saveCurrentPage,
    restoreCurrentPage,
    onPageChange,
    pageSize,
    currentPage,
  } = useCurrentPage();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      localStorage.setItem("activeTab", tab);
    }
  };

  const { pathname } = useLocation();

  const renderHelpDoc = () => {
    if (pathname === "/transaction/manage-transaction") {
      return HelpDocButton(helpDocUrl.manageTransaction);
    }
    return HelpDocButton(helpDocUrl.dashboard);
  };

  const addTransaction = () => {
    if (pathname === "/transaction/manage-transaction") {
      return (
        <div className="text-sm-end">
          <Link to={path.addTransaction}>
            <Button color="primary" className=" mb-3 ">
              Add Transaction
            </Button>
          </Link>
        </div>
      );
    }
    return;
  };

  const DeleteTransaction = async (transactionId) => {
    const result = await ConfirmationSwal(
      "Confirmation Required",
      "Confirm",
      "Cancel",
      "This action will overwrite the moving average and profit loss calculations, Please confirm to proceed"
    );
    if (result.isConfirmed) {
      setIsLoading(true);
      const response = await deleteApiData(
        `api/Transaction/DeleteTransaction?TransactionId=${transactionId}`
      );
      setIsLoading(false);
      if (response?.success) {
        toastSucess(response?.message);
        getTransactions();
      } else if (!response?.success) {
        toastError(response?.message);
      }
    }
  };

  const ViewTransactionDetails = (cellProps) => {
    const { transactionId } = cellProps;
    setTransactionId(transactionId);
    toggleViewModal();
  };

  const renderActions = (cellProps) => {
    const { createdAt, transactionId, status } = cellProps.row.original;

    return (
      <>
        <Button
          type="button"
          color="success"
          className="btn-sm btn-rounded"
          onClick={() => {
            setEditMode(true);
            ViewTransactionDetails(cellProps.row.original);
          }}
          style={{ marginRight: "5px", marginBottom: "5px" }}
          aria-label="view transaction"
          title="Edit Transaction"
        >
          {edit()}
        </Button>
        {isSystemAdmin && isWithinAWeek(createdAt) && (
          <Button
            type="button"
            color="danger"
            className="btn-sm btn-rounded"
            onClick={() => {
              DeleteTransaction(transactionId);
            }}
            style={{ marginRight: "5px", marginBottom: "5px" }}
            aria-label="deletetransaction"
            title="Delete Transaction"
          >
            {trash()}
          </Button>
        )}
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "transactionId",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: isCustomer ? "Name" : "Company Name",
        accessor: "name",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "On the Counter";
        },
      },
      ...(isCustomer
        ? []
        : [
            {
              Header: "Agent Name",
              accessor: "Name",
              disableFilters: true,
              filterable: false,
              Cell: (cellProps) => {
                return cellProps.value ? cellProps.value : "";
              },
            },
          ]),
      {
        Header: "Currency ",
        accessor: "currencyType",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          const { currencyType, transactionType, fromCustomerId, agentId } =
            cellProps.row.original;

          // const name = agentId
          //   ? "Agent"
          //   : fromCustomerId
          //   ? "Customer"
          //   : "Customer";

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <span>{name}</span> */}
              <Badge
                className={
                  "font-size-11 ms-1 me-1 badge-soft-" + transactionType?.color
                }
              >
                {transactionType?.label}
              </Badge>
              <span>{currencyType}</span>
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "fromCurrValue",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Rate",
        accessor: "exchangeRate",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Converted Amount",
        accessor: "toCurrValue",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Booked Date",
        accessor: "transactionDate",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Pickup",
        accessor: "pickup",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "Not Mentioned";
        },
      },
      {
        Header: "Status",
        accessor: "statusText",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                cellProps.row.original.statusText.color
              }
            >
              {cellProps.row.original.statusText.label}
            </Badge>
          );
        },
      },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "view",
        Cell: renderActions,
      },
    ],

    [isCustomer, transactionData]
  );

  const getTransactions = () => {
    if (activeTab === 1) {
      if (branchId !== null && branchId !== undefined) {
        getTransaction();
      }
    } else if (activeTab === 2) {
      if (branchId !== null && branchId !== undefined) {
        getAgentTransaction();
      }
    }
  };

  useEffect(() => {
    getTransactions();
  }, [branchId]);

  const refreshData = () => {
    if (activeTab === 1) {
      if (branchId !== null && branchId !== undefined) {
        getTransaction();
      }
    } else if (activeTab === 2) {
      if (branchId !== null && branchId !== undefined) {
        getAgentTransaction();
      }
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ViewLatestTransactionModel
            isOpen={modal1}
            toggle={toggleViewModal}
            transactionId={transactionId}
            getAgentTransaction={getAgentTransaction}
            getTransaction={getTransaction}
            activeTab={activeTab}
          />
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex mb-2">
                  <div className=" mb-2 h4 card-title">Latest Transactions</div>
                  {renderHelpDoc()}
                </div>
                {addTransaction()}
              </div>
              <div className="mb-3"></div>
              <Nav
                fill
                tabs
                style={{
                  marginBottom: "30px",
                  width: "50%",
                }}
              >
                {/* Madhangi changes for scrum 17 - start*/}
                <NavItem>
                  <NavLink
                    className={activeTab === 1 ? "active" : ""}
                    style={
                      activeTab === 1
                        ? { backgroundColor: "#556ee6", color: "white" }
                        : { borderStyle: "solid", borderColor: "gray" }
                    }
                    onClick={() => {
                      toggle(1);
                      setIsCustomer(true);
                      getTransaction();
                    }}
                  >
                    Customers Transactions
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={activeTab === 2 ? "active" : ""}
                    style={
                      activeTab === 2
                        ? {
                            backgroundColor: "#556ee6",
                            color: "white",
                          }
                        : { borderStyle: "solid", borderColor: "gray" }
                    }
                    onClick={() => {
                      toggle(2);
                      setIsCustomer(false);
                      getAgentTransaction();
                    }}
                  >
                    Agent Transactions
                  </NavLink>
                </NavItem>
              </Nav>
              {transactionData != null && transactionData.length !== 0 ? (
                <TableContainer
                  columns={columns}
                  data={transactionData}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  // customPageSize={10}
                  isPageSelect={false}
                  customPageSize={pageSize}
                  pageSize={pageSize}
                  pageIndex={currentPage}
                  onPageChange={onPageChange}
                  refresh={true}
                  onRefreshClick={refreshData}
                />
              ) : (
                <div className="text-sm-center fs-5">No Transactions Found</div>
              )}
            </CardBody>
          </Card>
          <ToastContainer closeButton={false} limit={1} />
        </>
      )}
    </React.Fragment>
  );
};

export default TransactionTableDashboard;
