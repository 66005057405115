import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import ViewCustomerDocuments from "./ViewCustomerDocuments";
import EditCustomerDetails from "./ViewEditUserDetails";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { putApiData } from "../../helpers/axiosHelper";
import RiskSelectionModal from "../../components/Common/RiskSelectionModal";

const ViewDetailsToggle = (props) => {
  const {
    isOpen,
    toggle,
    customerID,
    editMode,
    uId,
    status,
    getCustomer,
    toggleViewDetails,
  } = props;
  const [activeTab, setActiveTab] = useState(1);
  const [isRiskModalOpen, setRiskModalOpen] = useState(false);
  const [userState, setUserState] = useState({ riskId: "", riskError: false });
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const isSystemAdmin = authUser?.role === "System Admin";

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleRiskChange = (e) => {
    setUserState({
      ...userState,
      riskId: e.target.value,
      riskError: !e.target.value,
    });
  };

  const forceApproveUser = () => {
    Swal.fire({
      text: "This action will override Profile and Document validation checks. Are you sure you want to proceed with force approving the customer? ",
      icon: "info",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#556ee6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await putApiData(
            `api/User/ApproveCustomerByStatus?UID=${uId}&forceApprove=true&CustomerId=${customerID}&RiskId=${userState.riskId}`
          );
          if (response?.success === true) {
            toast.success(
              response?.message !== null
                ? `${response?.message}`
                : `Customer Force Approved Successfully`,
              {
                position: "top-right",
                autoClose: 2000,
                closeButton: false,
              }
            );
            // onClose: () => {
            toggleViewDetails();
            getCustomer();
            // },
            // }
            // );
          } else if (response?.success === false) {
            toast.error(`${response?.message}`, {
              position: "top-right",
              autoClose: 2000,
              closeButton: false,
            });
          } else {
            toast.error(`Error, Contact Admin`, {
              position: "top-right",
              autoClose: 2000,
              closeButton: false,
            });
          }
        } catch (error) {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 2000,
            closeButton: false,
          });
        }
      }
    });
  };
  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <RiskSelectionModal
        show={isRiskModalOpen}
        onCloseClick={() => setRiskModalOpen(false)}
        handleRiskChange={handleRiskChange}
        userState={userState}
        onConfirmApproval={() => {
          setRiskModalOpen(false);
          forceApproveUser();
        }}
        customerID={customerID}
      />
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
        fullscreen={true}
        onClosed={() => getCustomer()}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Customer Details</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-between align-content-center">
              <Nav
                fill
                tabs
                style={{
                  marginBottom: "10px",
                  width: "50%",
                }}
              >
                <NavItem>
                  <NavLink
                    className={activeTab === 1 ? "active" : ""}
                    style={
                      activeTab === 1
                        ? { backgroundColor: "#556ee6", color: "white" }
                        : { borderStyle: "solid", borderColor: "gray" }
                    }
                    onClick={() => {
                      toggleTab(1);
                    }}
                  >
                    {editMode === true ? `View Details` : `Edit Details`}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 2 ? "active" : ""}
                    style={
                      activeTab === 2
                        ? {
                            backgroundColor: "#556ee6",
                            color: "white",
                          }
                        : { borderStyle: "solid", borderColor: "gray" }
                    }
                    onClick={() => {
                      toggleTab(2);
                    }}
                  >
                    {editMode === true ? `View Documents` : `Edit Documents`}
                  </NavLink>
                </NavItem>
              </Nav>
              <div>
                {status !== 4 && status !== 5 && isSystemAdmin ? (
                  <Button
                    type="button"
                    color="success"
                    size="sm"
                    title="Force Approve"
                    onClick={() => {
                      setRiskModalOpen(true);
                      // forceApproveUser();
                    }}
                    style={{
                      height: "40px",
                    }}
                  >
                    Force Approve
                  </Button>
                ) : null}
              </div>
            </div>
            {activeTab === 1 && (
              <EditCustomerDetails
                uId={uId}
                editMode={editMode}
                customerID={customerID}
              />
            )}
            {activeTab === 2 && (
              <ViewCustomerDocuments
                uId={uId}
                editMode={editMode}
                customerID={customerID}
              />
            )}
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ViewDetailsToggle;
