import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { postApiData, putApiData } from "../../helpers/axiosHelper";
import TableContainer from "../../components/Common/TableContainer";
import Swal from "sweetalert2";
import ReactSelect from "react-select";
import SelectStyle from "../../common/data/SelectStyle";
import { toastError, toastSucess } from "../../common/data/commonfunctions";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import Loader from "../../components/Common/Loader";
import CustomLoader from "../../components/Common/CustomLoader";
import { RateSize } from "../../common/data/RateSheet";
import { displayFlag } from "../../common/data/currency";

const AddRateSheetToggle = (props) => {
  const {
    isOpen,
    toggle: toggleModal,
    rateSheet,
    setIsLoading,
    sendRateSheet,
    isLoading,
    copy,
    rateMasterData,
    setRateMasterData,
    rateMasterForSheet,
    isTransfer = false,
  } = props;

  const formRef = useRef(null);
  const [submitting, setSubmitting] = useState(false);
  const { branchId, branches } = useLatestTransactions();
  const [rateSheetAdding, setRateSheetAdding] = useState(false);
  const forceApproveBranches = [];
  const branchOptions =
    [
      { label: "All", value: 0 },
      ...(branches.filter((x) => x.active === 1) || []),
    ] || [];

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const isSystemAdmin = authUser?.role === "System Admin";

  const toggle = () => {
    toggleModal();
  };

  const resetModal = () => {
    validation.resetForm();
    // rateMasterForSheet();
  };

  const handleBranchChange = (selectedOption) => {
    const isAllSelected = selectedOption.some((option) => option.value === 0);
    if (isAllSelected) {
      validation.setFieldValue("branch", [{ label: "All", value: 0 }]);
    } else {
      validation.setFieldValue("branch", selectedOption);
    }
  };

  const isAtLeastOneValueFilled = (tableData) => {
    const isFilled = tableData.some((item) => item.value !== "");

    if (!isFilled) {
      toast.error("Please Enter atleast one value", {
        position: "top-right",
        autoClose: 3000,
        closeButton: false,
      });
    }
    return isFilled;
  };

  const validateFormValues = (values) => {
    for (const item of values.tableData) {
      for (const rate of item.rateData) {
        if (!/^\d*\.?\d*$/.test(rate.value) || parseFloat(rate.value) < 0) {
          return false;
        }
      }
    }
    return true;
  };

  const rateTypeMapping = {
    Buying: 1,
    Selling: 2,
    Transfer: 3,
  };

  const insertRateSheet = async (
    requestRateSheetBody,
    values,
    branchValue,
    active,
    isDraft = false
  ) => {
    setRateSheetAdding(true);
    const response = await postApiData(
      `api/RateMaster/InsertRateSheet`,
      JSON.stringify(requestRateSheetBody)
    );

    if (response?.success) {
      const requestBodyRateHistory = values.tableData?.flatMap((item) =>
        item.rateData
          ?.map((rate) => ({
            rateSheetID: response?.data?.rateSheetId,
            branchId: branchValue,
            rateType: rateTypeMapping[rate.rateType],
            currencyCode: item.currencyCode,
            currencyName: item.currencyName,
            value: rate.value,
            agentCharges: rate.agentCharges,
            worldRate: rate.worldRate,
            wireTransferFee: rate.wireTransferFee,
            commissionCharges: rate.commissionCharges,
            active: active,
          }))
          .filter((row) => {
            if (!isDraft) {
              return (
                row.value ||
                row.agentCharges ||
                row.worldRate ||
                row.wireTransferFee ||
                row.commissionCharges
              );
            }
            return true;
          })
      );
      return await insertRateHistory(requestBodyRateHistory);
    } else {
      setRateSheetAdding(false);
      return response;
    }
  };

  const insertRateHistory = async (requestBodyRateHistory) => {
    const rateHistoryResponse = await postApiData(
      `api/RateHistory/InsertRateHistory`,
      JSON.stringify(requestBodyRateHistory)
    );
    setRateSheetAdding(false);
    return rateHistoryResponse;
  };

  const saveAsDraft = async (values) => {
    const branchValues = Array.isArray(values.branch)
      ? values.branch.map((branch) => branch.value)
      : [];

    if (!branchValues?.length) {
      validation.setFieldTouched("branch", true);
      validation.setErrors("branch");
    }

    setRateSheetAdding(true);
    let allSuccess = false;
    for (var branchValue of branchValues) {
      const requestRateSheetBody = {
        BranchID: branchValue !== 0 && branchValue !== null ? branchValue : 0,
        EndTime: null,
        Status: 2,
        isAllBranch:
          (isTransfer && branchValue === 0) ||
          (!isTransfer && branchValue === 0)
            ? true
            : false,
        isTransfer: isTransfer,
      };

      try {
        const historyResponse = await insertRateSheet(
          requestRateSheetBody,
          values,
          branchValue,
          0,
          true,
          false
        );
        allSuccess = historyResponse?.success;
      } catch (error) {
        console.error(error);
        allSuccess = false;
      }
    }

    if (allSuccess) {
      toastSucess("Draft Saved Successfully!");
      resetModal();
      rateSheet();
      toggle();
    } else {
      if (branchValues.length) {
        toastError("An error occurred while saving draft.");
      }
    }
    setRateSheetAdding(false);
  };

  const forceApproveRateSheet = async (allSuccess, values) => {
    const result = await Swal.fire({
      text: "An Active Sheet Already exists, Do you want to elapse the active sheet?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes, Elapse it",
      confirmButtonColor: "#556ee6",
    });
    setRateSheetAdding(true);
    if (result.isConfirmed) {
      for (const branchValue of forceApproveBranches) {
        const elapseResponse = await putApiData(
          `api/RateMaster/ForceUpdateActiveRateSheet?branchId=${branchValue}&isTransfer=${isTransfer}`
        );
        if (elapseResponse?.success) {
          const requestRateSheetBody = {
            BranchID: branchValue,
            EndTime: null,
            Status: 1,
            isAllBranch:
              (isTransfer && branchValue === 0) ||
              (!isTransfer && branchValue === 0)
                ? true
                : false,
            isTransfer: isTransfer,
          };
          const insertResponse = await insertRateSheet(
            requestRateSheetBody,
            values,
            branchValue,
            1
          );
          if (insertResponse?.success) {
            allSuccess = true;
          } else {
            allSuccess = false;
          }
        }
      }
    }
    setRateSheetAdding(false);
    return allSuccess;
  };

  const rateError = (e) => {
    if (!/^\d*\.?\d*$/.test(e.target.value) && e.target.value !== "") {
      toast.error("Please Enter Only Positive Numbers", {
        position: "top-right",
        autoClose: 3000,
        closeButton: false,
      });
    }
  };

  const renderAgentCharges = (item, row, index) => {
    return (
      <div>
        <style>
          {`
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }

            input[type="number"] {
            -moz-appearance: textfield;
            }
          `}
        </style>
        <Input
          name="agentCharges"
          type="number"
          step="any"
          min={0}
          placeholder="Agent Charges"
          title="Agent Charges"
          aria-label="Agent Charges"
          defaultValue={item.agentCharges ? item.agentCharges : ""}
          onChange={(e) => {
            const newValue = e.target.value.replace(/\s+/g, "");
            row.original.rateData[index].agentCharges = newValue;
          }}
          onBlur={rateError}
          onFocus={(e) => {
            e.currentTarget.onwheel = (event) => event.preventDefault();
          }}
          style={{ width: RateSize.AGENT_CHARGES }}
        />
      </div>
    );
  };

  const renderRateValue = (item, row, index) => {
    return (
      <Input
        name="value"
        type="number"
        step="any"
        min={0}
        placeholder={item.rateType + ` Rate`}
        defaultValue={item.value ? item.value : ""}
        title={item.rateType + ` Rate`}
        aria-label={item.rateType + ` Rate`}
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s+/g, "");
          row.original.rateData[index].value = newValue;
        }}
        onBlur={rateError}
        onFocus={(e) => {
          e.currentTarget.onwheel = (event) => event.preventDefault();
        }}
        className="mr-1"
        style={{
          marginLeft: item.rateType === "Transfer" ? "0px" : "10px",
          width: RateSize.VALUE,
        }}
      />
    );
  };

  const renderWireTransferFee = (item, row, index) => {
    return (
      <Input
        name="wireTransferFee"
        type="number"
        step="any"
        min={0}
        placeholder={"Wire Transfer Fee"}
        title="Wire Transfer Fee"
        aria-label="Wire Transfer Fee"
        defaultValue={item.wireTransferFee ? item.wireTransferFee : ""}
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s+/g, "");
          row.original.rateData[index].wireTransferFee = newValue;
        }}
        onBlur={rateError}
        onFocus={(e) => {
          e.currentTarget.onwheel = (event) => event.preventDefault();
        }}
        style={{ width: RateSize.WIRE_TRANSFER_FEE }}
      />
    );
  };

  const renderCommissionCharges = (item, row, index) => {
    return (
      <Input
        name="commissionCharges"
        type="number"
        step="any"
        min={0}
        placeholder={"Commission Charges"}
        title="Commission Charges"
        aria-label="Commission Charges"
        defaultValue={item.commissionCharges ? item.commissionCharges : ""}
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s+/g, "");
          row.original.rateData[index].commissionCharges = newValue;
        }}
        onBlur={rateError}
        onFocus={(e) => {
          e.currentTarget.onwheel = (event) => event.preventDefault();
        }}
        style={{ width: RateSize.COMMISSION_CHARGES }}
      />
    );
  };

  const renderWorldRate = (item, row, index) => {
    return (
      <Input
        name="worldRate"
        type="number"
        step="any"
        min={0}
        placeholder={"World Rate"}
        defaultValue={item.worldRate ? item.worldRate : ""}
        title="World Rate"
        aria-label="World Rate"
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s+/g, "");
          row.original.rateData[index].worldRate = newValue;
        }}
        onBlur={rateError}
        onFocus={(e) => {
          e.currentTarget.onwheel = (event) => event.preventDefault();
        }}
        style={{ width: RateSize.WORLD_RATE }}
      />
    );
  };

  const renderRateTypes = ({ row }) => {
    return (
      <div
        className="p-2"
        style={{ border: isTransfer ? "none" : "1px solid #ccc" }}
      >
        {row.original.rateData?.map((item, index) => (
          <div
            key={index}
            className={`d-flex flex-wrap align-items-center gap-1 py-1 ${
              !isTransfer && index < row.original.rateData?.length - 1
                ? "border-bottom border-light"
                : ""
            }`}
          >
            {!isTransfer && <span className="mr-2">{item.rateType}</span>}
            {renderRateValue(item, row, index)}
            {renderAgentCharges(item, row, index)}

            {isTransfer && (
              <>
                {renderWireTransferFee(item, row, index)}
                {renderCommissionCharges(item, row, index)}
                {renderWorldRate(item, row, index)}
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Currency Name",
        accessor: "currencyName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          //   return cellProps.value ? cellProps.value : "";
          const { currencyFlag } = cellProps.row.original;
          return (
            <>
              {displayFlag(currencyFlag)}
              {cellProps.value ?? ""}
            </>
          );
        },
      },
      {
        Header: "Rate Types",
        accessor: "rateData",
        disableFilters: true,
        filterable: false,
        Cell: renderRateTypes,
      },
    ],
    [isTransfer]
  );

  const handleValueChange = (e, rowIndex, clearValue = false) => {
    const newValue = clearValue ? "" : e.target.value;
    setRateMasterData((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        value: newValue,
      };
      isAtLeastOneValueFilled(updatedData);
      return updatedData;
    });
  };

  const handleSubmit = async (values) => {
    setSubmitting(true);
    const branchValues = Array.isArray(values.branch)
      ? values.branch.map((branch) => branch.value)
      : [];

    if (branchValues == null || branchValues?.length === 0) {
      toastError("Please Select a Branch");
    }

    if (validateFormValues(values)) {
      if (!isAtLeastOneValueFilled(values.tableData)) {
        setSubmitting(false);
        return;
      }

      let allSuccess = false;
      setRateSheetAdding(true);

      for (const branchValue of branchValues) {
        if (branchValue !== 0 && branchValue !== null) {
          const requestRateSheetBody = {
            BranchID: branchValue,
            EndTime: null,
            Status: 1,
            isAllBranch: false,
            isTransfer: isTransfer,
          };

          try {
            const response = await postApiData(
              `api/RateMaster/InsertRateSheet`,
              JSON.stringify(requestRateSheetBody)
            );
            if (response?.success) {
              const requestBodyRateHistory = values.tableData?.flatMap((item) =>
                item.rateData
                  ?.map((rate) => ({
                    rateSheetID: response?.data?.rateSheetId,
                    branchId: branchValue,
                    rateType: rateTypeMapping[rate.rateType],
                    currencyCode: item.currencyCode,
                    currencyName: item.currencyName,
                    value: rate.value,
                    agentCharges: rate.agentCharges,
                    worldRate: rate.worldRate,
                    wireTransferFee: rate.wireTransferFee,
                    commissionCharges: rate.commissionCharges,
                    active: requestRateSheetBody.Status,
                  }))
                  .filter(
                    (row) =>
                      row.value ||
                      row.agentCharges ||
                      row.worldRate ||
                      row.wireTransferFee ||
                      row.commissionCharges
                  )
              );

              const insertResponse = await insertRateHistory(
                requestBodyRateHistory
              );
              if (!insertResponse?.success) {
                allSuccess = false;
              } else {
                allSuccess = true;
              }
            } else {
              forceApproveBranches.push(branchValue);
            }
          } catch (error) {
            console.error(error);
            allSuccess = false;
          }
        } else if (branchValue === 0) {
          const requestRateSheetBody = {
            BranchID: 0,
            EndTime: null,
            Status: 1,
            isAllBranch: true,
            isTransfer: isTransfer,
          };

          try {
            const insertResponse = await insertRateSheet(
              requestRateSheetBody,
              values,
              branchValue,
              1
            );

            if (!insertResponse?.success) {
              forceApproveBranches.push(branchValue);
              allSuccess = false;
            } else {
              allSuccess = true;
            }
          } catch (error) {
            console.error(error);
            allSuccess = false;
          }
        }
      }
      setRateSheetAdding(false);
      if (forceApproveBranches?.length > 0) {
        allSuccess = await forceApproveRateSheet(allSuccess, values);
      }

      if (allSuccess) {
        toastSucess("Rate Sheets Inserted Successfully!");
        rateSheet();
        resetModal();
        toggle();
      }
    }
    setSubmitting(false);
  };

  const validation = useFormik({
    initialValues: {
      branch:
        isSystemAdmin && !isTransfer
          ? null
          : isTransfer && isSystemAdmin
          ? { label: "All", value: 0 }
          : { label: "userBranch", value: branchId },
      tableData: rateMasterData,
      isTransfer: isTransfer,
    },

    validationSchema: Yup.object({
      branch: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string().required("Please Select a Branch"),
            value: Yup.string().required("Please Select a Branch"),
          })
        )
        .min(1, "At least one branch must be selected"),
    }),

    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (isOpen) {
      if (!isSystemAdmin && branchId) {
        validation.setFieldValue("branch", {
          label: "userBranch",
          value: branchId,
        });
      }
      if (rateMasterData) {
        validation.setFieldValue("tableData", rateMasterData);
      }
    }
  }, [isOpen, isSystemAdmin, branchId, rateMasterData]);

  useEffect(() => {
    if (
      isTransfer &&
      branchOptions.length > 0 &&
      !validation.values.branch?.length
    ) {
      validation.setFieldValue("branch", [branchOptions[0]]);
    }
  }, [isTransfer, branchOptions, validation]);

  useEffect(() => {
    if (!isTransfer) {
      validation.setFieldValue("branch", null);
    }
  }, [isTransfer]);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      {isLoading ? (
        <Loader />
      ) : (
        <Modal
          isOpen={isOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          size="xl"
          toggle={toggle}
          onClosed={() => {
            if (validation.values.tableData !== null) {
              resetModal();
            }
          }}
        >
          <div className="modal-content">
            <Form
              className="add-rate"
              id="addRateForm"
              onSubmit={(e) => {
                e.preventDefault();
                return false;
              }}
              ref={formRef}
            >
              <ModalHeader
                toggle={() => {
                  toggle();
                }}
              >
                Add New Rate Sheet
              </ModalHeader>
              <ModalBody>
                {isSystemAdmin && (
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="branch">Branch</Label>
                      <ReactSelect
                        name="branch"
                        id="branch"
                        placeholder="Select Branch"
                        options={branchOptions}
                        isMulti={true}
                        onChange={handleBranchChange}
                        styles={SelectStyle}
                        value={
                          isTransfer
                            ? branchOptions[0]
                            : validation.values.branch || []
                        }
                        isClearable={true}
                        invalid={
                          validation.touched.branch && validation.errors.branch
                            ? true
                            : false
                        }
                        isDisabled={isTransfer}
                      />
                      {validation.values.branch === null &&
                        validation.touched.branch &&
                        validation.errors.branch && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "80%" }}
                          >
                            Please Select a Branch
                          </span>
                        )}
                    </FormGroup>
                  </Col>
                )}
                <TableContainer
                  columns={columns}
                  data={copy ? sendRateSheet : rateMasterData}
                  isAddOptions={false}
                  customPageSize={
                    copy ? sendRateSheet?.length : rateMasterData?.length
                  }
                  isPageSelect={false}
                  onValueChange={handleValueChange}
                  pageSizeOptions={true}
                />
              </ModalBody>
              <ModalFooter className="w-100">
                <div className="w-75">
                  <Button
                    color="success"
                    name="createActiveSheet"
                    type="submit"
                    onClick={validation.handleSubmit}
                    disabled={submitting}
                  >
                    Create an Active Sheet
                  </Button>
                </div>
                <div
                  style={{
                    width: "20%",
                  }}
                >
                  <Button
                    color="primary"
                    className="mr-1"
                    name="saveAsDraft"
                    onClick={() => saveAsDraft(validation.values, true)}
                    disabled={submitting}
                  >
                    Save as Draft
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          </div>
        </Modal>
      )}
      {rateSheetAdding && <CustomLoader text="Creating RateSheet" />}
    </React.Fragment>
  );
};

export default AddRateSheetToggle;
