import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../images/logo-dark.png";
import { FaDownload } from "react-icons/fa";
import { getApiData } from "../../helpers/axiosHelper";
import { toast } from "react-toastify";
import Footer from "../../components/SuperAdminLayout/Footer";
import { mapTransactionType } from "../../common/data/StatusLabels";
import { formatDateToUserTimeZone } from "../../common/data/date";
import { rateToFixed } from "../../common/data/commonfunctions";
import Loader from "../../components/Common/Loader";
import { TransactionType } from "../../common/data/transactionType";

const ReceiptModal = ({ show, handleClose, transactionID }) => {
  const [receiptData, setReceiptData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const loadLogoAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL("image/png"));
      };
      img.onerror = (err) => reject(err);
    });
  };

  const getTransaction = async () => {
    setIsLoading(true);
    try {
      const response = await getApiData(
        `api/Transaction/GetTransaction?TransactionID=${transactionID}`
      );
      const mappedResponse = response?.data?.map((item) => ({
        transactionId: item.transactionId,
        fromCustomerId: item.fromCustomerId,
        agentId: item.agentId,
        customerName: item.customerName,
        agentName: item.agentName,
        branchName: item.branchName,
        fromBranchId: item.fromBranchId,
        transactionDate: item.transactionDate,
        fromCurrType: item.fromCurrType,
        fromCurrValue: item.fromCurrValue,
        toCurrType: item.toCurrType,
        toCurrValue: item.toCurrValue,
        transactionType: mapTransactionType(item.transactionType),
        discountedRate: item.discountedRate,
        exchangeRate: item.exchangeRate,
      }));
      setReceiptData(mappedResponse);
      setReceiptData(response);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (transactionID) {
      getTransaction();
    }
  }, [transactionID]);

  const generatePDF = async () => {
    const {
      transactionId,
      transactionDate,
      customerName,
      fromCurrType,
      fromCurrValue,
      transactionType,
      toCurrType,
      toCurrValue,
      agentBranch,
      discountedRate,
      branchName,
      exchangeRate,
    } = receiptData;
    const toCurrency = toCurrType;

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 3;

    const logoWidth = 35;
    const logoHeight = 5;
    const logoX = (pageWidth - logoWidth) / 2; // Center horizontally
    const logoY = margin + 5; // Adjust y position from top
    try {
      const logoBase64 = await loadLogoAsBase64(logo); // Load the logo as base64
      doc.addImage(logoBase64, "PNG", logoX, logoY, logoWidth, logoHeight);
    } catch (error) {
      console.error("Error loading logo:", error);
    }

    const titleFontSize = 15;
    doc.setFontSize(titleFontSize);
    doc.setFont("helvetica", "bold");
    const titleY = logoY + logoHeight + 10; // Position below the logo
    doc.text("Transaction Receipt", pageWidth / 2, titleY, { align: "center" });

    const detailsMarginBottom = 5; // Space between title and details
    const leftDetailsY = titleY + 10 + detailsMarginBottom; // Position details below the title
    const rightDetailsY = leftDetailsY;

    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    doc.text(`Transaction ID: ${transactionId}`, 20, leftDetailsY);
    doc.text(
      `Transaction Date: ${formatDateToUserTimeZone(transactionDate)}`,
      20,
      leftDetailsY + 10
    );
    doc.text(
      `Branch Name: ${branchName || "N/A"}`,
      pageWidth - 20,
      rightDetailsY,
      { align: "right" }
    );
    doc.text(
      `Customer Name: ${
        receiptData?.fromCustomerId
          ? receiptData.customerName
          : receiptData?.agentId
          ? receiptData.agentName
          : "Counter Customer"
      }`,
      pageWidth - 20,
      rightDetailsY + 10,
      { align: "right" }
    );

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Currency Exchange", pageWidth / 2, 70, { align: "center" }); // Centered title before the table
    doc.autoTable({
      startY: 75,
      head: [
        [
          "Type",
          "Currency",
          "F/C Amount",
          "Rate",
          `${toCurrency} Amount`,
          //  "Payout Currency",
        ],
      ],
      body: [
        [
          //transactionType,
          `${receiptData?.agentId ? "Agent" : "Customer"} - ${
            mapTransactionType(transactionType)?.label
          }`,
          fromCurrType,
          fromCurrValue,
          exchangeRate,
          toCurrValue,
          // `SGD : ${toCurrValue}` || "N/A",
        ],
      ],
      theme: "grid",
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [10, 10, 10],
      },
      styles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
      margin: { horizontal: 20 },
      // styles: { fontSize: 10 },
    });
    const signatureStartY = doc.lastAutoTable.finalY + 15;
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    doc.text("Admin Signature", 30, signatureStartY);
    doc.text(
      "Receiver Signature",
      doc.internal.pageSize.getWidth() - 60,
      signatureStartY
    );
    if (receiptData?.sign?.sign) {
      const signatureImage = `data:image/png;base64,${receiptData.sign.sign}`;
      const imageX = pageWidth - 55;
      const imageY = signatureStartY + 3;
      const imageWidth = 30;
      const imageHeight = 10;
      doc.addImage(
        signatureImage,
        "PNG",
        imageX,
        imageY,
        imageWidth,
        imageHeight
      );
    }

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(
      "Thank you for using Crescent Exchange",
      doc.internal.pageSize.getWidth() / 2,
      doc.lastAutoTable.finalY + 40,
      { align: "center" }
    );
    doc.text(
      `${new Date().getFullYear()} © Crescent Exchange`,
      doc.internal.pageSize.getWidth() / 2,
      doc.lastAutoTable.finalY + 45,
      { align: "center" }
    );
    doc.text(
      "11 Collyer Quay, The Arcade #03-07B",
      doc.internal.pageSize.getWidth() / 2,
      doc.lastAutoTable.finalY + 50,
      { align: "center" }
    );
    doc.text(
      "Singapore 049317 Tel: +65 8590 0090 | +65 9055 5575",
      doc.internal.pageSize.getWidth() / 2,
      doc.lastAutoTable.finalY + 55,
      { align: "center" }
    );
    doc.save("receipt.pdf");
  };

  const charges =
    receiptData?.transactionType === 1
      ? receiptData?.fromRatehistory || {}
      : receiptData?.fromCurrType === "SGD"
      ? receiptData?.toRatehistory || {}
      : receiptData?.toCurrType === "SGD"
      ? receiptData?.toRatehistory || {}
      : receiptData?.toRatehistory || {};

  const isSGD =
    receiptData?.fromCurrType === "SGD" || receiptData?.toCurrType === "SGD";

  const wireTransferFee = parseFloat(charges?.wireTransferFee || "0.00");
  const commissionCharges = parseFloat(charges?.commissionCharges || "0.00");
  const agentCharges = parseFloat(charges?.agentCharges || "0.00");

  const transactionType = receiptData?.transactionType;

  const rateValue = parseFloat(charges?.value || "0.00");

  const rate = parseFloat(
    receiptData?.buyRateHistoryMainBranch?.value || "0.00"
  );

  const calculateFee = (fee) => {
    return isSGD ? fee : parseFloat((fee / rate).toFixed(2));
  };

  const totalFees =
    transactionType === 3
      ? isSGD
        ? wireTransferFee + commissionCharges + agentCharges
        : calculateFee(wireTransferFee) +
          calculateFee(commissionCharges) +
          calculateFee(agentCharges)
      : transactionType === 1 || transactionType === 2
      ? isSGD
        ? agentCharges
        : calculateFee(agentCharges)
      : 0;

  const handlePrint = () => {
    const printWindow = window.open("", "", "width=900,height=600");

    printWindow.document.write(`
  <html>
        <head>
            <title>&#160;</title>
            <style>
         @media print {
            @page {
              size: 80mm 260mm;
              margin: 0;
            }
               body {
                margin: 0;
              }
              .no-print {
                display: none;
              }
    }
            body {
              margin: 0;
              padding: 0;
            }
            .receipt {
              width: 80mm;
              text-align: left;
              margin: 30;
            }
            .header, .footer {
              text-align: center;
              margin-bottom: 10px;
            }
            .header img {
              max-height: 80px;
              max-width: 150px;
              margin: auto;
            }
            .details, .amount {
              margin-bottom: 15px;
            }
            .details p, .amount p {
              margin: 5px 0;
              font-size: 12px;
            }
            .divider {
              border-top: 1px dashed #000;
              margin: 10px 0;
            }
            .currency-exchange {
              width: 80%;
              margin: auto;
              padding: 10px;
              border: 0px solid #000;
              border-radius: 8px;
            }
            .currency-row {
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              margin-bottom: 5px;
            }
            .currency-row:not(:last-child) {
              border-bottom: 1px dashed #000;
              padding-bottom: 5px;
            }
            .signature-section {
              margin-top: 40px;
              text-align: center;
            }
            .footer p {
              font-size: 10px;
              margin: 5px 0;
            }
            .head {
              text-align: center;
               font-size: 20px;
              margin-top: 5px;
              margin-bottom: 10px;
            }
        </style>
        </head>
        <body>
          <div class="receipt">
            <div class="header">
              <img src="${logo}" alt="Logo">
              <p>
                 #03-07B The Arcade<br>
                 11 Collyer Quay <br>
                Singapore 049317<br>
                Tel: +65 8590 0090 | +65 9055 5575
              </p>
            </div>
            <div class="divider"></div>

            <div class="details">
             <div class="head"><strong>Transaction Receipt</strong></div>
             </p>
              <p><strong>Transaction Date:</strong> ${
                formatDateToUserTimeZone(receiptData?.transactionDate) || "N/A"
              }</p>
              <p><strong>Transaction ID:</strong> ${
                receiptData?.transactionId || "N/A"
              }</p>
              <p><strong>Operator:</strong> Admin</p>
              <p><strong>Branch Name:</strong> ${
                receiptData?.branchName || "N/A"
              }</p>
              <p><strong>
                    ${
                      receiptData?.fromCustomerId
                        ? "Customer: "
                        : receiptData?.agentId
                        ? "Agent: "
                        : "Customer: "
                    }
                  </strong> ${
                    receiptData?.fromCustomerId
                      ? receiptData.customerName
                      : receiptData?.agentId
                      ? receiptData.agentName
                      : "Counter Customer"
                  }</p>
               <p><strong>Nat:</strong> Singaporean</p>
                <p><strong>Add:</strong> 11 Collyer Quay #01-18 The Arcade</p>
            </div>
            <div class="divider"></div>

            <div class="currency-exchange">
              <div class="currency-row">
                <strong>Currency</strong>
                <strong>Amount ($)</strong>
              </div>
              <div class="currency-row">
                <strong>${receiptData?.fromCurrType || "N/A"}</strong>
                ${
                  receiptData?.fromCurrValue &&
                  !isNaN(receiptData.fromCurrValue)
                    ? parseFloat(
                        Number(receiptData.fromCurrValue).toFixed(2)
                      ).toString()
                    : "N/A"
                }

              </div>

            ${
              transactionType === 3 &&
              charges?.wireTransferFee !== null &&
              calculateFee(charges.wireTransferFee) !== Infinity &&
              !isNaN(calculateFee(charges.wireTransferFee))
                ? `
              <div class="currency-row">
                <strong>Wire Transfer Fee</strong>
                 ${calculateFee(wireTransferFee)}
              </div>`
                : ""
            }

            ${
              transactionType === 3 &&
              charges?.commissionCharges !== null &&
              calculateFee(charges.commissionCharges) !== Infinity &&
              !isNaN(calculateFee(charges.commissionCharges))
                ? `
              <div class="currency-row">
                <strong>Commission Charges</strong>
                 ${calculateFee(commissionCharges)}
              </div>`
                : ""
            }

            ${
              (transactionType === 3 ||
                transactionType === 1 ||
                transactionType === 2) &&
              charges?.agentCharges !== "" &&
              !isNaN(calculateFee(charges.agentCharges)) &&
              calculateFee(charges.agentCharges) !== Infinity
                ? `
              <div class="currency-row">
                <strong>Agent Charges</strong>
                  ${calculateFee(agentCharges)}
              </div>`
                : ""
            }

            ${
              transactionType === 3 &&
              (charges?.agentCharges ||
                charges?.commissionCharges ||
                charges?.wireTransferFee) &&
              totalFees !== Infinity &&
              !isNaN(totalFees)
                ? `
              <div class="currency-row">
                <strong>Total Fees</strong>
                 ${parseFloat(parseFloat(totalFees).toFixed(2))}
              </div>`
                : ""
            }
              <div class="currency-row">
                <strong>Exchange Rate</strong>
                ${
                  receiptData?.exchangeRate && !isNaN(receiptData.exchangeRate)
                    ? parseFloat(
                        Number(receiptData.exchangeRate).toFixed(8)
                      ).toString()
                    : "N/A"
                }

              </div>
              <div class="currency-row">
                <strong>${receiptData?.toCurrType || "N/A"}</strong>
                ${
                  receiptData?.toCurrValue && !isNaN(receiptData.toCurrValue)
                    ? parseFloat(
                        Number(receiptData.toCurrValue).toFixed(2)
                      ).toString()
                    : "N/A"
                }

              </div>
            </div>
            <div class="divider"></div>

            <div class="signature-section">
            ${
              receiptData?.sign?.sign.startsWith("iVBORw0")
                ? `
                <img src="data:image/png;base64,${receiptData?.sign?.sign}"
                  alt="Signature" style="width: 150px; height: 50px; object-fit: contain; cursor: pointer;" />
              `
                : ""
            }
            <p><strong>Receiver Signature</strong></p>
          </div>

            <div class="footer">
              <p>Thank you for using Crescent Exchange</p>
              <p>${new Date().getFullYear()} © Crescent Exchange</p>
            </div>
          </div>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.focus();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.close();
    };
  };

  const handleImageClick = (imageSrc) => {
    const imageUrl = `data:image/png;base64,${imageSrc}`;
    const newTab = window.open("", "_blank");
    newTab.document.write(
      `<img src="${imageUrl}" alt="Signature" style="display:block; margin:auto; width:100%; max-width:800px;"/>`
    );
    newTab.document.title = "Signature";
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body id="modal-print-content">
            <div className="text-center mb-4">
              <img
                src={logo}
                alt="Logo"
                className="img-fluid mb-3"
                style={{ maxHeight: "130px", maxWidth: "190px" }}
              />
              <p>
                #03-07B The Arcade, 11 Collyer Quay,
                <br />
                Singapore 049317&nbsp;&nbsp; |&nbsp;&nbsp;Tel : +65 8590 0090
                &nbsp;&nbsp; |&nbsp;&nbsp; +65 9055 5575
              </p>

              <hr />
            </div>
            <h3 className="text-center mb-3">Transaction Receipt</h3>
            <div className="row mb-1">
              <div className="col-6">
                <p style={{ fontSize: "15px" }}>
                  <strong>Transaction Date : </strong>
                  {formatDateToUserTimeZone(receiptData?.transactionDate) ||
                    "N/A"}
                </p>
                <p style={{ fontSize: "15px" }}>
                  <strong>Transaction ID : </strong>
                  {receiptData?.transactionId || "N/A"}
                </p>

                <p style={{ fontSize: "15px" }}>
                  <strong>Operator : </strong>
                  Admin
                </p>

                <p style={{ fontSize: "15px" }}>
                  <strong>Branch Name : </strong>
                  {receiptData?.branchName || "N/A"}
                </p>
                <p style={{ fontSize: "15px" }}>
                  <strong>
                    {receiptData?.fromCustomerId
                      ? "Customer : "
                      : receiptData?.agentId
                      ? "Agent : "
                      : "Customer : "}
                  </strong>
                  {receiptData?.fromCustomerId
                    ? receiptData.customerName
                    : receiptData?.agentId
                    ? receiptData.agentName
                    : "Counter Customer"}
                </p>

                <p style={{ fontSize: "15px" }}>
                  <strong>Nat : </strong>
                  Singaporean
                </p>
                <p style={{ fontSize: "15px" }}>
                  <strong>Add : </strong>
                  11 Collyer Quay #01-18 The Arcade
                </p>
              </div>
            </div>
            <hr />
            <div className="mb-4">
              {/* <h3 className="text-center mb-3">Currency Exchanged</h3> */}

              <div className="d-flex justify-content-center align-items-center">
                <div
                  className="receipt-box border p-4"
                  style={{
                    width: "80%",
                    maxWidth: "600px",
                    borderRadius: "8px",
                  }}
                >
                  <div className="row mb-3">
                    <div className="col-6 fs-5">
                      <strong>Currency</strong>
                    </div>
                    <div className="col-6 text-right fs-5">
                      <strong>Amount ($)</strong>
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-2">
                    <div className="col-6">
                      <strong> {receiptData?.fromCurrType || "N/A"}</strong>
                    </div>
                    <div className="col-6 text-right">
                      {receiptData?.fromCurrValue &&
                      !isNaN(receiptData.fromCurrValue)
                        ? parseFloat(
                            Number(receiptData.fromCurrValue).toFixed(2)
                          ).toString()
                        : "N/A"}
                    </div>
                  </div>
                  <hr />

                  {transactionType === 3 &&
                    charges?.wireTransferFee !== 0 &&
                    charges?.wireTransferFee !== null &&
                    !isNaN(charges.wireTransferFee) &&
                    !isNaN(calculateFee(charges.wireTransferFee)) &&
                    calculateFee(charges.wireTransferFee) !== Infinity && (
                      <>
                        <div className="row mb-2">
                          <div className="col-6">
                            <strong>Wire Transfer Fee</strong>
                          </div>
                          <div className="col-6 text-right">
                            {calculateFee(wireTransferFee)}
                          </div>
                        </div>
                        <hr />
                      </>
                    )}

                  {transactionType === 3 &&
                    charges?.commissionCharges !== 0 &&
                    charges?.commissionCharges !== null &&
                    !isNaN(charges.commissionCharges) &&
                    !isNaN(calculateFee(charges.commissionCharges)) &&
                    calculateFee(charges.commissionCharges) !== Infinity && (
                      <>
                        <div className="row mb-2">
                          <div className="col-6">
                            <strong>Commission Charges</strong>
                          </div>
                          <div className="col-6 text-right">
                            {calculateFee(commissionCharges)}
                          </div>
                        </div>
                        <hr />
                      </>
                    )}

                  {(transactionType === 3 ||
                    transactionType === 1 ||
                    transactionType === 2) &&
                    charges?.agentCharges !== 0 &&
                    charges?.agentCharges !== null &&
                    charges?.agentCharges !== "" &&
                    calculateFee(charges.agentCharges) !== Infinity &&
                    !isNaN(calculateFee(charges.agentCharges)) &&
                    !isNaN(charges?.agentCharges) && (
                      <>
                        <div className="row mb-2">
                          <div className="col-6">
                            <strong>Agent Charges</strong>
                          </div>
                          <div className="col-6 text-right">
                            {calculateFee(agentCharges)}
                          </div>
                        </div>
                        <hr />
                      </>
                    )}

                  {transactionType === 3 &&
                    (charges?.agentCharges ||
                      charges?.commissionCharges ||
                      charges?.wireTransferFee) &&
                    !isNaN(totalFees) &&
                    totalFees !== Infinity && (
                      <>
                        <div className="row mb-2">
                          <div className="col-6">
                            <strong>Total Fees</strong>
                          </div>
                          <div className="col-6 text-right">
                            {parseFloat(parseFloat(totalFees).toFixed(2))}
                          </div>
                        </div>
                        <hr />
                      </>
                    )}

                  <div className="row mb-2">
                    <div className="col-6">
                      <strong>Exchange Rate</strong>
                    </div>
                    <div className="col-6 text-right">
                      {receiptData?.exchangeRate &&
                      !isNaN(receiptData.exchangeRate)
                        ? parseFloat(
                            Number(receiptData.exchangeRate).toFixed(8)
                          ).toString()
                        : "N/A"}
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-0">
                    <div className="col-6">
                      <strong> {receiptData?.toCurrType || "N/A"}</strong>
                    </div>
                    <div className="col-6 text-right">
                      {receiptData?.toCurrValue &&
                      !isNaN(receiptData.toCurrValue)
                        ? parseFloat(
                            Number(receiptData.toCurrValue).toFixed(2)
                          ).toString()
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mb-4">
              <div className="text-end">
                <div className="d-flex flex-column align-items-end">
                  {receiptData?.sign?.sign.startsWith("iVBORw0") && (
                    <>
                      <img
                        src={`data:image/png;base64,${receiptData?.sign?.sign}`}
                        alt="Signature"
                        style={{
                          width: "150px",
                          height: "50px",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleImageClick(receiptData?.sign?.sign)
                        }
                      />
                    </>
                  )}
                  <p className="mt-2">
                    <strong>Receiver Signature</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center mt-4">
              <p>
                Thank you for using Crescent Exchange
                <br />
                {new Date().getFullYear()} © Crescent Exchange
                <br />
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={generatePDF}>
              <FaDownload /> PDF
            </Button>
            <Button variant="primary" onClick={handlePrint}>
              Print
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ReceiptModal;
