import { Button } from "reactstrap";
import config from "../../../config";
import { magnifyingGlass } from "./icons";

export const amlSearch = (dob, name, nationality) => {
  const handleViewAML = (dob, name, nationality) => {
    window.open(
      config.amlUrl +
        `birthDate=${dob}&dataset=default&name=${encodeURIComponent(
          name
        )}&nationality=${encodeURIComponent(nationality)}&schema=Person`
    );
  };
  return (
    <Button
      className="btn-sm btn-rounded me-2"
      type="button"
      color="info"
      title="AML"
      onClick={() => handleViewAML(dob, name, nationality)}
    >
      {magnifyingGlass()}
    </Button>
  );
};
