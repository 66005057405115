import React, { useState, useMemo, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Currency } from "./RateTableCol";
import EditRatesToggle from "./EditRates";
import AddRatesToggle from "./AddRates";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApiData, putApiData } from "../../helpers/axiosHelper";
import TableContainer from "../../components/Common/TableContainer";
import Loader from "./../../components/Common/Loader";
import { activate, deactivate } from "../../common/data/icons";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import CreateCurrency from "./Currency/CreateCurrency";
import useScrollPosition from "../../common/data/useScroll";
import { useCurrentPage } from "./../../common/data/CustomPagination";
import ButtonSpinner from "./../../components/Common/ButtonSpinner";
import {
  toastError,
  toastSucess,
  toastWarn,
} from "../../common/data/commonfunctions";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import { displayFlag, getCurrencyFlag } from "./../../common/data/currency";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import { helpDocUrl } from "../HelpDocs/HelpDocUrl";
import RefreshButton from "../../common/data/RefreshButton";

const RateTable = (props) => {
  document.title = "Rate Master | Crescent Exchange";
  const [modal1, setModal1] = useState(false);
  const [addRateModal, setAddRateModal] = useState(false);
  const [rateMasterData, setRateMasterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [addCurrencyModal, setAddCurrencyModal] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [originalCheckedItems, setOriginalCheckedItems] = useState({});
  const {
    saveCurrentPage,
    restoreCurrentPage,
    onPageChange,
    pageSize,
    currentPage,
  } = useCurrentPage();
  const { saveScrollPosition, restoreScrollPosition } = useScrollPosition();
  const { allCurrencies } = useLatestTransactions();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const toggleViewModal = () => setModal1(!modal1);
  const addRateModalToggle = () => setAddRateModal(!addRateModal);
  const addCurrencyModalToggle = () => setAddCurrencyModal(!addCurrencyModal);

  const rateMasterButton = [
    {
      label: "Add New Currency",
      value: 1,
      onClick: addCurrencyModalToggle,
    },
    {
      label: "Manage Currency",
      value: 2,
      onClick: addRateModalToggle,
    },
  ];

  const rate = useCallback(async () => {
    saveCurrentPage();
    saveScrollPosition();
    setIsLoading(true);
    const response = await getApiData(`api/RateMaster/GetAllRateMaster`);
    setIsLoading(false);

    const rateTypeNames = {
      1: "Buying",
      2: "Selling",
      3: "Transfer",
    };

    const groupedData = response?.reduce((acc, item) => {
      const currencyIndex = acc.findIndex(
        (data) => data.currencyCode === item.currencyCode
      );
      const rateItem = {
        rateType: rateTypeNames[item.rateType],
        value: "",
        active: item.active === 1,
        rateId: item.rateId,
      };

      if (currencyIndex > -1) {
        acc[currencyIndex].rates.push(rateItem);
      } else {
        acc.push({
          rateId: item.rateId,
          branchId: item.branchId,
          rateTypeId: item.rateType,
          currencyName: item.currencyName,
          currencyCode: item.currencyCode,
          rates: [rateItem],
          active: item.active,
          currencyFlag: getCurrencyFlag(allCurrencies, item.currencyCode),
        });
      }

      return acc;
    }, []);

    setRateMasterData(groupedData);

    const initialCheckedItems = {};
    groupedData.forEach((currency) => {
      initialCheckedItems[currency.currencyCode] = {};
      currency.rates.forEach((rate) => {
        initialCheckedItems[currency.currencyCode][rate.rateType] = {
          active: rate.active,
          rateId: rate.rateId,
        };
      });
    });
    setCheckedItems(initialCheckedItems);
    setOriginalCheckedItems(initialCheckedItems);
  }, [saveCurrentPage, saveScrollPosition]);

  const renderRateTypes = ({ row }) => (
    <div className="p-2 d-flex flex-wrap align-items-center gap-3">
      {row.original.rates.map((item, index) => (
        <div key={index} className="d-flex align-items-center gap-2 mb-0">
          <Input
            type="checkbox"
            name={item.rateType}
            checked={
              checkedItems[row.original.currencyCode]?.[item.rateType]?.active
            }
            onChange={() =>
              handleCheckboxChange(row.original.currencyCode, item.rateType)
            }
            className="mr-2"
          />
          <span>{item.rateType}</span>
        </div>
      ))}
    </div>
  );

  const handleCheckboxChange = (currencyCode, rateType) => {
    setCheckedItems((prev) => ({
      ...prev,
      [currencyCode]: {
        ...prev[currencyCode],
        [rateType]: {
          ...prev[currencyCode][rateType],
          active: !prev[currencyCode][rateType].active,
        },
      },
    }));
  };

  const handleUpdateRates = async () => {
    const payload = [];

    Object.keys(checkedItems).forEach((currencyCode) => {
      Object.keys(checkedItems[currencyCode]).forEach((rateType) => {
        const rate = checkedItems[currencyCode][rateType];
        const originalRate = originalCheckedItems[currencyCode][rateType];
        if (rate.active !== originalRate.active) {
          payload.push({
            rateId: rate.rateId,
            rateType: rateType,
            currencyCode: currencyCode,
            active: rate.active,
          });
        }
      });
    });

    if (payload.length === 0) {
      toastWarn("No rate types selected");
      return;
    }

    const result = await ConfirmationSwal(
      `Are you sure you want to update these rates?`,
      "Yes",
      "Cancel"
    );

    if (result.isConfirmed) {
      setButtonClicked(true);
      try {
        for (const rate of payload) {
          const response = await putApiData(
            `api/RateMaster/UpdateRateMaster?RateMasterID=${rate.rateId}&active=${rate.active}`
          );
          if (!response?.success) {
            toastError("Rates cannot be updated: " + response?.message);
          }
        }
        toastSucess(`Selected Rate Types updated successfully`);
        rate();
      } catch (error) {
        console.error(error);
        toastError("Error updating rates");
      } finally {
        setButtonClicked(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Currency Name",
        accessor: "currencyName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { currencyFlag } = cellProps.row.original;
          return (
            <>
              {displayFlag(currencyFlag)}
              {cellProps.value ?? ""}
            </>
          );
        },
      },
      {
        Header: "Rate Type",
        accessor: "rates",
        filterable: false,
        disableFilters: true,
        Cell: renderRateTypes,
      },
    ],
    [checkedItems]
  );

  useEffect(() => {
    rate();
  }, []);

  useEffect(() => {
    if (rateMasterData.length > 0) {
      restoreScrollPosition();
      restoreCurrentPage();
    }
  }, [rateMasterData]);

  return (
    <React.Fragment>
      <EditRatesToggle isOpen={modal1} toggle={toggleViewModal} />
      <CreateCurrency
        isOpen={addCurrencyModal}
        toggle={addCurrencyModalToggle}
        getRates={rate}
      />
      <AddRatesToggle
        isOpen={addRateModal}
        toggle={addRateModalToggle}
        getRates={rate}
      />
      <Card>
        <CardBody>
          <div className="d-flex mb-4 flex-wrap align-items-center">
            <div className="mb-0 h4 card-title">Rates</div>
            {HelpDocButton(helpDocUrl.rateMaster)}
            <div className="ms-auto d-flex align-items-center">
              <Button
                type="button"
                color="success"
                title="Update Rates"
                className="btn me-2"
                onClick={handleUpdateRates}
                disabled={buttonClicked}
              >
                {buttonClicked ? <ButtonSpinner /> : "Update"}
              </Button>
              <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                <DropdownToggle color="primary" caret>
                  Add Rates
                </DropdownToggle>
                <DropdownMenu>
                  {rateMasterButton.map((x) => (
                    <DropdownItem key={x.value} onClick={x.onClick}>
                      {x.label}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <TableContainer
              columns={columns}
              data={rateMasterData}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={pageSize}
              isPageSelect={false}
              pageSize={pageSize}
              pageIndex={currentPage}
              onPageChange={onPageChange}
              refresh={true}
              onRefreshClick={rate}
            />
          )}
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

RateTable.propTypes = {
  orders: PropTypes.array,
};

export default RateTable;
