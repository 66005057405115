import React, { useMemo } from "react";
import { Modal, ModalBody, ModalHeader, Input } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { RateSize } from "../../common/data/RateSheet";
import { displayFlag } from "../../common/data/currency";

const ViewRateSheetToggle = ({ isOpen, toggle, sendRateSheet, isTransfer }) => {
  const renderAgentCharges = (item) => {
    return (
      <Input
        name="agentCharges"
        type="number"
        step="any"
        min={0}
        title="Agent Charges"
        aria-label="Agent Charges"
        disabled
        placeholder="Agent Charges"
        defaultValue={item.agentCharges?.trim() || ""}
        style={{ width: RateSize.AGENT_CHARGES }}
      />
    );
  };

  const renderRateValue = (item) => {
    return (
      <Input
        name="value"
        type="number"
        step="any"
        min={0}
        disabled
        title={item.rateType + ` Rate`}
        aria-label={item.rateType + ` Rate`}
        placeholder={item.rateType + ` Rate`}
        defaultValue={item.value?.trim() || ""}
        className="mr-1"
        style={{ width: RateSize.AGENT_CHARGES }}
      />
    );
  };

  const renderWireTransferFee = (item) => {
    return (
      <Input
        name="wireTransferFee"
        type="number"
        step="any"
        min={0}
        title="Wire Transfer Fee"
        aria-label="Wire Transfer Fee"
        placeholder={"Wire Transfer Fee"}
        defaultValue={item.wireTransferFee?.trim() || ""}
        disabled
        style={{ width: RateSize.AGENT_CHARGES }}
      />
    );
  };

  const renderCommissionCharges = (item) => {
    return (
      <Input
        name="commissionCharges"
        type="number"
        step="any"
        min={0}
        title="Commission Charges"
        aria-label="Commission Charges"
        placeholder={"Commission Charges"}
        defaultValue={item.commissionCharges?.trim() || ""}
        disabled
        style={{ width: RateSize.AGENT_CHARGES }}
      />
    );
  };

  const renderWorldRate = (item) => {
    return (
      <Input
        name="worldRate"
        type="number"
        step="any"
        min={0}
        title="World Rate"
        aria-label="World Rate"
        placeholder={"World Rate"}
        defaultValue={item.worldRate?.trim() || ""}
        disabled
        style={{ width: RateSize.AGENT_CHARGES }}
      />
    );
  };

  const renderRateTypes = ({ row }) => {
    const currencyRates = row.original.rateData;

    return (
      <div className=" p-2" style={{ border: "1px solid #ccc" }}>
        {currencyRates?.map((item, index) => (
          <div
            key={index}
            className={`d-flex flex-wrap align-items-center gap-3 py-1 ${
              index < currencyRates?.length - 1
                ? "border-bottom border-light"
                : ""
            }`}
          >
            {!isTransfer && <span className="mr-2">{item.rateType}</span>}
            {renderRateValue(item, row, index)}
            {renderAgentCharges(item, row, index)}

            {isTransfer && (
              <>
                {renderWireTransferFee(item, row, index)}
                {renderCommissionCharges(item, row, index)}
                {renderWorldRate(item, row, index)}
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Currency Name",
        accessor: "currencyName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { currencyFlag } = cellProps.row.original;
          return (
            <>
              {displayFlag(currencyFlag)}
              {cellProps.value ?? ""}
            </>
          );
        },
      },
      {
        Header: "Rate Types",
        accessor: "rateData",
        disableFilters: true,
        filterable: false,
        Cell: renderRateTypes,
      },
    ],
    [isTransfer]
  );

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      size="xl"
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>
          {sendRateSheet[0]?.rateData[0]?.rateType === "Transfer"
            ? "Rate Sheet - Global Transfer"
            : sendRateSheet[0]?.rateData[0]?.branchId
            ? `Rate Sheet - ${sendRateSheet[0]?.rateData[0]?.branchName}`
            : "Rate Sheet - All"}
        </ModalHeader>
        <ModalBody>
          {sendRateSheet?.length === 0 ? (
            <div className="text-sm-center fs-5">No Data Found</div>
          ) : (
            <TableContainer
              columns={columns}
              data={sendRateSheet}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={sendRateSheet?.length || 10}
              isPageSelect={false}
              pageSizeOptions={true}
            />
          )}
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ViewRateSheetToggle;
