import config from "../../../config";

const getUrl = (fileName) => {
  return `${config.docUrl}${fileName}.pdf`;
};

export const helpDocUrl = {
  branch: getUrl("ManageBranch"),
  stock: getUrl("ManageStock"),
  stockLog: getUrl("StockLog"),
  currencyReport: getUrl("CurrencyReport"),
  profitReport: getUrl("ProfitAndLossReport"),
  transactionReport: getUrl("TransactionReport"),
  stockReport: getUrl("StockSoldReport"),
  branchReport: getUrl("BranchReport"),
  dashboard: getUrl("Dashboard"),
  manageCompany: getUrl("ManageCompany"),
  manageUser: getUrl("SystemUser"),
  addTransaction: getUrl("AddTransaction"),
  transactionHistory: getUrl("TransactionHistory"),
  rateSheet: getUrl("RateSheet"),
  rateMaster: getUrl("RateMaster"),
  manageTransaction: getUrl("ManageTransaction"),
  customers: getUrl("Customers"),
};
