import React, { useState } from "react";
import classnames from "classnames";
import { Collapse, Table } from "reactstrap";
import { OpenFileInNewTab } from "../../../common/data/OpenFile";
import { fileIcon } from "../../../common/data/icons";
import { putApiData } from "../../../helpers/axiosHelper";
import { toastError, toastSucess } from "../../../common/data/commonfunctions";

const TransactionDocs = (props) => {
  const { col14, t_col14, transactionDocs = [] } = props;
  const [docs, setDocs] = useState(transactionDocs);

  const softDeleteDocument = async (docID) => {
    const response = await putApiData(
      `api/TransactionDocs/SoftDeleteDoc?DocID=${docID}&Active=${false}`
    );

    if (response?.success) {
      toastSucess("Document Deleted Successfully");
      setDocs((prevDocs) => prevDocs.filter((doc) => doc.docID !== docID));
    } else {
      toastError(response?.message || "Error, Contact Admin");
    }
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFlushTwo">
        <button
          className={classnames("accordion-button", "fw-medium", {
            collapsed: !col14,
          })}
          type="button"
          onClick={t_col14}
          style={{ cursor: "pointer" }}
        >
          Document Info
        </button>
      </h2>
      <Collapse isOpen={col14} className="accordion-collapse">
        <div className="accordion-body">
          <div className="text-muted">
            <Table className="table-nowrap mb-0">
              <tbody>
                {docs ? (
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                    {docs.map((doc) => {
                      const { docID, contentType, document, transactionID } =
                        doc || {};
                      return (
                        <div className="col" key={docID}>
                          <div
                            className="card h-100 position-relative text-center"
                            style={{
                              cursor: "pointer",
                              border: "2px solid #ddd",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                            onClick={() =>
                              OpenFileInNewTab(
                                contentType,
                                document,
                                transactionID
                              )
                            }
                          >
                            {contentType === "png" ||
                            contentType === "jpg" ||
                            contentType === "jpeg" ? (
                              <img
                                src={`data:image/${contentType};base64,${document}`}
                                alt={`Document ${docID}`}
                                className="card-img-top"
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : contentType === "pdf" ? (
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                  height: "150px",
                                }}
                              >
                                {fileIcon()}
                              </div>
                            ) : null}
                            <i
                              className="fa fa-trash text-danger"
                              title="Delete document"
                              style={{
                                position: "absolute",
                                bottom: "8px",
                                right: "8px",
                                cursor: "pointer",
                                zIndex: 1,
                                transition: "transform 0.2s, color 0.2s",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                softDeleteDocument(docID);
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.transform = "scale(1.2)";
                                e.target.style.color = "#dc3545";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.transform = "scale(1)";
                                e.target.style.color = "red";
                              }}
                            ></i>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <tr>
                    <td>
                      <div
                        className="text-muted d-flex justify-content-center align-items-center"
                        style={{ minHeight: "100px" }}
                      >
                        <h3>No Docs</h3>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default TransactionDocs;
