import React, { useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { postApiData } from "../../helpers/axiosHelper";
import ShowHidePassword from "../Authentication/ShowHidePassword";
import { path } from "../../routes/path";

const ChangePassword = (props) => {
  document.title = "Change Password | Crescent Exchange";
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required("Please Enter Your current Password")
        .matches(
          /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
          "Password must be at least 8 characters long and contain at least one digit, one uppercase letter, and one special character"
        ),
      newPassword: Yup.string()
        .required("Please Enter Your new Password")
        .matches(
          /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
          "Password must be at least 8 characters long and contain at least one digit, one uppercase letter, and one special character"
        ),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),

    onSubmit: async (values) => {
      //  delete values.password_confirmation

      const response = await postApiData(
        "api/ChangePassword/ChangeUserPassword",
        JSON.stringify(values)
      );
      if (response?.success === true) {
        toast.success("Password Successfully Changed ", {
          position: "top-right",
          autoClose: 3000,

          // Clear session storage items
          // sessionStorage.clear();
        });
        navigate(path.logout);
      } else {
        toast.error(response?.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <ToastContainer closeButton={false} limit={1} />
          <Row className="justify-content-center ">
            <Col md={8} lg={6} xl={5}>
              <div className="page-title-box">
                <h4 className="mb-sm-0 font-size-18">Change Password</h4>
              </div>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div id="changePage" style={{ display: "block" }}>
                        <div className="mb-3 ">
                          <Label className="form-label">Current Password</Label>
                          <RequiredAsterisk />
                          <div className="input-group">
                            <Input
                              name="oldPassword"
                              type={isPasswordVisible ? "text" : "password"}
                              placeholder="Enter Current Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.oldPassword || ""}
                              invalid={
                                validation.touched.oldPassword &&
                                validation.errors.oldPassword
                              }
                            />
                            <ShowHidePassword
                              isPasswordVisible={isPasswordVisible}
                              toggleVisibility={() =>
                                setIsPasswordVisible(!isPasswordVisible)
                              }
                            />
                            {validation.touched.oldPassword &&
                            validation.errors.oldPassword ? (
                              <FormFeedback type="invalid">
                                {validation.errors.oldPassword}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">New Password</Label>
                          <RequiredAsterisk />
                          <div className="input-group">
                            <Input
                              name="newPassword"
                              type={isPasswordVisible ? "text" : "password"}
                              placeholder="Enter New Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.newPassword || ""}
                              invalid={
                                validation.touched.newPassword &&
                                validation.errors.newPassword
                              }
                            />
                            <ShowHidePassword
                              isPasswordVisible={isPasswordVisible}
                              toggleVisibility={() =>
                                setIsPasswordVisible(!isPasswordVisible)
                              }
                            />
                            {validation.touched.newPassword &&
                            validation.errors.newPassword ? (
                              <FormFeedback type="invalid">
                                {validation.errors.newPassword}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <div className="mb-2">
                          <Label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirm Password{" "}
                          </Label>
                          <RequiredAsterisk />
                          <div className="input-group">
                            <Input
                              name="password_confirmation"
                              type={isPasswordVisible ? "text" : "password"}
                              placeholder="Confirm Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.password_confirmation || ""
                              }
                              invalid={
                                validation.touched.password_confirmation &&
                                validation.errors.password_confirmation
                              }
                            />
                            <ShowHidePassword
                              isPasswordVisible={isPasswordVisible}
                              toggleVisibility={() =>
                                setIsPasswordVisible(!isPasswordVisible)
                              }
                            />
                            {validation.touched.password_confirmation &&
                            validation.errors.password_confirmation ? (
                              <FormFeedback type="invalid">
                                <div>
                                  {validation.errors.password_confirmation}
                                </div>
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ChangePassword);
