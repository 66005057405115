import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CompanyBeneficiaryForm from "./CompanyBeneficiaryForm";

const BeneficiaryModal = (props) => {
  const {
    isOpen,
    toggle,
    bank,
    address,
    companyBenId,
    companyId,
    custData,
    mode,
    fetchData,
    submitting,
    customerId,
    setCompanyBenId,
    beneficiaryState,
    setBeneficiaryState,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      onClosed={() => {
        setCompanyBenId(null);
        setBeneficiaryState((prev) => ({
          ...prev,
          bank: null,
          custData: null,
          docData: [],
          address: null,
        }));
      }}
    >
      <ModalHeader toggle={toggle}>Beneficiary</ModalHeader>
      <ModalBody>
        <CompanyBeneficiaryForm
          bank={bank}
          custData={custData}
          mode={mode}
          setCompanyBenId={setCompanyBenId}
          beneficiaryState={beneficiaryState}
          submitting={submitting}
          address={address}
          companyBenId={companyBenId}
          companyId={companyId}
          fetchData={fetchData}
        />
      </ModalBody>
    </Modal>
  );
};

export default BeneficiaryModal;
