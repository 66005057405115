import React from "react";
import { Badge, Button, Collapse } from "reactstrap";
import { formatDateToUserTimeZone } from "../../../common/data/date";
import { mapStatus } from "../../../common/data/StatusLabels";
import classnames from "classnames";

const ReviewInfo = (props) => {
  const {
    col11,
    t_col11,
    status,
    sendOpTimeTransactionDetails,
    commentData,
    openCommentPopup,
  } = props;

  const UserTypes = {
    1: "Admin",
    2: "Customer",
    3: "Agent",
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFlushThree">
        <button
          className={classnames("accordion-button", "fw-medium", {
            collapsed: !col11,
          })}
          type="button"
          onClick={t_col11}
          style={{ cursor: "pointer" }}
        >
          Review Info
        </button>
      </h2>
      <Collapse isOpen={col11} className="accordion-collapse">
        <div className="accordion-body">
          <div className="text-muted">
            <div
              className="mt-1 overflow-auto"
              style={{ maxHeight: "180px", maxWidth: "auto" }}
            >
              {commentData?.length > 0 ? (
                commentData?.map((comment, index) => (
                  <div key={index}>
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <h6>{UserTypes[comment.userType]}</h6>
                        <div>{formatDateToUserTimeZone(comment.createdOn)}</div>
                      </div>
                      <p>
                        Status:{" "}
                        <Badge
                          className={
                            "font-size-11 badge-soft-" +
                            mapStatus(comment.transactionStatus).color
                          }
                        >
                          {mapStatus(comment.transactionStatus).label}
                        </Badge>
                      </p>
                    </span>
                    <span>
                      <h6>
                        <b>Comment: {comment.comments}</b>
                      </h6>
                    </span>
                    <hr />
                    <span>{""}</span>
                  </div>
                ))
              ) : (
                <div
                  className="text-muted d-flex justify-content-center align-items-center"
                  style={{ minHeight: "100px" }}
                >
                  <h3>No comments to show</h3>
                </div>
              )}
            </div>
            {sendOpTimeTransactionDetails !== null &&
              (status === 1 ||
                status === 2 ||
                status === 3 ||
                status === 4 ||
                status === 8 ||
                status === 10 ||
                status === 11) && (
                <div className="mt-4">
                  <Button
                    type="button"
                    color="primary"
                    className="btn-primary"
                    onClick={() => openCommentPopup(status)}
                  >
                    Comment
                  </Button>
                </div>
              )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default ReviewInfo;
