import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { getApiData, postApiData } from "./../../../helpers/axiosHelper";
import { NotificationType } from "../../../common/data/Notification/NotificationTypes";
import { formatDateToUserTimeZone } from "../../../common/data/date";
import { useNotificationService } from "../../../helpers/Services/NotificationService";
import {
  emptyNotification,
  loading,
  sync,
  notificationIcon,
} from "../../../common/data/icons";
import ViewLatestTransactionModel from "../../../pages/Dashboard_Admin/ViewTransactionDetailsDashboard";
import ViewDetailsToggle from "../../../pages/ManageUser/ViewDetailsToggle";
import { path } from "../../../routes/path";
import { useNavigate } from "react-router-dom";
import { toastError } from "../../../common/data/commonfunctions";
import ButtonSpinner from "../../Common/ButtonSpinner";

const getNotificationTitle = (id) =>
  ({
    1: "Transaction",
    2: "Profile",
  }[id] || `Title ${id}`);

const Notifications = () => {
  // const [viewModal, setViewModal] = useState(false);
  // const [status, setStatus] = useState();
  // const [customerID, setCustomerID] = useState("");
  // const [uId, setUId] = useState(null);
  // const toggleViewDetails = () => setViewModal(!viewModal);
  const [modal1, setModal1] = useState(false);
  const toggleViewModal = () => setModal1(!modal1);
  const [transactionId, setTransactionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    bellMenu: false,
    activeTab: 1,
    totalCount: 0,
    actionCount: 0,
    infoCount: 0,
    actionNotifications: { today: [], yesterday: [], older: [] },
    infoNotifications: { today: [], yesterday: [], older: [] },
    markAsReadDropdown: {},
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const navTabs = [
    {
      name: `Actions`,
      count: notification.actionCount,
    },
    { name: `Info`, count: notification.infoCount },
  ];

  const toggleMarkAsReadDropdown = (notificationID) =>
    setNotification((prev) => {
      const updatedDropdowns = Object.keys(prev.markAsReadDropdown).reduce(
        (acc, key) => {
          acc[key] = false;
          return acc;
        },
        {}
      );

      return {
        ...prev,
        markAsReadDropdown: {
          ...updatedDropdowns,
          [notificationID]: !prev.markAsReadDropdown[notificationID],
        },
      };
    });

  const toggleBellMenu = () =>
    setNotification((prev) => ({ ...prev, bellMenu: !prev.bellMenu }));

  const toggleTab = (tab) =>
    setNotification((prev) => ({ ...prev, activeTab: tab }));

  const categorizeNotifications = (notifications) => {
    const today = new Date();
    const startOfToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const startOfYesterday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    );

    return notifications.reduce(
      (acc, notification) => {
        const date = new Date(notification.date);
        if (date >= startOfToday) acc.today.push(notification);
        else if (date >= startOfYesterday) acc.yesterday.push(notification);
        else acc.older.push(notification);
        return acc;
      },
      { today: [], yesterday: [], older: [] }
    );
  };

  // const viewCustomerDetails = (forUID, referenceID) => {
  //   setCustomerID(referenceID);
  //   setUId(forUID);
  //   toggleViewDetails();
  // };

  const getNotifications = (data) => {
    if (!data) return;

    const formattedData = {
      title: getNotificationTitle(data.title),
      desc: data.description,
      value: data.notificationID,
      date: formatDateToUserTimeZone(data.createdAt),
      type: data.type,
      isRead: data.isRead ? 1 : 0,
      referenceID: data.referenceID,
      forUID: data.forUID,
      notificationID: data.notificationID,
    };

    const allNotifications = [
      ...notification.actionNotifications.today,
      ...notification.actionNotifications.yesterday,
      ...notification.actionNotifications.older,
      ...notification.infoNotifications.today,
      ...notification.infoNotifications.yesterday,
      ...notification.infoNotifications.older,
    ];

    const isRead =
      allNotifications.some(
        (notif) => notif.notificationID === formattedData.notificationID
      ) === null;

    const isAction = data.type === NotificationType.Action;
    const isInfo = data.type === NotificationType.Info;

    if (!isRead) {
      const audio = new Audio("/assets/NotificationSound.wav");
      audio
        .play()
        .catch((err) =>
          console.error("Error playing notification sound:", err)
        );
    }

    setNotification((prev) => {
      const updateNotifications = (
        notifications,
        newNotification,
        shouldRemove
      ) => {
        if (!notifications) {
          notifications = { today: [], yesterday: [], older: [] };
        }

        if (shouldRemove) {
          const allNotifications = [
            ...notifications.today,
            ...notifications.yesterday,
            ...notifications.older,
          ];
          const filtered = allNotifications.filter(
            (notif) => notif.value !== newNotification.value
          );
          return categorizeNotifications(filtered);
        }

        return {
          today: [newNotification, ...notifications.today],
          yesterday: [...notifications.yesterday],
          older: [...notifications.older],
        };
      };

      const {
        totalCount,
        actionCount,
        infoCount,
        actionNotifications,
        infoNotifications,
      } = prev;

      return {
        ...prev,
        totalCount: formattedData.isRead ? totalCount - 1 : totalCount + 1,
        actionCount: isAction
          ? formattedData.isRead
            ? actionCount - 1
            : actionCount + 1
          : actionCount,
        infoCount: isInfo
          ? formattedData.isRead
            ? infoCount - 1
            : infoCount + 1
          : infoCount,
        actionNotifications: isAction
          ? updateNotifications(
              actionNotifications,
              formattedData,
              formattedData.isRead
            )
          : actionNotifications,
        infoNotifications: isInfo
          ? updateNotifications(
              infoNotifications,
              formattedData,
              formattedData.isRead
            )
          : infoNotifications,
      };
    });
  };

  const getInitialNotifications = async () => {
    const resp = await getApiData("api/SystemNotification/GetAll");

    if (resp?.length) {
      const notifications = resp?.map((x) => ({
        title: getNotificationTitle(x.title),
        desc: x.description,
        value: x.notificationID,
        notificationID: x.notificationID,
        date: formatDateToUserTimeZone(x.createdAt),
        type: x.type,
        forUID: x.forUID,
        referenceID: x.referenceID,
      }));

      const actionNotifications = notifications?.filter(
        (x) => x.type === NotificationType.Action
      );
      const infoNotifications = notifications?.filter(
        (x) => x.type === NotificationType.Info
      );

      setNotification((prev) => ({
        ...prev,
        totalCount: notifications?.length,
        actionCount: actionNotifications?.length,
        infoCount: infoNotifications?.length,
        actionNotifications: categorizeNotifications(actionNotifications),
        infoNotifications: categorizeNotifications(infoNotifications),
      }));
    }
  };

  useEffect(() => {
    getInitialNotifications();
  }, []);

  useNotificationService({
    onNotificationReceived: getNotifications,
  });

  const handleMarkAllAsRead = async (tab) => {
    try {
      setIsLoading(true);
      const allNotifications =
        tab === NotificationType.Action
          ? [
              ...notification.actionNotifications.today,
              ...notification.actionNotifications.yesterday,
              ...notification.actionNotifications.older,
            ]
          : tab === NotificationType.Info
          ? [
              ...notification.infoNotifications.today,
              ...notification.infoNotifications.yesterday,
              ...notification.infoNotifications.older,
            ]
          : [];

      if (allNotifications.length === 0) {
        return;
      }

      const ids = allNotifications.map((x) => x.notificationID);
      const resp = await postApiData(
        "api/SystemNotification/MarkAllAsRead",
        ids
      );
      if (resp) {
        setIsLoading(false);
        setNotification((prev) => ({
          ...prev,
          totalCount:
            prev.totalCount -
            (tab === NotificationType.Action
              ? prev.actionCount
              : tab === NotificationType.Info
              ? prev.infoCount
              : 0),
          actionCount: tab === NotificationType.Action ? 0 : prev.actionCount,
          infoCount: tab === NotificationType.Info ? 0 : prev.infoCount,
          actionNotifications:
            tab === NotificationType.Action ? [] : prev.actionNotifications,
          infoNotifications:
            tab === NotificationType.Info ? [] : prev.infoNotifications,
        }));
      } else if (!resp) {
        toastError("Error in marking all as read");
      }
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotificationClick = async (notificationID, type) => {
    try {
      await postApiData(
        `api/SystemNotification/MarkAsRead?NotificationId=${notificationID}`
      );

      setNotification((prev) => {
        const updatedActionNotifications = removeNotification(
          prev.actionNotifications,
          notificationID
        );
        const updatedInfoNotifications = removeNotification(
          prev.infoNotifications,
          notificationID
        );

        return {
          ...prev,
          actionNotifications: updatedActionNotifications,
          infoNotifications: updatedInfoNotifications,
          actionCount:
            type === NotificationType.Action
              ? prev.actionCount - 1
              : prev.actionCount,
          infoCount:
            type === NotificationType.Info
              ? prev.infoCount - 1
              : prev.infoCount,
        };
      });
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const removeNotification = (notifications, notificationID) => {
    return Object.fromEntries(
      Object.entries(notifications).map(([key, items]) => [
        key,
        items?.filter((item) => item.value !== notificationID),
      ])
    );
  };

  const renderNotificationItems = (items) =>
    items.map((x) => (
      <DropdownItem
        key={x.value}
        className="d-flex align-items-start justify-content-between"
        onClick={() => {
          if (x.type === NotificationType.Action) {
            setTransactionId(x.referenceID);
            toggleViewModal();
          } else if (x.type === NotificationType.Info) {
            navigate(path.manageUser);
          }
        }}
      >
        <div>
          <strong>{x.title}</strong>
          <small className="ms-2 text-muted">{x.date}</small>
          <p
            className="mb-0 mt-1"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {x.desc}
          </p>
        </div>
        <div className="position-relative">
          <Dropdown
            key={x.notificationID}
            isOpen={notification.markAsReadDropdown[x.notificationID] || false}
            toggle={() => toggleMarkAsReadDropdown(x.notificationID)}
          >
            <DropdownToggle
              tag="button"
              className="btn"
              onClick={(e) => e.stopPropagation()}
            >
              <FontAwesomeIcon icon={faEllipsisV} size="lg" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleNotificationClick(x.notificationID, x.type);
                }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="sm"
                  className="me-2"
                />
                Mark as Read
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </DropdownItem>
    ));

  const renderCategory = (category, items) => {
    if (items?.length === 0) return null;
    return (
      <div key={category}>
        <h5 className="mt-3 d-flex align-items-center">
          {category.charAt(0).toUpperCase() + category.slice(1)}
        </h5>
        {renderNotificationItems(items)}
      </div>
    );
  };

  const renderTabContent = (notifications) =>
    Object.entries(notifications).map(([category, items]) =>
      renderCategory(category, items)
    );

  const renderEmpty = () => (
    <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
      {emptyNotification()}
      <p>No unread notifications</p>
    </div>
  );

  const handleRefresh = async () => {
    setIsLoading(true);
    await getInitialNotifications();
    setIsLoading(false);
  };
  const { bellMenu, activeTab, actionNotifications, infoNotifications } =
    notification;

  return (
    <>
      <Dropdown isOpen={bellMenu} toggle={toggleBellMenu} className="ms-3">
        <DropdownToggle
          tag="button"
          className="btn header-item position-relative"
        >
          {notificationIcon()}
          {notification.actionCount + notification.infoCount > 0 && (
            <span className="badge bg-danger rounded-circle position-absolute top-0 start-100 translate-middle mt-4 ms-n2">
              {notification.actionCount + notification.infoCount}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu
          className="dropdown-menu-end responsive-dropdown p-3"
          style={{
            minWidth: window.innerWidth <= 768 ? "345px" : "500px",
            maxHeight: "600px",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "10px",
          }}
        >
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>Notifications</h5>
            <div className="ms-auto d-flex align-items-center">
              {isLoading ? loading() : sync(handleRefresh)}
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle tag="button" className="btn">
                  <FontAwesomeIcon icon={faEllipsisV} size="lg" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem onClick={() => handleMarkAllAsRead(activeTab)}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size="sm"
                      className="me-2"
                    />
                    Mark All as Read
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <Nav tabs className="mb-3">
            {navTabs.map((tab, idx) => (
              <NavItem key={idx}>
                <NavLink
                  className={`d-flex align-items-center ${
                    activeTab === idx + 1 ? "active" : ""
                  }`}
                  style={{
                    backgroundColor:
                      activeTab === idx + 1 ? "#eff2f7" : "transparent",
                  }}
                  onClick={() => toggleTab(idx + 1)}
                >
                  {tab.name}
                  {tab.count > 0 && (
                    <span className="badge bg-danger ms-2">{tab.count}</span>
                  )}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          {isLoading ? (
            <div className="my-5 text-center">
              <ButtonSpinner size="md" color="primary" />
            </div>
          ) : (
            <TabContent activeTab={activeTab}>
              <TabPane tabId={1}>
                {Object.values(actionNotifications).some((arr) => arr?.length)
                  ? renderTabContent(actionNotifications)
                  : renderEmpty()}
              </TabPane>
              <TabPane tabId={2}>
                {Object.values(infoNotifications).some((arr) => arr?.length)
                  ? renderTabContent(infoNotifications)
                  : renderEmpty()}
              </TabPane>
            </TabContent>
          )}
        </DropdownMenu>
      </Dropdown>
      {modal1 ? (
        <ViewLatestTransactionModel
          isOpen={modal1}
          toggle={toggleViewModal}
          transactionId={transactionId}
          // getTransaction={getTransaction}
        />
      ) : null}
      {/* {viewModal ? (
        <ViewDetailsToggle
          isOpen={viewModal}
          toggle={toggleViewDetails}
          customerID={customerID}
          uId={uId}
          status={status}
          // getCustomer={getCustomer}
          toggleViewDetails={toggleViewDetails}
        />
      ) : null} */}
    </>
  );
};

export default Notifications;
