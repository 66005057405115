import React, { useState } from "react";

const ShowHidePassword = ({ isPasswordVisible, toggleVisibility }) => {
  const hidePassword = () => {
    return <i className="bx bx-hide" />;
  };

  const showPassword = () => {
    return <i className="bx bx-show" />;
  };

  return (
    <button
      type="button"
      className="btn"
      style={{ border: "1px solid #ced4da" }}
      onClick={toggleVisibility}
    >
      {isPasswordVisible ? hidePassword() : showPassword()}
    </button>
  );
};

export default ShowHidePassword;
