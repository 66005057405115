import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Badge,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap";
import { getApiData, postApiData, putApiData } from "../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import ReactSelect from "react-select";
import SelectStyle from "../../common/data/SelectStyle";
import ViewProfileComments from "./ProfileComments";
import {
  mobileNumberValidator,
  toastError,
} from "../../common/data/commonfunctions";
import BeneficiaryAccordian from "./BeneficiaryAccordian";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import ProfileImage from "./ProfileImage";
import { mapRiskType } from "../../common/data/StatusLabels";
import ShowHidePassword from "../Authentication/ShowHidePassword";
import CustomDatePicker from "../../common/data/customDate";

const EditCustomerDetails = (props) => {
  const { customerID, editMode, uId } = props;
  const [salaryRange, setSalaryRange] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  const [nationalityList, setNationalityList] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);
  const [col12, setcol12] = useState(false);
  const [col13, setcol13] = useState(false);
  const [col14, setcol14] = useState(false);
  const [state, setState] = useState({
    comments: [],
  });

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
    setcol14(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
    setcol14(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
    setcol12(false);
    setcol13(false);
    setcol14(false);
  };

  const t_col12 = () => {
    setcol9(false);
    setcol12(!col12);
    setcol11(false);
    setcol10(false);
    setcol13(false);
    setcol14(false);
  };
  const t_col13 = () => {
    setcol9(false);
    setcol10(false);
    setcol11(false);
    setcol12(false);
    setcol13(!col13);
    setcol14(false);
  };
  const t_col14 = () => {
    setcol9(false);
    setcol10(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
    setcol14(!col14);
  };

  const statusList = [
    { label: "Deactivated", value: 0 },
    { label: "Active", value: 1 },
  ];

  const getComments = async () => {
    const response = await getApiData(
      `api/ProfileComments/GetProfileComments?uid=${uId}`
    );
    if (response?.success === true) {
      setState((prevState) => ({ ...prevState, comments: response?.data }));
    } else if (response?.success === false) {
      toastError(response?.message);
    }
  };

  const mapSalaryRange = (salaryRange) => {
    if (!salaryRange) return [];
    return salaryRange.map((e) => ({
      label: e.rangeText,
      value: e.salaryRangeId,
    }));
  };

  const fetchSalaryRange = async () => {
    const response = await getApiData("api/SalaryRange/GetAllSalaryRange");
    setSalaryRange(mapSalaryRange(response));
  };

  useEffect(() => {
    fetchSalaryRange();
  }, []);

  const mapNationality = (nationality) => {
    if (!nationality) return [];
    return nationality.map((e) => ({
      label: e.nation,
      value: e.id,
    }));
  };

  const fetchNationality = async () => {
    const response = await getApiData("api/Address/GetAllNationality");
    setNationalityList(mapNationality(response));
  };

  const getCustomerDetails = async () => {
    const response = await getApiData(
      `api/User/GetUserByCustomerID?CustomerID=${customerID}`
    );

    const status = {
      1: "Active",
      2: "Deactivated",
    };

    const UserStatus = {
      1: "Verification",
      2: "Document Pending",
      3: "Approval Pending",
      4: "Approved",
      5: "Deactivated",
    };

    const mappedResponse = {
      uid: response?.uid,
      addressId: response?.addressId,
      customerId: response?.customerId,
      finId: response?.financialId,
      firstName: response?.firstName,
      middlename: response?.middleName,
      lastName: response?.lastName,
      email: response?.email,
      dob: response?.dob,
      password: response?.password,
      mobile: response?.mobile,
      userStatus: UserStatus[response?.userStatus],
      active: response?.active,
      street: response?.street,
      city: response?.city,
      postalCode: response?.postalCode,
      state: response?.state,
      country: response?.country,
      nationality: response?.nationality,
      expectedTransactionAmt: response?.expectedTransactionAmt,
      networth: response?.networth,
      profession: response?.profession,
      riskId: response?.riskId,
      salaryRange: response?.salaryRange,
      annualSalaryRangeID: response?.annualSalaryRangeID,
    };
    setCustomerDetails(mappedResponse);
  };

  const userValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstname: customerDetails?.firstName || "",
      lastname: customerDetails?.lastName || "",
      middlename: customerDetails?.middlename || "",
      email: customerDetails?.email || "",
      mobile: customerDetails?.mobile || "",
      dob: customerDetails?.dob?.split("T")[0] || "",
      password: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only letters")
        .required("Please Enter First name"),
      middlename: Yup.string().matches(
        /^[A-Za-z\s]+$/,
        "Middle Name should contain only letters"
      ),
      lastname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name should contain only letters")
        .required("Please Enter Last name"),
      email: Yup.string().email("Invalid Email").required("Please Enter Email"),
      mobile: Yup.string()
        .required("Please Enter Phone Number")
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .matches(
          new RegExp(
            `^\\+?\\d{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
          ),
          `Invalid Mobile Number`
        ),
      dob: Yup.date()
        .required("Please Enter Date of Birth")
        .max(new Date(), "Date of Birth cannot be in the future"),
      password: Yup.string().matches(
        /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
        "Password must be at least 8 characters and must contain at least one digit, one uppercase letter, and one special character"
      ),
      confirmpassword: Yup.string().oneOf(
        [Yup.ref("password")],
        "Passwords must match"
      ),
    }),
    onSubmit: async (values) => {
      const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

      if (result.isConfirmed) {
        const combinedValues = { ...values };

        if (values.mobile === userValidation.initialValues.mobile) {
          delete combinedValues.mobile;
        }
        if (values.email === userValidation.initialValues.email) {
          delete combinedValues.email;
        }

        if (customerID) {
          const hasChanges = Object.keys(values).some(
            (key) => values[key] !== userValidation.initialValues[key]
          );
          if (hasChanges) {
            const updatedResponse = await putApiData(
              `api/Customer/UpdateCustomer?Uid=${customerDetails?.uid}&CustomerId=${customerID}`,
              combinedValues
            );
            if (updatedResponse?.success === true) {
              toast.success("User Details Updated Successfully", {
                position: "top-right",
                autoClose: 3000,
              });
              getCustomerDetails();
            } else {
              toastError(updatedResponse?.message);
            }
          } else {
            toast.error("No Changes to Save", {
              position: "top-right",
              autoClose: 2000,
            });
          }
        }
      }
    },
  });

  const addressValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      street: customerDetails?.street || "",
      city: customerDetails?.city || "",
      state: customerDetails?.state || "",
      country: customerDetails?.country || "",
      postalcode: customerDetails?.postalCode || "",
      nationality:
        (nationalityList &&
          nationalityList.find(
            (nation) => nation.value === parseInt(customerDetails.nationality)
          )) ||
        null,
    },
    validationSchema: Yup.object({
      street: Yup.string().required("Please Enter Street"),
      city: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "City should contain only letters")
        .required("Please Enter City"),
      state: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "State should contain only letters")
        .required("Please Enter State"),
      country: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Country should contain only letters")
        .required("Please Enter Country"),
      postalcode: Yup.string()
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .required("Please Enter Postal Code"),
      nationality: Yup.object().shape({
        label: Yup.string().required("Please Select Nationality"),
        value: Yup.string().required("Please Select Nationality"),
      }),
    }),
    onSubmit: async (values) => {
      const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

      if (result.isConfirmed) {
        const nationalityValue = values.nationality && values.nationality.value;
        const combinedValues = {
          ...values,
          uId: uId,
          addressId: customerDetails.addressId,
          nationality: nationalityValue,
        };

        if (customerDetails.addressId) {
          const hasChanges = Object.keys(values).some(
            (key) => values[key] !== addressValidation.initialValues[key]
          );
          if (hasChanges) {
            const updatedResponse = await putApiData(
              `api/Address/UpdateAddress?AddressID=${customerDetails.addressId}`,
              combinedValues
            );
            if (updatedResponse?.success === true) {
              toast.success("Address Updated Successfully", {
                position: "top-right",
                autoClose: 3000,
              });
            } else {
              toastError(updatedResponse?.message);
            }
          } else {
            toast.error("No Changes to Save", {
              position: "top-right",
              autoClose: 2000,
            });
          }
        } else {
          const combinedValues = {
            ...values,
            uId: uId,
            nationality: `${nationalityValue}`,
          };

          const response = await postApiData(
            "api/Address/CreateAddress",
            JSON.stringify(combinedValues)
          );
          if (response?.success === true) {
            toast.success("Address Added Successfully", {
              position: "top-right",
              autoClose: 3000,
            });
          } else {
            toastError(response?.message);
          }
        }
      }
    },
  });

  const finValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      profession: customerDetails?.profession || "",
      annualSalaryRangeId:
        salaryRange &&
        salaryRange.find(
          (salary) =>
            salary.value === parseInt(customerDetails?.annualSalaryRangeID)
        ),
      networth: customerDetails?.networth || "",
      expectedTransAmtMth: customerDetails?.expectedTransactionAmt || "",
    },
    validationSchema: Yup.object({
      networth: Yup.string().matches(/^[0-9]*$/, "Please enter only numbers"),
      expectedTransAmtMth: Yup.string().matches(
        /^[0-9]*$/,
        "Please enter only numbers"
      ),
    }),
    onSubmit: async (values) => {
      const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

      if (result.isConfirmed) {
        const annualSalaryRangeValue =
          values.annualSalaryRangeId && values.annualSalaryRangeId.value;
        if (customerDetails.finId) {
          const combinedValues = {
            ...values,
            custFinId: customerDetails.finId,
            annualSalaryRangeId: annualSalaryRangeValue,
          };
          const hasChanges = Object.keys(values).some(
            (key) => values[key] !== finValidation.initialValues[key]
          );
          if (hasChanges) {
            const updatedResponse = await putApiData(
              `api/CustomerFinancial/UpdateCustomerFinancial?CustFinID=${customerDetails.finId}`,
              combinedValues
            );
            if (updatedResponse?.success === true) {
              toast.success("Financial Details Updated Successfully", {
                position: "top-right",
                autoClose: 3000,
              });
            } else {
              toastError(updatedResponse?.message);
            }
          } else {
            toast.error("No Changes to Save", {
              position: "top-right",
              autoClose: 2000,
            });
          }
        } else {
          const combinedValues = {
            ...values,
            customerId: customerID,
            annualSalaryRangeId: annualSalaryRangeValue,
          };
          const response = await postApiData(
            "api/CustomerFinancial/CreateCustomerFinancial",
            JSON.stringify(combinedValues)
          );
          if (response?.success === true) {
            toast.success("Financial Details Added Successfully", {
              position: "top-right",
              autoClose: 3000,
            });
          }
        }
      }
    },
  });

  useEffect(() => {
    getCustomerDetails();
    fetchNationality();
    getComments();
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="mt-4">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col9,
                    })}
                    type="button"
                    onClick={t_col9}
                    style={{ cursor: "pointer" }}
                  >
                    User Details&nbsp;&nbsp;&nbsp; {"  "}
                    <Badge
                      className={
                        "font-size-11 bg-" +
                        mapRiskType(customerDetails?.riskId)?.color
                      }
                    >
                      {mapRiskType(customerDetails?.riskId)?.label}
                    </Badge>
                  </button>
                </h2>

                <Collapse isOpen={col9} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="text-muted">
                      <Form
                        className="needs-validation"
                        onSubmit={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        <Row
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="firstname">First Name</Label>
                              <RequiredAsterisk />
                              <Input
                                name="firstname"
                                placeholder="Enter Last Name"
                                type="text"
                                id="firstname"
                                disabled={editMode}
                                value={userValidation.values.firstname}
                                onChange={userValidation.handleChange}
                                onBlur={userValidation.handleBlur}
                                invalid={
                                  userValidation.touched.firstname &&
                                  userValidation.errors.firstname
                                }
                              />
                              {userValidation.touched.firstname &&
                              userValidation.errors.firstname ? (
                                <FormFeedback type="invalid">
                                  {userValidation.errors.firstname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="middlename">Middle Name</Label>
                              <Input
                                name="middlename"
                                placeholder="Enter Middle Name"
                                id="middlename"
                                disabled={editMode}
                                value={userValidation.values.middlename}
                                onChange={userValidation.handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="lastname">Last name</Label>

                              <RequiredAsterisk />
                              <Input
                                name="lastname"
                                placeholder="Enter Last Name"
                                type="text"
                                id="lastname"
                                disabled={editMode}
                                value={userValidation.values.lastname}
                                onChange={userValidation.handleChange}
                                onBlur={userValidation.handleBlur}
                                invalid={
                                  userValidation.touched.lastname &&
                                  userValidation.errors.lastname
                                }
                              />
                              {userValidation.touched.lastname &&
                              userValidation.errors.lastname ? (
                                <FormFeedback type="invalid">
                                  {userValidation.errors.lastname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="email">Email</Label>
                              <RequiredAsterisk />
                              <Input
                                name="email"
                                placeholder="Enter Email"
                                type="email"
                                id="email"
                                disabled={editMode}
                                value={userValidation.values.email}
                                onChange={userValidation.handleChange}
                                onBlur={userValidation.handleBlur}
                                invalid={
                                  userValidation.touched.email &&
                                  userValidation.errors.email
                                }
                              />
                              {userValidation.touched.email &&
                              userValidation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {userValidation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="dob">DOB</Label>
                              <RequiredAsterisk />
                              <CustomDatePicker
                                showYear={true}
                                selectedDate={
                                  userValidation.values.dob
                                    ? new Date(userValidation.values.dob)
                                    : null
                                }
                                onChange={(date) => {
                                  const formattedDate = date
                                    ? date.toISOString().split("T")[0]
                                    : "";
                                  userValidation.setFieldValue(
                                    "dob",
                                    formattedDate
                                  );
                                }}
                                isInvalid={
                                  userValidation.touched.dob &&
                                  userValidation.errors.dob
                                }
                                onBlur={userValidation.handleBlur}
                                value={userValidation.values.dob || ""}
                                invalid={
                                  userValidation.touched.dob &&
                                  userValidation.errors.dob
                                    ? true
                                    : false
                                }
                                validationMessage={userValidation.errors.dob}
                              />
                              {userValidation.touched.dob &&
                              userValidation.errors.dob ? (
                                <FormFeedback type="invalid">
                                  {userValidation.errors.dob}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="mobile">Mobile</Label>
                              <RequiredAsterisk />
                              <Input
                                name="mobile"
                                placeholder="Enter Mobile Number"
                                type="text"
                                id="mobile"
                                disabled={editMode}
                                value={userValidation.values.mobile}
                                onChange={userValidation.handleChange}
                                onBlur={userValidation.handleBlur}
                                invalid={
                                  userValidation.touched.mobile &&
                                  userValidation.errors.mobile
                                }
                              />
                              {userValidation.touched.mobile &&
                              userValidation.errors.mobile ? (
                                <FormFeedback type="invalid">
                                  {userValidation.errors.mobile}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="password">Password</Label>
                              <div className="input-group">
                                <Input
                                  name="password"
                                  placeholder="********"
                                  disabled={editMode}
                                  type={isPasswordVisible ? "text" : "password"}
                                  id="password"
                                  value={userValidation.values.password}
                                  onChange={userValidation.handleChange}
                                  onBlur={userValidation.handleBlur}
                                  invalid={userValidation.errors.password}
                                />
                                <ShowHidePassword
                                  isPasswordVisible={isPasswordVisible}
                                  toggleVisibility={() =>
                                    setIsPasswordVisible(!isPasswordVisible)
                                  }
                                />
                                {userValidation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {userValidation.errors.password}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="confirmpassword">
                                Confirm Password
                              </Label>
                              <div className="input-group">
                                <Input
                                  name="confirmpassword"
                                  placeholder="********"
                                  type={isPasswordVisible ? "text" : "password"}
                                  id="confirmpassword"
                                  disabled={editMode}
                                  value={userValidation.values.confirmpassword}
                                  onChange={userValidation.handleChange}
                                  onBlur={userValidation.handleBlur}
                                  invalid={
                                    userValidation.errors.confirmpassword
                                  }
                                />
                                <ShowHidePassword
                                  isPasswordVisible={isPasswordVisible}
                                  toggleVisibility={() =>
                                    setIsPasswordVisible(!isPasswordVisible)
                                  }
                                />
                                {userValidation.errors.confirmpassword ? (
                                  <FormFeedback type="invalid">
                                    {userValidation.errors.confirmpassword}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        {editMode ? null : (
                          <Button
                            type="button"
                            color="primary"
                            className="btn-primary"
                            onClick={userValidation.handleSubmit}
                            disabled={userValidation.isSubmitting}
                          >
                            Update
                          </Button>
                        )}
                      </Form>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushTwo">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col10,
                    })}
                    type="button"
                    onClick={t_col10}
                    style={{ cursor: "pointer" }}
                  >
                    Address Details
                  </button>
                </h2>
                <Collapse isOpen={col10} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="text-muted">
                      <Form
                        className="needs-validation"
                        onSubmit={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        <Row
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="street">Street</Label>
                              <RequiredAsterisk />
                              <Input
                                name="street"
                                placeholder="Enter Street"
                                type="text"
                                id="street"
                                disabled={editMode}
                                value={addressValidation.values.street}
                                onChange={addressValidation.handleChange}
                                onBlur={addressValidation.handleBlur}
                                invalid={
                                  addressValidation.touched.street &&
                                  addressValidation.errors.street
                                    ? true
                                    : false
                                }
                              />
                              {addressValidation.touched.street &&
                              addressValidation.errors.street ? (
                                <FormFeedback type="invalid">
                                  {addressValidation.errors.street}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="city">City</Label>
                              <RequiredAsterisk />
                              <Input
                                name="city"
                                placeholder="Enter City"
                                type="text"
                                id="city"
                                disabled={editMode}
                                value={addressValidation.values.city}
                                onChange={addressValidation.handleChange}
                                onBlur={addressValidation.handleBlur}
                                invalid={
                                  addressValidation.touched.city &&
                                  addressValidation.errors.city
                                    ? true
                                    : false
                                }
                              />
                              {addressValidation.touched.city &&
                              addressValidation.errors.city ? (
                                <FormFeedback type="invalid">
                                  {addressValidation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="state">State</Label>
                              <RequiredAsterisk />
                              <Input
                                name="state"
                                placeholder="Enter State"
                                type="text"
                                id="state"
                                disabled={editMode}
                                value={addressValidation.values.state}
                                onChange={addressValidation.handleChange}
                                onBlur={addressValidation.handleBlur}
                                invalid={
                                  addressValidation.touched.state &&
                                  addressValidation.errors.state
                                    ? true
                                    : false
                                }
                              />
                              {addressValidation.touched.state &&
                              addressValidation.errors.state ? (
                                <FormFeedback type="invalid">
                                  {addressValidation.errors.state}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="country">Country</Label>
                              <RequiredAsterisk />
                              <Input
                                name="country"
                                placeholder="Enter Country"
                                type="text"
                                id="country"
                                disabled={editMode}
                                value={addressValidation.values.country}
                                onChange={addressValidation.handleChange}
                                onBlur={addressValidation.handleBlur}
                                invalid={
                                  addressValidation.touched.country &&
                                  addressValidation.errors.country
                                    ? true
                                    : false
                                }
                              />
                              {addressValidation.touched.country &&
                              addressValidation.errors.country ? (
                                <FormFeedback type="invalid">
                                  {addressValidation.errors.country}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="postalcode">Postal Code</Label>
                              <RequiredAsterisk />
                              <Input
                                name="postalcode"
                                placeholder="Enter Postalcode"
                                type="number"
                                id="postalcode"
                                disabled={editMode}
                                value={addressValidation.values.postalcode}
                                onChange={addressValidation.handleChange}
                                onBlur={addressValidation.handleBlur}
                                invalid={
                                  addressValidation.touched.postalcode &&
                                  addressValidation.errors.postalcode
                                    ? true
                                    : false
                                }
                              />
                              {addressValidation.touched.postalcode &&
                              addressValidation.errors.postalcode ? (
                                <FormFeedback type="invalid">
                                  {addressValidation.errors.postalcode}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="nationality">Nationality</Label>
                              <RequiredAsterisk />
                              <ReactSelect
                                name="nationality"
                                placeholder="Select Nationality"
                                type="text"
                                id="nationality"
                                options={nationalityList}
                                isDisabled={editMode}
                                value={addressValidation.values.nationality}
                                onChange={(selectedOption) =>
                                  addressValidation.setFieldValue(
                                    "nationality",
                                    selectedOption
                                  )
                                }
                                onBlur={addressValidation.handleBlur}
                                invalid={
                                  addressValidation.touched.nationality &&
                                  addressValidation.errors.nationality
                                    ? true
                                    : false
                                }
                              />
                              {addressValidation.errors.nationality &&
                              addressValidation.touched.nationality ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "80%" }}
                                >
                                  {addressValidation.errors.nationality}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        {editMode ? null : (
                          <Button
                            type="button"
                            color="primary"
                            className="btn-primary"
                            onClick={addressValidation.handleSubmit}
                            disabled={addressValidation.isSubmitting}
                          >
                            Update
                          </Button>
                        )}
                      </Form>
                    </div>
                  </div>
                </Collapse>
              </div>
              {/* profile*/}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushThree">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col14,
                    })}
                    type="button"
                    onClick={t_col14}
                    style={{ cursor: "pointer" }}
                  >
                    Profile Image
                  </button>
                </h2>
                <Collapse isOpen={col14} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="text-muted">
                      <Form
                        className="needs-validation"
                        //onSubmit={profilevalidation.handleSubmit}

                        onSubmit={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        <ProfileImage
                          customerID={customerID}
                          editMode={editMode}
                        />
                      </Form>
                    </div>
                  </div>
                </Collapse>
              </div>

              {/* profile*/}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushThree">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col11,
                    })}
                    type="button"
                    onClick={t_col11}
                    style={{ cursor: "pointer" }}
                  >
                    Financial Details
                  </button>
                </h2>
                <Collapse isOpen={col11} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="text-muted">
                      <Form
                        className="needs-validation"
                        onSubmit={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        <Row
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="profession">Profession</Label>
                              <Input
                                name="profession"
                                placeholder="Enter Profession"
                                type="text"
                                id="profession"
                                disabled={editMode}
                                value={finValidation.values.profession}
                                onChange={finValidation.handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="annualSalaryRangeId">
                                Annual Salary Range
                              </Label>
                              <ReactSelect
                                name="annualSalaryRangeId"
                                placeholder="Select Annual Salary Range"
                                id="annualSalaryRangeId"
                                options={salaryRange && salaryRange}
                                styles={SelectStyle}
                                isDisabled={editMode}
                                value={finValidation.values.annualSalaryRangeId}
                                onChange={(selecteOption) => {
                                  finValidation.setFieldValue(
                                    "annualSalaryRangeId",
                                    selecteOption
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="networth">Net Worth</Label>
                              <Input
                                name="networth"
                                placeholder="Enter Net Worth"
                                type="text"
                                id="networth"
                                disabled={editMode}
                                value={finValidation.values.networth}
                                onChange={finValidation.handleChange}
                              />
                              {finValidation.errors.networth ? (
                                <div
                                  className="text-danger"
                                  style={{ fontSize: "80%" }}
                                >
                                  {finValidation.errors.networth}
                                </div>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="expectedTransAmtMth">
                                Expected Transaction Amount
                              </Label>
                              <Input
                                name="expectedTransAmtMth"
                                placeholder="Enter Expected Transaction Amount"
                                type="text"
                                id="expectedTransAmtMth"
                                disabled={editMode}
                                value={finValidation.values.expectedTransAmtMth}
                                onChange={finValidation.handleChange}
                              />
                              {finValidation.errors.expectedTransAmtMth ? (
                                <div
                                  className="text-danger"
                                  style={{ fontSize: "80%" }}
                                >
                                  {finValidation.errors.expectedTransAmtMth}
                                </div>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        {editMode ? null : (
                          <Button
                            type="button"
                            color="primary"
                            className="btn-primary"
                            onClick={finValidation.handleSubmit}
                            disabled={finValidation.isSubmitting}
                          >
                            Update
                          </Button>
                        )}
                      </Form>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFlushThree">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col12,
                    })}
                    type="button"
                    onClick={t_col12}
                    style={{ cursor: "pointer" }}
                  >
                    Comments
                  </button>
                </h2>
                <Collapse isOpen={col12} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="text-muted">
                      <ViewProfileComments
                        editMode={editMode}
                        commentData={state.comments}
                        uId={uId}
                        getComments={getComments}
                      />
                      {/* ) : (
                      <Form  className="needs-validation"
                        onSubmit={(e) => {
                          e.preventDefault();
                          return false;
                        }}>
                      <Row
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Input
                                name="comment"
                                placeholder="Enter Your Comments..."
                                type="textarea"
                                id="comment"
                                disabled={editMode}
                                value={''}
                                onChange={''}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button
                        type="button"
                        color="primary"
                        onClick={""}
                        disabled={""}
                      >
                        Send
                      </Button>
                      </Form>
                    )} */}
                    </div>
                  </div>
                </Collapse>
              </div>
              <BeneficiaryAccordian
                editMode={editMode}
                col13={col13}
                t_col13={t_col13}
                customerId={customerID}
                UId={uId}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default React.memo(EditCustomerDetails);
