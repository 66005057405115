import React from "react";
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postApiData } from "../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import RequiredAsterisk from "./../../components/Common/RequiredAsterisk";
import { mobileNumberValidator } from "../../common/data/commonfunctions";

const AddAgentCompany = ({ isOpen, toggle, getAllCompany }) => {
  const generateCompanyCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .matches(/^[A-Za-z0-9\s]+$/, "Please enter only letters and numbers")
      .required("Please Enter Company Name"),
    street: Yup.string().required("Please Enter Street"),
    city: Yup.string().required("Please Enter City"),
    state: Yup.string().required("Please Enter State"),
    country: Yup.string().required("Please Enter Country"),
    postalCode: Yup.string().required("Please Enter PostalCode"),
    companyRegistration: Yup.string()
      .matches(/^[A-Za-z0-9]*$/, "Please enter only numbers")
      .required("Please Enter Company Registration Number"),
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Please Enter Company Email"),
    mobile: Yup.string()
      .matches(/^[0-9]*$/, "Please enter only numbers")
      .matches(
        new RegExp(
          `^\\+?\\d{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
        ),
        `Invalid Mobile Number`
      )
      .required("Please Enter Company Mobile Number"),
  });

  const initialValues = {
    companyName: "",
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    companyRegistration: "",
    email: "",
    mobile: "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const {
        companyName,
        street,
        city,
        state,
        postalCode,
        country,
        companyRegistration,
        email,
        mobile,
      } = values;
      const companyCode = generateCompanyCode();
      const requestBody = {
        companyCode,
        companyName,
        street,
        city,
        state,
        country,
        postalCode,
        companyRegistration,
        email,
        mobile,
      };

      const response = await postApiData(
        "api/AgentUser/CreateCompany",
        requestBody
      );
      if (response?.success) {
        toast.success("Agent Company Created Successfully", {
          position: "top-right",
          autoClose: 2000,
        });
        toggle();
        getAllCompany();
        resetForm();
      } else if (response?.message === "Company name already exists") {
        toast.error(
          "Company name already exists. Please use a different name.",
          {
            position: "top-right",
            autoClose: 2000,
          }
        );
      } else {
        toast.error(response?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while creating the company.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
        onClosed={() => {
          formik.resetForm();
        }}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Add Company</ModalHeader>
          <ModalBody>
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="companyName">Name</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      id="companyName"
                      placeholder="Enter Company Name"
                      {...formik.getFieldProps("companyName")}
                      invalid={
                        formik.touched.companyName && formik.errors.companyName
                      }
                    />
                    {formik.touched.companyName &&
                      formik.errors.companyName && (
                        <FormFeedback>{formik.errors.companyName}</FormFeedback>
                      )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <RequiredAsterisk />
                    <Input
                      type="email"
                      id="email"
                      placeholder="Enter Email"
                      {...formik.getFieldProps("email")}
                      invalid={formik.touched.email && formik.errors.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <FormFeedback>{formik.errors.email}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="companyRegistration">
                      Registration Number
                    </Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      id="companyRegistration"
                      placeholder="Enter Registration Number"
                      {...formik.getFieldProps("companyRegistration")}
                      invalid={
                        formik.touched.companyRegistration &&
                        formik.errors.companyRegistration
                      }
                    />
                    {formik.touched.companyRegistration &&
                      formik.errors.companyRegistration && (
                        <FormFeedback>
                          {formik.errors.companyRegistration}
                        </FormFeedback>
                      )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="mobile">Mobile Number</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      id="mobile"
                      placeholder="Enter Mobile Number "
                      {...formik.getFieldProps("mobile")}
                      invalid={formik.touched.mobile && formik.errors.mobile}
                    />
                    {formik.touched.mobile && formik.errors.mobile && (
                      <FormFeedback>{formik.errors.mobile}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                {/* <FormGroup>
                <Label htmlFor="companyAddress">Address</Label>
                <RequiredAsterisk />
                <Input
                  type="text"
                  id="companyAddress"
                  placeholder="Enter Company Address"
                  {...formik.getFieldProps("companyAddress")}
                  invalid={
                    formik.touched.companyAddress &&
                    formik.errors.companyAddress
                  }
                />
                {formik.touched.companyAddress &&
                  formik.errors.companyAddress && (
                    <FormFeedback>{formik.errors.companyAddress}</FormFeedback>
                  )}
              </FormGroup> */}

                <Col md={6}>
                  <FormGroup>
                    <Label for="street">Street</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      id="street"
                      placeholder="Enter street"
                      {...formik.getFieldProps("street")}
                      invalid={formik.touched.street && formik.errors.street}
                    />
                    {formik.touched.street && formik.errors.street && (
                      <FormFeedback>{formik.errors.street}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="city">City</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      id="city"
                      placeholder="Enter city"
                      {...formik.getFieldProps("city")}
                      invalid={formik.touched.city && formik.errors.city}
                    />
                    {formik.touched.city && formik.errors.city && (
                      <FormFeedback>{formik.errors.city}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="state">State</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      id="state"
                      placeholder="Enter state"
                      {...formik.getFieldProps("state")}
                      invalid={formik.touched.state && formik.errors.state}
                    />
                    {formik.touched.state && formik.errors.state && (
                      <FormFeedback>{formik.errors.state}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="postalCode">Postal Code</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      id="postalCode"
                      placeholder="Enter postalCode"
                      {...formik.getFieldProps("postalCode")}
                      invalid={
                        formik.touched.postalCode && formik.errors.postalCode
                      }
                    />
                    {formik.touched.postalCode && formik.errors.postalCode && (
                      <FormFeedback>{formik.errors.postalCode}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="country">Country</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      id="country"
                      placeholder="Enter Country"
                      {...formik.getFieldProps("country")}
                      invalid={formik.touched.country && formik.errors.country}
                    />
                    {formik.touched.country && formik.errors.country && (
                      <FormFeedback>{formik.errors.country}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              type="submit"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}
            >
              Submit
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default AddAgentCompany;
