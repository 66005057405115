import React, { useState, useEffect, useRef } from "react";
import { Container } from "reactstrap";
import LiveFxRates from "./LiveFxRates";
import * as signalR from "@microsoft/signalr";
import config from "../../../config";

const LiveFxRatesIndex = () => {
    document.title = "Live Rates | Crescent Exchange";

    const [rates, setRates] = useState([]);
    const [avg, setAvg] = useState(0);
    const [isConnected, setIsConnected] = useState(false);
    const connectionRef = useRef(null);
    const [finalResult, setFinalResult] = useState({});
    const [rawRates, setRawRates] = useState([]);

    const directPairLogic = (directPairs, ratesMap) => {
        return directPairs.map((pairObj) => {
            const pair = pairObj.directPair;
            const transformedPair = pair.replace("/", "");
            const rate = ratesMap[transformedPair];

            if (rate) {
                const timestamp = rate.timestamp || Date.now(); 
                return {
                    currency: pair,
                    bid: rate.bid || 0,
                    ask: rate.ask || 0,
                    high: rate.high || 0,
                    low: rate.low || 0,
                    open: rate.open || 0,
                    close: rate.close || 0,
                    time: new Date(timestamp).toISOString(), 
                };
            }
            return null;
        }).filter(Boolean);
    };

    const invertedPairLogic = (invertedPairs, ratesMap) => {
        return invertedPairs.map((pairObj) => {
            const pair = pairObj.invertedPair; 
            if (!pair) {
                console.error("Invalid inverted pair:", pairObj);
                return null;
            }

            const [base, quote] = pair.split("/"); 
            if (!base || !quote) {
                console.error("Invalid pair format:", pair);
                return null;
            }

            const flippedPair = `${quote}${base}`;
            const rate = ratesMap[flippedPair];

            if (rate) {

                const bid = 1 / rate.bid || 0;
                const ask = 1 / rate.ask || 0;
                const high = 1 / rate.high || 0;
                const low = 1 / rate.low || 0;
                const open = 1 / rate.open || 0;
                const close = 1 / rate.close || 0;
                const timestamp = rate.timestamp || Date.now();

                return {
                    currency: pair,
                    bid: parseFloat(bid.toFixed(6)),
                    ask: parseFloat(ask.toFixed(6)),
                    high: parseFloat(high.toFixed(6)),
                    low: parseFloat(low.toFixed(6)),
                    open: parseFloat(open.toFixed(6)),
                    close: parseFloat(close.toFixed(6)),
                    time: new Date(timestamp).toISOString(),
                };
            } else {
                console.error("Rate not found for flipped pair:", flippedPair);
            }
            return null;
        }).filter(Boolean);
    };

    const indirectPairLogic = (indirectPairs, ratesMap) => {
        return indirectPairs.map((pairObj) => {
            const pair = pairObj.indirectPair;
            const [from, to] = pair.split(" - ");
            const fromRate = ratesMap[from.replace("/", "")];
            const toRate = ratesMap[to.replace("/", "")];

            if (fromRate && toRate) {
                const fromBid = fromRate.bid || 0;
                const fromAsk = fromRate.ask || 1;
                const fromHigh = fromRate.high || 0;
                const fromLow = fromRate.low || 1;

                const toBid = toRate.bid || 0;
                const toAsk = toRate.ask || 1;
                const toHigh = toRate.high || 0;
                const toLow = toRate.low || 1;

                let derivedBid, derivedAsk, derivedHigh, derivedLow;
                let derivedCurrency;

                const latestTime = new Date().toISOString();

                // Dynamic determination of scenarios
                const [fromBase, fromQuote] = from.split("/");
                const [toBase, toQuote] = to.split("/");

                if (fromBase === toBase) {
                    derivedBid = parseFloat((toBid / fromAsk).toFixed(6));
                    derivedAsk = parseFloat((toAsk / fromBid).toFixed(6));
                    derivedHigh = parseFloat((toHigh / fromLow).toFixed(6));
                    derivedLow = parseFloat((toLow / fromHigh).toFixed(6));
                    derivedCurrency = getDerivedCurrency(from, to);
                } else if (fromBase === toQuote) {
                    derivedBid = parseFloat((1 / (fromAsk * toAsk)).toFixed(6));
                    derivedAsk = parseFloat((1 / (fromBid * toBid)).toFixed(6));
                    derivedHigh = parseFloat((1 / (fromLow * toLow)).toFixed(6));
                    derivedLow = parseFloat((1 / (fromHigh * toHigh)).toFixed(6));
                    derivedCurrency = getDerivedCurrency(from, to);
                } else if (fromQuote === toQuote) {
                    derivedBid = parseFloat((fromBid / toAsk).toFixed(6));
                    derivedAsk = parseFloat((fromAsk / toBid).toFixed(6));
                    derivedHigh = parseFloat((fromHigh / toLow).toFixed(6));
                    derivedLow = parseFloat((fromLow / toHigh).toFixed(6));
                    derivedCurrency = getDerivedCurrency(from, to);
                } else if (fromQuote === toBase) {
                    derivedBid = parseFloat((fromBid * toBid).toFixed(6));
                    derivedAsk = parseFloat((fromAsk * toAsk).toFixed(6));
                    derivedHigh = parseFloat((fromHigh * toHigh).toFixed(6));
                    derivedLow = parseFloat((fromLow * toLow).toFixed(6));
                    derivedCurrency = getDerivedCurrency(from, to);
                }else {
                    return null;
                }
                return {
                    currency: derivedCurrency,
                    bid: derivedBid,
                    ask: derivedAsk,
                    high: derivedHigh,
                    low: derivedLow,
                    time: latestTime,
                };
            }
            return null;
        }).filter(Boolean);
    };

    // Function to calculate the derived currency for ANY pair
    const getDerivedCurrency = (from, to) => {
        const extractNonUSD = (pair) => pair.split("/").filter((currency) => currency !== "USD");

        const [fromNonUSD] = extractNonUSD(from);
        const [toNonUSD] = extractNonUSD(to);

        if (fromNonUSD && toNonUSD) {
            return `${fromNonUSD}/${toNonUSD}`;
        }
        return null;
    };

    useEffect(() => {
        const selectedPairs = JSON.parse(sessionStorage.getItem("selectedPairs")) || [];
        if (!selectedPairs.length || !finalResult || !rawRates.length) return;

        const ratesMap = {};
        rawRates.forEach((rate) => {
            ratesMap[rate.currency] = rate;
        });

        // Separate the pairs into direct, indirect, and inverted categories
        const directPairs = selectedPairs.filter((pair) => pair.directPair);
        const invertedPairs = selectedPairs.filter((pair) => pair.invertedPair);
        const indirectPairs = selectedPairs.filter((pair) => pair.indirectPair);

        const directProcessedRates = directPairLogic(directPairs, ratesMap);

        const invertedProcessedRates = invertedPairLogic(invertedPairs, ratesMap);

        const indirectProcessedRates = indirectPairLogic(indirectPairs, ratesMap);

        const allProcessedRates = [
            ...directProcessedRates,
            ...invertedProcessedRates,
            ...indirectProcessedRates
        ];

        setRates(allProcessedRates);
    }, [rawRates, finalResult]);

    const handleAddCurrency = async (currencies) => {
        setFinalResult(currencies);
        const uniquePairs = new Set();

        Object.values(currencies).forEach((currency) => {
            const { key, pair, from, to } = currency;

            if (key === "directPair" && pair) {
                const sanitizedPair = pair.replace("/", "");
                if (!uniquePairs.has(sanitizedPair)) {
                    uniquePairs.add(sanitizedPair);
                }
            } else if (key === "invertedPair" && pair) {
                const [base, quote] = pair.split("/");
                if (base && quote) {
                    const invertedPair = `${quote}${base}`; 
                    if (!uniquePairs.has(invertedPair)) {
                        uniquePairs.add(invertedPair);
                    }
                }
            } else if (key === "indirectPair" && from && to) {
                const sanitizedFrom = from.replace("/", "");
                const sanitizedTo = to.replace("/", "");
                if (!uniquePairs.has(sanitizedFrom)) {
                    uniquePairs.add(sanitizedFrom);
                }
                if (!uniquePairs.has(sanitizedTo)) {
                    uniquePairs.add(sanitizedTo);
                }
            }
        });

        // Convert the set to a comma-separated string
        const currencyCodes = Array.from(uniquePairs).join(",");

        const connection = connectionRef.current;
        if (connection && connection.state === signalR.HubConnectionState.Connected) {
            try {
                await connection.invoke("GetRates", currencyCodes);
            } catch (err) {
                console.error("Error invoking GetRates on SignalR Hub:", err);
            }
        }
    };

    const clearRates = () => {
        setRates([]);
    };


    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(config.livefxUrl)
            .withAutomaticReconnect()
            .build();

        connectionRef.current = connection;

        connection
            .start()
            .then(() => {
                setIsConnected(true);
                console.log("SignalR connection established.");
            })
            .catch((err) => console.error("Error starting SignalR connection:", err));

        connection.on("ReceiveRates", (data) => {
            const parsedRates = JSON.parse(data);
            const ratesArray = Array.isArray(parsedRates) ? parsedRates : [parsedRates];

            setRawRates((prevRates) => [...prevRates, ...ratesArray]);

            const firstThreeBids = ratesArray.slice(0, 3).map((rate) => rate?.bid);
            const sumOfBids = firstThreeBids.reduce((acc, bid) => acc + bid, 0);
            setAvg(sumOfBids / firstThreeBids.length || 0);
        });

        connection.onclose(() => {
            setIsConnected(false);
            console.warn("SignalR connection closed.");
        });

        return () => connection.stop();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <LiveFxRates rates={rates} avg={avg} onAddCurrency={handleAddCurrency} onClearRates={clearRates} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default LiveFxRatesIndex;
