import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { getYear, getMonth } from "date-fns";

const DatePickerWrapper = styled.div`
  .react-datepicker__header {
    background-color: #556ee6;
    color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .react-datepicker__current-month {
    color: #ffffff;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #000;
  }

  .react-datepicker__day:hover {
    background-color: #eee;
  }

  .react-datepicker__day--selected {
    background-color: #556ee6;
    color: #ffffff;
  }

  .custom-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }

  .custom-header input {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 3px;
    font-size: 14px;
  }
  .custom-header select {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
  }
  .custom-datepicker-popper {
    z-index: 1050;
  }
`;

const CustomDatePicker = ({
  onChange,
  selectedDate,
  showYear = false,
  isInvalid = false,
  isDisabled = false,
  placeholderText = "yyyy/mm/dd",
  validationMessage = "",
}) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear - 10 + i);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div>
      <DatePickerWrapper>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="custom-header">
              {showYear && (
                <div className="year-select">
                  <input
                    type="number"
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) => {
                      changeYear(Number(value));
                    }}
                    min={years[0]}
                    max={years[years.length - 1]}
                    className="year-input"
                  />
                </div>
              )}
              <select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          )}
          onChange={(date) => {
            if (date) {
              const normalizedDate = new Date(date.setHours(12, 0, 0, 0));
              onChange(normalizedDate);
            }
          }}
          disabled={isDisabled}
          selected={selectedDate}
          dateFormat="yyyy/MM/dd"
          placeholderText={placeholderText}
          className={`form-control ${isInvalid ? "is-invalid" : ""}`}
          onKeyDown={(e) => e.preventDefault()}
          popperClassName="custom-datepicker-popper"
          popperModifiers={[
            {
              name: "preventOverflow",
              options: {
                boundary: "viewport",
              },
            },
          ]}
        />
      </DatePickerWrapper>
      {isInvalid && validationMessage && (
        <div
          className="error-message"
          style={{ color: "#f46a6a", fontSize: "10px", marginTop: "5px" }}
        >
          {validationMessage}
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
