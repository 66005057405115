import React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormFeedback,
} from "reactstrap";
import { putApiData } from "../../../../helpers/axiosHelper";
import { ToastContainer } from "react-toastify";
import RequiredAsterisk from "../../../../components/Common/RequiredAsterisk";
import ReactSelect from "react-select";
import SelectStyle from "../../../../common/data/SelectStyle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { roleList } from "../../../../common/data/roles";
import {
  mobileNumberValidator,
  toastError,
  toastInfo,
  toastSucess,
} from "../../../../common/data/commonfunctions";
import ButtonSpinner from "../../../../components/Common/ButtonSpinner";

const ViewEditSystemUser = (props) => {
  const {
    isOpen,
    toggle,
    editModeSystemUser,
    branch,
    systemUserData,
    UId,
    getAllSystemUser,
  } = props;

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const branchID = authUser?.branchId;
  const isSystemAdmin = authUser?.role === "System Admin";

  const systemUserValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstname: systemUserData?.firstName || "",
      middlename: systemUserData?.middleName || "",
      lastname: systemUserData?.lastName || "",
      email: systemUserData?.email || "",
      mobile: systemUserData?.mobile || "",
      role:
        roleList &&
        roleList.find(
          (role) => parseInt(role.value) === parseInt(systemUserData?.roleId)
        ),
      branchId: isSystemAdmin
        ? branch &&
          branch.find(
            (branch) =>
              parseInt(branch.value) === parseInt(systemUserData?.branchId)
          )
        : { label: "Branch", value: branchID },
      // password: '',
      // confirmpassword: '',
    },

    validationSchema: Yup.object({
      firstname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only letters")
        .required("Please Enter First name"),
      lastname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name should contain only letters")
        .required("Please Enter Last name"),
      middlename: Yup.string().matches(
        /^[A-Za-z\s]+$/,
        "Middle Name should contain only letters"
      ),
      email: Yup.string().email("Invalid Email").required("Please Enter Email"),
      mobile: Yup.string()
        .required("Please Enter Mobile Number")
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .matches(
          new RegExp(
            `^\\+?\\d{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
          ),
          `Invalid Mobile Number`
        ),
      branchId: Yup.object().shape({
        label: Yup.string().required("Please Select a Branch"),
        value: Yup.string().required("Please Select a Branch"),
      }),
      role: Yup.object().shape({
        label: Yup.string().required("Please Select a Role"),
        value: Yup.string().required("Please Select a Role"),
      }),
      // password: Yup.string()
      // .matches(
      //   /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
      //   "Password must be at least 8 characters and must contain at least one digit, one uppercase letter, and one special character"
      // ),
      // confirmpassword: Yup.string()
      //   .oneOf([Yup.ref("password"), null], "Passwords must match")
    }),

    onSubmit: async (values, { resetForm }) => {
      const branchValue = values.branchId && values.branchId.value;
      const roleValue = values.role && values.role.value;
      const combinedValues = {
        ...values,
        userstatus: 4,
        branchId: branchValue,
        role: roleValue,
      };
      if (combinedValues.email === systemUserValidation.initialValues.email) {
        delete combinedValues.email;
      }
      if (combinedValues.mobile === systemUserValidation.initialValues.mobile) {
        delete combinedValues.mobile;
      }
      const hasChanges = Object.keys(values).some(
        (key) => values[key] !== systemUserValidation.initialValues[key]
      );
      if (hasChanges) {
        const response = await putApiData(
          `api/User/UpdateSystemUser?UID=${UId}`,
          JSON.stringify(combinedValues)
        );
        if (response?.success) {
          toastSucess("User Updated Successfully");
          getAllSystemUser();
          toggle();
        } else {
          toastError(response.message);
        }
      } else if (!hasChanges) {
        toastInfo("No Changes to Update");
      }
    },
  });

  return (
    <>
      <ToastContainer closeButton={false} limit={1} />
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>
            {editModeSystemUser ? "View System User" : "Edit System User"}
          </ModalHeader>
          <ModalBody>
            <Form
              className="needs-validation"
              onSubmit={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              <Row>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="firstname">First name</Label>
                    <RequiredAsterisk />
                    <Input
                      name="firstname"
                      placeholder="Enter First name"
                      type="text"
                      id="firstname"
                      disabled={editModeSystemUser}
                      value={systemUserValidation.values.firstname}
                      onChange={systemUserValidation.handleChange}
                      onBlur={systemUserValidation.handleBlur}
                      invalid={
                        systemUserValidation.touched.firstname &&
                        systemUserValidation.errors.firstname
                      }
                    />
                    {systemUserValidation.touched.firstname &&
                    systemUserValidation.errors.firstname ? (
                      <FormFeedback type="invalid">
                        {systemUserValidation.errors.firstname}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="middlename">Middle Name</Label>
                    <Input
                      name="middlename"
                      placeholder="Enter Middle Name"
                      id="middlename"
                      value={systemUserValidation.values.middlename}
                      onChange={systemUserValidation.handleChange}
                      disabled={editModeSystemUser}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="lastname">Last name</Label>
                    <RequiredAsterisk />
                    <Input
                      name="lastname"
                      placeholder="Enter Last name"
                      type="text"
                      id="lastname"
                      disabled={editModeSystemUser}
                      value={systemUserValidation.values.lastname}
                      onChange={systemUserValidation.handleChange}
                      onBlur={systemUserValidation.handleBlur}
                      invalid={
                        systemUserValidation.touched.lastname &&
                        systemUserValidation.errors.lastname
                      }
                    />
                    {systemUserValidation.touched.lastname &&
                    systemUserValidation.errors.lastname ? (
                      <FormFeedback type="invalid">
                        {systemUserValidation.errors.lastname}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="email">Email</Label>
                    <RequiredAsterisk />
                    <Input
                      name="email"
                      placeholder="Enter Email"
                      type="email"
                      id="email"
                      disabled={editModeSystemUser}
                      value={systemUserValidation.values.email}
                      onChange={systemUserValidation.handleChange}
                      onBlur={systemUserValidation.handleBlur}
                      invalid={
                        systemUserValidation.touched.email &&
                        systemUserValidation.errors.email
                      }
                    />
                    {systemUserValidation.touched.email &&
                    systemUserValidation.errors.email ? (
                      <FormFeedback type="invalid">
                        {systemUserValidation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label>Mobile</Label>
                    <RequiredAsterisk />
                    <Input
                      name="mobile"
                      placeholder="Enter Mobile Number"
                      type="text"
                      id="mobile"
                      disabled={editModeSystemUser}
                      value={systemUserValidation.values.mobile}
                      onChange={systemUserValidation.handleChange}
                      onBlur={systemUserValidation.handleBlur}
                      invalid={
                        systemUserValidation.touched.mobile &&
                        systemUserValidation.errors.mobile
                      }
                    />
                    {systemUserValidation.touched.mobile &&
                    systemUserValidation.errors.mobile ? (
                      <FormFeedback type="invalid">
                        {systemUserValidation.errors.mobile}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="role">Role</Label>
                    <RequiredAsterisk />
                    <ReactSelect
                      name="role"
                      placeholder="Select Role"
                      id="role"
                      isDisabled={editModeSystemUser}
                      options={isSystemAdmin ? roleList : roleList.slice(1)}
                      styles={SelectStyle}
                      value={systemUserValidation.values.role}
                      onChange={(selectedOption) => {
                        systemUserValidation.setFieldValue(
                          "role",
                          selectedOption
                        );
                      }}
                      invalid={
                        systemUserValidation.touched.role &&
                        systemUserValidation.errors.role
                      }
                    />
                    {systemUserValidation.errors.role &&
                    systemUserValidation.touched.role ? (
                      <span className="text-danger" style={{ fontSize: "80%" }}>
                        {systemUserValidation.errors.role}
                      </span>
                    ) : null}
                  </FormGroup>
                </Col>
                {isSystemAdmin && (
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="branchId">Branch</Label>
                      <RequiredAsterisk />
                      <ReactSelect
                        name="branchId"
                        placeholder="Select Branch"
                        options={branch}
                        styles={SelectStyle}
                        id="branchId"
                        isDisabled={editModeSystemUser}
                        value={systemUserValidation.values.branchId}
                        onChange={(selectedOption) => {
                          systemUserValidation.setFieldValue(
                            "branchId",
                            selectedOption
                          );
                        }}
                        invalid={
                          systemUserValidation.touched.branchId &&
                          systemUserValidation.errors.branchId
                        }
                      />
                      {systemUserValidation.errors.branchId &&
                      systemUserValidation.touched.branchId ? (
                        <span
                          className="text-danger"
                          style={{ fontSize: "80%" }}
                        >
                          {systemUserValidation.errors.branchId}
                        </span>
                      ) : null}
                    </FormGroup>
                  </Col>
                )}
                {/* <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="password">Password</Label>
                      <Input
                        name="password"
                        placeholder="Enter Password"
                        type="password"
                        id="password"
                        value={systemUserValidation.values.password}
                        onChange={systemUserValidation.handleChange}
                        onBlur={systemUserValidation.handleBlur}
                        invalid={systemUserValidation.errors.password ?
                          true : false
                        }
                      />
                      {systemUserValidation.errors.password ? (
                        <FormFeedback type="invalid">{systemUserValidation.errors.password}</FormFeedback>
                      ): null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="confirmpassword">Confirm Password</Label>
                      <Input
                        name="confirmpassword"
                        placeholder="Confirm Password"
                        type="password"
                        id="confirmpassword"
                        value={systemUserValidation.values.confirmpassword}
                        onChange={systemUserValidation.handleChange}
                        onBlur={systemUserValidation.handleBlur}
                        invalid={ systemUserValidation.errors.confirmpassword ?
                          true : false
                        }
                      />
                      {systemUserValidation.errors.confirmpassword ? (
                        <FormFeedback type="invalid">{systemUserValidation.errors.confirmpassword}</FormFeedback>
                      ): null}
                    </FormGroup>
                  </Col> */}
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            {editModeSystemUser ? null : (
              <Button
                type="button"
                color="primary"
                onClick={systemUserValidation.handleSubmit}
                disabled={systemUserValidation.isSubmitting}
              >
                {systemUserValidation.isSubmitting ? (
                  <ButtonSpinner />
                ) : (
                  "Update"
                )}
              </Button>
            )}
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default ViewEditSystemUser;
