import React from "react";
import {
  mapStatus,
  mapTransactionType,
} from "../../../common/data/StatusLabels";
import classnames from "classnames";
import {
  handleCommaSeperator,
  TransactionType,
} from "../../../common/data/transactionType";
import { Badge, Collapse, Label, Table } from "reactstrap";
import { baseCurrency } from "../../../common/data/currency";

const CurrencyInfo = (props) => {
  const {
    col9,
    t_col9,
    transactionType,
    sendTransactionDetails,
    fromCurrType,
    toCurrType,
    fromCurrValue,
    toCurrValue,
    exchangeRate,
    charges,
    fromRatehistory,
    transfer2ndScenario,
    transfer3rdScenario,
    discountedRate,
    status,
  } = props;

  const displayChargesWithCurrency = (charge) => {
    return (
      handleCommaSeperator(charge) +
      " " +
      (transfer3rdScenario ? fromRatehistory?.currencyCode : baseCurrency)
    );
  };

  const conversionRate = (exchangeRate) => {
    return transactionType === TransactionType.Buy || transfer2ndScenario
      ? `1 ${fromCurrType} = ${exchangeRate} ${toCurrType}`
      : transactionType === TransactionType.Sell || TransactionType.Transfer
      ? `1 ${toCurrType} = ${exchangeRate} ${fromCurrType}`
      : null;
  };
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFlushOne">
        <button
          className={classnames("accordion-button", "fw-medium", {
            collapsed: !col9,
          })}
          type="button"
          onClick={t_col9}
          style={{ cursor: "pointer" }}
        >
          Currency Info -&nbsp;
          <Badge
            className={
              "font-size-11 ms-1 me-1 badge-soft-" +
              mapTransactionType(transactionType)?.color
            }
          >
            {mapTransactionType(transactionType)?.label}
          </Badge>
        </button>
      </h2>

      <Collapse isOpen={col9} className="accordion-collapse">
        <div className="accordion-body">
          <div className="text-muted">
            <Table className="table-nowrap mb-0">
              <tbody>
                {sendTransactionDetails && fromCurrType && (
                  <>
                    <tr>
                      <th scope="row">From currency :</th>
                      <td>
                        {fromCurrType} - {handleCommaSeperator(fromCurrValue)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">To currency :</th>
                      <td>
                        {toCurrType} - {handleCommaSeperator(toCurrValue)}
                      </td>
                    </tr>
                    {charges.wireTransferFee !== 0 &&
                      !isNaN(charges.wireTransferFee) && (
                        <tr>
                          <th scope="row">Wire Transfer Fee :</th>
                          <td>
                            {displayChargesWithCurrency(
                              charges.wireTransferFee
                            )}
                          </td>
                        </tr>
                      )}
                    {charges.agentCharges !== 0 &&
                      !isNaN(charges.agentCharges) && (
                        <tr>
                          <th scope="row">Agent Charges :</th>
                          <td>
                            {displayChargesWithCurrency(charges.agentCharges)}
                          </td>
                        </tr>
                      )}
                    {charges.commissionCharges !== 0 &&
                      !isNaN(charges.commissionCharges) && (
                        <tr>
                          <th scope="row">Commission Charges :</th>
                          <td>
                            {displayChargesWithCurrency(
                              charges.commissionCharges
                            )}
                          </td>
                        </tr>
                      )}
                    {charges.totalCharges !== 0 &&
                      !isNaN(charges.totalCharges) &&
                      transactionType === TransactionType.Transfer && (
                        <tr>
                          <th scope="row">Total Charges :</th>
                          <td>
                            {displayChargesWithCurrency(charges.totalCharges)}
                          </td>
                        </tr>
                      )}
                    <tr>
                      <th scope="row">Conversion rate :</th>
                      <td>
                        <Label
                          className="form-check-Label"
                          htmlFor="formrow-customCheck"
                        >
                          {conversionRate(exchangeRate)}
                          {parseFloat(discountedRate) !== 0 &&
                          parseFloat(discountedRate) !== null
                            ? " (Discounted)"
                            : ""}
                        </Label>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Status :</th>
                      <td>
                        <Badge
                          className={
                            "font-size-11 ms-1 me-1 badge-soft-" +
                            mapStatus(status)?.color
                          }
                        >
                          {mapStatus(status)?.label}
                        </Badge>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default CurrencyInfo;
