import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toggleLeftmenu } from "../../store/actions";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo from "../../../images/cresentLogo.svg";
import logoLight from "../../../images/logo-light.svg";
import logoLightSvg from "../../../images/logo-light.svg";
import logoDark from "../../../images/logo-dark.svg";
import { withTranslation } from "react-i18next";
import SelectBranch from "./SelectBranch";
import { path } from "../../routes/path";
import { close } from "../../common/data/icons";

const Header = (props) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isLeftMenuOpen = props.leftMenu;
      const isMobileView = window.innerWidth <= 991;
      const isInsideMenu = event.target.closest("#topnav-menu-content");
      const isMenuButton = event.target.closest(".header-item");

      if (isLeftMenuOpen && isMobileView && !isInsideMenu && !isMenuButton) {
        props.toggleLeftmenu(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [props.leftMenu]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link
                to={path.home}
                className="logo logo-dark"
                aria-label="logo-dark"
              >
                <span className="logo-sm">
                  <img src={logo} alt="" width="100%" height="22px" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" width="100%" height="19px" />
                </span>
              </Link>

              <Link
                to={path.home}
                className="logo logo-light"
                aria-label="logo-light"
              >
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" width="100%" height="22px" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" width="100%" height="19px" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              aria-label="nav-menu"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              style={{
                transition: "transform 0.3s ease",
                transform: props.leftMenu ? "rotate(90deg)" : "rotate(0deg)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              {props.leftMenu ? close() : <i className="fa fa-fw fa-bars" />}
            </button>
          </div>

          <div className="d-flex align-items-center">
            <div className="me-3">
              <SelectBranch />
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.bool,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default connect(mapStatetoProps, { toggleLeftmenu })(
  withTranslation()(Header)
);
