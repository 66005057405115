import React from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import RequiredAsterisk from "../../../../components/Common/RequiredAsterisk";
import CustomDatePicker from "../../../../common/data/customDate";

const PersonalDetailsTab = (props) => {
  const { disabled, personalData, personalValidation } = props;

  return (
    <React.Fragment>
      <Form>
        <Row>
          <Col lg="6">
            <FormGroup className="mb-2">
              <Label for="beneficiaryName">Account Holder's Full Name</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="beneficiaryName"
                placeholder="Name Registered with Bank"
                disabled={disabled}
                {...personalValidation.getFieldProps("beneficiaryName")}
                invalid={
                  personalValidation.errors.beneficiaryName &&
                  personalValidation.touched.beneficiaryName
                    ? true
                    : false
                }
              />
              <FormFeedback>
                {personalValidation.errors.beneficiaryName}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup className="mb-3">
              <Label for="nationalID">National ID</Label>
              <RequiredAsterisk />
              <Input
                id="nationalID"
                placeholder="Enter the National ID"
                {...personalValidation.getFieldProps("nationalID")}
                disabled={disabled}
                invalid={
                  personalValidation.errors.nationalID &&
                  personalValidation.touched.nationalID
                    ? true
                    : false
                }
              />
              <FormFeedback>
                {personalValidation.errors.nationalID}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="mb-3">
              <Label for="dob">DOB</Label>
              <RequiredAsterisk />
              <CustomDatePicker
                showYear={true}
                selectedDate={
                  personalValidation.values.dob
                    ? new Date(personalValidation.values.dob)
                    : null
                }
                onChange={(date) => {
                  const formattedDate = date
                    ? date.toISOString().split("T")[0]
                    : "";
                  personalValidation.setFieldValue("dob", formattedDate);
                }}
                isInvalid={
                  personalValidation.touched.dob &&
                  personalValidation.errors.dob
                }
                onBlur={personalValidation.handleBlur}
                value={personalValidation.values.dob || ""}
                invalid={
                  personalValidation.touched.dob &&
                  personalValidation.errors.dob
                    ? true
                    : false
                }
                validationMessage={personalValidation.errors.dob}
              />
              <FormFeedback>{personalValidation.errors.dob}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="mb-2">
              <Label for="email">Email</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="email"
                placeholder="Enter the Email"
                {...personalValidation.getFieldProps("email")}
                disabled={disabled}
                invalid={
                  personalValidation.errors.email &&
                  personalValidation.touched.email
                    ? true
                    : false
                }
              />
              <FormFeedback>{personalValidation.errors.email}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="mb-2">
              <Label for="mobile">Mobile Number</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="mobile"
                placeholder="Enter the Mobile Number"
                {...personalValidation.getFieldProps("mobile")}
                disabled={disabled}
                invalid={
                  personalValidation.errors.mobile &&
                  personalValidation.touched.mobile
                    ? true
                    : false
                }
              />
              <FormFeedback>{personalValidation.errors.mobile}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        {disabled !== true && (
          <Button
            color="primary"
            onClick={personalValidation.handleSubmit}
            disabled={personalValidation.isSubmitting}
          >
            {personalData === null ? "Save" : "Update"}
          </Button>
        )}
      </Form>
    </React.Fragment>
  );
};

export default React.memo(PersonalDetailsTab);
