import React from "react";
import classnames from "classnames";
import { Collapse, Table } from "reactstrap";
import { formatDateToUserTimeZone } from "../../../common/data/date";

const BranchInfo = (props) => {
  const {
    sendBranchDetails,
    t_col10,
    col10,
    sendTransactionDetails,
    sendOpTimeTransactionDetails,
    transactionDate,
    pickup,
  } = props;
  const { street, city, branchName, state, postalCode, country } =
    sendBranchDetails || {};

  const days = [
    "All Days",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  function convertTime(timeString) {
    const [hours, minutes, seconds] = timeString.split(":");
    const hoursIn12HrFormat = hours % 12 || 12; // Handle 00:00 as 12 AM
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${hoursIn12HrFormat}:${minutes.padStart(
      2,
      "0"
    )}:${seconds} ${amPm}`;
    return formattedTime;
  }

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFlushTwo">
        <button
          className={classnames("accordion-button", "fw-medium", {
            collapsed: !col10,
          })}
          type="button"
          onClick={t_col10}
          style={{ cursor: "pointer" }}
        >
          Branch Info
        </button>
      </h2>

      <Collapse isOpen={col10} className="accordion-collapse">
        <div className="accordion-body">
          <div className="text-muted">
            <Table className="table-nowrap mb-0">
              <tbody>
                {sendTransactionDetails && (
                  <>
                    <tr>
                      <th scope="row">Branch Name :</th>
                      <td>{branchName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Branch Address :</th>
                      <td>
                        {street},<br />
                        {city},{state},<br />
                        {country},{postalCode}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Booked Date :</th>
                      {/* Madhangi changes for scrum 17 - start*/}
                      <td>{formatDateToUserTimeZone(transactionDate)}</td>
                      {/* Madhangi changes for scrum 17 - end*/}
                    </tr>
                    <tr>
                      <th scope="row">Operational Time :</th>
                      {/* Madhangi changes for scrum 17 - start*/}
                      <td>
                        {sendOpTimeTransactionDetails?.map((item) => (
                          <div key={item.day}>
                            <span className="me-2">{days[item.day]}</span>
                            <span className="me-2">
                              {convertTime(item.startTime)}-{" "}
                            </span>
                            <span className="me-2">
                              {convertTime(item.endTime)}
                            </span>
                          </div>
                        ))}
                      </td>
                      {/* Madhangi changes for scrum 17 - end*/}
                    </tr>
                    {pickup && (
                      <tr>
                        <th scope="row">Pickup :</th>
                        <td>{formatDateToUserTimeZone(pickup)}</td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default BranchInfo;
