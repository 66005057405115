export const path = {
  adminDashboard: "/admindashboard",
  users: "/manage-user",
  branch: "/branch",
  login: "/",
  default: "/landing",
  resetSuccess: "/reset-success",
  logout: "/logout",
  home: "/",
  manageUser: "/manage-user",
  manageCompany: "/manage-company",
  manageRates: "/manage-rates",
  manageStock: "/stock/manage-stock",
  stockLog: "/stock/stock-log",
  liveRates: "/live-rates",
  currencyReport: "/currency-report",
  branchReport: "/branch-report",
  transactionReport: "/transaction-report",
  stockSoldReport: "/stocksold-report",
  profitLossReport: "/profit-and-loss-report",
  manageTransaction: "/transaction/manage-transaction",
  transactionHistory: "/transaction/transaction-history",
  addTransaction: "/transaction/add-transaction",
  forgotPassword: "/forgot-password",
  supportEmail: "mailto:support@crescentexchange.com",
};
