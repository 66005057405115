import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import Loader from "../../components/Common/Loader";
import CurrencyModal from "../LiveFxRates/SelectCurrency";

const LiveFxRates = ({ rates, avg, onAddCurrency, isConnected, onClearRates }) => {
    const [liveRateState, setLiveRateState] = useState({
        rateMaster: [], 
        isLoading: true,
    });

    // Update state whenever rates change
    useEffect(() => {
        setLiveRateState({
            rateMaster: rates, 
            isLoading: false, 
        });
    }, [rates]);

    // Ensure loader disappears once SignalR is connected
    useEffect(() => {
        if (isConnected) {
            setLiveRateState((prevState) => ({
                ...prevState,
                isLoading: false,
            }));
        }
    }, [isConnected]);

    const columns = [
        {
            Header: "Currency",
            accessor: "currency",
            filterable: false,
            disableFilters: true,
        },
        {
            Header: "Bid",
            accessor: "bid",
            filterable: false,
            disableFilters: true,
            Cell: ({ value }) => (
                <div
                    style={{
                        color: `${parseFloat(value) > avg ? "rgb(5, 196, 62)" : "rgb(250, 77, 24)"}`,
                    }}
                >
                    {value}
                    <span className="arrow">{parseFloat(value) > avg ? " ▲" : " ▼"}</span>
                </div>
            ),
        },
        { Header: "Ask", accessor: "ask", filterable: false, disableFilters: true },
        { Header: "High", accessor: "high", filterable: false, disableFilters: true },
        { Header: "Low", accessor: "low", filterable: false, disableFilters: true },
        { Header: "Open", accessor: "open", filterable: false, disableFilters: true },
        { Header: "Close", accessor: "close", filterable: false, disableFilters: true },
        {
            Header: "Time",
            accessor: "time",
            filterable: false,
            disableFilters: true,
            Cell: ({ value }) => {
                const date = new Date(value);
                return value ? date.toLocaleString() : "";
            },
        },
    ];

    return (
        <React.Fragment>
            {liveRateState.isLoading ? (
                <Loader />
            ) : (
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <CardTitle>Live Rates</CardTitle>
                                <CurrencyModal onAddCurrency={onAddCurrency} onClearRates={onClearRates} />
                        </div>
                        <TableContainer
                            columns={columns}
                            data={liveRateState.rateMaster} 
                            isGlobalFilter={true}
                            isAddOptions={false}
                            customPageSize={10}
                            isPageSelect={false}
                            onPageChange={() => null}
                        />
                    </CardBody>
                </Card>
            )}
        </React.Fragment>
    );
};

export default LiveFxRates;
