import config from "../../../config";

export const getFileType = (contentType) => {
  switch (contentType) {
    case "png":
      return "image/png";
    case "jpg":
      return "image/jpg";
    case "jpeg":
      return "image/jpeg";
    case "pdf":
      return "application/pdf";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    default:
      return "application/octet-stream";
  }
};

export const OpenFileInNewTab = (contentType, base64, fileName) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length)
    .fill(0)
    .map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: getFileType(contentType) });
  const url = URL.createObjectURL(blob);

  const newWindow = window.open(url, "_blank");

  if (newWindow) {
    newWindow.onload = () => {
      newWindow.document.title = fileName ? `${fileName}` : "Document";
      const link = newWindow.document.createElement("link");
      link.rel = "icon";
      link.type = "image/svg+xml";
      link.href = config.logoUrl;
      newWindow.document.head.appendChild(link);
    };
  } else {
    console.error("Failed to open the new window.");
  }
};

export const downloadFile = (contentType, fileName, base64) => {
  const byteChars = atob(base64);
  const byteNumbers = new Array(byteChars.length);
  for (let i = 0; i < byteChars.length; i++) {
    byteNumbers[i] = byteChars.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: getFileType(contentType) });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.${contentType !== null ? contentType : "png"}`;
  link.click();
  URL.revokeObjectURL(url);
};
