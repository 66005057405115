import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormFeedback,
  Table,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import RequiredAsterisk from "./../../../../components/Common/RequiredAsterisk";
import { postApiData } from "../../../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import { mobileNumberValidator } from "../../../../common/data/commonfunctions";
import ButtonSpinner from "../../../../components/Common/ButtonSpinner";
import ShowHidePassword from "../../../Authentication/ShowHidePassword";
import CustomDatePicker from "../../../../common/data/customDate";

const AddCustomer = (props) => {
  const { isOpen, toggle, getCustomer } = props;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const addNewCustomerValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      firstname: "",
      middlename: "",
      lastname: "",
      mobile: "",
      dob: "",
      password: "",
      confirmpassword: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().email("Invalid Email").required("Please Enter Email"),
      firstname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only letters")
        .required("Please Enter First name"),
      middlename: Yup.string().matches(
        /^[A-Za-z\s]+$/,
        "Middle Name should contain only letters"
      ),
      lastname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name should contain only letters")
        .required("Please Enter Last name"),
      mobile: Yup.string()
        .required("Please Enter Mobile Number")
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .matches(
          new RegExp(
            `^\\+?\\d{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
          ),
          `Invalid Mobile Number`
        ),
      dob: Yup.date()
        .required("Please Enter Date of Birth")
        .max(new Date(), "Date of Birth cannot be in the future"),
      password: Yup.string()
        .required("Please Enter Password")
        .matches(
          /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
          "Password must be at least 8 characters and must contain at least one digit, one uppercase letter, and one special character"
        ),
      confirmpassword: Yup.string()
        .required("Please Enter Password to Confirm")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const response = await postApiData(
        "api/Customer/CreateCustomer",
        JSON.stringify(values)
      );
      if (response?.success === true) {
        toast.success("Customer Created Successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        toggle();
        resetForm();
        getCustomer();
      } else {
        toast.error(`${response?.message}`, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    },
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
        onClosed={() => {
          addNewCustomerValidation.resetForm();
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              toggle();
              addNewCustomerValidation.resetForm();
            }}
          >
            Add Customer
          </ModalHeader>
          <ModalBody>
            <Form
              className="needs-validation"
              onSubmit={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              <Row>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="firstname">First Name</Label>
                    <RequiredAsterisk />
                    <Input
                      name="firstname"
                      placeholder="Enter First Name"
                      type="text"
                      id="firstname"
                      value={addNewCustomerValidation.values.firstname}
                      onChange={addNewCustomerValidation.handleChange}
                      onBlur={addNewCustomerValidation.handleBlur}
                      invalid={
                        addNewCustomerValidation.touched.firstname &&
                        addNewCustomerValidation.errors.firstname
                          ? true
                          : false
                      }
                    />
                    {addNewCustomerValidation.touched.firstname &&
                    addNewCustomerValidation.errors.firstname ? (
                      <FormFeedback type="invalid">
                        {addNewCustomerValidation.errors.firstname}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="middlename">Middle Name</Label>
                    <Input
                      name="middlename"
                      placeholder="Enter Middle Name"
                      id="middlename"
                      value={addNewCustomerValidation.values.middlename}
                      onChange={addNewCustomerValidation.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="lastname">Last Name</Label>
                    <RequiredAsterisk />
                    <Input
                      name="lastname"
                      placeholder="Enter Last name"
                      type="text"
                      id="lastname"
                      value={addNewCustomerValidation.values.lastname}
                      onChange={addNewCustomerValidation.handleChange}
                      onBlur={addNewCustomerValidation.handleBlur}
                      invalid={
                        addNewCustomerValidation.touched.lastname &&
                        addNewCustomerValidation.errors.lastname
                          ? true
                          : false
                      }
                    />
                    {addNewCustomerValidation.touched.lastname &&
                    addNewCustomerValidation.errors.lastname ? (
                      <FormFeedback type="invalid">
                        {addNewCustomerValidation.errors.lastname}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="email">Email</Label>
                    <RequiredAsterisk />
                    <Input
                      name="email"
                      placeholder="Enter Email"
                      type="email"
                      id="email"
                      value={addNewCustomerValidation.values.email}
                      onChange={addNewCustomerValidation.handleChange}
                      onBlur={addNewCustomerValidation.handleBlur}
                      invalid={
                        addNewCustomerValidation.touched.email &&
                        addNewCustomerValidation.errors.email
                          ? true
                          : false
                      }
                    />
                    {addNewCustomerValidation.touched.email &&
                    addNewCustomerValidation.errors.email ? (
                      <FormFeedback type="invalid">
                        {addNewCustomerValidation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="dob">DOB</Label>
                    <RequiredAsterisk />
                    <CustomDatePicker
                      showYear={true}
                      selectedDate={
                        addNewCustomerValidation.values.dob
                          ? new Date(addNewCustomerValidation.values.dob)
                          : null
                      }
                      onChange={(date) => {
                        const formattedDate = date
                          ? date.toISOString().split("T")[0]
                          : "";
                        addNewCustomerValidation.setFieldValue(
                          "dob",
                          formattedDate
                        );
                      }}
                      isInvalid={
                        addNewCustomerValidation.touched.dob &&
                        addNewCustomerValidation.errors.dob
                      }
                      onBlur={addNewCustomerValidation.handleBlur}
                      value={addNewCustomerValidation.values.dob || ""}
                      invalid={
                        addNewCustomerValidation.touched.dob &&
                        addNewCustomerValidation.errors.dob
                          ? true
                          : false
                      }
                      validationMessage={addNewCustomerValidation.errors.dob}
                    />
                    {addNewCustomerValidation.touched.dob &&
                    addNewCustomerValidation.errors.dob ? (
                      <FormFeedback type="invalid">
                        {addNewCustomerValidation.errors.dob}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="mobile">Mobile</Label>
                    <RequiredAsterisk />
                    <Input
                      name="mobile"
                      placeholder="Enter Mobile Number"
                      type="text"
                      id="mobile"
                      value={addNewCustomerValidation.values.mobile}
                      onChange={addNewCustomerValidation.handleChange}
                      onBlur={addNewCustomerValidation.handleBlur}
                      invalid={
                        addNewCustomerValidation.touched.mobile &&
                        addNewCustomerValidation.errors.mobile
                          ? true
                          : false
                      }
                    />
                    {addNewCustomerValidation.touched.mobile &&
                    addNewCustomerValidation.errors.mobile ? (
                      <FormFeedback type="invalid">
                        {addNewCustomerValidation.errors.mobile}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="password">Password</Label>
                    <RequiredAsterisk />
                    <div className="input-group">
                      <Input
                        name="password"
                        placeholder="Enter Password"
                        type={isPasswordVisible ? "text" : "password"}
                        id="password"
                        value={addNewCustomerValidation.values.password}
                        onChange={addNewCustomerValidation.handleChange}
                        onBlur={addNewCustomerValidation.handleBlur}
                        invalid={
                          addNewCustomerValidation.touched.password &&
                          addNewCustomerValidation.errors.password
                        }
                      />
                      <ShowHidePassword
                        isPasswordVisible={isPasswordVisible}
                        toggleVisibility={() =>
                          setIsPasswordVisible(!isPasswordVisible)
                        }
                      />
                      {addNewCustomerValidation.touched.password &&
                      addNewCustomerValidation.errors.password ? (
                        <FormFeedback type="invalid">
                          {addNewCustomerValidation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="confirmpassword">Confirm Password</Label>
                    <RequiredAsterisk />
                    <div className="input-group">
                      <Input
                        name="confirmpassword"
                        placeholder="Confirm Password"
                        type={isPasswordVisible ? "text" : "password"}
                        id="confirmpassword"
                        value={addNewCustomerValidation.values.confirmpassword}
                        onChange={addNewCustomerValidation.handleChange}
                        onBlur={addNewCustomerValidation.handleBlur}
                        invalid={
                          addNewCustomerValidation.touched.confirmpassword &&
                          addNewCustomerValidation.errors.confirmpassword
                        }
                      />
                      <ShowHidePassword
                        isPasswordVisible={isPasswordVisible}
                        toggleVisibility={() =>
                          setIsPasswordVisible(!isPasswordVisible)
                        }
                      />
                      {addNewCustomerValidation.touched.confirmpassword &&
                      addNewCustomerValidation.errors.confirmpassword ? (
                        <FormFeedback type="invalid">
                          {addNewCustomerValidation.errors.confirmpassword}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              onClick={addNewCustomerValidation.handleSubmit}
              disabled={addNewCustomerValidation.isSubmitting}
            >
              {addNewCustomerValidation.isSubmitting ? (
                <ButtonSpinner />
              ) : (
                "Submit"
              )}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default AddCustomer;
