import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const info = (url) => {
  return (
    <FontAwesomeIcon
      icon={faCircleInfo}
      onClick={() => {
        window.open(url, "_blank");
      }}
      style={{ color: "556ee6", cursor: "pointer" }}
      className="ms-2"
      title="Help"
    />
  );
};

export const HelpDocButton = (url) => {
  return <div>{info(url)}</div>;
};
