import React from "react";
import classnames from "classnames";
import { Collapse, Table } from "reactstrap";

const VIewTransactionBeneficiaryAccordian = (props) => {
  const { col12, t_col12, beneficiaryDetails = {} } = props;
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFlushThree">
        <button
          className={classnames("accordion-button", "fw-medium", {
            collapsed: !col12,
          })}
          type="button"
          onClick={t_col12}
          style={{ cursor: "pointer" }}
        >
          Beneficiary Info
        </button>
      </h2>

      <Collapse isOpen={col12} className="accordion-collapse">
        <div className="accordion-body">
          <div className="text-muted">
            <Table className="table-nowrap mb-0">
              <tbody>
                {beneficiaryDetails && (
                  <>
                    <tr>
                      <th scope="row">Beneficiary Name :</th>
                      <td>{beneficiaryDetails.beneficiaryName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Currency :</th>
                      <td>{beneficiaryDetails.currencyName}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default VIewTransactionBeneficiaryAccordian;

// import React, { useEffect, useState } from "react";
// import CustomerBeneficiaryForm from "../../ManageUser/CustomerBeneficiary/CustomerBeneficiaryForm";
// import classnames from "classnames";
// import { Collapse } from "reactstrap";
// import { getApiData } from "../../../helpers/axiosHelper";
// import {
//   beneficiaryStatus,
//   beneficiaryAccountType,
//   beneficiaryActive,
// } from "./../../../common/data/Beneficiary/data";
// import { useLatestTransactions } from "../../../common/data/latest-transaction-context";

// const VIewTransactionBeneficiaryAccordian = (props) => {
//   const { col12, t_col12, customerId, companyBenId } = props;
//   const { allCurrencies } = useLatestTransactions();
//   const [state, setState] = useState({
//     bankData: null,
//     addressData: null,
//     documentData: null,
//     UId: null,
//     customerId: null,
//   });

//   const getBeneficiary = async () => {
//     const benResponse = await getApiData(
//       `api/CustomerBenMap/GetCustBenMapByCustomerId?CustomerId=${customerId}`
//     );
//     const mapBenReponse = {
//       beneficiaryName: benResponse?.data?.beneficiaryName,
//       currency: benResponse?.data?.currencyName,
//       email: benResponse?.data?.email,
//       mobile: benResponse?.data?.mobile,
//       customerId: benResponse?.data?.customerId,
//       custBenId: benResponse?.data?.custBenId,
//       nationalId: benResponse?.data?.nationalId,
//       isMyAccount: benResponse?.data?.isMyAccount,
//       accountType: beneficiaryAccountType[benResponse?.data?.accountType],
//       accountNumber: benResponse?.data?.accountNumber,
//       swift: benResponse?.data?.swift,
//       bankName: benResponse?.data?.bankName,
//       street: benResponse?.data?.street,
//       bankAddress: benResponse?.data?.bankAddress,
//       other: benResponse?.data?.other,
//       currencyId: benResponse?.data?.currencyId,
//       dob: benResponse?.data?.dob,
//       active: beneficiaryActive[benResponse?.data?.active],
//       status: beneficiaryStatus[benResponse?.data?.status],
//       label: benResponse?.data?.beneficiaryName,
//       value: benResponse?.data?.customerId,
//     };

//     const custId = benResponse?.data?.customerId;

//     if (custId) {
//       const custResponse = await getApiData(
//         `api/Customer/GetCustomer?CustomerID=${customerId}`
//       );

//       const addressId = custResponse?.residentialAddressId;
//       const UId = custResponse?.uid;

//       setState((prev) => ({
//         ...prev,
//         bankData: mapBenReponse,
//         UId,
//       }));
//       if (addressId) {
//         const addressResp = await getApiData(
//           `api/Address/GetAddress?AddressID=${addressId}`
//         );

//         setState((prev) => ({
//           ...prev,
//           addressData: addressResp,
//         }));
//       }

//       const docResp = await getApiData(
//         `api/CustomerDoc/GetCustDocByCustomerIdAdmin?CustomerID=${customerId}`
//       );
//       const custBen = docResp?.map((doc) => ({
//         ...doc,
//         custBenId: doc?.custBenId || mapBenReponse?.custBenId,
//       }));

//       setState((prev) => ({
//         ...prev,
//         documentData: custBen,
//       }));
//     }
//   };

//   const fetchData = async () => {
//     const response = await getApiData(
//       `api/AgentBeneficiary/GetAllCompanyBen?CompanyId=${companyBenId}`
//     );
//     const ben = response?.data?.find((item) => item.companyId === companyBenId);
//     const benId = ben?.companyBenId;

//     const responseData = await getApiData(
//       `api/AgentBeneficiary/GetBen?CompanyBenId=${benId}`
//     );
//     const benResponse = responseData.data;

//     const nationalID = benResponse.nationalId;

//     if (responseData) {
//       setState((prev) => ({
//         ...prev,
//         bankData: responseData?.data,
//         documentData: responseData?.data,
//       }));

//       const { addressId } = responseData.data;
//       if (addressId) {
//         const addressResponse = await getApiData(
//           `api/Address/GetAddress?AddressID=${addressId}`
//         );
//         if (addressResponse) {
//           setState((prev) => ({
//             ...prev,
//             addressData: addressResponse,
//           }));
//         }
//       }
//     }
//   };

//   useEffect(() => {
//     if (companyBenId) {
//       fetchData();
//     } else if (customerId) {
//       getBeneficiary();
//     }
//   }, [companyBenId, customerId]);

//   return (
//     <React.Fragment>
//       <div className="accordion-item">
//         <h2 className="accordion-header" id="headingFlushThree">
//           <button
//             className={classnames("accordion-button", "fw-medium", {
//               collapsed: !col12,
//             })}
//             type="button"
//             onClick={t_col12}
//             style={{ cursor: "pointer" }}
//           >
//             Beneficiary Details
//           </button>
//         </h2>
//         <Collapse isOpen={col12} className="accordion-collapse">
//           <div className="accordion-body">
//             <div className="text-muted">
//               <CustomerBeneficiaryForm
//                 bankData={state.bankData}
//                 addressData={state.addressData}
//                 documentData={state.documentData}
//                 customerId={customerId}
//                 UId={state.UId}
//                 companyBenId={companyBenId}
//                 fetchData={fetchData}
//               />
//             </div>
//           </div>
//         </Collapse>
//       </div>
//     </React.Fragment>
//   );
// };

// export default VIewTransactionBeneficiaryAccordian;
