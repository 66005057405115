import React from "react";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Table,
} from "reactstrap";

import { Link } from "react-router-dom";
import { path } from "../../../../routes/path";

const Contact = (props) => {
  //meta title
  document.title = "Crescent Exchange | Home";

  return (
    <React.Fragment>
      <section className="section bg-white" id="contact">
        <Container fluid={false}>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h4>Contact Us</h4>
              </div>
            </Col>
          </Row>

          <Row>
            {/* <Col xl={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">You can also reach out to us using the contact form below:</CardTitle>

                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your Name"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                      <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Enter Your Email ID"
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">Message</Label>
                      <textarea
                          id="formrow-message-Input"
                          className="form-control"
                          rows="2"
                          placeholder="Enter Your Message"
                        />
                    </div>

                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Submit
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col> */}

            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Location & Contact Info
                  </CardTitle>
                  <p className="text-muted mb-4">
                    If you have any inquiries or require assistance, please
                    don't hesitate to get in touch with us:
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Company :</th>
                          <td>Crescent Exchange Pte. Ltd.</td>
                        </tr>
                        <tr>
                          <th scope="row">Address :</th>
                          <td>
                            11 Collyer Quay, The Arcade #03-11 <br /> Singapore
                            049317
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>+65 8590 0090 | +65 9055 5575</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>
                            <Link
                              to={path.supportEmail}
                              className="text-primary"
                            >
                              support@crescentexchange.com{" "}
                            </Link>
                          </td>
                        </tr>
                        {/* <tr>
                          <th scope="row">Location :</th>
                          <td>{userProfile.location}</td>
                        </tr> */}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </section>
    </React.Fragment>
  );
};

export default Contact;
