import { toast } from "react-toastify";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { useState, useEffect } from "react";
import { beneficiaryActive, beneficiaryStatus } from "../data/Beneficiary/data";
import { debounce } from "lodash";
import { displayDigits } from "./currency";

export const mobileNumberValidator = { min: 8, max: 12 };
export const rateToFixed = 6;

export const useAuthUser = () => {
  const [userState, setUserState] = useState({
    authUser: null,
    isSystemAdmin: false,
    isSystemBranchAdmin: false,
    isSystemUser: false,
    userBranchId: null,
  });

  useEffect(() => {
    const fetchUser = async () => {
      const user = await JSON.parse(localStorage.getItem("authUser"));
      setUserState({
        authUser: user,
        isSystemAdmin: user?.role === "System Admin",
        isSystemBranchAdmin: user?.role === "System Branch Admin",
        isSystemUser: user?.role === "System User",
        userBranchId: authUser?.branchId,
      });
    };
    fetchUser();
  }, []);

  return userState;
};

export const sessionBranch = await JSON.parse(
  localStorage.getItem("selectedBranchId")
);
export const authUser = await JSON.parse(localStorage.getItem("authUser"));
export const isSystemAdmin = authUser?.role === "System Admin";
export const isSystemBranchAdmin = authUser?.role === "System Branch Admin";
export const isSystemUser = authUser?.role === "System User";
export const userBranchId = authUser?.branchId;

export const useBranch = () => {
  const [branch, setBranch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const mapBranchOptions = (branches) => {
    if (!branches) return [];
    return branches
      .map((branch) => ({
        label: branch.branchName,
        value: branch.branchId,
        branchId: branch.branchId,
        branchName: branch.branchName,
        active: branch.active,
        isMainBranch: branch.isMainBranch,
        branchAddress: `${branch.street}, ${branch.city},${branch.state}, ${branch.country}, ${branch.postalCode}`,
      }))
      .sort((a, b) => b.isMainBranch - a.isMainBranch);
  };

  const fetchBranch = async () => {
    setIsLoading(true);
    const response = await getApiData(`api/BranchMaster/GetAllBranch`);
    setBranch(mapBranchOptions(response?.data));
    setIsLoading(false);
    return mapBranchOptions(response?.data);
  };

  return { isLoading, branch, fetchBranch, mapBranchOptions };
};

export const useCurrency = () => {
  const [currency, setCurrency] = useState([]);

  const mapCurrencyOptions = (currency) => {
    if (!currency) return [];
    const startingNumber = 1;
    let currentNumber = startingNumber;
    return currency.map((e) => ({
      label: e.currencyCode,
      value: currentNumber++,
    }));
  };

  const getCurrency = async () => {
    const response = await getApiData("api/RateMaster/GetAllCurrency");
    setCurrency(mapCurrencyOptions(response?.data));
  };

  return { currency, getCurrency };
};

export const useCompany = () => {
  const [companyOptions, setCompanyOptions] = useState([]);

  const mapCompanyOptions = (company) => {
    if (!company) return [];
    return company.map((e) => ({
      label: e.companyName,
      value: e.companyId,
    }));
  };

  const getAllCompany = async () => {
    const response = await getApiData("api/AgentUser/GetAllCompany");
    setCompanyOptions(mapCompanyOptions(response?.data));
  };

  return { companyOptions, getAllCompany };
};

export const useCurrencyList = () => {
  const [currencyList, setCurrencyList] = useState([]);

  const getCurrencyList = async () => {
    const response = await getApiData(
      "api/Currency/GetAllCurrencies",
      {},
      true
    );
    if (response?.success) {
      const mappedResponse = response?.data?.map((item) => ({
        label: item.currencyName,
        currencyCode: item.currencyCode,
        currencyName: item.currencyName,
        value: item.currencyId,
        currencyId: item.currencyId,
        currencyFlag: item.currencyFlag,
      }));
      setCurrencyList(mappedResponse);
      return mappedResponse;
    }
  };
  return { currencyList, getCurrencyList };
};

// export const useRateMaster = () => {
//   const [rateMaster, setRateMaster] = useState([]);

//   const getRateMaster = async () => {
//     const response = await getApiData(`api/RateMaster/GetAllRateMaster`);

//     const rateTypeNames = {
//       1: "Buying",
//       2: "Selling",
//       3: "Transfer",
//     };
//     const tableData = response.map((item) => ({
//       rateId: item.rateId,
//       branchId: item.branchId,
//       rateTypeId: item.rateType,
//       currencyName: item.currencyName,
//       currencyCode: item.currencyCode,
//       rateType: rateTypeNames[item.rateType],
//       active: item.active,
//     }));
//     setRateMaster(tableData);
//   };
//   return { rateMaster, getRateMaster };
// };

export const toastError = (error) => {
  return toast.error(`${error}` || "Error, Contact Admin", {
    position: "top-right",
    autoClose: 3000,
    closeButton: false,
  });
};
export const toastInfo = (error) => {
  return toast.info(`${error}`, {
    position: "top-right",
    autoClose: 2000,
    closeButton: false,
  });
};

export const toastSucess = (msg) => {
  return toast.success(`${msg}`, {
    position: "top-right",
    autoClose: 2000,
    closeButton: false,
  });
};

export const toastWarn = (msg) => {
  return toast.warn(`${msg}`, {
    position: "top-right",
    autoClose: 2000,
    closeButton: false,
  });
};

export const validFileTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const getCustomerBeneficiaryByCustomerId = async (customerId) => {
  const response = await getApiData(
    `api/CustomerBenMap/GetAllCustBenMapByCustomerId?CustomerId=${customerId}`
  );
  if (response?.success) {
    const mappedResponse = response?.data?.map((item) => ({
      beneficiaryName: item.beneficiaryName,
      currency: item.currencyName,
      other: item.other,
      customerId: item.customerId,
      custBenId: item.custBenId,
      isMyAccount: item.isMyAccount,
      bankAddress: item.bankAddress,
      accountType: item.accountType,
      accountNumber: item.accountNumber,
      swift: item.swift,
      bankName: item.bankName,
      currencyId: item.currencyId,
      nationalID: item.nationalId,
      dob: item.dob.split("T")[0],
      email: item.email,
      mobile: item.mobile,
      active: beneficiaryActive[item.active],
      status: beneficiaryStatus[item.status] || "Unknown",
      label: `${item.beneficiaryName}`,
      value: item.customerId,
      addressId: item.addressId,
      street: item.street,
      city: item.city,
      state: item.state,
      country: item.country,
      postalCode: item.postalCode,
      nationality: item.nationality,
    }));

    return mappedResponse;
  }
};

export const getAgentBeneficiaryByCompanyId = async (companyId) => {
  const response = await getApiData(
    `api/AgentBeneficiary/GetAllCompanyBen?CompanyId=${companyId}`
  );
  if (response?.success) {
    const mappedResponse = response?.data?.map((item) => ({
      beneficiaryName: item.beneficiaryName,
      currency: item.currencyName,
      other: item.other,
      companyId: item.companyId,
      agentBenId: item.agentBenId,
      isMyAccount: item.isMyAccount,
      bankAddress: item.bankAddress,
      accountType: item.accountType,
      accountNumber: item.accountNumber,
      swift: item.swift,
      bankName: item.bankName,
      currencyId: item.currencyId,
      nationalID: item.nationalId,
      dob: item.dob,
      email: item.email,
      mobile: item.mobile,
      active: item.active,
      status: beneficiaryStatus[item.status],
      label: `${item.beneficiaryName} [${item.currencyCode}]`,
      value: item.companyBenId,
      addressId: item.addressId,
      street: item.street,
      city: item.city,
      state: item.state,
      country: item.country,
      postalCode: item.postalCode,
      nationality: item.nationality,
    }));

    return mappedResponse;
  }
};

export const toggleMode = {
  view: 1,
  edit: 2,
  add: 3,
};

export const useCustomer = () => {
  const [customerList, setCustomerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCustomer = async (enteredText) => {
    const data = {
      pageNumber: 1,
      pageCount: 150,
      filterColumns: [
        {
          columnName: "firstName",
          filterValue: `${enteredText}`,
          filterType: 6,
        },
        {
          columnName: "lastName",
          filterValue: `${enteredText}`,
          filterType: 6,
        },
        {
          columnName: "email",
          filterValue: `${enteredText}`,
          filterType: 6,
        },
      ],
    };
    setLoading(true);
    const response = await postApiData(
      "api/Customer/GetAllCustomerPagination",
      data
    );

    const mappedResponse = response?.model?.map((item, index) => ({
      index: index + 1,
      label: `${item.firstName} ${item.middleName && item.middleName} ${
        item.lastName
      } [${item.email}]`,
      customerName: `${item.firstName} ${
        item.middleName ? item.middleName + " " : ""
      }${item.lastName}`,
      value: item.customerId,
      userStatus: item.userStatus,
      customerId: item.customerId,
      UId: item.uid,
    }));
    setLoading(false);

    if (mappedResponse?.length <= 0) {
      if (enteredText !== undefined) {
        toast.error(`Customer Not Found`, {
          position: "top-right",
          autoClose: 2000,
          closeButton: false,
        });
      } else {
        setCustomerList(mappedResponse);
      }
    } else if (mappedResponse?.length > 0) {
      setCustomerList(mappedResponse);
    }
  };
  const delayGetCustomer = debounce(getCustomer, 1500);

  return { customerList, delayGetCustomer, loading };
};

export const useProfile = (customerID) => {
  const [profileImage, setProfileImage] = useState(null);
  const getProfileImage = async () => {
    const imageResponse = await getApiData(
      `api/ProfileImage/GetImage?CustomerId=${customerID}`
    );
    setProfileImage(imageResponse?.data);
  };

  return { profileImage, getProfileImage };
};

export const isWithinAWeek = (date) => {
  if (!date) return false;
  const approvedDateObj = new Date(date);
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  return approvedDateObj > oneWeekAgo;
};

export const roundOff = (value) => {
  if (value === null || value === undefined || value === "") return 0;
  return parseFloat(parseFloat(value).toFixed(displayDigits));
};
