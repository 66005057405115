import React from "react";
import classnames from "classnames";
import { Collapse, Table } from "reactstrap";
import { OpenFileInNewTab } from "../../../common/data/OpenFile";

const SignatureInfo = (props) => {
  const { col13, t_col13, sendTransactionDetails, sign } = props;

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFlushOne">
        <button
          className={classnames("accordion-button", "fw-medium", {
            collapsed: !col13,
          })}
          type="button"
          onClick={t_col13}
          style={{ cursor: "pointer" }}
        >
          Signature Info
        </button>
      </h2>
      <Collapse isOpen={col13} className="accordion-collapse">
        <div className="accordion-body">
          <div className="text-muted">
            <Table className="table-nowrap mb-0">
              <tbody>
                {sendTransactionDetails && sign ? (
                  <tr>
                    <td>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          minHeight: "100px",
                        }}
                      >
                        {sign?.sign !== null && (
                          <img
                            src={`data:image/png;base64,${sign?.sign}`}
                            alt="Signature"
                            style={{
                              width: "150px",
                              height: "50px",
                              objectFit: "contain",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              OpenFileInNewTab(
                                "png",
                                sign?.sign,
                                sign?.transactionId
                              )
                            }
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>
                      <div
                        className="text-muted d-flex justify-content-center align-items-center"
                        style={{ minHeight: "100px" }}
                      >
                        <h3>No signature available</h3>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default SignatureInfo;
