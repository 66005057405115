import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import ReactSelect from "react-select";
import { agentList } from "../../common/data/roles";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import SelectStyle from "../../common/data/SelectStyle";
import { mobileNumberValidator } from "../../common/data/commonfunctions";
import ShowHidePassword from "../Authentication/ShowHidePassword";
const AddManageAgent = ({ isOpen, toggle, getAgents, companyId }) => {
  const [branchOptions, setBranchOptions] = useState([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const fetchBranch = async () => {
    const response = await getApiData(
      `api/BranchMaster/GetAllAgentBranch?companyId=${companyId}`
    );
    if (response?.success) {
      setBranchOptions(mapBranchOptions(response?.data));
    }
  };

  const mapBranchOptions = (branches) => {
    if (!branches) return [];
    return branches.map((branch) => ({
      label: branch.branchName,
      value: branch.branchId,
    }));
  };

  useEffect(() => {
    if (companyId) {
      fetchBranch();
    }
  }, [companyId]);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
      status: 4,
      roleId: null,
      branchId: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only letters")
        .required("Please Enter First Name"),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name should contain only letters")
        .required("Please Enter Last Name"),
      middleName: Yup.string(),
      email: Yup.string()
        .email("Invalid Email Address")
        .required("Please Enter Email"),
      mobile: Yup.string()
        .required("Please Enter Mobile Number")
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .matches(
          new RegExp(
            `^\\+?\\d{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
          ),
          `Invalid Mobile Number`
        ),
      password: Yup.string()
        .required("Please Enter Password")
        .matches(
          /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
          "Password must be at least 8 characters and must contain at least one digit, one uppercase letter, and one special character"
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please Confirm Password"),
      branchId: Yup.object().shape({
        label: Yup.string().required("Please Select a Branch"),
        value: Yup.string().required("Please Select a Branch"),
      }),
      roleId: Yup.object().shape({
        label: Yup.string().required("Please Select a Role"),
        value: Yup.string().required("Please Select a Role"),
      }),
    }),
    onSubmit: async (values, { resetForm }) => {
      const branchValue = values.branchId?.value;
      const roleValue = values.roleId?.value;
      const requestData = {
        ...values,
        companyId,
        userName: values.email,
        roleId: roleValue,
        branchId: branchValue,
      };
      const response = await postApiData(
        `api/AgentUser/CreateAgent`,
        requestData
      );
      if (response?.success) {
        toast.success("Agent Created Successfully", {
          position: "top-right",
          autoClose: 2000,
        });
        toggle();
        resetForm();
        getAgents(companyId);
      } else {
        toast.error(response?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    },
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered
        onClosed={() => {
          formik.resetForm();
        }}
      >
        <ModalHeader toggle={toggle}>Create New Agent</ModalHeader>
        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    id="firstName"
                    placeholder="Enter First Name"
                    {...formik.getFieldProps("firstName")}
                    invalid={
                      formik.touched.firstName && !!formik.errors.firstName
                    }
                  />
                  <FormFeedback>{formik.errors.firstName}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    id="lastName"
                    placeholder="Enter Last Name"
                    {...formik.getFieldProps("lastName")}
                    invalid={
                      formik.touched.lastName && !!formik.errors.lastName
                    }
                  />
                  <FormFeedback>{formik.errors.lastName}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="middleName">Middle Name</Label>
                  <Input
                    type="text"
                    id="middleName"
                    placeholder="Enter Middle Name"
                    {...formik.getFieldProps("middleName")}
                    invalid={
                      formik.touched.middleName && !!formik.errors.middleName
                    }
                  />
                  <FormFeedback>{formik.errors.middleName}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="email">Email</Label>
                  <RequiredAsterisk />
                  <Input
                    type="email"
                    id="email"
                    placeholder="Enter Email"
                    {...formik.getFieldProps("email")}
                    invalid={formik.touched.email && !!formik.errors.email}
                  />
                  <FormFeedback>{formik.errors.email}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="mobile">Mobile Number</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    id="mobile"
                    placeholder="Enter Mobile Number"
                    {...formik.getFieldProps("mobile")}
                    invalid={formik.touched.mobile && !!formik.errors.mobile}
                  />
                  <FormFeedback>{formik.errors.mobile}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="roleId">Role</Label>
                  <RequiredAsterisk />
                  <ReactSelect
                    id="roleId"
                    placeholder="Select Role"
                    value={formik.values.roleId}
                    onChange={(option) =>
                      formik.setFieldValue("roleId", option)
                    }
                    options={agentList}
                    styles={SelectStyle}
                    invalid={
                      formik.touched.roleId && formik.errors.roleId
                        ? true
                        : false
                    }
                  />
                  {formik.touched.roleId &&
                    formik.values.roleId === null &&
                    formik.errors.roleId && (
                      <span className="text-danger" style={{ fontSize: "80%" }}>
                        {`Please Select a Role` || formik.errors.roleId}
                      </span>
                    )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="branchId">Branch</Label>
                  <RequiredAsterisk />
                  <ReactSelect
                    id="branchId"
                    value={formik.values.branchId}
                    onChange={(selectedOption) => {
                      formik.setFieldValue("branchId", selectedOption);
                    }}
                    styles={SelectStyle}
                    options={branchOptions}
                    placeholder="Select Branch"
                    invalid={
                      formik.touched.branchId && formik.errors.branchId
                        ? true
                        : false
                    }
                  />
                  {formik.touched.branchId &&
                    formik.values.branchId === null &&
                    formik.errors.branchId && (
                      <span className="text-danger" style={{ fontSize: "80%" }}>
                        Please Select a Branch
                      </span>
                    )}
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label for="password">Password</Label>
                  <RequiredAsterisk />
                  <div className="input-group">
                    <Input
                      type={isPasswordVisible ? "text" : "password"}
                      id="password"
                      placeholder="Enter Password"
                      {...formik.getFieldProps("password")}
                      invalid={
                        formik.touched.password && formik.errors.password
                      }
                    />
                    <ShowHidePassword
                      isPasswordVisible={isPasswordVisible}
                      toggleVisibility={() =>
                        setIsPasswordVisible(!isPasswordVisible)
                      }
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <FormFeedback>{formik.errors.password}</FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="confirmPassword">Confirm Password</Label>
                  <RequiredAsterisk />
                  <div className="input-group">
                    <Input
                      type={isPasswordVisible ? "text" : "password"}
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      {...formik.getFieldProps("confirmPassword")}
                      invalid={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                    />
                    <ShowHidePassword
                      isPasswordVisible={isPasswordVisible}
                      toggleVisibility={() =>
                        setIsPasswordVisible(!isPasswordVisible)
                      }
                    />
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <FormFeedback>
                        {formik.errors.confirmPassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            Submit
          </Button>
        </ModalFooter>
        <ToastContainer closeButton={false} limit={1} />
      </Modal>
    </React.Fragment>
  );
};

export default AddManageAgent;
