import React, { useState } from "react";
import { Col, NavItem, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { postApiData, putApiData } from "./../../../helpers/axiosHelper";
import {
  mobileNumberValidator,
  toastError,
  toastInfo,
  toggleMode,
} from "../../../common/data/commonfunctions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import BankDetails from "./Tab/BankDetails";
import CompanyDetails from "./Tab/CompanyDetails";
import AddressDetails from "./Tab/addressDetails";
import AgentDocument from "./Tab/AgentDocument";

const CompanyBeneficiaryForm = (props) => {
  const {
    mode,
    bank,
    custData,
    address,
    companyId,
    companyBenId,
    beneficiaryState,
    setCompanyBenId,
    fetchData,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const disabled = mode === toggleMode.view ? true : false;

  const [state, setState] = useState({
    nationalityOptions: [],
    modal: false,
  });

  const [activeTab, setActiveTab] = useState(1);

  const navItem = [
    { title: "Beneficiary Details", value: 1 },
    { title: "Bank Details", value: 2 },
    { title: "Address", value: 3 },
    { title: "Documents", value: 4 },
  ];

  const companyValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyId: companyId !== null ? companyId : "",
      beneficiaryName: custData?.beneficiaryName || "",
      mobile: custData?.mobile || "",
      email: custData?.email || "",
      nationalID: custData?.nationalId || "",
      dob: custData?.dob || "",
    },

    validationSchema: Yup.object({
      beneficiaryName: Yup.string().required(
        "Please Enter the Beneficiary Name"
      ),
      nationalID: Yup.string().required("Please Enter the National ID"),
      mobile: Yup.string()
        .required("Please Enter Phone Number")
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .matches(
          new RegExp(
            `^\\+?\\d{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
          ),
          `Invalid Mobile Number`
        ),
      email: Yup.string().email("Invalid Email").required("Please Enter Email"),
      dob: Yup.date()
        .required("Please Select the Date of Birth")
        .max(new Date(), "Date of Birth cannot be in the future"),
    }),

    onSubmit: async (values) => {
      toast.dismiss();
      if (companyBenId) {
        const hasChanges = Object.keys(values).some(
          (key) => values[key] !== companyValidation.initialValues[key]
        );
        if (values.email === companyValidation.initialValues.email) {
          delete values.email;
        }
        if (values.phone === companyValidation.initialValues.phone) {
          delete values.phone;
        }
        if (!hasChanges) {
          toastInfo("No Changes to Update");
        } else {
          const response = await putApiData(
            `api/AgentBeneficiary/UpdateBeneficiary?CompanyBenId=${companyBenId}`,
            JSON.stringify(values)
          );
          if (response?.success) {
            toast.success("Beneficiary Updated", {
              position: "top-right",
              autoClose: 2000,
              closeButton: false,
            });
            fetchData(companyId);
            setActiveTab(activeTab + 1);
          } else {
            toastError(response?.message);
          }
        }
      } else {
        setIsLoading(true);
        const response = await postApiData(
          `api/AgentBeneficiary/CreateBeneficiary`,
          JSON.stringify(values)
        );
        if (response?.success) {
          toast.success("Beneficiary Created", {
            position: "top-right",
            autoClose: 2000,
            closeButton: false,
          });
          setCompanyBenId(response?.data?.companyBenId);
          setActiveTab(activeTab + 1);
          fetchData(companyId);
        } else {
          toastError(response?.message);
        }
        setIsLoading(false);
      }
    },
  });

  return (
    <Row>
      <Col lg="12">
        <div className="wizard clearfix">
          <div className="steps clearfix">
            <ul>
              {navItem.map((item) => (
                <NavItem
                  key={item.value}
                  className={classnames("p-2 me-1 ", {
                    active: activeTab === item.value && mode !== toggleMode.add,
                    "bg-light": activeTab === item.value,
                  })}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (mode !== toggleMode.add) setActiveTab(item.value);
                  }}
                >
                  <span className="number">{item.value}</span>
                  {item.title}
                </NavItem>
              ))}
            </ul>
          </div>
          <div className="content clearfix">
            <TabContent activeTab={activeTab} className="body">
              <TabPane tabId={1}>
                <CompanyDetails
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  disabled={disabled}
                  companyData={custData}
                  companyBenId={companyBenId}
                  companyId={companyId}
                  fetchData={fetchData}
                  isSubmitting={isSubmitting}
                  companyValidation={companyValidation}
                />
              </TabPane>
              <TabPane tabId={2}>
                <BankDetails
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  disabled={disabled}
                  bank={bank}
                  companyId={companyId}
                  fetchData={fetchData}
                  companyBenId={companyBenId}
                />
              </TabPane>
              <TabPane tabId={3}>
                <AddressDetails
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  disabled={disabled}
                  address={address}
                  fetchData={fetchData}
                  setCompanyBenId={setCompanyBenId}
                  companyBenId={companyBenId}
                />
              </TabPane>
              <TabPane tabId={4}>
                <AgentDocument
                  beneficiaryState={beneficiaryState}
                  companyId={custData?.companyId || state.companyBenId}
                  companyBenId={companyBenId}
                />
              </TabPane>
            </TabContent>
            {/*             
            <div className="text-end">
              <Button
                color="secondary"
                className="me-1"
                disabled={state.activeTab === 1 ? true : false}
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    activeTab: state.activeTab - 1,
                  }));
                }}
              >
                {left()}
              </Button>

              <Button
                color="secondary"
                className="me-1"
                disabled={state.activeTab === 4 ? true : false}
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    activeTab: state.activeTab + 1,
                  }));
                }}
              >
                {right()}
              </Button>
            </div> */}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(CompanyBeneficiaryForm);
