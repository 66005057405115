import React from "react";
import { FaFilter } from "react-icons/fa";

const FilterDisplay = ({
  filters,
  selectedFilterTypes,
  branches,
  customerList,
  companyOptions,
  state,
  transactionOptions,
  statusOptions,
  stockReport,
}) => {
  const hasFilters =
    Object.values(filters).some((value) => value) ||
    (stockReport &&
      Object.values(stockReport?.values || {}).some((value) => value));

  return (
    <div className="mt-3">
      {hasFilters ? (
        <div className="text-muted mb-2">
          <h5 className="mt-3 fw-bold">
            <FaFilter className="me-2" size={18} color="#007bff" />
            Filter By:
          </h5>
          {selectedFilterTypes?.map((filter, index) => {
            let filterValue;
            switch (filter) {
              case "Transaction Date":
                filterValue = `From Date: ${
                  filters.fromDate || "N/A"
                }, To Date: ${filters.toDate || "N/A"}`;
                break;
              case "Branch":
                filterValue = `Branch: ${
                  branches.find((b) => b.value === filters.fromBranchId)
                    ?.label || "N/A"
                }`;
                break;
              case "Customer":
                filterValue = `Customer: ${
                  customerList.find(
                    (c) => c.customerId === filters.fromCustomerId
                  )?.customerName || "On the Counter"
                }`;
                break;
              case "Company":
                filterValue = `Company: ${
                  companyOptions.find((c) => c.value === filters.companyId)
                    ?.label || "N/A"
                }`;
                if (filters.companyId && filters.agentId) {
                  filterValue = (
                    <>
                      <div className="mt-1">{filterValue}</div>
                      <div className="mt-1">
                        Agent:{" "}
                        {state.agentOptions?.find(
                          (agent) => agent.value === filters.agentId
                        )?.label || "N/A"}
                      </div>
                    </>
                  );
                } else {
                  filterValue = <div className="mt-1">{filterValue}</div>;
                }
                break;
              case "Currency":
                filterValue = `Currency: ${
                  filters.fromCurrType || filters.toCurrType || "N/A"
                }`;
                break;
              case "Transaction Type":
                filterValue = `Transaction Type: ${
                  transactionOptions.find(
                    (t) => t.value === filters.transactionType
                  )?.label || "N/A"
                }`;
                break;
              case "Status":
                filterValue = `Status: ${
                  statusOptions.find((s) => s.value === filters.status)
                    ?.label || "N/A"
                }`;
                break;
              default:
                return null;
            }
            return (
              <div key={index} className="mt-1">
                {filterValue}
              </div>
            );
          })}

          {/* Additional stockReport filters */}
          <div>
            {stockReport?.values?.currencyCode && (
              <div>
                Currency:&nbsp;
                {stockReport.values.currencyCode.label ||
                  stockReport.values.currencyCode ||
                  "N/A"}
              </div>
            )}
            {stockReport?.values?.branchId && (
              <div>
                Branch:&nbsp;
                {branches.find((b) => b.value === stockReport.values.branchId)
                  ?.label ||
                  stockReport.values.branchId.label ||
                  "N/A"}
              </div>
            )}
            {stockReport?.values?.fromDate && (
              <div>From Date:&nbsp;{stockReport.values.fromDate}</div>
            )}
            {stockReport?.values?.toDate && (
              <div>To Date:&nbsp;{stockReport.values.toDate}</div>
            )}
          </div>
        </div>
      ) : (
        <h5 className="mt-4">
          <FaFilter className="me-2" size={18} color="gray" />
          No Filter Applied
        </h5>
      )}
    </div>
  );
};

export default FilterDisplay;
