import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Row,
  Input,
  Modal,
  Table,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  deleteApiData,
  getApiData,
  postApiData,
} from "../../helpers/axiosHelper";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../components/Common/Loader";
import { validFileTypes } from "../../common/data/commonfunctions";
import { download, plus, trash, view } from "../../common/data/icons";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import { downloadFile, getFileType } from "../../common/data/OpenFile";

const ViewCustomerDocuments = (props) => {
  const { customerID, editMode, custBenId } = props;
  let type = null,
    options = null;
  //const [modal1, setModal1] = useState(false);
  //const toggleViewModal = () => setModal1(!modal1);
  const [customerDocuments, setCustomerDocuments] = useState();
  //const [viewDocument, setViewDocument] = useState("")
  const [doctype, setDoctype] = useState("I");
  const [modal_standard, setmodal_standard] = useState(false);
  const [docIsValid, setDocIsValid] = useState(true);
  const [fileData, setFileData] = useState(null);
  const idlist = [
    "Choose...",
    "Passport",
    "National Id Card",
    "License",
    "Other",
  ];
  const addresslist = ["Choose...", "Telephone Bill", "Gas Bill", "Other"];
  const [isLoading, setIsLoading] = useState(false);
  const [docFormat, setDocFormat] = useState("");

  if (doctype === "I" || doctype === null) {
    type = idlist;
  } else {
    type = addresslist;
  }
  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
  }

  function handleDocTypeChanged(e) {
    docValidation.handleChange(e);
    setDoctype(e.target.value);
  }

  function handleFileChange(e) {
    setDocIsValid(true);
    const file = e.target.files[0];
    if (!file) return;

    if (!validFileTypes.includes(file.type)) {
      toast.error(
        "Invalid File Type. Only PNG, JPG, JPEG, PDF, and DOCX are allowed.",
        {
          position: "top-right",
          autoClose: 3000,
        }
      );
      setDocIsValid(false);
      return;
    }

    if (file.size > 5000 * 1024) {
      toast.error("File Size Should be less than 5MB", {
        position: "top-right",
        autoClose: 3000,
      });
      setDocIsValid(false);
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const type = reader.result.split(";")[0];
      const docType = type.split("/")[1];
      let base64String = "";
      const indexOfComma = reader.result.indexOf(",");
      if (indexOfComma !== -1) {
        base64String = reader.result.substring(indexOfComma + 1);
      }
      setDocFormat(docType);
      setFileData(base64String);
    };
    reader.readAsDataURL(file);
  }

  const viewDoc = (index) => {
    const data = customerDocuments[index].document;
    // setViewDocument(data)
    // toggleViewModal()
    const contentType = customerDocuments[index]?.contentType;
    const fileName = customerDocuments[index].documentName;
    const fileType = getFileType(contentType !== null ? contentType : "png");
    openDocumentInNewTab(data, fileType, fileName, contentType);
  };

  const openDocumentInNewTab = (data, fileType, fileName, contentType) => {
    const newWindow = window.open();
    if (
      fileType === "image/png" ||
      fileType === "image/jpg" ||
      fileType === "image/jpeg"
    ) {
      newWindow.document.write(
        `<img src="data:${fileType};base64,${data}" alt="Document" style="width:100%;height:100%;" />`
      );
    } else if (fileType === "application/pdf") {
      newWindow.document.write(
        `<embed src="data:${fileType};base64,${data}" type="application/pdf" width="100%" height="100%" />`
      );
    } else if (
      fileType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      const blob = new Blob(
        [Uint8Array.from(atob(data), (c) => c.charCodeAt(0))],
        { type: fileType }
      );
      const url = URL.createObjectURL(blob);
      newWindow.document.write(
        `<iframe src="${url}" width="100%" height="100%"></iframe>`
      );
    } else {
      newWindow.document.write("Unsupported File");
    }
    newWindow.document.title =
      fileName && `${fileName}.${contentType !== null ? contentType : "png"}`;
    newWindow.document.close();
  };

  function getDocumentType(obj) {
    if (obj === "I") {
      return "Identity Proof";
    } else if (obj === "A") {
      return "Address Proof";
    } else if (obj === "O") {
      return "Others";
    }
  }

  const getCustomerDocuments = async () => {
    setIsLoading(true);
    const response = await getApiData(
      `api/CustomerDoc/GetCustDocByCustomerIdAdmin?${
        customerID ? "CustomerID" : "CustBenId"
      }=${customerID || custBenId}`
    );
    setIsLoading(false);

    const mappedResponse = response?.map((item, index) => ({
      index: index + 1,
      docId: item.docId,
      documentName: item.documentName,
      documentType: item.documentType,
      document: item.document,
      contentType: item.documentFormat,
    }));

    setCustomerDocuments(mappedResponse || []);
  };

  const docValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      DocumentType: "",
      DocumentName: "",
      Document: null,
      contentType: null,
    },

    validationSchema: Yup.object({
      DocumentType: Yup.string().required("Please Select the Document Type"),
      DocumentName: Yup.string().required("Please Select the Document Name"),
      Document: Yup.string().required("Please Upload the Document"),
    }),

    onSubmit: async (values, { resetForm }) => {
      if (docIsValid) {
        const combinedValues = {
          ...values,
          Document: fileData,
          contentType: docFormat,
          customerId: customerID,
          custBenId: custBenId,
        };
        if (customerID === null) {
          delete combinedValues.customerId;
        } else if (custBenId === null) {
          delete combinedValues.custBenId;
        }
        setIsLoading(true);
        const response = await postApiData(
          "api/CustomerDoc/InsertCustomerDoc",
          combinedValues
        );
        setIsLoading(false);
        if (response?.success === true) {
          toast.success("Document Added Successfully", {
            position: "top-right",
            autoClose: 3000,
          });
          resetForm();
          tog_standard();
          getCustomerDocuments();
        } else {
          toast.error("Error Status. Contact Admin", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      } else {
        toast.error("File Size Should be less than 5MB", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    },
  });

  const softDeleteCustDoc = async (e) => {
    const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

    if (result.isConfirmed) {
      const response = await deleteApiData(
        `api/CustomerDoc/DeleteCustDoc?CustDocID=${e.docId}`
      );
      if (response?.success === true) {
        toast.success("Document Deleted", {
          position: "top-right",
          autoClose: 3000,
        });
        getCustomerDocuments();
      }
    }
  };

  useEffect(() => {
    if (customerID || custBenId) {
      getCustomerDocuments();
    }
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <Card>
          <CardBody>
            {editMode ? null : (
              <Button
                type="button"
                color="success"
                className="mb-2"
                onClick={() => {
                  tog_standard();
                }}
              >
                {plus()} &nbsp;Add
              </Button>
            )}
            <Modal
              isOpen={modal_standard}
              toggle={() => {
                tog_standard();
              }}
              onClosed={() => {
                docValidation.resetForm();
              }}
            >
              <ModalHeader
                toggle={() => {
                  tog_standard();
                  docValidation.resetForm();
                }}
              >
                Manage Documents
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col sm={6}>
                            <div>
                              <label
                                htmlFor="DocumentType"
                                className="col-md-10 col-form-label"
                              >
                                Document Type
                              </label>
                              <div>
                                <div className="col-md-10">
                                  <select
                                    defaultValue="0"
                                    className="form-select"
                                    name="DocumentType"
                                    id="DocumentType"
                                    onChange={handleDocTypeChanged}
                                    onBlur={docValidation.handleBlur}
                                    value={docValidation.values.DocumentType}
                                    invalid={
                                      docValidation.touched.DocumentType &&
                                      docValidation.errors.DocumentType
                                        ? true
                                        : false
                                    }
                                  >
                                    <option defaultValue="0">Choose...</option>
                                    <option value="I">ID Proof</option>
                                    <option value="A">Address Proof</option>
                                    <option value="O">Others</option>
                                  </select>
                                  {docValidation.errors.DocumentType &&
                                    docValidation.touched.DocumentType && (
                                      <span
                                        className="text-danger"
                                        style={{ fontSize: "80%" }}
                                      >
                                        {docValidation.errors.DocumentType}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div>
                              <label
                                htmlFor="DocumentName"
                                className="col-md-10 col-form-label"
                              >
                                Document Name
                              </label>
                              <div className="col-md-10">
                                <select
                                  defaultValue="0"
                                  className="form-select"
                                  id="DocumentName"
                                  name="DocumentName"
                                  onChange={docValidation.handleChange}
                                  onBlur={docValidation.handleBlur}
                                  value={docValidation.values.DocumentName}
                                  invalid={
                                    docValidation.touched.DocumentName &&
                                    docValidation.errors.DocumentName
                                      ? true
                                      : false
                                  }
                                >
                                  {options}
                                </select>
                                {docValidation.errors.DocumentName &&
                                  docValidation.touched.DocumentName && (
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: "80%" }}
                                    >
                                      {docValidation.errors.DocumentName}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12}>
                            <div className="mt-3">
                              <Label className="form-label">
                                Select Document
                              </Label>
                              <div className="col-md-10">
                                <Input
                                  type="file"
                                  id="Document"
                                  aria-label="Upload"
                                  //  value={docValidation.values.Document}
                                  onChange={(e) => {
                                    handleFileChange(e);
                                    docValidation.setFieldValue(
                                      "Document",
                                      e.currentTarget.files[0]
                                    ); // Store the selected file
                                  }}
                                  // onChange={(e) => {
                                  //   handleFileChange(e);
                                  //   docValidation.handleChange(e);
                                  // }}
                                  accept=".png, .jpg, .jpeg, .pdf, .docx"
                                  onBlur={docValidation.handleBlur}
                                  invalid={
                                    docValidation.touched.Document &&
                                    docValidation.errors.Document
                                      ? true
                                      : false
                                  }
                                />
                                {docValidation.errors.Document &&
                                docValidation.touched.Document ? (
                                  <span
                                    className="text-danger"
                                    style={{ fontSize: "80%" }}
                                  >
                                    {docValidation.errors.Document}
                                  </span>
                                ) : null}
                                <div>
                                  <span
                                    className="text-danger"
                                    style={{ fontSize: "80%" }}
                                  >
                                    Note : Only PNG, JPG, JPEG, PDF, and DOCX
                                    are allowed{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="primary"
                  onClick={docValidation.handleSubmit}
                  disabled={docValidation.isSubmitting}
                >
                  Upload
                </Button>
              </ModalFooter>
            </Modal>
            <Row>
              {customerDocuments && customerDocuments.length > 0 ? (
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>No.</th>
                        <th>Document Type</th>
                        <th>Document Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerDocuments
                        ? customerDocuments.map((arr, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{getDocumentType(arr.documentType)}</td>
                              <td>{arr.documentName}</td>
                              <td>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-sm btn-rounded mb-2 me-1"
                                  onClick={() => viewDoc(index)}
                                  aria-label="view"
                                >
                                  {view()}
                                </Button>
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-sm btn-rounded mb-2 me-1"
                                  onClick={() => {
                                    const data = customerDocuments[index];
                                    downloadFile(
                                      data.contentType,
                                      data.documentName,
                                      data.document
                                    );
                                  }}
                                >
                                  {download()}
                                </Button>
                                {editMode ? null : (
                                  <Button
                                    type="button"
                                    color="danger"
                                    className="btn-sm btn-rounded mb-2 me-1"
                                    onClick={() => softDeleteCustDoc(arr)}
                                  >
                                    {trash()}
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-sm-center fs-5">
                  <Label>No Documents Found</Label>
                </div>
              )}
            </Row>
            {/* <Modal
            size="lg"
              isOpen={modal1}
              toggle={toggleViewModal}
            >
              <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    View Document
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setModal1(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <img src={`data:image/png;base64,${viewDocument}`} alt="Base64 Image"
                      height="500px" width="100%" />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Modal> */}
          </CardBody>
        </Card>
      )}
      <ToastContainer limit={1} closeButton={false} />
    </React.Fragment>
  );
};

export default ViewCustomerDocuments;
