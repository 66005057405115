import React, { useState } from "react";
import { Row, Col, Container, Nav, NavItem, NavLink } from "reactstrap";
import RateSheetTable from "./RateSheetTable";
import RateTable from "./RateTable";

const ManageRatesFeature = (props) => {
  document.title = "Manage Rates | Crescent Exchange";
  const localStorageKey = "manageRateActiveTab";
  const [activeTab, setActiveTab] = useState(() => {
    return parseInt(localStorage.getItem(localStorageKey)) || 1;
  });

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      localStorage.setItem(localStorageKey, tab);
    }
  };

  const navTabs = [
    { label: "Rate Sheet", value: 1 },
    { label: "Rate Master", value: 2 },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Nav
            fill
            tabs
            style={{
              marginBottom: "10px",
              width: "50%",
            }}
          >
            {navTabs.map((x) => (
              <NavItem key={x.value}>
                <NavLink
                  className={activeTab === x.value ? "active" : ""}
                  style={
                    activeTab === x.value
                      ? { backgroundColor: "#556ee6", color: "white" }
                      : {}
                  }
                  onClick={() => {
                    toggle(x.value);
                  }}
                >
                  {x.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <Row>
            <Col lg="12">
              {activeTab === 1 ? <RateSheetTable /> : null}

              {activeTab === 2 ? <RateTable /> : null}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageRatesFeature;
