import { roundOff } from "./commonfunctions";
import { baseCurrency, rateDigits } from "./currency";
import { formatDateToUserTimeZone } from "./date";
import { mapStatus, mapTransactionType } from "./StatusLabels";

export const TransactionType = {
  Buy: 1,
  Sell: 2,
  Transfer: 3,
};

export const handleCommaSeperator = (value) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(roundOff(value));
};

export const mapTransactionData = (data) => {
  return data?.map((item, key) => {
    return {
      index: key + 1,
      transactionId: item.transactionId,
      customerName: item.customerName,
      name: item.companyName || item.name,
      Name: item.name,
      branchIdView: item.fromBranchId,
      agentId: item.agentId,
      agentName: item.agentName || item.name,
      fromCustomerId: item.fromCustomerId,
      agentBranch: item.branchName,
      branchName: item.branchName,
      toCurrType: item.toCurrType,
      toCurrValue:
        item.transactionType === TransactionType.Buy
          ? `${item.toCurrType} - ${handleCommaSeperator(item.toCurrValue)}`
          : item.transactionType === TransactionType.Sell ||
            item.fromCurrType === baseCurrency
          ? `${item.fromCurrType} - ${handleCommaSeperator(item.fromCurrValue)}`
          : `${item.toCurrType} - ${handleCommaSeperator(item.toCurrValue)}`,
      currencyType:
        item.transactionType === TransactionType.Buy
          ? item.fromCurrType
          : item.transactionType === TransactionType.Sell ||
            item.fromCurrType === baseCurrency
          ? item.toCurrType
          : item.fromCurrType,
      fromCurrType: item.fromCurrType,
      currencyValue: `${item.fromCurrValue} - ${handleCommaSeperator(
        item.toCurrValue
      )}`,
      fromCurrValue:
        item.transactionType === TransactionType.Buy
          ? `${item.fromCurrType} - ${handleCommaSeperator(item.fromCurrValue)}`
          : item.transactionType === TransactionType.Sell ||
            item.fromCurrType === baseCurrency
          ? `${item.toCurrType} - ${handleCommaSeperator(item.toCurrValue)}`
          : `${item.fromCurrType} - ${handleCommaSeperator(
              item.fromCurrValue
            )}`,
      transactionDate: formatDateToUserTimeZone(item.transactionDate),
      statusText: mapStatus(item.status),
      status: item.status,
      transactionType: mapTransactionType(item.transactionType),
      exchangeRate: parseFloat(item.exchangeRate).toFixed(rateDigits),
      movingAverage: item.movingAverage,
      pickup: formatDateToUserTimeZone(item.pickup),
      modifiedAt: item.modifiedAt,
      createdAt: item.createdAt,
      profitLossSelling: item.profitLossSelling,
    };
  });
};
