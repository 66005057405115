const config = {
  // apiUrl: process.env.REACT_APP_API_URL,
  // apiUrl: "https://localhost:7298/",
  // apiUrl: "https://prodapi.crescentexchange.com/system/",
  apiUrl: "https://sitapi.crescentexchange.com/system/",
  amlUrl: process.env.REACT_APP_AML_URL,
  livefxUrl: process.env.REACT_APP_LIVERATES_URL,
  docUrl: process.env.REACT_APP_HELP_DOC_URL,
  logoUrl: process.env.REACT_APP_LOGO_URL,
  // Add other configuration options as needed
};

export default config;
