import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "../../components/Common/withRouter";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { path } from "../../routes/path";

const Logout = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
      localStorage.removeItem("authUser");
      sessionStorage.removeItem("")

      // Clear all cache items 
      for (let i = 0; i < sessionStorage.length; i++) {
          const key = sessionStorage.key(i);
          if (key.startsWith("axios-cache")) {
              sessionStorage.removeItem(key);
              i--;
          }
      }
    history("/");
  }, [dispatch, history]);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
