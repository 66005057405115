import React, { useMemo, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { putApiData } from "../../helpers/axiosHelper";
import TableContainer from "../../components/Common/TableContainer";
import Swal from "sweetalert2";
import CustomLoader from "../../components/Common/CustomLoader";
import { displayFlag } from "../../common/data/currency";
import { RateSize } from "../../common/data/RateSheet";

const EditDraftRateSheetToggle = ({
  isOpen,
  toggle,
  sendRateSheet,
  rateSheetID,
  rateSheet,
  isTransfer,
}) => {
  const [state, setState] = useState({
    submitting: false,
  });

  const validateFormValues = (values) => {
    for (const item of values) {
      if (item.rateData) {
        for (const rate of item.rateData) {
          if (!/^\d*\.?\d*$/.test(rate.value) || parseFloat(rate.value) < 0) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const rateTypeMapping = {
    Buying: 1,
    Selling: 2,
    Transfer: 3,
  };

  const handleSubmit = async () => {
    if (validateFormValues(sendRateSheet)) {
      Swal.fire({
        text: "Are you sure to make this draft as Active?",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes, Make it as Active",
        confirmButtonColor: "#556ee6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            setState((prevState) => ({ ...prevState, submitting: true }));
            const activeDraftSheet = await putApiData(
              `api/RateMaster/UpdateRateSheetDraftToActive?RateSheetID=${rateSheetID}`
            );
            setState((prevState) => ({ ...prevState, submitting: false }));
            if (activeDraftSheet.success === true) {
              rateSheet();
              toggle();
              toast.success("The current draft has set to Active", {
                position: "top-right",
                autoClose: 1000,
                closeButton: false,
              });
            } else {
              Swal.fire({
                text:
                  activeDraftSheet.message +
                  "! Do you want to elapse the active sheet?",
                icon: "question",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Yes, Elapse it",
                confirmButtonColor: "#556ee6",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  setState((prevState) => ({ ...prevState, submitting: true }));
                  const branchId =
                    sendRateSheet[1]?.rateData[0]?.branchId === null
                      ? 0
                      : sendRateSheet[1]?.rateData[0]?.branchId;
                  const elapseResponse = await putApiData(
                    `api/RateMaster/ForceUpdateActiveRateSheet?branchId=${branchId}`
                  );
                  setState((prevState) => ({
                    ...prevState,
                    submitting: false,
                  }));

                  if (elapseResponse?.success === true) {
                    handleSubmit();
                  } else {
                    toast.error(elapseResponse?.message, {
                      position: "top-right",
                      autoClose: 3000,
                      closeButton: false,
                    });
                  }
                }
              });
            }
          } catch (error) {
            toast.error("Error: " + error, {
              position: "top-right",
              autoClose: 3000,
              closeButton: false,
            });
          } finally {
            setState((prevState) => ({ ...prevState, submitting: false }));
          }
        }
        setState((prevState) => ({ ...prevState, submitting: false }));
      });
    } else {
      toast.error("Please Enter Only Postive Numbers", {
        position: "top-right",
        autoClose: 3000,
        closeButton: false,
      });
      setState((prevState) => ({ ...prevState, submitting: false }));
    }
  };

  const editUpdateDraft = () => {
    if (validateFormValues(sendRateSheet)) {
      Swal.fire({
        text: "Are you sure to update this draft?",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes, Update it",
        confirmButtonColor: "#556ee6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const requestBodyRateHistory = sendRateSheet?.flatMap((item) =>
              item.rateData
                ?.map((rate) => ({
                  rateHistoryId: rate.rateHistoryId,
                  rateSheetID: rate.rateSheetId,
                  branchId: rate.branchId,
                  rateType: rateTypeMapping[rate.rateType],
                  currencyCode: item.currencyCode,
                  currencyName: item.currencyName,
                  value: rate.value,
                  agentCharges: rate.agentCharges,
                  worldRate: rate.worldRate,
                  wireTransferFee: rate.wireTransferFee,
                  commissionCharges: rate.commissionCharges,
                  active: 0,
                }))
                .filter(
                  (row) =>
                    row.value ||
                    row.agentCharges ||
                    row.worldRate ||
                    row.wireTransferFee ||
                    row.commissionCharges
                )
            );

            setState((prevState) => ({ ...prevState, submitting: true }));
            const rateHistoryResponse = await putApiData(
              `api/RateHistory/UpdateRateHistory`,
              JSON.stringify(requestBodyRateHistory)
            );
            if (rateHistoryResponse?.success === true) {
              toast.success("Draft has been Updated", {
                position: "top-right",
                autoClose: 1000,
                closeButton: false,
              });
              toggle();
            } else {
              toast.error(`${rateHistoryResponse?.message}`, {
                position: "top-right",
                autoClose: 3000,
                closeButton: false,
              });
            }
          } catch (error) {
            toast.error("Error: " + error, {
              position: "top-right",
              autoClose: 3000,
              closeButton: false,
            });
          } finally {
            setState((prevState) => ({ ...prevState, submitting: false }));
          }
        }
        setState((prevState) => ({ ...prevState, submitting: false }));
      });
    } else {
      toast.error("Please Enter Only Postive Numbers", {
        position: "top-right",
        autoClose: 3000,
        closeButton: false,
      });
      setState((prevState) => ({ ...prevState, submitting: false }));
    }
  };

  const rateError = (e) => {
    if (!/^\d*\.?\d*$/.test(e.target.value) && e.target.value !== "") {
      toast.error("Please Enter Only Positive Numbers", {
        position: "top-right",
        autoClose: 3000,
        closeButton: false,
      });
    }
  };

  const renderAgentCharges = (item, row, index) => {
    return (
      <div>
        <style>
          {`
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }

            input[type="number"] {
            -moz-appearance: textfield;
            }
          `}
        </style>
        <Input
          name="agentCharges"
          type="number"
          step="any"
          min={0}
          title="Agent Charges"
          aria-label="Agent Charges"
          placeholder="Agent Charges"
          defaultValue={item.agentCharges ? item.agentCharges : ""}
          onChange={(e) => {
            const newValue = e.target.value.replace(/\s+/g, "");
            if (row.original.rateData?.[index]) {
              row.original.rateData[index].agentCharges = newValue;
            }
          }}
          onBlur={rateError}
          onFocus={(e) => {
            e.currentTarget.onwheel = (event) => event.preventDefault();
          }}
          style={{ width: RateSize.AGENT_CHARGES }}
        />
      </div>
    );
  };

  const renderRateValue = (item, row, index) => {
    return (
      <Input
        name="value"
        type="number"
        step="any"
        min={0}
        title={item.rateType + ` Rate`}
        aria-label={item.rateType + ` Rate`}
        placeholder={item.rateType + ` Rate`}
        defaultValue={item.value ? item.value : ""}
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s+/g, "");
          if (row.original.rateData?.[index]) {
            row.original.rateData[index].value = newValue;
          }
        }}
        onBlur={rateError}
        onFocus={(e) => {
          e.currentTarget.onwheel = (event) => event.preventDefault();
        }}
        className="mr-1"
        style={{
          marginLeft: item.rateType === "Transfer" ? "0px" : "10px",
          width: RateSize.VALUE,
        }}
      />
    );
  };

  const renderWireTransferFee = (item, row, index) => {
    return (
      <Input
        name="wireTransferFee"
        type="number"
        step="any"
        min={0}
        title="Wire Transfer Fee"
        aria-label="Wire Transfer Fee"
        placeholder={"Wire Transfer Fee"}
        defaultValue={item.wireTransferFee ? item.wireTransferFee : ""}
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s+/g, "");
          if (row.original.rateData?.[index]) {
            row.original.rateData[index].wireTransferFee = newValue;
          }
        }}
        onBlur={rateError}
        onFocus={(e) => {
          e.currentTarget.onwheel = (event) => event.preventDefault();
        }}
        style={{ width: RateSize.WIRE_TRANSFER_FEE }}
      />
    );
  };

  const renderCommissionCharges = (item, row, index) => {
    return (
      <Input
        name="commissionCharges"
        type="number"
        step="any"
        min={0}
        title="Commission Charges"
        aria-label="Commission Charges"
        placeholder={"Commission Charges"}
        defaultValue={item.commissionCharges ? item.commissionCharges : ""}
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s+/g, "");
          if (row.original.rateData?.[index]) {
            row.original.rateData[index].commissionCharges = newValue;
          }
        }}
        onBlur={rateError}
        onFocus={(e) => {
          e.currentTarget.onwheel = (event) => event.preventDefault();
        }}
        style={{ width: RateSize.COMMISSION_CHARGES }}
      />
    );
  };

  const renderWorldRate = (item, row, index) => {
    return (
      <Input
        name="worldRate"
        type="number"
        step="any"
        min={0}
        title="World Rate"
        aria-label="World Rate"
        placeholder={"World Rate"}
        defaultValue={item.worldRate ? item.worldRate : ""}
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s+/g, "");
          if (row.original.rateData?.[index]) {
            row.original.rateData[index].worldRate = newValue;
          }
        }}
        onBlur={rateError}
        onFocus={(e) => {
          e.currentTarget.onwheel = (event) => event.preventDefault();
        }}
        style={{ width: RateSize.WORLD_RATE }}
      />
    );
  };

  const renderRateTypes = ({ row }) => {
    const rateData =
      row.original.rateData ??
      sendRateSheet?.filter(
        (item) => item.currencyName === row.original.currencyName
      );

    return (
      <div className=" p-2" style={{ border: "1px solid #ccc" }}>
        {rateData?.map((item, index) => (
          <div
            key={index}
            className={`d-flex flex-wrap align-items-center gap-1 py-1 ${
              index < rateData?.length - 1 ? "border-bottom border-light" : ""
            }`}
          >
            {!isTransfer && <span className="mr-2">{item.rateType}</span>}
            {renderRateValue(item, row, index)}
            {renderAgentCharges(item, row, index)}
            {item.rateType === "Transfer" && (
              <>
                {renderWireTransferFee(item, row, index)}
                {renderCommissionCharges(item, row, index)}
                {renderWorldRate(item, row, index)}
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Currency Name",
        accessor: "currencyName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { currencyFlag } = cellProps.row.original;
          return (
            <>
              {displayFlag(currencyFlag)}
              {cellProps.value ?? ""}
            </>
          );
        },
      },
      {
        Header: "Rate Types",
        accessor: "rateData",
        disableFilters: true,
        filterable: false,
        Cell: renderRateTypes,
      },
    ],
    [isTransfer]
  );

  const handleValueChange = (e, rowIndex) => {
    const newValue = e.target.value;
    sendRateSheet((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        value: newValue,
      };
      return updatedData;
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      size="xl"
    >
      <div className="modal-content">
        <Form className="add-rate" id="editRateForm">
          <ModalHeader toggle={toggle}>
            {sendRateSheet[0]?.rateData[0]?.rateType === "Transfer"
              ? "Rate Sheet - Global Transfer"
              : sendRateSheet[0]?.rateData[0]?.branchId
              ? `Rate Sheet - ${sendRateSheet[0]?.rateData[0]?.branchName}`
              : "Rate Sheet - All"}
          </ModalHeader>
          <ModalBody>
            <TableContainer
              columns={columns}
              data={sendRateSheet}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={sendRateSheet?.length || 10}
              isPageSelect={false}
              onValueChange={handleValueChange}
              pageSizeOptions={true}
            />
          </ModalBody>
          <ModalFooter
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "75%",
              }}
            >
              <Button
                color="success"
                name="createActiveSheet"
                onClick={handleSubmit}
                disabled={state.submitting}
              >
                Create an Active Sheet
              </Button>
            </div>
            <div
              style={{
                width: "20%",
              }}
            >
              <Button
                color="primary"
                style={{
                  marginRight: "5px",
                }}
                name="updateDraft"
                onClick={editUpdateDraft}
                disabled={state.submitting}
              >
                Update Draft
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </div>
      <ToastContainer />
      {state.submitting && <CustomLoader text="Updating Ratesheet" />}
    </Modal>
  );
};

export default EditDraftRateSheetToggle;
