import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import classname from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import withRouter from "./../Common/withRouter";
import {
  faArrowTrendUp,
  faBuilding,
  faDollarSign,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { path } from "../../routes/path";

const Navbar = ({ leftMenu, t }) => {
  const [dropdownStates, setDropdownStates] = useState({
    manageAdmin: false,
    stock: false,
    transaction: false,
    branch: false,
    report: false,
  });

  const toggleDropdown = (dropdown) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const role = authUser?.role;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".dropdown-menu")) {
        setDropdownStates({
          manageAdmin: false,
          stock: false,
          transaction: false,
          branch: false,
          report: false,
        });
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/admindashboard">
                    <i className="bx bx-home-circle me-1"></i>
                    {t("Dashboard")}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    onClick={() => toggleDropdown("manageAdmin")}
                  >
                    <i className="bx bx-user-circle me-1"></i>
                    {t("Admin Management")}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: dropdownStates.manageAdmin,
                    })}
                  >
                    {(role === "System Admin" ||
                      role === "System Branch Admin") && (
                      <>
                        <Link to={path.manageUser} className="dropdown-item">
                          {t("Manage User")}
                        </Link>

                        <Link to={path.manageCompany} className="dropdown-item">
                          {t("Manage Company")}
                        </Link>
                      </>
                    )}
                    <Link to={path.manageRates} className="dropdown-item">
                      {t("Manage Rate")}
                    </Link>
                  </div>
                </li>

                {(role === "System Admin" ||
                  role === "System Branch Admin") && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to={path.branch}
                    >
                      <FontAwesomeIcon icon={faBuilding} className="me-1" />
                      {t("Manage Branch")}
                    </Link>
                  </li>
                )}

                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    onClick={() => toggleDropdown("transaction")}
                  >
                    <i className="bx bx-wallet me-1"></i>
                    {t("Transaction")}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: dropdownStates.transaction,
                    })}
                  >
                    <Link to={path.manageTransaction} className="dropdown-item">
                      {t("Manage Transaction")}
                    </Link>
                    <Link
                      to={path.transactionHistory}
                      className="dropdown-item"
                    >
                      {t("Transaction History")}
                    </Link>
                    <Link to={path.addTransaction} className="dropdown-item">
                      {t("Add Transaction")}
                    </Link>
                  </div>
                </li>

                {/* {(role === "System User" || role === "System Admin") && ( */}
                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    onClick={() => toggleDropdown("stock")}
                  >
                    <FontAwesomeIcon icon={faArrowTrendUp} className="me-1" />
                    {t("Stock")}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: dropdownStates.stock,
                    })}
                  >
                    <Link to={path.manageStock} className="dropdown-item">
                      {t("Manage Stock")}
                    </Link>
                    <Link to={path.stockLog} className="dropdown-item">
                      {t("Stock Log")}
                    </Link>
                  </div>
                </li>

                {(role === "System Admin" ||
                  role === "System Branch Admin") && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to={path.liveRates}
                    >
                      <FontAwesomeIcon icon={faDollarSign} className="me-1" />
                      {t("Live Rates")}
                    </Link>
                  </li>
                )}

                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    onClick={() => toggleDropdown("report")}
                  >
                    <FontAwesomeIcon icon={faFileInvoice} className="me-1" />
                    {t("Reports")}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: dropdownStates.report,
                    })}
                  >
                    <Link to={path.currencyReport} className="dropdown-item">
                      {t("Currency Report")}
                    </Link>
                    <Link to={path.branchReport} className="dropdown-item">
                      {t("Branch Report")}
                    </Link>
                    <Link to={path.transactionReport} className="dropdown-item">
                      {t("Transaction Report")}
                    </Link>
                    <Link to={path.stockSoldReport} className="dropdown-item">
                      {t("StockSold Report")}
                    </Link>
                    <Link to={path.profitLossReport} className="dropdown-item">
                      {t("Profit And Loss Report")}
                    </Link>
                  </div>
                </li>
                {/* )} */}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Navbar))
);
