import React, { useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import ReactSelect from "react-select";
import {
  deleteApiData,
  getApiData,
  postApiData,
} from "../../helpers/axiosHelper";
import SelectStyle from "../../common/data/SelectStyle";
import TableContainer from "../../components/Common/TableContainer";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import Loader from "../../components/Common/Loader";
import withRouter from "../../components/Common/withRouter";
import {
  mapStatus,
  statusOptions,
  TransactionStatus,
} from "../../common/data/StatusLabels";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import { ToastContainer, toast } from "react-toastify";
import {
  isWithinAWeek,
  toastError,
  toastSucess,
  useBranch,
  useCompany,
  useCurrency,
  useCustomer,
} from "../../common/data/commonfunctions";
import { download, edit, trash, view } from "../../common/data/icons";
import ViewLatestTransactionModel from "../Dashboard_Admin/ViewTransactionDetailsDashboard";
import ReceiptPDF from "./Receipt";
import { mapTransactionData } from "../../common/data/transactionType";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import { helpDocUrl } from "../HelpDocs/HelpDocUrl";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import { FaFilter } from "react-icons/fa";
import FilterDisplay from "../Reports/FilterDisplay";

const TransactionHistory = (props) => {
  document.title = "Transaction History | Crescent Exchange";
  const [modal1, setModal1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [modal_filter, setmodal_filter] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const { branchId } = useLatestTransactions();
  const [totalPages, setTotalPages] = useState(null);
  const [totalRecord, setTotalRecords] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [columnName, setColumnName] = useState();
  const [error, setError] = useState();
  const { currency, getCurrency } = useCurrency();
  const { branch, fetchBranch } = useBranch();
  const { delayGetCustomer, customerList } = useCustomer();
  const [clearFiltersClicked, setClearFiltersClicked] = useState(false);
  const toggleViewModal = () => setModal1(!modal1);
  const [isCustomer, setIsCustomer] = useState(true);
  const localStorageKey = "transactionHistoryActiveTab";
  const [activeTab, setActiveTab] = useState(() => {
    return parseInt(localStorage.getItem(localStorageKey)) || 1;
  });
  const [showModal, setShowModal] = useState(false);
  const [transactionID, setTransactionID] = useState(null);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const isSystemAdmin = authUser?.role === "System Admin";
  const { companyOptions, getAllCompany } = useCompany();

  const handleShow = (data, download) => {
    setTransactionID(data);
    setTriggerDownload(download);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setTriggerDownload(false);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      localStorage.setItem(localStorageKey, tab);
    }
  };

  const [state, setState] = useState({
    customerName: "",
    agentOptions: [],
    agentUID: "",
    agentCompanyId: "",
    branchName: null,
    currency: null,
    transactionDate: null,
    status: null,
    submitting: false,
    currentPage: 1,
    pageSize: 10,
  });

  const navTabs = [
    { label: "Customer", value: 1, bool: true },
    { label: "Agent", value: 2, bool: false },
  ];

  const clearFilters = () => {
    const showClear =
      state.customerName !== "" ||
      state.agentUID !== "" ||
      state.currency !== null ||
      state.status !== null ||
      filterValue !== null ||
      filterType !== "" ||
      state.branchName !== null ||
      state.transactionDate !== null;
    return (
      <>
        {showClear && (
          <Button
            type="button"
            color="danger"
            onClick={resetFormValues}
            aria-label="clear"
          >
            Clear Filters
          </Button>
        )}
      </>
    );
  };

  const resetTransaction = () => {
    const showResetToDefault =
      validation.values.pagecount !== 10 ||
      validation.values.pagenumber !== 1 ||
      state.customerName !== "" ||
      state.agentUID !== "" ||
      state.companyId !== "" ||
      state.currency !== null ||
      state.status !== null ||
      state.branchName !== null ||
      state.transactionDate !== null;

    return (
      <>
        {showResetToDefault && (
          <Button
            type="button"
            color="danger"
            onClick={resetToDefault}
            aria-label="reset"
          >
            Reset
          </Button>
        )}
      </>
    );
  };

  function tog_addFilters() {
    setmodal_filter((prevState) => !prevState);
  }

  const resetToDefault = () => {
    setClearFiltersClicked(true);
    resetFormValues();
    validation.handleSubmit();
  };

  const resetFormValues = () => {
    validation.resetForm();
    setSelectedOption(null);
    //setSelectedPageCountOption(null);
    setColumnName(null);
    setFilterType("");
    setState({
      customerName: "",
      branchName: null,
      currency: null,
      agentUID: "",
      companyId: "",
      transactionDate: null,
      status: null,
      submitting: false,
      currentPage: 1,
      pageSize: 10,
    });
    setFilterValue(null);
    //validation.handleSubmit()
  };

  const columnList = [
    {
      label: `${activeTab === 1 ? "Customer Name" : "Agent Name"}`,
      value: `${activeTab === 1 ? "name" : "name"}`,
    },

    // { label: "Branch", value: "branchName" },
    { label: "Currency", value: "fromCurrType" },
    //{ label: "Transaction Date", value: "transactionDate" },
    { label: "Status", value: "status" },
  ];

  const getTransactionHistory = async (activeTab) => {
    setIsLoading(true);
    const response = await postApiData(
      `api/Transaction/GetTransactionBy${
        activeTab === 1 ? "User" : activeTab === 2 ? "Agent" : null
      }Pagination?branchId=${branchId}`,
      JSON.stringify({ pagenumber: 1, pagecount: 10 })
    );
    if (response?.success) {
      if (response?.model?.length === 0) {
        setTransactionHistoryData([]);
        setTotalPages(response?.totalPage);
        setTotalRecords(response?.totalRecord);
      } else {
        setTransactionHistoryData(mapTransactionData(response?.model));
        setTotalPages(response?.totalPage);
        setTotalRecords(response?.totalRecord);
      }
    } else {
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
  };

  const ViewTransactionDetails = (cellProps) => {
    const { transactionId } = cellProps;
    setTransactionID(transactionId);
    toggleViewModal();
  };

  useEffect(() => {
    if (branchId !== null && branchId !== undefined) {
      getTransactionHistory(activeTab);
    }
  }, [branchId]);

  useEffect(() => {
    // fetchBranch();
    getCurrency();
  }, []);

  const DeleteTransaction = async (transactionId) => {
    const result = await ConfirmationSwal(
      "Confirmation Required",
      "Confirm",
      "Cancel",
      "This action will overwrite the moving average and profit loss calculations, Please confirm to proceed"
    );
    if (result.isConfirmed) {
      setIsLoading(true);
      const response = await deleteApiData(
        `api/Transaction/DeleteTransaction?TransactionId=${transactionId}`
      );
      setIsLoading(false);
      if (response?.success) {
        toastSucess(response?.message);
        getTransactionHistory(activeTab);
      } else if (!response.success) {
        toastError(response?.message);
      }
    }
  };

  const getAgentsByCompany = async (companyId) => {
    const response = await getApiData(
      `api/AgentUser/GetAgentsViewByCompany?companyId=${companyId}`
    );

    if (response?.data?.length === 0) {
      toast.error("No Agents Found for the Selected Company", {
        position: "top-right",
        autoClose: 2000,
        closeButton: false,
      });
      setState((prevState) => ({ ...prevState, agentOptions: [] }));
      return;
    }
    console.log(response);

    const mappedResponse = response?.data?.map((e) => ({
      label: `${e.firstName} ${e.middleName !== null ? e.middleName : ""} ${
        e.lastName
      }`,
      value: e.uid,
      userStatus: e.userStatus,
      companyId: e.agentCompanyId,
    }));

    setState((prevState) => ({ ...prevState, agentOptions: mappedResponse }));
  };

  useEffect(() => {
    getAllCompany();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "transactionId",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      ...(isCustomer
        ? []
        : [
            {
              Header: "Company Name",
              accessor: "name",
              disableFilters: true,
              filterable: false,
              Cell: (cellProps) => {
                return cellProps.value ? cellProps.value : "";
              },
            },
          ]),

      {
        Header: isCustomer ? "Name" : "Name",
        accessor: "Name",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "On the Counter";
        },
      },
      {
        Header: "Currency ",
        accessor: "currencyType",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          const { currencyType, transactionType } = cellProps.row.original;
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Badge
                className={
                  "font-size-11 ms-1 me-1 badge-soft-" + transactionType?.color
                }
              >
                {transactionType?.label}
              </Badge>
              <span>{currencyType}</span>
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "fromCurrValue",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Rate",
        accessor: "exchangeRate",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Converted Amount",
        accessor: "toCurrValue",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Booked Date",
        accessor: "transactionDate",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Pickup",
        accessor: "pickup",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "Not Mentioned";
        },
      },
      {
        Header: "Moving Average",
        accessor: "movingAverage",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "0";
        },
      },
      {
        Header: "Status",
        accessor: "statusText",
        disableFilters: true,
        filterable: false,
        show: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                cellProps.row.original.statusText.color
              }
            >
              {cellProps.row.original.statusText.label}
            </Badge>
          );
        },
      },

      {
        Header: "View Details",
        disableFilters: true,
        Cell: (cellProps) => {
          const isDownloadable =
            cellProps.row.original.statusText === mapStatus(13);

          const { transactionId, createdAt, status } = cellProps.row.original;

          const viewCondition =
            status === TransactionStatus.CashCollected ||
            status === TransactionStatus.Declined ||
            status === TransactionStatus.UserDeclined ||
            status === TransactionStatus.UserCancelled;
          return (
            <div className="d-flex align-items-center">
              <Button
                type="button"
                color={viewCondition ? "primary" : "success"}
                title={viewCondition ? "View" : "Edit"}
                className="btn-sm btn-rounded me-1"
                onClick={() => {
                  ViewTransactionDetails(cellProps.row.original);
                }}
                aria-label="view"
              >
                {viewCondition ? view() : edit()}
              </Button>
              {isSystemAdmin && isWithinAWeek(createdAt) && (
                <Button
                  type="button"
                  color="danger"
                  title="Delete"
                  className="btn-sm btn-rounded me-1"
                  onClick={() => {
                    setIsLoading(true);
                    DeleteTransaction(transactionId);
                    setIsLoading(false);
                  }}
                  aria-label="deletetransaction"
                >
                  {trash()}
                </Button>
              )}

              {isDownloadable && (
                <Button
                  type="button"
                  color="success"
                  title="View Receipt"
                  className="btn-sm btn-rounded me-1"
                  onClick={() => handleShow(transactionId, true)}
                >
                  {download()}
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    [isCustomer]
  );

  const handleChange = (pageSize, currentPage) => {
    setState((prevState) => ({
      ...prevState,
      currentPage: currentPage,
      pageSize: pageSize,
    }));
    validation.setValues((values) => ({
      ...values,
      pagecount: pageSize,
      pagenumber: currentPage,
    }));
    validation.handleSubmit();
  };

  const agentName = state.agentOptions?.find(
    (agent) => agent.value === state.agentUID
  )?.label;

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      pagenumber: state.currentPage,
      pagecount: state.pageSize,
    },

    onSubmit: async (values) => {
      setState((prevState) => ({ ...prevState, submitting: true }));
      if (modal_filter) {
        values.pagenumber = 1;
        values.pagecount = 10;
        setState((prevState) => ({
          ...prevState,
          currentPage: 1,
          pageSize: 10,
        }));
      }
      let combinedValues = { ...values };
      if (columnName) {
        if (filterValue === null) {
          setError("Please Enter the Value");
          return;
        } else if (columnName && filterType === "Customer Name") {
          combinedValues = {
            ...combinedValues,
            filterColumns: [
              {
                columnName: columnName,
                filterValue:
                  state.customerName !== "" && state.customerName?.customerName,
              },
            ],
          };
        } else {
          const agentName = state.agentOptions?.find(
            (agent) => agent.value === state.agentUID
          )?.label;
          console.log(agentName);

          combinedValues = {
            ...combinedValues,
            filterColumns: [
              {
                columnName: columnName,
                filterValue:
                  agentName ||
                  // (state.agentUID !== null && `${state.agentUID}`) ||
                  (state.branchName !== null && state.branchName?.label) ||
                  (state.currency !== null && state.currency?.label) ||
                  (state.status !== null && `${state.status?.value}`) ||
                  (state.transactionDate !== null &&
                    `${state.transactionDate}`),
              },
            ],
          };
        }
      }
      const response = await postApiData(
        `api/Transaction/GetTransactionBy${
          activeTab === 1 ? "User" : activeTab === 2 ? "Agent" : null
        }Pagination?branchId=${branchId}`,
        JSON.stringify(combinedValues)
      );
      if (response?.success) {
        setTransactionHistoryData(mapTransactionData(response?.model));
        setTotalPages(response?.totalPage);
        setTotalRecords(response?.totalRecord);
        if (modal_filter) {
          tog_addFilters();
        }
        setState((prevState) => ({ ...prevState, submitting: false }));
      } else if (!response?.success) {
        toastError(response?.message);
        setState((prevState) => ({ ...prevState, submitting: false }));
      }
      setClearFiltersClicked(false);
    },
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      customerName: "",
      agentUID: "",
      branchName: null,
      currency: null,
      transactionDate: null,
      status: null,
    }));
  }, [filterType]);

  useEffect(() => {
    setSelectedOption(null);
    setFilterType("");
    setFilterValue("");
    setState((prevState) => ({
      ...prevState,
      customerName: "",
      currency: null,
      status: null,
      companyId: null,
      agentUID: null,
      agentOptions: [],
    }));
  }, [activeTab]);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      {showModal && (
        <ReceiptPDF
          show={showModal}
          handleClose={handleClose}
          triggerDownload={triggerDownload}
          transactionID={transactionID}
        />
      )}
      {modal1 && (
        <ViewLatestTransactionModel
          isOpen={modal1}
          toggle={toggleViewModal}
          getTransaction={() => getTransactionHistory(activeTab)}
          getAgentTransaction={() => getTransactionHistory(activeTab)}
          activeTab={activeTab}
          transactionId={transactionID}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between gap-1 flex-wrap">
                <div className="d-flex mb-4">
                  <div className="mb-4 h4 card-title">Transaction History</div>
                  {HelpDocButton(helpDocUrl.transactionHistory)}
                </div>
                <div className="mb-2 d-flex justify-content-start gap-1 flex-wrap">
                  <Button
                    type="button"
                    onClick={tog_addFilters}
                    color="primary"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    Add Filters
                  </Button>
                  {resetTransaction()}
                </div>
              </div>

              <Modal
                size="md"
                isOpen={modal_filter}
                toggle={() => {
                  tog_addFilters();
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="mySmallModalLabel">
                    Select Filters
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_filter(false);
                      validation.resetForm();
                      setFilterType("");
                      setError(null);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    {/* <Col lg="12">
                  <div className="mb-3">
                    <Label for="pagenumber">Page Number</Label>
                    <RequiredAsterisk/>
                    <Input
                      type="text"
                      className="form-control"
                      id="pagenumber"
                      placeholder="Enter Page Number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.pagenumber}
                      invalid={
                        validation.touched.pagenumber && validation.errors.pagenumber ? true : false 
                      }
                    />
                    {validation.touched.pagenumber && validation.errors.pagenumber ? (
                        <FormFeedback type="invalid">{validation.errors.pagenumber}</FormFeedback>
                    ) : null}
                  </div>
                  </Col>
                  <Col lg="12"> 
                    <div className="mb-2">
                      <Label for="pagecount">Page Count</Label>
                      <RequiredAsterisk/>
                      <ReactSelect
                        options={[10, 20, 30, 40, 50, 100].map(count => ({ value: count, label: count }))}
                        value={selectedPageCountOption}
                        styles={SelectStyle}
                        onChange={(selected) => {
                          setSelectedPageCountOption(selected);
                          validation.setFieldValue('pagecount', selected.value);
                        }}
                        placeholder="Select Page Count"
                        invalid={validation.errors.pagecount ? true : false}
                      />
                      {
                        validation.errors.pagecount && validation.touched.pagecount ? (
                          <span className="text-danger" style={{fontSize: "80%"}}>{validation.errors.pagecount}</span>
                        ) : null
                      }
                    </div>
                  </Col>*/}
                    <Col lg="12">
                      <div className="mb-2">
                        <Col lg="12">
                          <Label for="filterBy">Filter By</Label>
                        </Col>
                        <ReactSelect
                          options={columnList.map((column) => ({
                            value: column.value,
                            label: column.label,
                          }))}
                          value={selectedOption}
                          styles={SelectStyle}
                          onChange={(selected) => {
                            setSelectedOption(selected);
                            setFilterType(selected.label);
                            setColumnName(selected.value);
                          }}
                          placeholder="Select Filter"
                        />
                      </div>
                    </Col>
                    {
                      // filterType === 'Transaction Date' ?
                      // (
                      //   <Col lg="12">
                      //     <div className="mb-3">
                      //       <Label for="filterValue">Select {filterType}</Label>
                      //       <Input
                      //         type="date"
                      //         className="form-control"
                      //         id="filterValue"
                      //         placeholder={`Enter ${filterType}`}
                      //         onChange={(e)=> {
                      //           setState((prevState) => ({...prevState, transactionDate: e.target.value}))
                      //           setFilterValue("e")
                      //         }}
                      //         value={state.transactionDate}
                      //       />
                      //     </div>
                      //   </Col>
                      // ) :
                      filterType === "Customer Name" ? (
                        <Col lg="12">
                          <div className="mb-3">
                            <Label for="filterValue">Enter {filterType}</Label>
                            <RequiredAsterisk />
                            <ReactSelect
                              placeholder="Enter atleast 3 Letters to get Customer Name..."
                              aria-label="fromCustomerId"
                              id="fromCustomerId"
                              value={state.customerName}
                              styles={SelectStyle}
                              onChange={(selectedOption) => {
                                setState((prev) => ({
                                  ...prev,
                                  customerName: selectedOption,
                                }));
                                setFilterValue("e");
                              }}
                              options={customerList}
                              onInputChange={(inputValue, { action }) => {
                                if (
                                  action === "input-change" &&
                                  inputValue.length >= 3
                                ) {
                                  delayGetCustomer(inputValue);
                                }
                              }}
                              menuPortalTarget={document.getElementById(
                                "react-select-portal"
                              )}
                              menuPlacement="auto"
                            />
                            {/* <Input
                              type="text"
                              className="form-control"
                              id="filterValue"
                              placeholder={`Enter ${filterType}`}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                setState((prevState) => ({
                                  ...prevState,
                                  customerName: inputValue,
                                }));
                                setError(
                                  inputValue.trim() === ""
                                    ? "Please Enter the Value"
                                    : null
                                );
                                setFilterValue("e");
                              }}
                              value={state.customerName}
                              invalid={error ? true : false}
                            /> */}
                          </div>
                        </Col>
                      ) : //) : // ) :
                      // filterType === 'Agent Branch' ? (
                      //   <Col lg="12">
                      //     <div className="mb-3">
                      //       <Label for="filterValue">{`Select ${filterType}`}</Label>
                      //       <RequiredAsterisk />
                      //       <ReactSelect
                      //         options={branch}
                      //         value={state.branchName}
                      //         styles={SelectStyle}
                      //         onChange={(e) => {
                      //           setState((prevState) => ({...prevState, branchName: e}))
                      //           setFilterValue("e")
                      //         }}
                      //         placeholder="Select Branch..."
                      //         isClearable
                      //       />
                      //     </div>
                      //   </Col>
                      // filterType === "Company" ? (

                      filterType === "Currency" ? (
                        <Col lg="12">
                          <div className="mb-3">
                            <Label for="filterValue">{`Select ${filterType}`}</Label>
                            <RequiredAsterisk />
                            <ReactSelect
                              options={currency}
                              value={state.currency}
                              styles={SelectStyle}
                              onChange={(e) => {
                                setState((prevState) => ({
                                  ...prevState,
                                  currency: e,
                                }));
                                setFilterValue("e");
                              }}
                              placeholder="Select Currency..."
                            />
                          </div>
                        </Col>
                      ) : filterType === "Status" ? (
                        <Col lg="12">
                          <div className="mb-3">
                            <Label for="filterValue">{`Select ${filterType}`}</Label>
                            <RequiredAsterisk />
                            <ReactSelect
                              options={statusOptions}
                              value={state.status}
                              styles={SelectStyle}
                              onChange={(e) => {
                                setState((prevState) => ({
                                  ...prevState,
                                  status: e,
                                }));
                                setFilterValue("e");
                              }}
                              placeholder="Select Status..."
                            />
                          </div>
                        </Col>
                      ) : null
                    }
                    {filterType === "Agent Name" && (
                      <>
                        <Col lg="12">
                          <div className="mb-3">
                            <Label for="filterValue">Select Company</Label>
                            <ReactSelect
                              options={companyOptions.map((company) => ({
                                value: company.value,
                                label: company.label,
                              }))}
                              value={
                                companyOptions.find(
                                  (b) => b.value === state.companyId
                                ) || null
                              }
                              onChange={(selected) => {
                                setState((prevState) => ({
                                  ...prevState,
                                  companyId: selected ? selected.value : null,
                                }));
                                if (selected) {
                                  getAgentsByCompany(selected.value);
                                } else {
                                  setState((prevState) => ({
                                    ...prevState,
                                    agentOptions: [],
                                  }));
                                }
                              }}
                              styles={SelectStyle}
                              placeholder="Select Company..."
                            />
                          </div>
                        </Col>
                        {state.companyId && (
                          <Col md={12}>
                            <Label htmlFor="agentUID">Select Agent</Label>
                            <ReactSelect
                              id="agentUID"
                              aria-label="agentUID"
                              placeholder="Select Agent..."
                              styles={SelectStyle}
                              value={state.agentOptions?.find(
                                (agent) => agent.value === state.agentUID
                              )}
                              onChange={(e) => {
                                setState((prevState) => ({
                                  ...prevState,
                                  agentUID: e ? e.value : "",
                                }));
                                setFilterValue("e");
                              }}
                              options={state.agentOptions}
                            />
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={validation.handleSubmit}
                    disabled={state.submitting}
                  >
                    Apply Filters
                  </button>
                  {resetTransaction()}
                </div>
              </Modal>

              <Nav
                fill
                tabs
                style={{
                  marginBottom: "30px",
                  width: "50%",
                }}
              >
                {navTabs.map((e) => (
                  <NavItem key={e.value}>
                    <NavLink
                      className={activeTab === e.value ? "active" : ""}
                      style={
                        activeTab === e.value
                          ? { backgroundColor: "#556ee6", color: "white" }
                          : { borderStyle: "solid", borderColor: "gray" }
                      }
                      onClick={() => {
                        toggle(e.value);
                        setIsCustomer(e.bool);
                        getTransactionHistory(e.value);
                      }}
                    >
                      {e.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <div>
                {filterType && filterValue ? (
                  <div className="mb-3">
                    <h5 className="mt-4 fw-bold">
                      <FaFilter className="me-2" size={18} color="#007bff" />
                      Filter By:
                    </h5>
                    {filterType} : &nbsp;
                    {filterType === "Currency"
                      ? state.currency?.label || state.currency
                      : filterType === "Status"
                      ? state.status?.label || state.status
                      : filterType === "Customer Name"
                      ? state.customerName?.label || state.customerName
                      : filterType === "Agent Name"
                      ? agentName
                      : filterValue?.label || filterValue}
                  </div>
                ) : (
                  <h5 className="mt-4">
                    <FaFilter className="me-2" size={18} color="gray" />
                    No Filter Applied
                  </h5>
                )}
                <TableContainer
                  columns={columns}
                  data={transactionHistoryData}
                  customPageSize={10}
                  isPageSelect={false}
                  onPageChange={handleChange}
                  totalPage={totalPages}
                  totalRecord={totalRecord}
                  currentPage={state.currentPage}
                  goToPage={true}
                  clearFilters={clearFiltersClicked}
                  changePageOnResult={modal_filter}
                  refresh={true}
                  onRefreshClick={() => getTransactionHistory(activeTab)}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
      {isLoading && <Loader />}
    </React.Fragment>
  );
};

export default React.memo(withRouter(TransactionHistory));
