import React, { useEffect, useMemo, useState } from "react";
import { Button, Badge, CardBody, Card } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import ViewDetailsToggle from "./ViewDetailsToggle";
import {
  deleteApiData,
  getApiData,
  putApiData,
} from "../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import AddCustomer from "../Dashboard_Admin/ManageUser/Components/AddCustomer";
import Loader from "../../components/Common/Loader";
import DeleteModal from "../../components/Common/DeleteModal";
import {
  activate,
  approve,
  deactivate,
  edit,
  magnifyingGlass,
  view,
} from "../../common/data/icons";
import config from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import withReactContent from "sweetalert2-react-content";
import RiskSelectionModal from "../../components/Common/RiskSelectionModal";
import { useLocation } from "react-router-dom";
import { path } from "../../routes/path";
import { useCurrentPage } from "../../common/data/CustomPagination";
import RefreshButton from "../../common/data/RefreshButton";
import { amlSearch } from "../../common/data/amlSearch";

const ManageUserTab = ({ load = false }) => {
  document.title = "Manage User | Crescent Exchange";
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [customerID, setCustomerID] = useState("");
  const [isLoading, setIsLoading] = useState(load);
  const [deleteCustomerId, setDeleteCustomerId] = useState(null);
  const [uId, setUId] = useState(null);
  const [deactivateTitle, setDeactivateTitle] = useState(false);
  const [status, setStatus] = useState();
  const [showRiskModal, setShowRiskModal] = useState(false);
  const [userState, setUserState] = useState({
    riskId: "",
    riskError: "",
  });
  const { pathname } = useLocation();

  const onClickDelete = () => {
    setDeleteModal(true);
    setDeactivateTitle(true);
  };

  const toggleViewDetails = () => setViewModal(!viewModal);
  const toggleAddUser = () => setAddModal(!addModal);

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const isSystemBranchAdmin = authUser?.role === "System Branch Admin";
  const isSystemAdmin = authUser?.role === "System Admin";

  const {
    saveCurrentPage,
    restoreCurrentPage,
    onPageChange,
    pageSize,
    currentPage,
  } = useCurrentPage();

  const getCustomer = async () => {
    setIsLoading(true);
    const response = await getApiData(
      pathname === path.adminDashboard && isSystemAdmin
        ? `api/Customer/GetApprovalCustomers`
        : `api/User/GetUserByCustomer`
    );
    setIsLoading(false);

    const UserStatus = {
      1: "Verification",
      2: "Profile Pending",
      3: "Approval Pending",
      4: "Approved",
      5: "Deactivated",
    };

    const mappedResponse = response?.data?.map((item, index) => ({
      index: index + 1,
      Uid: item.uid,
      CustomerId: item.customerId,
      CustomerName: item.customerName,
      Email: item.email,
      userStatus: item.userStatus,
      UserStatus: UserStatus[item.userStatus],
      Active: item.active,
      dob: item.dob?.split("T")[0],
      nationality: item.nationality,
      CustomerProfile:
        item.residentialAddressId === null ? "Incomplete" : "Completed",
      CustomerFinancial:
        item.customerFinancialId === null ? "Incomplete" : "Completed",
      CustomerDocument: item.customerDocument?.split(", ")[0],
      ProfileImage:
        item.isProfileImageRecognised === null ||
        item.isProfileImageRecognised === 0
          ? "Not Verified"
          : "Verified",
    }));

    setCustomerData(mappedResponse || []);
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const viewCustomerDetails = (cellProps) => {
    const { CustomerId, Uid, userStatus } = cellProps;

    setCustomerID(CustomerId);
    setUId(Uid);
    setStatus(userStatus);
    toggleViewDetails();
  };

  const softDeleteData = async () => {
    const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

    if (result.isConfirmed) {
      const response = await deleteApiData(
        `api/Customer/DeactivateCustomer?customerId=${deleteCustomerId}`
      );
      if (response?.success === true) {
        toast.success("Customer Deactivated Successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        setDeleteModal(!deleteModal);
        getCustomer();
      } else {
        toast.error("Failed to deactivate customer", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };

  const RiskSwal = withReactContent(Swal);
  const toggleRiskModal = () => setShowRiskModal(!showRiskModal);
  const handleRiskChange = (e) => {
    setUserState((prevState) => ({
      ...prevState,
      riskId: e.target.value,
      riskError: !e.target.value,
    }));
  };

  const approveCustomerByUId = async (UId, CustomerId) => {
    setCustomerID(CustomerId);
    setUId(UId);
    toggleRiskModal();
  };

  const onConfirmApproval = async () => {
    if (!userState.riskId) {
      setUserState((prevState) => ({ ...prevState, riskError: true }));
      return;
    }
    RiskSwal.fire({
      title: "Are you sure?",
      icon: "warning",
      width: "300px",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#3085d6",
      cancelButtonText: "cancel",
    }).then(async (confirmResult) => {
      if (confirmResult.isConfirmed) {
        const response = await putApiData(
          `api/User/ApproveCustomerByStatus?UID=${uId}&forceApprove=false&CustomerId=${customerID}&RiskId=${userState.riskId}`
        );
        if (response?.success === true) {
          toast.success("Customer Approved", {
            position: "top-right",
            autoClose: 2000,
          });
          getCustomer();
        } else {
          toast.error(response?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        toggleRiskModal();
      }
    });
  };

  const activateCustomer = async (id) => {
    const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

    if (result.isConfirmed) {
      try {
        const response = await putApiData(
          `api/Customer/ActivateCustomer?customerId=${id}&userStatus=${1}`
        );
        if (response?.success === true) {
          toast.success("Customer Activated Successfully", {
            position: "top-right",
            autoClose: 2000,
            closeButton: false,
          });
          getCustomer();
        } else if (response?.success === false) {
          toast.error(`${response?.message}`, {
            position: "top-right",
            autoClose: 2000,
            closeButton: false,
          });
        }
      } catch (error) {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 2000,
          closeButton: false,
        });
      }
    }
  };

  const renderView = (cellProps) => {
    return (
      <Button
        type="button"
        color="primary"
        className="btn-sm btn-rounded me-2"
        title="View"
        onClick={(e) => {
          setEditMode(true);
          viewCustomerDetails(cellProps.row.original);
        }}
        aria-label="view"
      >
        {view()}
      </Button>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Customer ID",
        accessor: "CustomerId",
        filterable: false,
        disableFilters: true,
        width: 200,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Email",
        accessor: "Email",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Status",
        accessor: "UserStatus",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                (cellProps.value === "Approved"
                  ? "success"
                  : "danger" && cellProps.value === "Verification"
                  ? "warning"
                  : "danger" && cellProps.value === "Profile Pending"
                  ? "primary"
                  : "danger" && cellProps.value === "Approval Pending"
                  ? "danger"
                  : "danger")
              }
            >
              {cellProps.value}
            </Badge>
          );
        },
      },
      {
        Header: "Address",
        accessor: "CustomerProfile",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                (cellProps.value === "Completed" ? "success" : "danger")
              }
            >
              {cellProps.value}
            </Badge>
          );
        },
      },
      {
        Header: "Financial",
        accessor: "CustomerFinancial",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                (cellProps.value === "Completed" ? "success" : "danger")
              }
            >
              {cellProps.value}
            </Badge>
          );
        },
      },
      {
        Header: "Profile Image",
        accessor: "ProfileImage",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                (cellProps.value === "Verified" ? "success" : "danger")
              }
            >
              {cellProps.value}
            </Badge>
          );
        },
      },
      {
        Header: "Documents",
        accessor: "CustomerDocument",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? (
            cellProps.value
          ) : (
            <Badge className={"font-size-11 badge-soft-danger"}>
              No Docs Found
            </Badge>
          );
        },
      },
      {
        Header: "Active",
        accessor: "Active",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                (cellProps.row.original.Active === 1 ? "success" : "danger")
              }
            >
              {cellProps.row.original.Active === 1 ? "Active" : "Deactivated"}
            </Badge>
          );
        },
      },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "actions",
        Cell: (cellProps) => {
          const {
            dob,
            CustomerName,
            nationality,
            Active,
            CustomerId,
            UserStatus,
            Uid,
          } = cellProps.row.original;
          return (
            <>
              {isSystemAdmin ? (
                Active === 0 || Active === null ? (
                  <Button
                    type="button"
                    color="success"
                    title="Activate"
                    className="btn-sm btn-rounded me-2"
                    onClick={() => {
                      activateCustomer(CustomerId);
                    }}
                  >
                    {activate()}
                  </Button>
                ) : (
                  <>
                    {amlSearch(dob, CustomerName, nationality)}
                    {renderView(cellProps)}
                    {Active === 1 && (
                      <Button
                        type="button"
                        color="success"
                        className="btn-sm btn-rounded me-2"
                        title="Edit"
                        onClick={() => {
                          setEditMode(false);
                          viewCustomerDetails(cellProps.row.original);
                        }}
                      >
                        {edit()}
                      </Button>
                    )}
                    <Button
                      type="button"
                      color="danger"
                      className="btn-sm btn-rounded me-2"
                      title="Deactivate"
                      onClick={() => {
                        onClickDelete();
                        setDeleteCustomerId(CustomerId);
                      }}
                    >
                      {deactivate()}
                    </Button>

                    {UserStatus === "Approval Pending" && (
                      <Button
                        type="button"
                        color="success"
                        className="btn-sm btn-rounded me-2"
                        title="Approve"
                        onClick={(e) => {
                          approveCustomerByUId(
                            Uid,
                            CustomerId,
                            userState.riskId
                          );
                        }}
                      >
                        {approve()}
                      </Button>
                    )}
                  </>
                )
              ) : isSystemBranchAdmin ? (
                renderView(cellProps)
              ) : null}
            </>
          );
        },
      },
    ],
    [isSystemAdmin, isSystemBranchAdmin]
  );

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {viewModal && (
            <ViewDetailsToggle
              isOpen={viewModal}
              toggle={toggleViewDetails}
              customerID={customerID}
              editMode={editMode}
              uId={uId}
              status={status}
              getCustomer={getCustomer}
              toggleViewDetails={toggleViewDetails}
            />
          )}
          {deleteModal && (
            <DeleteModal
              show={deleteModal}
              onDeleteClick={softDeleteData}
              onCloseClick={() => setDeleteModal(false)}
              deactivateTitle={deactivateTitle}
            />
          )}
          {showRiskModal && (
            <RiskSelectionModal
              show={showRiskModal}
              onCloseClick={toggleRiskModal}
              handleRiskChange={handleRiskChange}
              userState={userState}
              onConfirmApproval={onConfirmApproval}
            />
          )}
          {addModal && (
            <AddCustomer
              isOpen={addModal}
              toggle={toggleAddUser}
              getCustomer={getCustomer}
            />
          )}

          <Card>
            <CardBody>
              {pathname === path.adminDashboard && (
                <div className="mb-4 h4 card-title">
                  Approval Pending Customers
                </div>
              )}
              {pathname === path.users && (
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="primary"
                    onClick={toggleAddUser}
                    className="btn mb-2 me-2"
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create New Customer
                  </Button>
                </div>
              )}
              <TableContainer
                columns={columns}
                data={customerData}
                isGlobalFilter={true}
                isAddOptions={false}
                // customPageSize={10}
                isPageSelect={false}
                customPageSize={pageSize}
                pageSize={pageSize}
                pageIndex={currentPage}
                onPageChange={onPageChange}
                refresh={true}
                onRefreshClick={getCustomer}
              />
            </CardBody>
          </Card>
        </div>
      )}
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default ManageUserTab;
