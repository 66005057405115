import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { path } from "../../routes/path";
import { publicRoutes } from "../../routes/allRoutes";
import { useBranch, useCurrencyList } from "./commonfunctions";

const authUser = JSON.parse(localStorage.getItem("authUser"));
const isSystemAdmin = authUser?.role === "System Admin";
const userBranchId = authUser?.branchId;
const sessionBranch = await JSON.parse(
  localStorage.getItem("selectedBranchId")
);

const initialBranchId = isSystemAdmin
  ? typeof window !== "undefined"
    ? sessionBranch
    : null
  : userBranchId;

const LatestTransactionsContext = createContext();

export const useLatestTransactions = () =>
  useContext(LatestTransactionsContext);

export const LatestTransactionsProvider = ({ children }) => {
  const [branchId, setBranchId] = useState(initialBranchId);
  const { fetchBranch } = useBranch();
  const [branches, setBranches] = useState([]);
  const { pathname } = useLocation();
  const fetchingRef = useRef(false);
  const { currencyList, getCurrencyList } = useCurrencyList();
  const [allCurrencies, setAllCurrencies] = useState(currencyList);

  const getBranches = async () => {
    if (fetchingRef.current) return;
    fetchingRef.current = true;
    try {
      const fetchedBranches = await fetchBranch();
      setBranches(fetchedBranches);
    } catch (error) {
      console.error("Failed to fetch branches:", error);
    } finally {
      fetchingRef.current = false;
    }
  };
  const verifyPath = () => {
    const publicPaths = publicRoutes.map((x) => x.path);
    return publicPaths.some((publicPath) => {
      if (publicPath.includes(":")) {
        const basePath = publicPath.split("/:")[0];
        return pathname.startsWith(basePath);
      }

      return pathname === publicPath;
    });
  };

  useEffect(() => {
    const isPublicRoute = verifyPath();

    if (!isPublicRoute && currencyList.length === 0) {
      getCurrencies();
    }
  }, [pathname]);

  useEffect(() => {
    if (branchId) {
      sessionStorage.setItem("selectedBranchId", branchId);
    }
  }, [branchId]);

  useEffect(() => {
    if (isSystemAdmin && branches?.length === 0 && pathname !== path.branch) {
      getBranches();
    }

    if (branches?.length === 0 && pathname === path.adminDashboard) {
      getBranches();
    }
  }, [branches.length, pathname]);

  const getCurrencies = async () => {
    const resp = await getCurrencyList();
    setAllCurrencies(resp);
  };

  return (
    <LatestTransactionsContext.Provider
      value={{
        branchId,
        setBranchId,
        branches,
        setBranches,
        allCurrencies,
        setAllCurrencies,
      }}
    >
      {children}
    </LatestTransactionsContext.Provider>
  );
};
