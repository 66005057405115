import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  Row,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap";
import { BranchAddress, BranchName } from "./BranchCol";
import TableContainer from "../../components/Common/TableContainer";
import withRouter from "../../components/Common/withRouter";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { getApiData, postApiData, putApiData } from "../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useBranch } from "../../common/data/commonfunctions";
import Loader from "../../components/Common/Loader";
import AddNewBranch from "./AddNewBranch";
import { activate, deactivate, edit, view } from "../../common/data/icons";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import { useCurrentPage } from "../../common/data/CustomPagination";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import { helpDocUrl } from "../HelpDocs/HelpDocUrl";

const Branch = (props) => {
  document.title = "Branch | Crescent Exchange";

  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const { fetchBranch, isLoading } = useBranch();
  const [modal_addBranch, setmodal_addBranch] = useState(false);
  const [viewBranchData, setViewBranchData] = useState([]);
  const [branchDetailsforTable, setBranchDetailsforTable] = useState([]);
  const [viewflag, setViewFlag] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [viewopData, setViewOpData] = useState([]);
  const [isMainBranch, setIsMainBranch] = useState(false);
  const [operationTimeData, setOperationTimeData] = useState([]);
  const selectedOperationTimes = [];
  const onDataChange = (data) => {
    setOperationTimeData(data);
  };
  const [submitting, setSubmitting] = useState(false);
  const { setBranches } = useLatestTransactions();
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const isSystemAdmin = authUser?.role === "System Admin";
  const {
    saveCurrentPage,
    restoreCurrentPage,
    onPageChange,
    pageSize,
    currentPage,
  } = useCurrentPage();

  function tog_addBranch() {
    setmodal_addBranch(!modal_addBranch);
    setactiveTab(1);
    if (resetForm) {
      createvalidation.resetForm();
      setViewBranchData([]);
      setIsMainBranch(false);
    }
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }
  function handleTabChange(e) {
    e.preventDefault();
    if (activeTab === 1) {
      //validate the branch details and toggle only if valid
      if (createvalidation.isValid) {
        toggleTab(activeTab + 1);
      } else {
        createvalidation.setTouched({
          branchName: true,
          street: true,
          city: true,
          state: true,
          country: true,
          postalCode: true,
        });
      }
    } else if (activeTab === 2) {
      setSubmitting(true);
      createvalidation.handleSubmit();
    }
  }

  function validateOPTime() {
    return operationTimeData
      .filter((td) => td.selected)
      .every((item) => {
        if (item.startTime > "00:00" && item.endTime > item.startTime) {
          selectedOperationTimes.push({
            day: item.day,
            start:
              item.startTime.length < 8
                ? item.startTime + ":00"
                : item.startTime,
            end: item.endTime.length < 8 ? item.endTime + ":00" : item.endTime,
          });
          return true;
        } else return false;
      });
  }

  const createvalidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      branchId: viewBranchData?.branch?.branchId || null,
      branchName: viewBranchData?.branch?.branchName || "",
      isMainBranch: viewBranchData?.branch?.isMainBranch || false,
      street: viewBranchData?.branch?.street || "",
      city: viewBranchData?.branch?.city || "",
      state: viewBranchData?.branch?.state || "",
      country: viewBranchData?.branch?.country || "",
      postalCode: viewBranchData?.branch?.postalCode || "",
      operationalTime: viewBranchData?.operationalTime || [],
    },
    validationSchema: Yup.object({
      branchName: Yup.string().required("Please Enter Branch Name"),
      street: Yup.string().required("Please Enter Your Street"),
      city: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "City should contain only letters")
        .required("Please Enter Your City"),
      state: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "State should contain only letters")
        .required("Please Enter Your State"),
      country: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Country should contain only letters")
        .required("Please Enter Your Country"),
      postalCode: Yup.string()
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .required("Please Enter Your Postal Code"),
    }),

    showErrors: function () {
      for (const [field, message] of Object.entries(this.errors)) {
        console.error(`${field}: ${message}`);
      }
    },

    onSubmit: async (values, { resetForm }) => {
      // check if the operation time data has atleast one selection, otherwise put a error toast
      if (operationTimeData.some((td) => td.selected)) {
        // create an array of the selected operating days to send to the API
        const validateOP = validateOPTime();
        if (validateOP) {
          values.isMainBranch = isMainBranch;
          values.operationalTime = selectedOperationTimes;
          try {
            //check if its in view mode
            if (!viewflag) {
              // Update if branchID is available
              if (values.branchId > 0 && viewBranchData.branch.branchId > 0) {
                //validate Tab1 with specific keys to look for any updates and update hasChanges
                const keysToCheck = [
                  "branchName",
                  "isMainBranch",
                  "street",
                  "city",
                  "state",
                  "country",
                  "postalCode",
                ];
                let hasChanges = false;
                let haveEqualSlots = false;
                for (const key of keysToCheck) {
                  if (values[key] !== createvalidation.initialValues[key]) {
                    hasChanges = true;
                    break; // Stop iterating if a change is found
                  }
                }
                //validate Tab2 with length to look for any additions/removal of days and update hasChanges
                if (
                  values.operationalTime.length !==
                  createvalidation.initialValues["operationalTime"].length
                ) {
                  hasChanges = true;
                }
                const combinedValues = {
                  ...values,
                  branchId: viewBranchData.branch.branchId,
                };
                //if Tab1 has no changes, validate Tab2 for changes
                if (!hasChanges) {
                  //compare the arrays for day, startTime and Endtime only
                  haveEqualSlots = arraysHaveEqualTimeSlots(
                    values.operationalTime,
                    createvalidation.initialValues.operationalTime
                  );
                }
                //update only if either BranchDetails or OperationalComp has changes
                if (hasChanges || !haveEqualSlots) {
                  const response = await putApiData(
                    "api/BranchMaster/UpdateBranch",
                    JSON.stringify(combinedValues)
                  );

                  tog_addBranch();
                  if (response?.success === true) {
                    toast.success("Branch Successfully Updated", {
                      position: "top-right",
                      autoClose: 2000,
                    });
                    setBranchDetailsforTable(await fetchBranch());
                    setSubmitting(false);
                  } else {
                    toast.error(
                      "Error while updating Branch id  " + values.branchId,
                      {
                        position: "top-right",
                        autoClose: 2000,
                      }
                    );
                  }
                } else {
                  toast.error("No changes to update", {
                    position: "top-right",
                    autoClose: 2000,
                  });
                  tog_addBranch();
                }
              }

              //create branch if branchID is not available
              else {
                const response = await postApiData(
                  "api/BranchMaster/CreateBranch",
                  JSON.stringify(values)
                );
                tog_addBranch();
                if (response?.success === true) {
                  toast.success("Branch Successfully Created", {
                    position: "top-right",
                    autoClose: 2000,
                  });
                  resetForm();
                  if (isSystemAdmin) {
                    const resp = await fetchBranch();
                    setBranchDetailsforTable(resp);
                    setBranches(resp);
                  }
                } else {
                  toast.error("Error while creating Branch", {
                    position: "top-right",
                    autoClose: 2000,
                  });
                }
              }
            }
          } catch (error) {
            toast.error("Error: " + error, {
              position: "top-right",
              autoClose: 3000,
            });
          } finally {
            setSubmitting(false);
          }
        } else {
          toast.error("Please select valid operating times", {
            position: "top-right",
            autoClose: 2000,
          });
          setSubmitting(false);
        }
      } else {
        toast.error("select atleast one operating day", {
          position: "top-right",
          autoClose: 1000,
        });
        setSubmitting(false);
      }
    },
  });

  function compareTimeSlots(slot1, slot2) {
    return (
      slot1.day === slot2.day &&
      slot1.start === slot2.startTime &&
      slot1.end === slot2.endTime
    );
  }
  function arraysHaveEqualTimeSlots(arr1, arr2) {
    for (const slot1 of arr1) {
      const hasMatch = arr2.some((slot2) => compareTimeSlots(slot1, slot2));
      if (!hasMatch) {
        return false;
      }
    }
  }

  //Code for operational data
  //Code to fetch data for view

  const viewBranchDetails = async (branchId) => {
    try {
      if (branchId != null) {
        const response = await getApiData(
          `api/BranchMaster/GetBranchView?branchId=${branchId}`
        );
        let branchData = response?.data;
        setIsMainBranch(branchData.branch.isMainBranch);
        setViewBranchData(branchData);
        setViewOpData(branchData.operationalTime);
        //if(isSystemAdmin){
        tog_addBranch();
        // }
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    createvalidation.setValues({
      branchId: viewBranchData?.branch?.branchId || null,
      branchName: viewBranchData?.branch?.branchName || "",
      street: viewBranchData?.branch?.street || "",
      city: viewBranchData?.branch?.city || "",
      state: viewBranchData?.branch?.state || "",
      country: viewBranchData?.branch?.country || "",
      postalCode: viewBranchData?.branch?.postalCode || "",
      operationalTime: viewBranchData?.operationalTime || [],
      isMainBranch: isMainBranch,
    });
    // }
  }, [viewBranchData]);

  //deleting a branch with branchID
  const softDeleteBranchData = async (branchId, isActive) => {
    let result;
    if (isActive) {
      result = await ConfirmationSwal(
        "Are you sure to activate?",
        "Yes",
        "Cancel"
      );
    } else {
      result = await ConfirmationSwal(
        "Are you sure you want to deactivate? All active ratesheets associated with this branch will also be deactivated",
        "Yes",
        "Cancel"
      );
    }

    if (result.isConfirmed) {
      try {
        if (branchId != null) {
          const response = await axios.delete(
            `api/BranchMaster/Delete?branchId=${branchId}&isActive=${isActive}`
          );
          if (response) {
            if (response?.data?.success === true) {
              toast.success(
                `Branch ${isActive ? "Activated" : "Deactivated"} Successfully`,
                {
                  position: "top-right",
                  autoClose: 2000,
                }
              );
              // tog_addBranch();
              //if(isSystemAdmin){
              const resp = await fetchBranch();
              setBranchDetailsforTable(resp);
              setBranches(resp);
              //}
            } else {
              toast.error("Error , Contact Admin", {
                position: "top-right",
                autoClose: 3000,
              });
            }
          }
        }
      } catch (error) {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    }
  };

  const fetch = async () => {
    const resp = await fetchBranch();
    setBranchDetailsforTable(resp);
    setBranches(resp);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Branch Name",
        accessor: "branchName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <BranchName {...cellProps} />
              {cellProps.row.original.isMainBranch && (
                <Badge color="success" className={"font-size-11 ms-2"}>
                  Main
                </Badge>
              )}
            </>
          );
        },
      },
      {
        Header: "Branch Address",
        accessor: "branchAddress",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <BranchAddress {...cellProps} />;
        },
      },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "actions",
        Cell: (cellProps) => {
          const active = cellProps.row.original.active;
          return (
            <>
              <Button
                type="button"
                color="primary"
                aria-label="view"
                className="btn-sm btn-rounded"
                style={{ marginRight: "5px", marginBottom: "5px" }}
                onClick={() => {
                  setViewFlag(true);
                  setIsEdit(true);
                  viewBranchDetails(cellProps.row.original.branchId);
                }}
              >
                {view()}
              </Button>
              {active === 1 && (
                <Button
                  type="button"
                  color="success"
                  className="btn-sm btn-rounded"
                  style={{ marginRight: "5px", marginBottom: "5px" }}
                  onClick={() => {
                    setViewFlag(false);
                    setIsEdit(true);
                    viewBranchDetails(cellProps.row.original.branchId);
                  }}
                >
                  {edit()}
                </Button>
              )}
              {isSystemAdmin && (
                <Button
                  type="button"
                  color={active === 1 ? "danger" : "success"}
                  className="btn-sm btn-rounded"
                  style={{ marginRight: "5px", marginBottom: "5px" }}
                  onClick={() => {
                    softDeleteBranchData(
                      cellProps.row.original.branchId,
                      active === 1 ? false : true
                    );
                  }}
                >
                  {active === 1 ? deactivate() : activate()}
                </Button>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    fetch();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ToastContainer closeButton={false} limit={1} />
          <Modal
            size="lg"
            isOpen={modal_addBranch}
            toggle={() => {
              tog_addBranch();
            }}
            name="Add Branch"
            //show={show}
          >
            <ModalHeader
              toggle={() => {
                tog_addBranch();
              }}
            >
              Branch Details
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col lg="12">
                  <AddNewBranch
                    activeTab={activeTab}
                    setactiveTab={setactiveTab}
                    createvalidation={createvalidation}
                    isMainBranch={isMainBranch}
                    viewflag={viewflag}
                    submitting={submitting}
                    handleTabChange={handleTabChange}
                    setIsMainBranch={setIsMainBranch}
                    viewopData={viewopData}
                    toggleTab={toggleTab}
                    setmodal_addBranch={setmodal_addBranch}
                    onDataChange={onDataChange}
                    passedSteps={passedSteps}
                    isEdit={isEdit}
                    branchData={viewBranchData}
                  />
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          <Card>
            <CardBody>
              <div className="d-flex mb-4">
                <div className="h4 card-title">Branch Details</div>
                {HelpDocButton(helpDocUrl.branch)}
              </div>
              {isSystemAdmin ? (
                <div className="text-sm-end">
                  <button
                    type="button"
                    onClick={() => {
                      setIsMainBranch(false);
                      setViewFlag(false);
                      setViewBranchData({});
                      setViewOpData([]);
                      tog_addBranch();
                      setResetForm(true);
                      setIsEdit(false);
                    }}
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    Add New Branch
                  </button>
                </div>
              ) : null}
              {isLoading ? (
                <Loader />
              ) : (
                <TableContainer
                  columns={columns}
                  data={branchDetailsforTable}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  //customPageSize={10}
                  isPageSelect={false}
                  customPageSize={pageSize}
                  pageSize={pageSize}
                  pageIndex={currentPage}
                  onPageChange={onPageChange}
                  refresh={true}
                  onRefreshClick={fetchBranch}
                />
              )}
            </CardBody>
          </Card>
          {/* </>
          )} */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Branch);
