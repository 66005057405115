import React, { useState } from "react";
import { Row, Col, Container, Nav, NavItem, NavLink } from "reactstrap";
import ManageUserTab from "./ManageUserTab";
import SystemUserTab from "../Dashboard_Admin/ManageUser/Components/SystemUserTab";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import { helpDocUrl } from "../HelpDocs/HelpDocUrl";

const ManageUser = () => {
  const localStorageKey = "manageUserActiveTab";
  const [activeTab, setActiveTab] = useState(() => {
    return parseInt(localStorage.getItem(localStorageKey)) || 1;
  });

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      localStorage.setItem(localStorageKey, tab);
    }
  };

  const helpDoc = () => {
    if (activeTab === 1) {
      return HelpDocButton(helpDocUrl.customers);
    }
    if (activeTab === 2) {
      return HelpDocButton(helpDocUrl.manageUser);
    }
    return null;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box ">
            <div className="d-flex mb-4">
              <h4 className="mb-sm-0 font-size-18">Manage Users</h4>
              {helpDoc()}
            </div>
          </div>
          <Nav
            fill
            tabs
            style={{
              marginBottom: "10px",
              width: "50%",
            }}
          >
            <NavItem>
              <NavLink
                className={activeTab === 1 ? "active" : ""}
                style={
                  activeTab === 1
                    ? { backgroundColor: "#556ee6", color: "white" }
                    : { borderStyle: "solid", borderColor: "gray" }
                }
                onClick={() => {
                  toggle(1);
                }}
              >
                Customers
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 2 ? "active" : ""}
                style={
                  activeTab === 2
                    ? {
                        backgroundColor: "#556ee6",
                        color: "white",
                      }
                    : { borderStyle: "solid", borderColor: "gray" }
                }
                onClick={() => {
                  toggle(2);
                }}
              >
                System users
              </NavLink>
            </NavItem>
          </Nav>
          <Row>
            <Col>
              {activeTab === 1 && <ManageUserTab />}

              {activeTab === 2 && <SystemUserTab />}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageUser;
