import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import RequiredAsterisk from "../../../../components/Common/RequiredAsterisk";
import { useFormik } from "formik";
import ReactSelect from "react-select";
import SelectStyle from "../../../../common/data/SelectStyle";
import { putApiData } from "../../../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import {
  toastError,
  toastSucess,
} from "../../../../common/data/commonfunctions";
import { useLatestTransactions } from "../../../../common/data/latest-transaction-context";

const BankDetails = (props) => {
  const {
    companyBenId,
    companyId,
    activeTab,
    setActiveTab,
    bank,
    disabled,
    fetchData,
  } = props;
  const { allCurrencies } = useLatestTransactions();
  const accType = [
    { label: "Personal", value: 1 },
    { label: "Business", value: 2 },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      bankName: bank?.bankName || "",
      bankAddress: bank?.bankAddress || "",
      swift: bank?.swift || "",
      accountNumber: bank?.accountNumber || "",
      currencyId:
        allCurrencies.find((opt) => opt.value === bank?.currencyId) || null,
      other: bank?.other || "",
      // isMyAccount: bank?.isMyAccount || null,
      accountType: bank?.accountType || null,
    },
    validationSchema: Yup.object({
      bankName: Yup.string().required("Please Enter the Bank Name"),
      bankAddress: Yup.string().required("Please Enter the Bank Address"),
      swift: Yup.string().required("Please Enter the SWIFT Code"),
      accountType: Yup.string().required("Please Select the Account Type"),
      accountNumber: Yup.string().required("Please Enter the Account Number"),
      currencyId: Yup.object().shape({
        label: Yup.string().required("Please Select a Currency"),
        value: Yup.string().required("Please Select a Currency"),
      }),
    }),
    onSubmit: async (values) => {
      const currencyValue = values.currencyId?.value;
      const combinedValues = {
        ...values,
        currencyId: currencyValue,
        active: 0,
        // isMyAccount: values.isMyAccount === null ? false : values.isMyAccount,
      };
      try {
        setIsLoading(true);
        const updateResponse = await putApiData(
          `api/AgentBeneficiary/UpdateBank?CompanyBenId=${companyBenId}`,
          JSON.stringify(combinedValues)
        );
        setIsLoading(false);

        if (updateResponse?.success) {
          toastSucess("Bank Details Updated");
          setActiveTab(activeTab + 1);
          fetchData(companyId);
        } else {
          toastError(updateResponse?.message);
        }
      } catch (error) {
        toastError("Failed to save bank details.");
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <div>
      <Form
        className="needs-validation"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label htmlFor="currencyId">Currency</Label>
              <RequiredAsterisk />
              <ReactSelect
                name="currencyId"
                id="currencyId"
                placeholder="Select a Currency"
                value={formik.values.currencyId}
                options={allCurrencies}
                styles={SelectStyle}
                isDisabled={disabled}
                onChange={(selectedOption) => {
                  formik.setFieldValue("currencyId", selectedOption);
                }}
                invalid={
                  formik.errors.currencyId && formik.touched.currencyId
                    ? true
                    : false
                }
              />
              {formik.errors.currencyId &&
                formik.touched.currencyId &&
                formik.values.currencyId === null && (
                  <span className="text-danger" style={{ "font-size": "80%" }}>
                    Please Select a Currency
                  </span>
                )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label htmlFor="accountType">Account Type</Label>
              <RequiredAsterisk />
              <Input
                id="accountType"
                name="accountType"
                type="select"
                value={formik.values.accountType}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.errors.accountType && formik.touched.accountType
                    ? true
                    : false
                }
              >
                <option hidden={true} value="">
                  Select Account Type
                </option>
                {accType.map((index) => (
                  <option key={index.value} value={index.value}>
                    {index.label}
                  </option>
                ))}
              </Input>
              {formik.errors.accountType && formik.touched.accountType && (
                <FormFeedback>{formik.errors.accountType}</FormFeedback>
              )}
            </FormGroup>

            {/* <FormGroup className="mb-2" check>
              <Label check className="mb-2">
                <Input
                  type="checkbox"
                  id="isMyAccount"
                  checked={formik.values.isMyAccount}
                  disabled={disabled}
                  {...formik.getFieldProps("isMyAccount")}
                />
                This is my account
              </Label>
            </FormGroup> */}
          </Col>
          <Col lg="6">
            <FormGroup className="mb-3">
              <Label for="bankName">Bank Name</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="bankName"
                placeholder="Bank Name "
                disabled={disabled}
                {...formik.getFieldProps("bankName")}
                invalid={
                  formik.errors.bankName && formik.touched.bankName
                    ? true
                    : false
                }
              />
              <FormFeedback>{formik.errors.bankName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="accountNumber">Account Number</Label>
              <RequiredAsterisk />
              <Input
                id="accountNumber"
                name="accountNumber"
                placeholder="Bank Account Number"
                type="text"
                disabled={disabled}
                {...formik.getFieldProps("accountNumber")}
                invalid={
                  formik.errors.accountNumber && formik.touched.accountNumber
                    ? true
                    : false
                }
              />
              <FormFeedback>{formik.errors.accountNumber}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="swift">SWIFT Code</Label>
              <Input
                id="swift"
                placeholder="Enter the Swift Number"
                type="text"
                disabled={disabled}
                {...formik.getFieldProps("swift")}
                invalid={
                  formik.errors.swift && formik.touched.swift ? true : false
                }
              />
              <FormFeedback>{formik.errors.swift}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="mb-2">
              <Label for="Other">Other</Label>
              <Input
                type="text"
                id="Other"
                placeholder="Any Other Info's"
                {...formik.getFieldProps("other")}
                disabled={disabled}
                invalid={
                  formik.errors.other && formik.touched.other ? true : false
                }
              />
              <FormFeedback>{formik.errors.other}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup className="mb-3">
              <Label for="bankAddress">Bank Address</Label>
              <RequiredAsterisk />
              <Input
                type="textarea"
                id="bankAddress"
                placeholder="Enter the Bank Address"
                disabled={disabled}
                {...formik.getFieldProps("bankAddress")}
                invalid={
                  formik.errors.bankAddress && formik.touched.bankAddress
                    ? true
                    : false
                }
              />
              <FormFeedback>{formik.errors.bankAddress}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <div>
        {!disabled && (
          <div>
            <Button
              color="secondary"
              className="me-1"
              onClick={() => {
                formik.setFieldValue("status", 0);
                formik.handleSubmit();
              }}
            >
              Draft
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (bank === null) {
                  formik.setFieldValue("status", 1);
                }
                formik.handleSubmit();
              }}
              disabled={formik.isSubmitting}
            >
              {bank === null ? "Add Bank Details" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(BankDetails);
