import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faCopy,
  faDownload,
  faEye,
  faFileExcel,
  faFilePdf,
  faPenToSquare,
  faPlus,
  faTrash,
  faUserCheck,
  faX,
  faPrint,
  faBellSlash,
  faBell,
  faRotate,
  faSyncAlt,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const activate = () => {
  return <FontAwesomeIcon icon={faCheck} />;
};

export const deactivate = () => {
  return <FontAwesomeIcon icon={faX} />;
};

export const edit = () => {
  return <FontAwesomeIcon icon={faPenToSquare} />;
};

export const approve = () => {
  return <FontAwesomeIcon icon={faUserCheck} />;
};

export const trash = () => {
  return <FontAwesomeIcon icon={faTrash} />;
};

export const copy = () => {
  return <FontAwesomeIcon icon={faCopy} />;
};

export const download = () => {
  return <FontAwesomeIcon icon={faDownload} />;
};

export const view = () => {
  return <FontAwesomeIcon icon={faEye} />;
};

export const plus = () => {
  return <FontAwesomeIcon icon={faPlus} />;
};

export const left = () => {
  return <FontAwesomeIcon icon={faArrowLeft} className="fa-lg" />;
};

export const right = () => {
  return <FontAwesomeIcon icon={faArrowRight} className="fa-lg" />;
};

export const pdf = () => {
  return <FontAwesomeIcon icon={faFilePdf} color="red" />;
};

export const excel = () => {
  return <FontAwesomeIcon icon={faFileExcel} color="green" />;
};

export const emptyNotification = () => {
  return (
    <FontAwesomeIcon
      icon={faBellSlash}
      size="2xl"
      className="mt-4"
      color="#FF5252"
    />
  );
};

export const notificationIcon = () => {
  return <FontAwesomeIcon icon={faBell} size="lg" />;
};

export const loading = () => {
  return (
    <FontAwesomeIcon icon={faRotate} className="cursor-pointer me-3" spin />
  );
};

export const sync = (func) => {
  return (
    <FontAwesomeIcon
      icon={faSyncAlt}
      className="cursor-pointer me-3"
      onClick={() => func()}
    />
  );
};

export const print = () => {
  return <FontAwesomeIcon icon={faPrint} color="green" />;
};

export const magnifyingGlass = () => {
  return <FontAwesomeIcon icon={faMagnifyingGlass} />;
};

export const fileIcon = () => {
  return <FontAwesomeIcon icon={faFilePdf} size="2xl" />;
};

export const close = () => {
  return <FontAwesomeIcon icon={faX} />;
};
