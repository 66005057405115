import React, { useMemo, useState, useEffect } from "react";
import { Button, Card, CardBody, Container } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import AddStock from "./AddStock";
import { useLatestTransactions } from "./../../common/data/latest-transaction-context";
import { getApiData } from "../../helpers/axiosHelper";
import { useCurrentPage } from "../../common/data/CustomPagination";
import { displayFlag, getCurrencyFlag } from "../../common/data/currency";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import { helpDocUrl } from "../HelpDocs/HelpDocUrl";
import { handleCommaSeperator } from "../../common/data/transactionType";

const Stock = () => {
  document.title = "Manage Stock | Crescent Exchange";
  const { allCurrencies } = useLatestTransactions();
  const [state, setState] = useState({
    modal: false,
    paymentType: null,
    stockDetails: [],
    isLoading: false,
  });

  const paymentOptions = [
    { label: "Deposit", value: 1, width: "88px", color: "success" },
    { label: "Withdraw", value: 1, width: null, color: "danger" },
  ];

  const {
    saveCurrentPage,
    restoreCurrentPage,
    onPageChange,
    pageSize,
    currentPage,
  } = useCurrentPage();

  const { branchId } = useLatestTransactions();
  const toggle = () =>
    setState((prevState) => ({ ...prevState, modal: !prevState.modal }));

  const getStockByBranchId = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await getApiData(
        `api/Stock/GetStockByBranchId?branchId=${branchId}`
      );
      setState((prevState) => ({ ...prevState, isLoading: false }));

      const mappedResponse = response?.data?.map((item, key) => ({
        index: key + 1,
        accountsId: item.accountsId,
        allocatedAmount: item.allocatedAmount,
        amount: item.amount,
        availableAmount: item.availableAmount,
        branchName: item.branchName,
        branchId: item.branchId,
        movingAverage: item.movingAverage,
        convertedStockAmount: item.convertedStockAmount,
        currencyCode: item.currencyCode,
        currencyFlag: getCurrencyFlag(allCurrencies, item.currencyCode),
      }));
      setState((prevState) => ({
        ...prevState,
        stockDetails: mappedResponse || [],
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Currency",
        accessor: "currencyCode",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { currencyFlag } = cellProps.row.original;

          return (
            <>
              {displayFlag(currencyFlag)}
              {cellProps.value ?? ""}
            </>
          );
        },
      },
      {
        Header: "Currency Amount",
        accessor: "amount",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return handleCommaSeperator(cellProps.value) ?? "0";
        },
      },
      {
        Header: "Booked Amount",
        accessor: "allocatedAmount",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return handleCommaSeperator(cellProps.value) ?? "0";
        },
      },
      {
        Header: "Available Amount",
        accessor: "availableAmount",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return handleCommaSeperator(cellProps.value) ?? "0";
        },
      },
      {
        Header: "Moving Average",
        accessor: "movingAverage",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "0";
        },
      },
      {
        Header: "Converted Stock Amount (SGD)",
        accessor: "convertedStockAmount",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return handleCommaSeperator(cellProps.value) ?? "0";
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (branchId !== null) {
      const intervalId = setInterval(getStockByBranchId, 5000);
      getStockByBranchId();
      return () => clearInterval(intervalId);
    }
  }, [branchId]);

  return (
    <React.Fragment>
      <AddStock
        toggle={toggle}
        isOpen={state.modal}
        paymentType={state.paymentType}
        getStockByBranchId={getStockByBranchId}
        stockDetails={state.stockDetails}
      />
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div className="d-flex mb-4">
                <div className="mb-4 h4 card-title">Stock</div>
                {HelpDocButton(helpDocUrl.stock)}
              </div>
              <div className="mb-2">
                {paymentOptions.map((x) => (
                  <Button
                    type="button"
                    onClick={() => {
                      toggle();
                      setState((prevState) => ({
                        ...prevState,
                        paymentType: x.value,
                      }));
                    }}
                    className="me-2 mb-1"
                    style={{ width: x.width }}
                    color={x.color}
                  >
                    {x.label}
                  </Button>
                ))}
              </div>
              {state.stockDetails?.length === 0 ? (
                <div className="text-sm-center fs-5">No Stocks Found</div>
              ) : (
                <TableContainer
                  data={state.stockDetails || []}
                  isGlobalFilter={true}
                  columns={columns}
                  isAddOptions={false}
                  isPageSelect={false}
                  customPageSize={state.stockDetails?.length}
                  pageSize={state.stockDetails?.length}
                  pageIndex={currentPage}
                  onPageChange={onPageChange}
                  refresh={true}
                  onRefreshClick={() => getStockByBranchId(branchId)}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Stock;
