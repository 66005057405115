import axios from "axios";
import config from "../../config";
import { setupCache, buildWebStorage } from "axios-cache-interceptor"; //Cache import
function initializeHeader() {
  var authUser = localStorage.getItem("authUser");
  var authObj = JSON.parse(authUser);
  axios.defaults.baseURL = config.apiUrl;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = "Bearer " + authObj?.token;
}


let cachedAxios = null;

function initializeCache() {
    var authUser = localStorage.getItem("authUser");
    var authObj = JSON.parse(authUser);

    // Set up the cachedAxios instance
    cachedAxios = setupCache(axios.create({
        baseURL: config.apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authObj?.token}`,
        },
    }), {
        storage: buildWebStorage(sessionStorage), // Use sessionStorage here
        ttl: 3 * 60 * 1000, // Cache TTL: 3 minutes
        exclude: {
            methods: ["post", "delete"], // Exclude POST and DELETE requests
        },
        debug: true, // Enable debug logs
    });
}

export const getApiData = async (endpoint, params = {}, useCache = false) => {
  try {
      initializeHeader();
      initializeCache();
      const axiosInstance = useCache ? cachedAxios : axios;
      const response = await axiosInstance.get(endpoint, {
          params,
      });
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export const postApiData = async (endpoint, data = {}) => {
  try {
    initializeHeader();
    const response = await axios.post(axios.defaults.baseURL + endpoint, data);
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export const putApiData = async (endpoint, data = {}) => {
  try {
    initializeHeader();
    const response = await axios.put(axios.defaults.baseURL + endpoint, data);
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteApiData = async (endpoint, data = {}) => {
  try {
    initializeHeader();
    const response = await axios.delete(
      axios.defaults.baseURL + endpoint,
      data
    );
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export default axios;
