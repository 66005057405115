import React, { useState, useMemo, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import EditDraftRateSheetToggle from "./EditDraftRateSheet";
import ViewRateSheetToggle from "./ViewRateSheet";
import AddRateSheetToggle from "./AddRateSheet";
import { getApiData, putApiData } from "../../helpers/axiosHelper";
import TableContainer from "../../components/Common/TableContainer";
import { useLatestTransactions } from "./../../common/data/latest-transaction-context";
import Loader from "../../components/Common/Loader";
import { edit, trash, view, copy } from "../../common/data/icons";
import { formatDateToUserTimeZone } from "../../common/data/date";
import { useBranch } from "../../common/data/commonfunctions";
import { useCurrentPage } from "../../common/data/CustomPagination";
import { getCurrencyFlag } from "../../common/data/currency";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import { helpDocUrl } from "../HelpDocs/HelpDocUrl";
import RefreshButton from "../../common/data/RefreshButton";

const RateSheetTable = (props) => {
  const { allCurrencies } = useLatestTransactions();
  const [addRateSheetModal, setAddRateSheetModal] = useState(false);
  const [viewRateSheetModal, setViewRateSheetModal] = useState(false);
  const [editDraftRateSheetModal, setEditDraftRateSheetModal] = useState(false);
  const [rateSheetData, setRateSheetData] = useState([]);
  const [rateMasterData, setRateMasterData] = useState([]);
  const [sendRateSheet, setSendRateSheet] = useState([]);
  const [rateSheetID, setRateSheetID] = useState("");
  const { branchId } = useLatestTransactions();
  const [isLoading, setIsLoading] = useState(false);
  const [showBranchSelect, setShowBranchSelect] = useState(false);
  const [viewCopy, setViewCopy] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const rateSheetButton = [
    {
      label: "Buy / Sell",
      value: 1,
      onClick: () => {
        handleAddSheet(false, false);
      },
    },
    {
      label: "Global Transfer",
      value: 2,
      onClick: () => {
        handleAddSheet(true, true);
      },
    },
  ];
  const {
    saveCurrentPage,
    restoreCurrentPage,
    onPageChange,
    pageSize,
    currentPage,
  } = useCurrentPage();
  //Toggle methods for Modal
  const addRateSheetModalToggle = () =>
    setAddRateSheetModal(!addRateSheetModal);

  const viewRateSheetModalToggle = () => {
    setViewRateSheetModal(!viewRateSheetModal);
  };

  const EditDraftRateSheetModalToggle = () => {
    setEditDraftRateSheetModal(!editDraftRateSheetModal);
  };

  const viewRateSheet = async (cellProps, name, isTransfer = false) => {
    const { rateSheetId } = cellProps;
    setRateSheetID(rateSheetId);
    const response = await getApiData(
      `api/RateHistory/GetRateHistorySheet?RateSheetId=${rateSheetId}`
    );

    const rateTypeNames = {
      1: "Buying",
      2: "Selling",
      3: "Transfer",
    };

    const groupedRateHistories = response?.reduce((acc, item) => {
      const currencyIndex = acc.findIndex(
        (data) => data.currencyCode === item.currencyCode
      );

      const rateItem = {
        rateHistoryId: item.rateHistoryId,
        rateSheetId: item.rateSheetId,
        branchId: item.branchId,
        rateType: rateTypeNames[item.rateType],
        currencyCode: item.currencyCode,
        currencyName: item.currencyName,
        value: item.value,
        wireTransferFee: item.wireTransferFee,
        agentCharges: item.agentCharges,
        commissionCharges: item.commissionCharges,
        worldRate: item.worldRate,
        branchName: item.branchName,
        currencyFlag: getCurrencyFlag(allCurrencies, item.currencyCode),
      };
      if (currencyIndex > -1) {
        acc[currencyIndex].rateData.push(rateItem);
      } else {
        acc.push({
          currencyName: item.currencyName,
          currencyCode: item.currencyCode,
          rateData: [rateItem],
          currencyFlag: getCurrencyFlag(allCurrencies, item.currencyCode),
        });
      }
      return acc;
    }, []);

    let mergedData;
    if (name !== "viewSheet") {
      const activeRateMasterResponse = await getApiData(
        "api/RateMaster/GetAllActiveRateMaster"
      );

      const activeRateMasterData = activeRateMasterResponse?.reduce(
        (acc, item) => {
          const currencyIndex = acc.findIndex(
            (data) => data.currencyCode === item.currencyCode
          );
          const rateItem = {
            rateType: rateTypeNames[item.rateType],
            value: "",
            agentCharges: "",
            worldRate: "",
            wireTransferFee: "",
            commissionCharges: "",
          };

          if (currencyIndex > -1) {
            acc[currencyIndex].rateData.push(rateItem);
          } else {
            acc.push({
              currencyName: item.currencyName,
              currencyCode: item.currencyCode,
              rateData: [rateItem],
              currencyFlag: getCurrencyFlag(allCurrencies, item.currencyCode),
            });
          }
          return acc;
        },
        []
      );

      mergedData = activeRateMasterData?.map((masterCurrency) => {
        const rateHistoryData = groupedRateHistories.find(
          (historyCurrency) =>
            historyCurrency.currencyCode === masterCurrency.currencyCode
        );

        //   return {
        //     ...masterCurrency,
        //     rateData: rateHistoryData
        //       ? rateHistoryData.rateData
        //       : masterCurrency.rateData,
        //   };
        // });

        return {
          ...masterCurrency,
          rateData: masterCurrency.rateData?.map((masterRate) => {
            const historicalRate = rateHistoryData?.rateData.find(
              (rate) => rate.rateType === masterRate.rateType
            );

            return {
              rateType: masterRate.rateType,
              branchId: historicalRate?.branchId || masterRate.branchId || 0,
              rateSheetId:
                historicalRate?.rateSheetId ||
                masterRate.rateSheetId ||
                rateSheetId,
              rateHistoryId:
                historicalRate?.rateHistoryId || masterRate.rateHistoryId,
              value: historicalRate?.value || masterRate.value,
              wireTransferFee:
                historicalRate?.wireTransferFee || masterRate.wireTransferFee,
              agentCharges:
                historicalRate?.agentCharges || masterRate.agentCharges,
              commissionCharges:
                historicalRate?.commissionCharges ||
                masterRate.commissionCharges,
              worldRate: historicalRate?.worldRate || masterRate.worldRate,
            };
          }),
        };
      });
    } else {
      mergedData = groupedRateHistories;
    }

    const finalFilteredData = isTransfer
      ? mergedData.map((data) => {
          const filteredRateData = data.rateData.filter(
            (item) => item.rateType === "Transfer"
          );

          return filteredRateData?.length > 0
            ? {
                ...data,
                rateData: filteredRateData,
              }
            : null;
        })
      : mergedData.map((data) => {
          const filteredRateData = data.rateData.filter(
            (item) => item.rateType !== "Transfer"
          );

          return filteredRateData?.length > 0
            ? {
                ...data,
                rateData: filteredRateData,
              }
            : null;
        });

    const filteredData = finalFilteredData.filter((data) => data !== null);
    setSendRateSheet(filteredData || []);
    if (name === "editDraft") {
      setShowBranchSelect(false);
      EditDraftRateSheetModalToggle();
    } else if (name === "viewSheet") {
      viewRateSheetModalToggle();
    } else if (name === "copy") {
      setViewCopy(true);
      addRateSheetModalToggle();
    }
  };

  const elapseCurrentRateSheet = async (cellProps) => {
    const { rateSheetId } = cellProps;
    Swal.fire({
      title: "Elapse",
      text: "Are you sure to elapse the rate sheet?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes, Elapse it",
      confirmButtonColor: "#f46a6a",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          const response = await putApiData(
            `api/RateMaster/UpdateRateSheetStatusToElapsed?RateSheetID=${rateSheetId}`
          );
          setIsLoading(false);
          if (response?.success) {
            toast.success("Rate Sheet has set to elapsed", {
              position: "top-right",
              autoClose: 1500,
            });
            rate(response?.data?.branchID);
          } else {
            toast.error(response?.message, {
              position: "top-right",
              autoClose: 3000,
            });
          }
        } catch (error) {
          toast.error(error, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      }
    });
  };

  const handleAddSheet = (isTransfer, isRateMasterSheet) => {
    setViewCopy(false);
    setIsTransfer(isTransfer);
    addRateSheetModalToggle();
    rateMasterForSheet(isRateMasterSheet);
  };
  const rate = async (branch) => {
    try {
      setIsLoading(true);
      const response = await getApiData(
        `api/RateMaster/GetAllRateSheetsForBranchNotElapsed?branchId=${
          branch || branchId
        }`
      );
      setIsLoading(false);
      const status = {
        1: "Active",
        2: "Draft",
        3: "Elapsed",
      };

      const mappedResponse = response?.map((item, index) => ({
        index: index + 1,
        rateSheetId: item.rateSheetId,
        branchId: item.branchId,
        branchName: item.branchName,
        startTime: formatDateToUserTimeZone(item.startTime),
        endTime: item.endTime,
        status: status[item.status],
        allBranch: item.allBranch,
        isTransfer: item.isTransfer,
      }));

      setRateSheetData(mappedResponse);
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const rateMasterForSheet = async (isTransfer = false) => {
    setIsLoading(true);
    const response = await getApiData(`api/RateMaster/GetAllActiveRateMaster`);
    setIsLoading(false);
    const rateTypeNames = {
      1: "Buying",
      2: "Selling",
      3: "Transfer",
    };
    const groupedData = response?.reduce((acc, item) => {
      const currencyIndex = acc.findIndex(
        (data) => data.currencyCode === item.currencyCode
      );

      const rateItem = {
        rateType: rateTypeNames[item.rateType],
        value: "",
        agentCharges: "",
        worldRate: "",
        wireTransferFee: "",
        commissionCharges: "",
      };

      if (currencyIndex > -1) {
        acc[currencyIndex].rateData.push(rateItem);
      } else {
        acc.push({
          currencyName: item.currencyName,
          currencyCode: item.currencyCode,
          rateData: [rateItem],
          currencyFlag: getCurrencyFlag(allCurrencies, item.currencyCode),
        });
      }
      return acc;
    }, []);

    const filteredData = isTransfer
      ? groupedData
          .map((data) => {
            const filteredRateData = data.rateData.filter(
              (item) => item.rateType === "Transfer"
            );
            return filteredRateData?.length > 0
              ? { ...data, rateData: filteredRateData }
              : null;
          })
          .filter((data) => data !== null)
      : groupedData
          .map((data) => {
            const filteredRateData = data.rateData.filter(
              (item) => item.rateType !== "Transfer"
            );
            return filteredRateData?.length > 0
              ? { ...data, rateData: filteredRateData }
              : null;
          })
          .filter((data) => data !== null);

    setRateMasterData(filteredData || []);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Start time",
        accessor: "startTime",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { value, row } = cellProps;
          const { allBranch } = row.original;
          const { isTransfer } = row.original;

          return (
            <>
              {value ? value : ""}
              {allBranch == 1 && !isTransfer && (
                <Badge className="font-size-11 ms-1" color="success">
                  All
                </Badge>
              )}
              {isTransfer && (
                <Badge className="font-size-11 ms-1" color="info">
                  Global Transfer
                </Badge>
              )}
            </>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        show: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                (cellProps.value === "Active"
                  ? "success"
                  : "danger" && cellProps.value === "Draft"
                  ? "primary"
                  : "danger")
              }
            >
              {cellProps.value}
            </Badge>
          );
        },
      },

      {
        Header: "Actions",
        disableFilters: true,
        accessor: "actions",
        Cell: (cellProps) => {
          const { status } = cellProps.row.original;
          const transfer = cellProps.row.original?.isTransfer;
          return (
            <>
              <Button
                type="button"
                color="primary"
                name="viewSheet"
                title="View"
                className="btn-sm btn-rounded"
                style={{ marginRight: "5px" }}
                onClick={(e) => {
                  viewRateSheet(cellProps.row.original, "viewSheet", transfer);
                  setIsTransfer(transfer);
                }}
              >
                {view()}
              </Button>
              {status === "Active" || status === "Draft" ? (
                <Button
                  type="button"
                  name="elapse"
                  color="danger"
                  title="Elapse"
                  className="btn-sm btn-rounded"
                  style={{
                    marginRight: "5px",
                  }}
                  onClick={() => elapseCurrentRateSheet(cellProps.row.original)}
                >
                  {trash()}
                </Button>
              ) : null}
              {status === "Draft" && (
                <Button
                  title="Edit Draft"
                  name="editDraft"
                  type="button"
                  color="success"
                  className="btn-sm btn-rounded"
                  style={{ marginRight: "5px" }}
                  onClick={(e) => {
                    viewRateSheet(
                      cellProps.row.original,
                      "editDraft",
                      transfer
                    );
                    setIsTransfer(transfer);
                  }}
                >
                  {edit()}
                </Button>
              )}
              {status === "Active" && (
                <Button
                  type="button"
                  color="secondary"
                  className="btn-sm btn-rounded"
                  onClick={(e) => {
                    viewRateSheet(cellProps.row.original, "copy", transfer);
                    setIsTransfer(transfer);
                    setViewCopy(true);
                  }}
                >
                  {copy()}
                </Button>
              )}
            </>
          );
        },
      },
    ],
    []
  );
  useEffect(() => {
    if (branchId !== null) {
      rate(branchId);
    }
  }, [branchId]);

  useEffect(() => {
    if (viewCopy && sendRateSheet) {
      setRateMasterData(sendRateSheet);
    }
  }, [viewCopy, sendRateSheet]);

  return (
    <React.Fragment>
      <AddRateSheetToggle
        isOpen={addRateSheetModal}
        toggle={addRateSheetModalToggle}
        rateSheet={rate}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        sendRateSheet={sendRateSheet}
        copy={viewCopy}
        rateMasterData={rateMasterData}
        setRateMasterData={setRateMasterData}
        rateMasterForSheet={rateMasterForSheet}
        isTransfer={isTransfer}
      />
      <ViewRateSheetToggle
        sendRateSheet={sendRateSheet}
        isOpen={viewRateSheetModal}
        toggle={viewRateSheetModalToggle}
        rateSheetID={rateSheetID}
        rateSheet={rate}
        branchId={branchId}
        isTransfer={isTransfer}
      />
      <EditDraftRateSheetToggle
        sendRateSheet={sendRateSheet}
        rateMasterData={rateSheetData}
        isOpen={editDraftRateSheetModal}
        toggle={EditDraftRateSheetModalToggle}
        rateSheetID={rateSheetID}
        rateSheet={rate}
        showBranchSelect={showBranchSelect}
        branchId={branchId}
        isTransfer={isTransfer}
      />
      <Card>
        <CardBody>
          <div className="d-flex mb-4">
            <div className="mb-4 h4 card-title">
              Rate Sheet{" "}
              {rateSheetData?.length &&
              rateSheetData.find((x) => x.branchId !== null)
                ? " - " +
                  rateSheetData.find((x) => x.branchId !== null)?.branchName
                : ""}
            </div>
            {HelpDocButton(helpDocUrl.rateSheet)}
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="ms-auto">
              <DropdownToggle color="primary">Add New Sheet</DropdownToggle>
              <DropdownMenu>
                {rateSheetButton.map((x) => (
                  <DropdownItem key={x.value} onClick={x.onClick}>
                    {x.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>

          {isLoading ? (
            <Loader />
          ) : (
            <>
              {rateSheetData?.length > 0 ? (
                <TableContainer
                  columns={columns}
                  data={rateSheetData}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  isPageSelect={false}
                  customPageSize={pageSize}
                  pageSize={pageSize}
                  pageIndex={currentPage}
                  onPageChange={onPageChange}
                  refresh={true}
                  onRefreshClick={() => rate(branchId)}
                />
              ) : (
                <div className="text-sm-center fs-5">No Rate Sheet Found</div>
              )}
            </>
          )}
        </CardBody>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
};

export default RateSheetTable;
